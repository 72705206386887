import { pick } from 'lodash';
import _delete from './delete.graphql';
import create from './create.graphql';
import getList from './getList.graphql';
import getMany from './getMany.graphql';
import getOne from './getOne.graphql';
import update from './update.graphql';

const dataToInput = ({ data }) => ({
    ...pick(data, ['id', 'name', 'trafficRate', 'finalizedAt']),
    insuranceProductId: data.insuranceProduct.id,
    decisionModels: (data.decisionModels || []).map((dm) => ({
        type: dm.type,
        modelId: dm.model.id,
    })),
    scoreModels: (data.scoreModels || []).map((sm) => ({
        type: sm.type,
        modelId: sm.model.id,
    })),
    externalDataModels: (data.externalDataModels || []).map((edm) => ({
        modelId: edm.model.id,
    })),
    questionnaires: (data.questionnaires || []).map((q) => ({
        slug: q.slug,
        questionnaireId: q.questionnaire.id,
    })),
});

const insuranceProductVersionQueries = {
    create,
    dataToInput,
    delete: _delete,
    getList,
    getMany,
    getOne,
    update,
};

export default insuranceProductVersionQueries;
