import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Field, useForm } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';

import { get } from 'lodash';
import { TextInput } from '../../../../forms';

const ConstantConfiguration = ({ disabled }) => {
    const form = useForm();

    return (
        <Grid container direction="column" spacing={1}>
            <Grid item>
                <OnChange name="node.configuration.value">
                    {(value) => {
                        if (!value) {
                            return;
                        }

                        const currentName = get(
                            form.getState(),
                            'values.node.name',
                        );

                        if (
                            !currentName ||
                            currentName.startsWith('Constant')
                        ) {
                            form.change('node.name', `Constant: ${value}`);
                        }
                    }}
                </OnChange>
                <Field
                    component={TextInput}
                    disabled={disabled}
                    name="node.configuration.value"
                    parse={(v) => v && parseFloat(v)}
                    label="Value"
                    required
                    autoFocus
                    type="number"
                    variant="outlined"
                />
            </Grid>
        </Grid>
    );
};

ConstantConfiguration.propTypes = {
    disabled: PropTypes.bool,
};

ConstantConfiguration.defaultProps = {
    disabled: false,
};

export default ConstantConfiguration;
