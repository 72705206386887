import type { FunctionComponent } from 'react';
import classnames from 'classnames';
import { Grid, makeStyles } from '@material-ui/core';
import { BooleanInput, ReferenceInput, SelectInput } from 'ra-ui-materialui';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(2),
    },
}));

type Props = {
    className?: string;
    record?: Record<string, string>;
    source?: string;
};

const DatasourceConfigurationRow: FunctionComponent<Props> = ({
    className = '',
    record,
    source,
    ...otherProps
}) => {
    const classes = useStyles();

    return (
        <Grid
            item
            container
            xs={12}
            spacing={4}
            alignItems="center"
            className={classnames(classes.root, className)}
            {...otherProps}
        >
            <Grid item xs={4}>
                <ReferenceInput
                    label="Datasource"
                    record={record}
                    source={`${source}.datasource.id`}
                    reference="Datasource"
                    fullWidth
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>
            </Grid>
            <Grid item xs={4}>
                <BooleanInput
                    record={record}
                    source={`${source}.sandboxDevelopmentMode`}
                    label="Development Mode on Sandbox"
                    initialValue={true}
                />
            </Grid>
        </Grid>
    );
};

export default DatasourceConfigurationRow;
