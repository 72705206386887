import validator from './validate';

const multiplicationProcessor = {
    name: 'Multiplication',
    slug: 'multiplication',
    description: 'Return the product of the given inputs',
    validate: validator,
    inputs: {
        id: 'inputs',
        dynamic: true,
        min: 2,
        schema: { type: 'number' },
    },
    outputs: [
        {
            id: 'output',
            name: 'Output',
            schema: { type: 'number' },
        },
    ],
};

export default multiplicationProcessor;
