import PropTypes from 'prop-types';

import { TextInput, PasswordInput, SelectInput } from 'react-admin';
import { CustomToolbar } from '../forms/CustomToolbar';
import { checkRequiredFieldsFactory } from '../forms/validation';
import OrganizationReferenceInput from '../organizations/OrganizationReferenceInput';

import SimpleForm from '../controllers/SimpleForm';
import EXTERNAL_DATA_PROVIDER from './providers';

export const validateExternalDataProviderCredentialsForm = (values) => {
    const errors = {
        ...checkRequiredFieldsFactory([
            'organizationId',
            'providerSlug',
            'name',
        ])(values),
    };

    if (!values.id && !values.value) {
        errors.value = 'Required field';
    }

    return errors;
};

const externalDataProviderOptions = Object.keys(EXTERNAL_DATA_PROVIDER).map(
    (id) => ({ id, name: EXTERNAL_DATA_PROVIDER[id] }),
);

const ExternalDataProviderCredentialsForm = ({
    valueHelperText,
    valueRequired,
    providerDisabled,
    ...props
}) => {
    return (
        <SimpleForm
            toolbar={
                <CustomToolbar
                    deleteConfirmTitle={(record) => `${record.name} removal`}
                />
            }
            validate={validateExternalDataProviderCredentialsForm}
            {...props}
        >
            <OrganizationReferenceInput
                label="Organization"
                source="organizationId"
                disabled
            />
            <SelectInput
                label="Provider"
                source="providerSlug"
                choices={externalDataProviderOptions}
                required
                disabled={providerDisabled}
            />
            <TextInput source="name" required fullWidth />
            <PasswordInput
                source="value"
                required={valueRequired}
                helperText={valueHelperText}
                fullWidth
            />
        </SimpleForm>
    );
};

ExternalDataProviderCredentialsForm.propTypes = {
    valueHelperText: PropTypes.string,
    valueRequired: PropTypes.bool,
    providerDisabled: PropTypes.bool,
    redirect: PropTypes.func,
    toolbar: PropTypes.node,
};

ExternalDataProviderCredentialsForm.defaultProps = {
    valueHelperText: 'The value will be encrypted and will never be returned',
    valueRequired: true,
    providerDisabled: false,
    redirect: undefined,
    toolbar: undefined,
};

export default ExternalDataProviderCredentialsForm;
