import { get, isEqual, omit, pick } from 'lodash';
import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

import { Typography, Tab } from '@material-ui/core';
import getOutputType from '@tint/core/src/processors/outputValidation/getOutputType';

import UnknownAttributeError from '@tint/core/src/processors/outputValidation/UnknownAttributeError';
import { blueprintPropType } from '../../../blueprintEditorPropTypes';

import DimensionAccordion from './DimensionAccordion';
import RatesTable from './RatesTable';
import { CONFIGURATION_TYPE_PER_INPUT_TYPE } from '../categorization/CategorizationForm';

export const getConfigurationTabs = () => [
    <Tab key="dimensions" label="Dimensions" />,
    <Tab key="rates" label="Rates" />,
];

const getDimensionPossibleValues = (dimension) =>
    [
        ...get(dimension, 'conditions', []).map((c) => c.label),
        get(dimension, 'catchAll'),
    ].filter((x) => x !== undefined);

// @see https://www.adrianhorning.com/an-understandable-caresian-product-of-multiple-arrays/
const getAllCombinationsFromDimensions = (dimensions) => {
    if (!dimensions.length) {
        return [];
    }

    const valuesPerDimension = dimensions.map(getDimensionPossibleValues);

    let combinations = [[]];

    for (
        let dimensionIndex = 0;
        dimensionIndex < valuesPerDimension.length;
        dimensionIndex++
    ) {
        const dimensionValues = valuesPerDimension[dimensionIndex];
        const temp = [];

        for (
            let combinationIndex = 0;
            combinationIndex < combinations.length;
            combinationIndex++
        ) {
            for (
                let valueIndex = 0;
                valueIndex < dimensionValues.length;
                valueIndex++
            ) {
                temp.push(
                    combinations[combinationIndex].concat(
                        dimensionValues[valueIndex],
                    ),
                );
            }
        }

        combinations = temp;
    }

    return combinations;
};

const RatingTableConfiguration = ({
    nodeId,
    blueprint,
    insuranceProduct,
    disabled,
    tab,
    maximumRatesNumber,
}) => {
    const inputEdges = blueprint.edges.filter(
        (edge) => edge.end.node === nodeId,
    );

    const inputNodes = inputEdges.map((edge) => ({
        ...blueprint.nodes[edge.start.nodes],
        id: edge.start.node,
    }));

    const form = useForm();
    const { values } = form.getState();
    const dimensions = get(values, 'node.configuration.dimensions', []);
    const combinations = useMemo(
        () => getAllCombinationsFromDimensions(dimensions),
        [dimensions],
    );

    useEffect(() => {
        if (!disabled && inputNodes.length > 0) {
            const expectedDimensions = inputEdges.map((edge) => {
                let inputType = null;

                try {
                    inputType = getOutputType(
                        blueprint,
                        insuranceProduct.validationSchema,
                        edge.start.node,
                        edge.start.pin,
                    );
                } catch (error) {
                    if (!(error instanceof UnknownAttributeError)) {
                        throw error;
                    }
                }

                return {
                    input: edge.start.node,
                    type: inputType
                        ? CONFIGURATION_TYPE_PER_INPUT_TYPE[inputType]
                        : null,
                };
            });

            const currentDimensions = get(
                values,
                'node.configuration.dimensions',
                [],
            ).map((dimension) => pick(dimension, ['input', 'type']));

            if (!isEqual(expectedDimensions, currentDimensions)) {
                form.change(
                    'node.configuration.dimensions',
                    expectedDimensions,
                );
            }
        }
    }, [inputNodes]);

    useEffect(() => {
        const expectedRates = combinations.map((combination) =>
            combination.reduce((acc, value, i) => {
                acc[dimensions[i].input] = value;
                return acc;
            }, {}),
        );

        const currentRates = get(values, 'node.configuration.rates', []).map(
            (rate) => omit(rate, ['value']),
        );

        if (expectedRates.length !== currentRates.length) {
            form.change(
                'node.configuration.rates',
                Array.from({ length: expectedRates.length }),
            );
        }
    }, [combinations]);

    if (inputNodes.length === 0) {
        return (
            <Typography variant="body1">
                Please connect the Rating Table input in order to configure it
            </Typography>
        );
    }

    // Dimensions
    if (tab === 0) {
        return (
            <FieldArray name="node.configuration.dimensions">
                {({ fields }) =>
                    fields.map((field) => (
                        <DimensionAccordion
                            key={field}
                            field={field}
                            disabled={disabled}
                            blueprint={blueprint}
                            dimension={get(values, field)}
                            maximumRatesNumber={maximumRatesNumber}
                        />
                    ))
                }
            </FieldArray>
        );
    }

    // Rates
    if (tab === 1) {
        return (
            <RatesTable
                blueprint={blueprint}
                node={values.node}
                combinations={combinations}
                disabled={disabled}
                maximumRatesNumber={maximumRatesNumber}
            />
        );
    }

    return null;
};

RatingTableConfiguration.propTypes = {
    nodeId: PropTypes.string.isRequired,
    blueprint: PropTypes.shape(blueprintPropType).isRequired,
    insuranceProduct: PropTypes.shape({
        id: PropTypes.string.isRequired,
        validationSchema: PropTypes.object.isRequired,
    }).isRequired,
    disabled: PropTypes.bool,
    tab: PropTypes.number.isRequired,
    maximumRatesNumber: PropTypes.number,
};

RatingTableConfiguration.defaultProps = {
    disabled: false,
    maximumRatesNumber: 1000,
};

export default RatingTableConfiguration;
