import { SimpleForm as RaSimpleForm } from 'react-admin';

const SimpleForm = ({ children, ...props }) => (
    <RaSimpleForm sanitizeEmptyValues={false} {...props}>
        {children}
    </RaSimpleForm>
);

SimpleForm.propTypes = RaSimpleForm.propTypes;

export default SimpleForm;
