import { differenceBy, intersectionBy } from 'lodash';

const classifyDatasourceConfigurations = (
    currentDatasourceConfigurations,
    newDatasourceConfigurations,
) => {
    return {
        toCreate: differenceBy(
            newDatasourceConfigurations,
            currentDatasourceConfigurations,
            'id',
        ),
        toUpdate: intersectionBy(
            newDatasourceConfigurations,
            currentDatasourceConfigurations,
            'id',
        ),
        toDelete: differenceBy(
            currentDatasourceConfigurations,
            newDatasourceConfigurations,
            'id',
        ),
    };
};

export default classifyDatasourceConfigurations;
