import classnames from 'classnames';
import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Grid, makeStyles, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ExpandMore } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.grays[500],
        fontSize: '0.75rem',
    },
    link: {
        color: theme.palette.grays[500],
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    separator: {
        transform: 'rotate(-90deg)',
        fontSize: '0.75rem',
        position: 'relative',
        top: 2,
    },
}));

const Breadcrumb = ({ className, links }) => {
    const classes = useStyles();

    const lastLink = [...links].pop();

    return (
        <Grid
            container
            spacing={1}
            alignItems="center"
            className={classnames(classes.root, className)}
        >
            {links.slice(0, -1).map((link) => (
                <Fragment key={link.href}>
                    <Grid item>
                        <Link className={classes.link} to={link.href}>
                            <Typography variant="body2">
                                {link.label}
                            </Typography>
                        </Link>
                    </Grid>
                    <Grid item>
                        <ExpandMore
                            data-testid="caret"
                            className={classes.separator}
                        />
                    </Grid>
                </Fragment>
            ))}
            <Grid item>
                <Link className={classes.link} to={lastLink.href}>
                    {lastLink.label}
                </Link>
            </Grid>
        </Grid>
    );
};

Breadcrumb.propTypes = {
    className: PropTypes.string,
    links: PropTypes.arrayOf(
        PropTypes.shape({
            href: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        }),
    ).isRequired,
};

Breadcrumb.defaultProps = {
    className: null,
};

export default Breadcrumb;
