import PropTypes from 'prop-types';
import {
    IconButton,
    ListItemSecondaryAction,
    ListItemText,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import inputPropTypes from './inputProptypes';
import InputText from './InputText';

const InputItem = ({ input, disconnect, disabled }) => {
    return (
        <>
            <ListItemText primary={<InputText input={input} />} />
            {!disabled && (
                <ListItemSecondaryAction>
                    <IconButton edge="end" onClick={disconnect(input)}>
                        <DeleteIcon />
                    </IconButton>
                </ListItemSecondaryAction>
            )}
        </>
    );
};

InputItem.propTypes = {
    input: inputPropTypes.isRequired,
    disconnect: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
};

export default InputItem;
