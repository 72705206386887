import type { FunctionComponent } from 'react';
import { useState } from 'react';
import { TextInput, NumberInput, BooleanInput, Button } from 'react-admin';
import { useForm } from 'react-final-form';
import {
    FormControl,
    FormLabel,
    Tabs,
    Tab,
    Typography,
    Grid,
} from '@material-ui/core';

import { JsonInput } from '../inputs/JsonInput';
import TestMailConfig from './TestMailConfig';

const providers = ['smtp_over_oauth2', 'mailgun'] as const;
type Provider = (typeof providers)[number];

interface Props {
    record?: { id: string; mailConfig?: { provider?: Provider } };
}

const MailConfig: FunctionComponent<Props> = ({ record }) => {
    const form = useForm();
    const {
        values: { mailConfig },
    } = form.getState();

    const [withMailConfig, setWithMailConfig] = useState<boolean>(!!mailConfig);
    const [provider, setProvider] = useState<Provider>(
        record?.mailConfig?.provider === 'mailgun'
            ? 'mailgun'
            : 'smtp_over_oauth2',
    );

    const removeMailConfig = () => {
        form.change('mailConfig', null);
        setWithMailConfig(false);
    };

    if (!withMailConfig) {
        return (
            <>
                <Typography>The email provider is not configured.</Typography>
                <Button
                    onClick={() => setWithMailConfig(true)}
                    label="Configure the email provider"
                />
            </>
        );
    }

    const handleEmailProviderChange = (evt, value) => {
        const provider = providers[value];
        setProvider(provider);
        form.change('mailConfig', mailConfig);

        if (provider === 'mailgun') {
            form.change('mailConfig.provider', 'mailgun');
        } else {
            form.change('mailConfig.provider', 'saml_over_oauth2');
            form.change('mailConfig.auth.type', 'OAUTH2');
        }
    };

    return (
        <Grid container xs={12} spacing={3}>
            <Grid item xs={12}>
                <Button
                    onClick={removeMailConfig}
                    label="Delete the email provider configuration"
                />
            </Grid>
            <Grid item xs={12}>
                <Tabs
                    value={providers.findIndex((item) => provider === item)}
                    onChange={handleEmailProviderChange}
                >
                    <Tab label="SMTP over Oauth2" />
                    <Tab label="Mailgun" />
                </Tabs>
            </Grid>
            {provider === 'smtp_over_oauth2' && (
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <FormLabel>SMTP Configuration</FormLabel>
                        <TextInput
                            label="host"
                            source="mailConfig.host"
                            defaultValue="smtp.gmail.com"
                            fullWidth
                            required
                        />
                        <NumberInput
                            label="port"
                            source="mailConfig.port"
                            defaultValue={465}
                            fullWidth
                            required
                        />
                        <BooleanInput
                            label="secure"
                            source="mailConfig.secure"
                            defaultValue={true}
                            required
                        />
                        <FormControl fullWidth>
                            <FormLabel>Authentication</FormLabel>
                            <TextInput
                                label="user"
                                source="mailConfig.auth.user"
                                fullWidth
                                required
                            />
                            <TextInput
                                label="clientId"
                                source="mailConfig.auth.clientId"
                                fullWidth
                                required
                            />
                            <TextInput
                                label="clientSecret"
                                source="mailConfig.auth.clientSecret"
                                fullWidth
                                required
                            />
                            <TextInput
                                label="refreshToken (only required for Gmail)"
                                source="mailConfig.auth.refreshToken"
                                fullWidth
                            />
                            <TextInput
                                label="accessUrl"
                                source="mailConfig.auth.accessUrl"
                                defaultValue="https://accounts.google.com/o/oauth2/token"
                                fullWidth
                                required
                            />
                            <JsonInput
                                label="Custom Parameters (like grant_type, scope, ...)"
                                source="mailConfig.auth.customParams"
                            />
                        </FormControl>
                    </FormControl>
                </Grid>
            )}
            {provider === 'mailgun' && (
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <FormLabel>Configuration</FormLabel>
                        <TextInput
                            label="Mailgun Domain"
                            source="mailConfig.domain"
                            fullWidth
                            required
                        />
                        <TextInput
                            label="Mailgun API Key"
                            source="mailConfig.apiKey"
                            fullWidth
                            required
                        />
                        <TextInput
                            label="Sender"
                            source="mailConfig.sender"
                            fullWidth
                            required
                        />
                        <TextInput
                            label="Reply To (optional)"
                            source="mailConfig.replyTo"
                            fullWidth
                        />
                    </FormControl>
                </Grid>
            )}
            <Grid item xs={12}>
                <TestMailConfig />
            </Grid>
        </Grid>
    );
};

export default MailConfig;
