import PropTypes from 'prop-types';
import { useQuery } from 'react-admin';
import { ListItemText } from '@material-ui/core';

const InsuranceProductOptionText = ({ record }) => {
    const { data: organization } = useQuery({
        type: 'getOne',
        resource: 'Organization',
        payload: { id: record.organization.id },
    });

    if (organization) {
        return (
            <ListItemText primary={record.name} secondary={organization.name} />
        );
    }

    return record.name;
};

InsuranceProductOptionText.propTypes = {
    record: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        organization: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
};

export default InsuranceProductOptionText;
