import { get } from 'lodash';

const isBrowsedEdge = (trace) => {
    if (!trace || !Object.keys(trace).length) {
        return () => false;
    }

    const traceNodes = Object.keys(trace);

    return (edge) =>
        traceNodes.includes(edge.start.node) &&
        Object.keys(get(trace, `["${edge.start.node}"].outputs`, {})).includes(
            edge.start.pin,
        ) &&
        traceNodes.includes(edge.end.node);
};

export default isBrowsedEdge;
