import get from 'lodash/get';
import PropTypes from 'prop-types';
import { TableCell, TableRow, Typography } from '@material-ui/core';
import { Field, useForm } from 'react-final-form';
import getDefaultLookoutPeriod from '@tint/core/src/processors/sambaSafety/getDefaultLookoutPeriod';
import { OnChange } from 'react-final-form-listeners';

import { FlatSelect, TextInput } from '../../../../../forms';

const LOOKOUT_PERIOD_OPTIONS = [
    {
        value: 3,
        label: '3 years',
    },
    {
        value: 7,
        label: '7 years',
    },
    {
        value: 0,
        label: 'Unlimited',
    },
];

const formatNumericValue = (value) => {
    if (typeof value === 'undefined' || value === '' || value === null) {
        return '';
    }

    return value.toString();
};

const parseNumericValue = (value) => {
    if (typeof value === 'undefined' || value === '' || value === null) {
        return undefined;
    }

    return parseInt(value, 10);
};

export const ViolationRow = ({ violation, disabled }) => {
    const form = useForm();
    const { values } = form.getState();
    const threshold = get(
        values,
        `node.configuration.events.${violation['Record Code']}.threshold`,
    );

    return (
        <TableRow key={violation['Record Code']}>
            <TableCell style={{ width: 80 }}>
                {violation['Record Code']}
            </TableCell>
            <TableCell>
                <Typography variant="body1">
                    {violation['Record Name']}
                </Typography>
            </TableCell>
            <TableCell style={{ width: 100 }}>
                <OnChange
                    name={`node.configuration.events.${violation['Record Code']}.threshold`}
                >
                    {(value, prevValue) => {
                        if (value === prevValue) {
                            return;
                        }
                        if (
                            (value || value === 0) &&
                            !prevValue &&
                            prevValue !== 0
                        ) {
                            form.change(
                                `node.configuration.events.${violation['Record Code']}.lookoutPeriodInYear`,
                                getDefaultLookoutPeriod(
                                    violation['Record Code'],
                                ),
                            );
                            return;
                        }
                        if (!value && value !== 0) {
                            form.change(
                                `node.configuration.events.${violation['Record Code']}.lookoutPeriodInYear`,
                                '',
                            );
                        }
                    }}
                </OnChange>
                <Field
                    name={`node.configuration.events.${violation['Record Code']}.threshold`}
                    disabled={disabled}
                    component={TextInput}
                    fullWidth
                    size="small"
                    type="number"
                    parse={parseNumericValue}
                    format={formatNumericValue}
                    inputProps={{ min: 1 }}
                />
            </TableCell>
            <TableCell>
                <Field
                    name={`node.configuration.events.${violation['Record Code']}.lookoutPeriodInYear`}
                    disabled={disabled || (!threshold && threshold !== 0)}
                    component={FlatSelect}
                    fullWidth
                    options={LOOKOUT_PERIOD_OPTIONS}
                    format={formatNumericValue}
                    parse={parseNumericValue}
                    inputProps={{
                        'data-testid': `lookout-period-${violation['Record Code']}`,
                    }}
                />
            </TableCell>
        </TableRow>
    );
};

ViolationRow.propTypes = {
    disabled: PropTypes.bool,
    violation: PropTypes.shape({
        'Record Code': PropTypes.string,
        'Record Name': PropTypes.string,
    }).isRequired,
};
