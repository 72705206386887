import classnames from 'classnames';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    root: {
        fontSize: '1em',
    },
}));

const P = ({ className, ...otherProps }) => {
    const classes = useStyles();

    return (
        <Typography
            variant="body1"
            className={classnames(className, classes.root)}
            {...otherProps}
        />
    );
};

P.propTypes = {
    className: PropTypes.string,
};

P.defaultProps = {
    className: null,
};

export default P;
