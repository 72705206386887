import classnames from 'classnames';
import PropTypes from 'prop-types';

import { Grid, makeStyles, Tooltip, Typography } from '@material-ui/core';
import { formatTimestampDuration } from '@tint/core/src/formatters';

import { dataPropType } from '../blockPropTypes';
import { TickIcon } from '../../../../icons';
import { WarningOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    section: {
        padding: theme.spacing(2),
    },
    label: {
        fontSize: 12,
    },
    successIcon: {
        fill: theme.palette.success.main,
        position: 'relative',
        top: 1,
    },
    errorIcon: {
        fill: theme.palette.reds[500],
        position: 'relative',
        top: 3,
    },
    errorLabel: {
        fontSize: 12,
        color: theme.palette.reds[500],
    },
    errorLabelWithTooltip: {
        fontSize: 12,
        color: theme.palette.reds[500],
        borderBottom: `1px dashed ${theme.palette.reds[500]}`,
    },
    errorTooltip: {
        backgroundColor: theme.palette.reds[100],
        color: theme.palette.reds[500],
        padding: theme.spacing(2),
    },
    errorTooltipArrow: {
        color: theme.palette.reds[100],
    },
}));

const ErrorDetails = ({ error }) => {
    const classes = useStyles();

    if (error.code === 'TIMEOUT') {
        return (
            <Typography
                component="div"
                variant="body1"
                className={classes.errorLabel}
            >
                Vendor timed out
                <br />({error.message})
            </Typography>
        );
    }

    if (error.code === 'ERROR') {
        if (!error.message || error.message.length > 15) {
            return (
                <Tooltip
                    title={error.message}
                    arrow
                    classes={{
                        tooltip: classes.errorTooltip,
                        arrow: classes.errorTooltipArrow,
                    }}
                    interactive
                >
                    <Typography
                        component="div"
                        variant="body1"
                        className={classes.errorLabelWithTooltip}
                    >
                        Vendor failed with an error
                    </Typography>
                </Tooltip>
            );
        }

        return (
            <Typography
                component="div"
                variant="body1"
                className={classes.errorLabel}
            >
                Vendor failed:
                <br />
                {error.message}
            </Typography>
        );
    }

    return (
        <Typography
            component="div"
            variant="body1"
            className={classes.errorLabel}
        >
            Unknown error
        </Typography>
    );
};

ErrorDetails.propTypes = {
    error: PropTypes.shape({
        code: PropTypes.oneOf(['ERROR', 'TIMEOUT']).isRequired,
        message: PropTypes.string,
    }).isRequired,
};

const ExternalDataReportContent = ({ data, className }) => {
    const classes = useStyles();

    if (!data.trace) {
        return null;
    }

    if (data.trace.error) {
        return (
            <Grid
                container
                alignItems="center"
                spacing={1}
                className={classnames(classes.section, className)}
            >
                <Grid item>
                    <WarningOutlined
                        className={classes.errorIcon}
                        fontSize="small"
                    />
                </Grid>
                <Grid item>
                    <ErrorDetails error={data.trace.error} />
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid
            container
            alignItems="center"
            spacing={1}
            className={classnames(classes.section, className)}
        >
            <Grid item>
                <TickIcon className={classes.successIcon} />
            </Grid>
            <Grid item>
                <Typography
                    component="div"
                    variant="body1"
                    className={classes.label}
                >
                    Fetched in{' '}
                    {formatTimestampDuration(data.trace.duration, {
                        maxDecimalPoints: 2,
                        largest: 2,
                    })}
                </Typography>
            </Grid>
        </Grid>
    );
};

ExternalDataReportContent.propTypes = {
    data: PropTypes.shape(dataPropType).isRequired,
    className: PropTypes.string,
};

ExternalDataReportContent.defaultProps = {};

export default ExternalDataReportContent;
