import getJsonSchemaPartByPath from './getJsonSchemaPartByPath';

import {
    getNodeOutputs,
    hasDynamicOutputs,
    getOutputTypeFromSchema,
} from '../io';
import OUTPUT_TYPES from '../outputTypes';
import type { JSONSchema7 } from 'json-schema';
import InternalError from '../../InternalError';

type Blueprint = {
    nodes: Record<
        string,
        {
            name: string;
            processor: string;
            configuration?: {
                path?: string;
                value?: number;
                deploymentId?: string;
                unit?: string;
                mapping?: string[];
                rates?: number[];
                dimensions?: {
                    type: string;
                    input: string;
                    conditions?: Record<string, unknown>[];
                }[];
            };
            outputType?: string;
        }
    >;
};

export const getOutputTypeFromValidationSchema = (
    validationSchema: JSONSchema7,
    path: string,
) => {
    if (path.includes('extra.')) {
        return OUTPUT_TYPES.ANY;
    }

    const schema = getJsonSchemaPartByPath(validationSchema, path);
    return getOutputTypeFromSchema(schema);
};

const getOutputType = (
    blueprint: Blueprint,
    validationSchema: JSONSchema7,
    nodeId: string,
    outputId: string,
) => {
    const node = blueprint.nodes[nodeId];
    if (!node) {
        throw new InternalError(`Unable to find the node "${nodeId}"`);
    }
    const outputs = getNodeOutputs(node);
    const output = hasDynamicOutputs(node)
        ? outputs[0] // The dynamic outputs return the same output type
        : outputs.find((o: { id: string }) => o.id === outputId);

    if (!output) {
        throw new InternalError(
            `Unable to find the output "${outputId}" for the node "${nodeId}"`,
        );
    }

    if (node.processor === 'attribute') {
        // @TODO Move this block into `attributeProcessor` getType function
        return getOutputTypeFromValidationSchema(
            validationSchema,
            node.configuration?.path || '',
        );
    }

    if (output.getType) {
        return output.getType(node, validationSchema);
    }

    if (!output.schema) {
        return OUTPUT_TYPES.ANY;
    }

    return getOutputTypeFromSchema(output.schema);
};

export default getOutputType;
