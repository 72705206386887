import validator from './validate';

const previousQuoteProcessor = {
    name: 'Previous Quote',
    slug: 'previous-quote',
    validate: validator,
    inputs: [] as never[],
    outputs: [{ id: 'output', name: 'Output' }],
};

export default previousQuoteProcessor;
