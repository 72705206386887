import { useTheme } from '@material-ui/core';

import { MODEL_TYPE_DECISION } from '@tint/core/src/models/types';

import blockPropType from './blockPropTypes';
import BaseBlock from './BaseBlock';
import ConnectorPin from './ConnectorPin';
import { OutputIcon } from './icons/OutputIcon';
import DecisionIcon from './icons/DecisionIcon';
import getColorFromOutputType from './getColorFromOutputType';

export const getOutputBlockColor = (theme, data) => {
    if (data.outputType === MODEL_TYPE_DECISION) {
        return getColorFromOutputType(theme, data.editor.outputType);
    }

    return theme.palette.darkerGray;
};

const OutputBlock = ({ data, id, selected, ...props }) => {
    const theme = useTheme();
    const color = getOutputBlockColor(theme, data);

    return (
        <BaseBlock
            {...props}
            selected={selected}
            disabled={data.disabled}
            id={id}
            data={data}
            color={color}
            icon={
                data.outputType === MODEL_TYPE_DECISION ? (
                    <DecisionIcon
                        color={color}
                        outputType={data.editor.outputType}
                        withErrors={data.errors.length > 0}
                    />
                ) : (
                    <OutputIcon withErrors={data.errors.length > 0} />
                )
            }
            pins={
                <ConnectorPin
                    key="input"
                    selector={`${id}-input`}
                    nodeId={id}
                    type="target"
                    position="left"
                    id="input"
                    name="Input"
                    blockSelected={selected}
                    color={color}
                    connecting={data.connecting}
                    isValidConnection={data.isValidConnection}
                />
            }
        />
    );
};

OutputBlock.propTypes = blockPropType;

export default OutputBlock;
