import { get } from 'lodash';
import { VALIDATION_CODES } from '../../validation/validationCodes';

type Blueprint = {
    nodes: Record<string, unknown>;
    edges: {
        start: {
            node: string;
            pin: string;
        };
        end: {
            node: string;
            pin: string;
        };
    }[];
};

const validateEkataIdentityCheck = (blueprint: Blueprint, nodeId: string) => {
    const name = get(blueprint, `nodes["${nodeId}"].name`, 'New Block');
    const edges = blueprint.edges.filter((edge) => edge.end.node === nodeId);

    if (edges.length === 0) {
        return [
            {
                id: nodeId,
                code: VALIDATION_CODES.GLOBAL_ERROR,
                message: `The Ekata processor "${name}" needs at least one input`,
            },
        ];
    }

    return [];
};

export default validateEkataIdentityCheck;
