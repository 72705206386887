import InsuranceProduct from './insuranceProducts';
import InsuranceProductVersion from './insuranceProductVersions';
import Model from './models';
import User from './users';
import ApiToken from './apiTokens';

const graphqlQueries = {
    InsuranceProduct,
    InsuranceProductVersion,
    Model,
    User,
    ApiToken,
};

export default graphqlQueries;
