import { useEffect } from 'react';

import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import { useSummaryDisplaySchemaState } from './summaryDisplaySchemaReducer';
import { SummaryDisplaySchemaItemList } from './SummaryDisplaySchemaItemList';
import { SummaryDisplayItemInput } from './SummaryDisplayItemInput';

export const SummaryDisplaySchemaWizardInput = ({
    input: { value, onChange },
}) => {
    const {
        state,
        summaryDisplaySchema,
        selectDisplaySchema,
        updateDisplaySchema,
        removeDisplaySchema,
        addDisplaySchema,
        moveDisplaySchema,
        summaryDisplayList,
        selectedDisplaySchema,
    } = useSummaryDisplaySchemaState(value);
    useEffect(() => {
        onChange(summaryDisplaySchema);
    }, [summaryDisplaySchema]);

    return (
        <Grid container spacing={4}>
            <Grid item xs={6} md={4}>
                <SummaryDisplaySchemaItemList
                    itemList={summaryDisplayList}
                    selectedId={state.selectedId}
                    selectDisplaySchema={selectDisplaySchema}
                    removeDisplaySchema={removeDisplaySchema}
                    addDisplaySchema={addDisplaySchema}
                    moveDisplaySchema={moveDisplaySchema}
                />
            </Grid>
            <Grid item xs={6} md={8}>
                <SummaryDisplayItemInput
                    summaryDisplayItem={selectedDisplaySchema}
                    onChange={updateDisplaySchema}
                />
            </Grid>
        </Grid>
    );
};

SummaryDisplaySchemaWizardInput.propTypes = {
    input: PropTypes.shape({
        value: PropTypes.array,
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
    }).isRequired,
};

SummaryDisplaySchemaWizardInput.defaultProps = {};
