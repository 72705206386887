import classnames from 'classnames';
import PropTypes from 'prop-types';

import { Grid, IconButton, makeStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';

import TextInput from '../../../../../../forms/TextInput';

import { Field } from 'react-final-form';
import { Select } from '../../../../../../forms';
import ChipsInput from '../../../../../../forms/ChipsInput';

const useStyles = makeStyles((theme) => ({
    row: {
        marginTop: theme.spacing(2),
        position: 'relative',
    },
    deleteButton: {
        opacity: 0.25,
        transition: 'opacity 0.25s ease',
        '&:hover': {
            opacity: 1,
        },
    },
}));

const STRING_OPERATIONS = [
    { value: 'match', label: 'Match' },
    { value: 'contain', label: 'Contains' },
    { value: 'start-with', label: 'Starts with' },
    { value: 'end-with', label: 'Ends with' },
];

const StringRow = ({ className, name, onDelete, disabled }) => {
    const classes = useStyles();

    return (
        <Grid
            className={classnames(className, classes.root)}
            container
            spacing={1}
            alignItems="flex-end"
        >
            <Grid item xs={3}>
                <Field
                    component={Select}
                    name={`${name}.operation`}
                    label="Operation"
                    options={STRING_OPERATIONS}
                    disabled={disabled}
                    margin="dense"
                />
            </Grid>
            <Grid item xs={5}>
                <Field
                    component={TextInput}
                    name={`${name}.label`}
                    label="Category"
                    fullWidth
                    disabled={disabled}
                    margin="dense"
                />
            </Grid>
            <Grid item xs={10}>
                <Field
                    name={`${name}.values`}
                    render={ChipsInput}
                    fullWidth
                    inputProps={{
                        margin: 'dense',
                        fullWidth: true,
                    }}
                    label="Conditions"
                    disabled={disabled}
                />
            </Grid>
            {!disabled && onDelete && (
                <Grid item xs={2}>
                    <IconButton
                        onClick={onDelete}
                        title="Delete this row"
                        className={classes.deleteButton}
                    >
                        <DeleteIcon size="small" />
                    </IconButton>
                </Grid>
            )}
        </Grid>
    );
};

StringRow.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    input: PropTypes.shape({
        value: PropTypes.shape({
            label: PropTypes.string,
            matches: PropTypes.arrayOf(PropTypes.string),
        }).isRequired,
        onChange: PropTypes.func.isRequired,
    }),
    onDelete: PropTypes.func,
};

StringRow.defaultProps = {
    className: null,
    disabled: false,
    onDelete: null,
    input: null,
};

export default StringRow;
