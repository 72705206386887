import { cloneElement, useEffect } from 'react';
import PropTypes from 'prop-types';
import ModalBase from '@material-ui/core/Modal';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        backgroundColor: '#fff',
        maxWidth: 800,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
        top: '50%',
        left: '50%',
        transform: `translate(-50%,-50%)`,
        borderRadius: 4,
    },
    actions: {
        marginTop: theme.spacing(4),
        textAlign: 'right',
    },
    action: {
        '&:first-child': {
            marginRight: theme.spacing(2),
        },
    },
}));

const Modal = ({ actions, content, opened, ...otherProps }) => {
    const classes = useStyles();

    useEffect(() => {
        // eslint-disable-next-line no-console
        console.warn(
            'The @tint/ui Modal component is deprecated. Please use @material/core Dialog instead.',
        );
    }, []);

    return (
        <ModalBase classes={classes} open={opened} {...otherProps}>
            <div className={classes.paper}>
                {content}
                {actions.length > 0 && (
                    <div className={classes.actions}>
                        {actions.map((action) =>
                            cloneElement(action, {
                                className: classes.action,
                            }),
                        )}
                    </div>
                )}
            </div>
        </ModalBase>
    );
};

Modal.propTypes = {
    opened: PropTypes.bool,
    content: PropTypes.node.isRequired,
    actions: PropTypes.arrayOf(PropTypes.node),
};

Modal.defaultProps = {
    opened: false,
    actions: [],
};

export default Modal;
