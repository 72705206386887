import { Tab } from '@material-ui/core';

import {
    getNodeInputs,
    getNodeOutputs,
    hasDynamicInputs,
} from '@tint/core/src/processors/io';
import { get } from 'lodash';

import { MODEL_TYPE_DECISION } from '@tint/core/src/models/types';
import { getMatchingProcessor } from '../../ProcessorSelect/processorGroups';

import DataRobotConfiguration, {
    getDataRobotConfigurationTabs,
} from './DataRobotConfiguration';
import AttributeConfiguration from './AttributeConfiguration';
import CategorizationForm from './categorization/CategorizationForm';
import ConcatenateConfiguration from './ConcatenateConfiguration';
import ConstantConfiguration from './ConstantConfiguration';
import DatePartConfiguration from './DatePartConfiguration';
import DecisionConfiguration from './DecisionConfiguration';
import DivisionConfiguration from './DivisionConfiguration';
import EkataIdentityCheckConfiguration, {
    getEkataIdentityCheckConfigurationTabs,
} from './EkataIdentityCheckConfiguration';
import EquifaxDigitalIdentityTrustConfiguration, {
    getEquifaxConfigurationTabs,
} from './EquifaxDigitalIdentityTrustConfiguration';
import FuzzyMatchingConfiguration from './FuzzyMatchingConfiguration';
import LogarithmConfiguration from './LogarithmConfiguration';
import RatingTableConfiguration, {
    getConfigurationTabs as getRatingTableConfigurationTabs,
} from './RatingTableConfiguration';
import ReferencedModelConfiguration from './ReferencedModelConfiguration';
import RoundConfiguration from './RoundConfiguration';
import TimeDifferenceConfiguration from './TimeDifferenceConfiguration';
import SambaSafetyConfiguration, {
    getSambaSafetyConfigurationTabs,
} from './SambaSafetyConfiguration/index';
import ModuloConfiguration from './ModuloConfiguration';
import equifaxConsumerCreditReportConfiguration, {
    getEquifaxConsumerCreditReportTabs,
} from './EquifaxConsumerCreditReportConfiguration';
const CONFIGURATION_TABS_BY_PROCESSOR = {
    'data-robot': getDataRobotConfigurationTabs,
    'ekata-identity-check': getEkataIdentityCheckConfigurationTabs,
    'rating-table': getRatingTableConfigurationTabs,
    'samba-safety': getSambaSafetyConfigurationTabs,
    'equifax-digital-identity-trust': getEquifaxConfigurationTabs,
    'equifax-consumer-credit-report': getEquifaxConsumerCreditReportTabs,
};

const CONGIFURATION_FORMS_BY_PROCESSOR = {
    'data-robot': DataRobotConfiguration,
    'date-part': DatePartConfiguration,
    'ekata-identity-check': EkataIdentityCheckConfiguration,
    'equifax-digital-identity-trust': EquifaxDigitalIdentityTrustConfiguration,
    'equifax-consumer-credit-report': equifaxConsumerCreditReportConfiguration,
    'fuzzy-matching': FuzzyMatchingConfiguration,
    'rating-table': RatingTableConfiguration,
    'referenced-model': ReferencedModelConfiguration,
    'samba-safety': SambaSafetyConfiguration,
    'time-difference': TimeDifferenceConfiguration,
    attribute: AttributeConfiguration,
    categories: CategorizationForm,
    concatenate: ConcatenateConfiguration,
    constant: ConstantConfiguration,
    division: DivisionConfiguration,
    logarithm: LogarithmConfiguration,
    modulo: ModuloConfiguration,
    round: RoundConfiguration,
    switch: CategorizationForm,
};

const hasInputsConfiguration = (node) => !!getNodeInputs(node, false)?.length;

export const getConfigurationForm = (node) => {
    if (node.outputType === MODEL_TYPE_DECISION) {
        return DecisionConfiguration;
    }

    return CONGIFURATION_FORMS_BY_PROCESSOR[node.processor];
};

export const getConfigurationTabs = (node, showRestrictedConfiguration) => {
    const matchingProcessor = getMatchingProcessor(node.processor);
    if (!showRestrictedConfiguration && matchingProcessor?.restricted) {
        return [<Tab key="inputs" label="Inputs" />];
    }

    let tabs = [];

    const getProcessorConfigurationTabs =
        CONFIGURATION_TABS_BY_PROCESSOR[node.processor];

    if (getProcessorConfigurationTabs) {
        tabs = tabs.concat(getProcessorConfigurationTabs());
    } else if (getConfigurationForm(node)) {
        tabs.push(<Tab key="configuration" label="Configuration" />);
    }

    if (hasInputsConfiguration(node)) {
        tabs.push(<Tab key="inputs" label="Inputs" />);
    }

    return tabs;
};

export const getNodeInputLabel = (input) => {
    return `${input.name}${
        input.pin !== 'output' ? ` - ${input.displayName}` : ''
    }`;
};

export const getNodeInputPins = (blueprint, nodeId, node) => {
    const inputs = getNodeInputs(node, false);
    return blueprint.edges.reduce((p, c) => {
        const startNode = blueprint.nodes[c.start.node];
        const outputs = getNodeOutputs(startNode, false);
        const displayName = outputs.find((x) => x.id === c.start.pin).name;
        const targetName =
            hasDynamicInputs(node) || c.end.pin === 'input'
                ? null
                : get(
                      inputs.find((x) => x.id === c.end.pin),
                      'name',
                  );

        return [
            ...p,
            ...(c.end.node === nodeId
                ? [
                      {
                          name: startNode.name,
                          id: c.start.node,
                          pin: c.start.pin,
                          displayName,
                          targetName,
                      },
                  ]
                : []),
        ];
    }, []);
};
