import { get, without } from 'lodash';
import dagre from 'dagre';
import { getNodeOutputs } from '@tint/core/src/processors/io';

const doesNodeHaveValidPosition = (editorData) => (nodeId) => {
    if (!editorData || !editorData.nodes) {
        return false;
    }

    const editor = editorData.nodes[nodeId];

    if (!editor || !editor.position) {
        return false;
    }

    const { position } = editor;

    return (
        Object.keys(position).includes('x') &&
        Object.keys(position).includes('y')
    );
};

const getMissingNodePositions = (blueprint, editorData) => {
    const nodes = Object.keys(blueprint.nodes);
    const positionedNodes = Object.keys(get(editorData, 'nodes', {})).filter(
        doesNodeHaveValidPosition(editorData),
    );

    return without(nodes, ...positionedNodes);
};

export const areNodePositionsInvalid = (blueprint, editorData) => {
    const missingNodePositions = getMissingNodePositions(blueprint, editorData);

    if (missingNodePositions.length > 0) {
        return true;
    }

    return false;
};

const BASE_HEIGHT = 80;

const VARIABLE_PROCESSORS = [
    'switch',
    'ekata-identity-check',
    'checkr',
    'function',
    'stripe',
];

export const guessNodeHeight = (node) => {
    if (VARIABLE_PROCESSORS.includes(node.processor)) {
        const outputs = getNodeOutputs(node);

        return BASE_HEIGHT + outputs.length * BASE_HEIGHT;
    }

    return BASE_HEIGHT;
};

export const computeNodePositions = ({ nodes, edges }) => {
    const graph = new dagre.graphlib.Graph();
    graph.setDefaultEdgeLabel(() => ({}));
    graph.setGraph({ rankdir: 'LR' });

    Object.keys(nodes).forEach((id) => {
        graph.setNode(id, { width: 200, height: guessNodeHeight(nodes[id]) });
    });

    edges.forEach((edge) => {
        graph.setEdge(edge.start.node, edge.end.node);
    });

    dagre.layout(graph);

    return Object.keys(nodes).reduce((acc, id) => {
        const nodeWithPosition = graph.node(id);

        acc[id] = {
            position: {
                x: Math.round(nodeWithPosition.x),
                y: Math.round(nodeWithPosition.y),
            },
        };

        return acc;
    }, {});
};

export const suggestNodePositions = (blueprint, editorData) => {
    const positions = computeNodePositions(blueprint);
    const nodes = get(editorData, 'nodes', {});

    return {
        blueprint,
        editorData: {
            ...editorData,
            nodes: Object.keys(positions).reduce((acc, nodeId) => {
                acc[nodeId] = {
                    ...(nodes[nodeId] || {}),
                    position: positions[nodeId].position,
                };

                return acc;
            }, {}),
        },
    };
};
