import PropTypes from 'prop-types';

import { Grid } from '@material-ui/core';
import { Field, useForm } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';

import { titleize } from 'inflection';
import { get } from 'lodash';
import AttributesSelect from '../../../../forms/AttributesSelect';

const titleizeAttributeName = (value) =>
    value ? titleize(value.replace(/\./g, ' ')) : 'New Block';

const AttributeConfiguration = ({ disabled, insuranceProduct }) => {
    const form = useForm();

    return (
        <Grid container direction="column" spacing={1}>
            <Grid item>
                <OnChange name="node.configuration.path">
                    {(value, previousValue) => {
                        const currentName = get(
                            form.getState(),
                            'values.node.name',
                        );

                        if (
                            !currentName ||
                            currentName === 'New Block' ||
                            currentName === titleizeAttributeName(previousValue)
                        ) {
                            form.change(
                                'node.name',
                                titleizeAttributeName(value),
                            );
                        }
                    }}
                </OnChange>
                <Field
                    disabled={disabled}
                    name="node.configuration.path"
                    label="Policy Details"
                    component={AttributesSelect}
                    insuranceProduct={insuranceProduct}
                    required
                    autoFocus
                />
            </Grid>
        </Grid>
    );
};

AttributeConfiguration.propTypes = {
    disabled: PropTypes.bool,
    insuranceProduct: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
};

AttributeConfiguration.defaultProps = {
    disabled: false,
};

export default AttributeConfiguration;
