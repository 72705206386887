/* eslint-disable */
import { Component } from 'react';
import { FormSpy } from 'react-final-form';
import { diff } from 'deep-object-diff';

// Copied from https://final-form.org/docs/react-final-form/examples

class AutoSave extends Component {
    constructor(props) {
        super(props);
        this.state = { values: props.values, submitting: false };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(this.save, this.props.debounce);
    }

    UNSAFE_componentWillUnmount() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    save = async () => {
        if (this.promise) {
            await this.promise;
        }
        const { hasValidationErrors, values, save } = this.props;
        if (hasValidationErrors) {
            return;
        }

        // This diff step is totally optional
        const difference = diff(this.state.values, values);
        if (Object.keys(difference).length) {
            // values have changed
            this.setState({ submitting: true, values });
            this.promise = save(difference);
            await this.promise;
            delete this.promise;
            this.setState({ submitting: false });
        }
    };

    render() {
        return null;
    }
}

const AutoSaveSpy = (props) => (
    <FormSpy {...props} subscription={{ values: true }} component={AutoSave} />
);

export default AutoSaveSpy;
