import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    title: {
        color: theme.palette.primary.main,
        marginTop: 0,
        marginBottom: 0,
    },
    subtitle: {
        marginTop: 0,
        marginBottom: 0,
        color: theme.palette.regularText,
    },
}));

const TitleBlock = ({ title, subtitle }) => {
    const classes = useStyles();

    return (
        <>
            <Typography variant="h3" className={classes.title}>
                {title}
            </Typography>
            {subtitle && (
                <Typography
                    variant="h4"
                    className={classes.subtitle}
                    data-testid="subtitle"
                >
                    {subtitle}
                </Typography>
            )}
        </>
    );
};

TitleBlock.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
};

TitleBlock.defaultProps = {
    subtitle: null,
};

export default TitleBlock;
