import processors from '@tint/core/src/processors';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import DatePartIcon from '@material-ui/icons/Event';

import {
    AndIcon,
    CategoriesIcon,
    CoalesceIcon,
    ConcatenateIcon,
    ConstantIcon,
    DataRobotIcon,
    DivisionIcon,
    EqualIcon,
    FuzzyMatchingIcon,
    LogarithmIcon,
    MaximumIcon,
    MinimumIcon,
    ModelIcon,
    ModuloIcon,
    OrIcon,
    RangeIcon,
    RatingTableIcon,
    RoundIcon,
    TimeDiffIcon,
} from '../../../icons';

import ekataIcon from './icons/ekata.png';
import equifaxIcon from './icons/equifax.ico';
import sambaSafetyIcon from './icons/samba-safety.png';
import bitcoinIcon from './icons/bitcoinIcon.svg';

export const PROCESSORS = {
    and: {
        ...processors.and,
        icon: <AndIcon />,
        iconSizes: { list: 36, editor: 28 },
    },
    or: {
        ...processors.or,
        icon: <OrIcon />,
        iconSizes: { list: 38, editor: 28 },
    },
    constant: {
        ...processors.constant,
        icon: <ConstantIcon />,
        iconSizes: { list: 28, editor: 18 },
    },
    'referenced-model': {
        ...processors['referenced-model'],
        icon: <ModelIcon />,
        iconSizes: { list: 28, editor: 24 },
    },
    categories: {
        ...processors.categories,
        icon: <CategoriesIcon />,
        iconSizes: { list: 28, editor: 28 },
    },
    'rating-table': {
        ...processors['rating-table'],
        iconSizes: { list: 20, editor: 22 },
        icon: <RatingTableIcon />,
    },
    switch: {
        ...processors.switch,
        iconSizes: { list: 28, editor: 28 },
        icon: <CallSplitIcon />,
    },
    coalesce: {
        ...processors.coalesce,
        iconSizes: { list: 28, editor: 22 },
        icon: <CoalesceIcon />,
    },
    'data-robot': {
        ...processors['data-robot'],
        icon: <DataRobotIcon />,
        iconSizes: { list: 32, editor: 32 },
    },
    sum: {
        ...processors.sum,
        iconSizes: { list: 28, editor: 28 },
        icon: <AddIcon />,
    },
    multiplication: {
        ...processors.multiplication,
        iconSizes: { list: 28, editor: 28 },
        icon: <CloseIcon />,
    },
    division: {
        ...processors.division,
        iconSizes: { list: 32, editor: 32 },
        icon: <DivisionIcon />,
    },
    logarithm: {
        ...processors.logarithm,
        iconSizes: { list: 32, editor: 32 },
        icon: <LogarithmIcon />,
    },
    minimum: {
        ...processors.minimum,
        iconSizes: { list: 32, editor: 32 },
        icon: <MinimumIcon />,
    },
    maximum: {
        ...processors.maximum,
        iconSizes: { list: 32, editor: 32 },
        icon: <MaximumIcon />,
    },
    range: {
        ...processors.range,
        iconSizes: { list: 32, editor: 32 },
        icon: <RangeIcon />,
    },
    round: {
        ...processors.round,
        iconSizes: { list: 32, editor: 32 },
        icon: <RoundIcon />,
    },
    'date-part': {
        ...processors['date-part'],
        iconSizes: { list: 28, editor: 22 },
        icon: <DatePartIcon />,
    },
    'time-difference': {
        ...processors['time-difference'],
        iconSizes: { list: 20, editor: 20 },
        icon: <TimeDiffIcon />,
    },
    'fuzzy-matching': {
        ...processors['fuzzy-matching'],
        icon: <FuzzyMatchingIcon />,
        iconSizes: { list: 28, editor: 28 },
    },
    concatenate: {
        ...processors.concatenate,
        iconSizes: { list: 28, editor: 28 },
        icon: <ConcatenateIcon />,
    },
    'samba-safety': {
        ...processors['samba-safety'],
        icon: sambaSafetyIcon,
        iconSizes: { list: 28, editor: 28 },
    },
    'ekata-identity-check': {
        ...processors['ekata-identity-check'],
        icon: ekataIcon,
        iconSizes: { list: 28, editor: 28 },
    },
    'equifax-digital-identity-trust': {
        ...processors['equifax-digital-identity-trust'],
        icon: equifaxIcon,
        iconSizes: { list: 22, editor: 22 },
    },
    'equifax-consumer-credit-report': {
        ...processors['equifax-consumer-credit-report'],
        icon: equifaxIcon,
        iconSizes: { list: 22, editor: 22 },
        restricted: true,
    },
    'btc-price': {
        ...processors['btc-price'],
        icon: bitcoinIcon,
        iconSizes: { list: 30, editor: 22 },
    },
    modulo: {
        ...processors.modulo,
        icon: <ModuloIcon />,
        iconSizes: { list: 28, editor: 28 },
    },
    equality: {
        ...processors.equality,
        icon: <EqualIcon />,
        iconSizes: { list: 28, editor: 28 },
    },
    'current-date': {
        ...processors['current-date'],
        icon: <DatePartIcon />,
        iconSizes: { list: 28, editor: 22 },
    },
};

const PROCESSOR_GROUPS = [
    {
        name: 'General',
        processors: [
            PROCESSORS.constant,
            PROCESSORS['referenced-model'],
            PROCESSORS.categories,
            PROCESSORS['rating-table'],
            PROCESSORS.switch,
            PROCESSORS.coalesce,
            PROCESSORS.equality,
        ],
    },
    {
        name: 'Logical',
        processors: [PROCESSORS.and, PROCESSORS.or],
    },
    {
        name: 'Numbers',
        processors: [
            PROCESSORS.sum,
            PROCESSORS.multiplication,
            PROCESSORS.division,
            PROCESSORS.modulo,
            PROCESSORS.logarithm,
            PROCESSORS.minimum,
            PROCESSORS.maximum,
            PROCESSORS.range,
            PROCESSORS.round,
        ],
    },
    {
        name: 'Dates',
        processors: [
            PROCESSORS['current-date'],
            PROCESSORS['date-part'],
            PROCESSORS['time-difference'],
        ],
    },
    {
        name: 'Strings',
        processors: [PROCESSORS['fuzzy-matching'], PROCESSORS.concatenate],
    },
    {
        name: 'Data Sources',
        processors: [
            PROCESSORS['data-robot'],
            PROCESSORS['samba-safety'],
            PROCESSORS['ekata-identity-check'],
            PROCESSORS['equifax-digital-identity-trust'],
            PROCESSORS['equifax-consumer-credit-report'],
            PROCESSORS['btc-price'],
        ],
    },
];

export const getMatchingProcessor = (processorSlug) =>
    PROCESSORS[processorSlug] || null;

export default PROCESSOR_GROUPS;
