import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Field } from 'react-final-form';
import { TextInput } from '../../../../forms';

const LogarithmConfiguration = ({ disabled }) => {
    return (
        <Grid container direction="column" spacing={1}>
            <Grid item>
                <Field
                    component={TextInput}
                    disabled={disabled}
                    name="node.configuration.default"
                    label="Default"
                    autoFocus
                    parse={(v) => parseFloat(v)}
                    type="number"
                    variant="outlined"
                    helperText="The default value is returned if the input is lower or equal to 0"
                />
            </Grid>
        </Grid>
    );
};

LogarithmConfiguration.propTypes = {
    disabled: PropTypes.bool,
};

LogarithmConfiguration.defaultProps = {
    disabled: false,
};

export default LogarithmConfiguration;
