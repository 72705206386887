import { createValidator } from '../../validation/validatorFactory';

const validator = createValidator();

const validate = validator.compile({
    type: ['object', 'null'],
    required: ['alwaysUseTintTriggers', 'hint', 'events'],
    properties: {
        hint: {
            type: 'string',
            enum: ['Full', 'Activity', 'Clean'],
        },
        alwaysUseTintTriggers: {
            type: 'boolean',
        },
        events: {
            type: 'object',
            minProperties: 1,
            properties: {
                AA01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                AA02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                AA03: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                AA04: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                AA05: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                AA06: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                AE01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                AE04: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                AE05: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                AE06: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                AE03: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UC04: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UC06: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UC09: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UC05: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UC03: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UC02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UC08: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UC01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UC07: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MK02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MK06: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MK03: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MK04: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MK07: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MK08: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MK01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UG22: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UG20: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MA08: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UG15: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UG21: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                CB10: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                CA02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                CB13: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                CB01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                CB09: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                CB02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                CB03: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                CB04: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                CB05: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                CB06: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                CB07: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                CB08: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                CC01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                CA06: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                CA04: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                CA09: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                CA08: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                CB18: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                CA05: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                CA01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                CB11: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                CA03: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB03: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB04: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB05: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB06: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB07: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB08: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB09: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                CA07: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                CB14: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                CB16: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                CB12: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                CC02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                CC03: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UE02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UE01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UE07: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UE03: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UE04: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UE05: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UE06: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MA19: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MC01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MC10: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MC15: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MC11: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MC12: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MC13: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MC14: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MC16: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MC20: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MC02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MC03: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MC04: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MC05: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MC06: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MC07: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MC08: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MC19: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MC09: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MC18: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MC17: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DE05: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DE06: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DE08: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DE09: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DE01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DE02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DE03: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DE10: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                ME02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                ME03: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                ME04: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                ME01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                ME05: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                EI01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MA20: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                EI04: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                EI05: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                EI03: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                EI02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                AD02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                AD03: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                AD04: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                AD01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                AE02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                AB01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                AB03: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                AB02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                AB04: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                AC04: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                AC02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                AC03: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                AC01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MB02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MB01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MB12: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MB08: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MB18: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MB14: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MB20: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MB21: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MB16: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MB22: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MB07: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MB05: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MB06: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MB11: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MB24: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MB09: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MB23: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MB03: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MB10: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MB15: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MB13: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VA09: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MB04: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UH20: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UH21: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UH19: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB36: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB34: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                KC21: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                KC02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                KC05: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                KC06: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                KC08: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                KC11: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                KC12: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                KC14: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                KC17: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                KC20: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                KC07: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                KC01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                KC09: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                KC10: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                KC13: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                KC15: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                KC16: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                KC03: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                KC04: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                KC18: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                KC19: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB59: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB28: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB13: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB37: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB55: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB56: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DH01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB14: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DH06: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB15: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB57: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB54: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UH29: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UH25: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UH27: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UH28: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UH24: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UH26: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB38: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB17: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB33: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB12: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB20: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB42: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB61: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB11: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB35: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB29: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB30: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB60: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB31: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB32: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UH12: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UH23: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB27: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB26: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB45: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB43: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB62: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UH06: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB44: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB58: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB51: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB53: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB50: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB48: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB52: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB47: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB46: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB49: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DD03: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DD02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DD01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                EF11: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                EF13: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                EF01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                EF08: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                EF10: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                EF09: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                EF14: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                EF12: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VA11: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VA12: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                EF03: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                EF04: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VH02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                CB17: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DE11: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UG09: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UG12: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UH03: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UH02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UE08: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VF03: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UA05: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UA03: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UA04: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UF03: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UH18: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UG14: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UG11: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VF02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB10: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UH04: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DA03: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DF01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DF02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                EL08: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                EL01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DH02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DE13: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DE14: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DE15: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DE16: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DE17: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DG07: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UF04: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DG02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DF03: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                EF05: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VD01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                AE07: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DA04: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VH03: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DA05: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DG03: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UA02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DE12: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MA06: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UA01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UF05: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DA06: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                AE08: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DH03: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DG04: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VF07: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DF05: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DF04: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UH07: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UG13: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UG16: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UH01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UG03: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UG04: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UH08: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VG13: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VH01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                EL03: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                EL02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VG11: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UG05: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UF01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UF02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB18: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DC09: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UG07: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VA01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DF06: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DA01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VD02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DG01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                EL06: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VG08: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VH05: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UH22: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VC11: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VC10: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UH09: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UG18: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DH04: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB19: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VG01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB39: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB41: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB16: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UG17: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UH10: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UH11: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DA02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VF04: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UH17: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DH05: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                EL09: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UG25: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VH13: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DA07: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DG05: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DF07: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DE04: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VI02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VI03: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VI01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VC08: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UG06: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UH05: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UF06: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UF07: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UG10: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UH13: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VF01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UH14: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UH15: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UH16: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DG06: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UN00: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UNKN: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VI04: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VC09: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VD05: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UG23: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                CB15: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB21: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DD04: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DC10: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UG19: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB23: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UG24: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB22: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB24: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB25: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DD05: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                EG03: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                EC03: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                EH01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                EG01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                EF15: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                ED01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                EA01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                EA08: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                EA09: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                EC01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                EM01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                EA10: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                EG02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                EA07: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                EA05: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                EA06: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                EH02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                ED03: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                EA04: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                EC02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                EL04: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VH08: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                EA11: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VA10: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VA14: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VA17: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VA13: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                ED02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                EA03: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VH07: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                EA02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                EL05: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VE09: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VE06: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VE02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VE04: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VE07: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VE14: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VE19: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VE10: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VE15: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VE17: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VE12: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VE16: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VE18: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VE11: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VE21: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VE13: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VE08: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VE20: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VE03: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MD04: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VE05: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MF01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VA06: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VA07: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MF03: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MF06: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MF09: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MF02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MF05: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MF04: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MF07: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MF08: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UD07: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UD01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UD04: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UD05: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UD03: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UD06: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UD02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA03: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MA22: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MA21: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MA09: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MA10: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MB17: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MK05: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                CA10: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                CA11: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DC02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DC03: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DC06: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DC01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DC07: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DC08: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DC11: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DC05: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DC04: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DB40: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DC15: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DC12: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DC13: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                DC14: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                EB01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VE01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VA15: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VA16: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VA04: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VA18: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                EB02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                EL07: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VJ02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                EJ01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                EJ03: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                EJ02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VB04: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VJ01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VB03: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA05: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA07: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SB09: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SB10: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SB15: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SB08: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SB11: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SB12: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SB13: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SB14: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SB16: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA95: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA18: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA24: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA30: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA36: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA43: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA50: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA57: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA64: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA70: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA77: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA82: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA89: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA96: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA98: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA17: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA19: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA25: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA31: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA37: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA44: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA51: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA58: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA65: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA71: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA78: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA83: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA90: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SB07: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA99: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA20: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA26: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA32: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA38: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA45: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA52: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA59: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA66: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA72: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA79: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA84: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA91: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SB01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA21: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA27: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA33: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA39: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA46: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA53: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA60: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA67: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA73: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA80: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA85: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA92: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SB02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA22: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA28: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA34: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA40: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA47: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA54: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA61: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA68: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA74: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA86: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA93: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SB03: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA29: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA35: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA41: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA48: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA55: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA62: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA69: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA75: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA87: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SB04: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA23: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA42: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA49: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA56: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA63: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA76: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA81: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA88: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SB05: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA94: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA97: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SB06: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA04: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SB17: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA08: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA10: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA11: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA12: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA13: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA14: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA15: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA16: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA09: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SA06: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                SB18: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MD01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MD05: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MD03: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MD02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MA15: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VG07: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VF05: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VF06: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MA01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MA02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MA04: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MA11: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MA12: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MA17: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MA03: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MA05: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MA07: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MA13: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MA14: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MA16: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MA18: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MH02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MH01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MH03: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MH08: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MH04: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MH05: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MH06: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MH07: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MH09: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VG05: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                ED04: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                ML02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                ML01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VG02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MG06: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MG04: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                ML05: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MG02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                ED05: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MG03: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                EF06: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                EF07: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MG01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MG05: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                ML03: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VH15: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                ML04: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VH14: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VG09: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VG04: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VG03: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VA05: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VA03: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VA02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VA08: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VG06: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                EF02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VB02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VB01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VH06: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                ML06: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VH04: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                CD01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                CD02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VC03: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VC02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VD03: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VD04: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VC14: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VC06: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VC04: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VC05: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VC07: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VC13: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VC01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VC12: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UG02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VH09: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VG12: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VG10: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UG01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VH10: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VH12: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UG08: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                VH11: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UB06: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UB01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UB07: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UB03: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UB04: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UB02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                UB05: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MI05: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MJ01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MJ02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MJ03: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MI01: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MI03: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MI04: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MI02: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MJ04: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MB19: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MJ05: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
                MJ06: {
                    type: 'object',
                    required: ['threshold', 'lookoutPeriodInYear'],
                    properties: {
                        threshold: {
                            type: 'number',
                            minimum: 0,
                        },
                        lookoutPeriodInYear: {
                            type: 'integer',
                            minimum: 0,
                        },
                    },
                },
            },
            additionalProperties: false,
        },
        timeout: {
            type: 'number',
            minimum: 0,
            maximum: 60000,
        },
        fallbackValue: {
            type: ['string', 'null'],
            enum: ['ACTIVITY', 'CLEAN', null],
        },
    },
    additionalProperties: false,
});

export default validate;
