import classnames from 'classnames';
import PropTypes from 'prop-types';

import { Grid, makeStyles } from '@material-ui/core';

import TextInput from '../../../../../../forms/TextInput';

import { Field } from 'react-final-form';
import { Select } from '../../../../../../forms';

const useStyles = makeStyles((theme) => ({
    row: {
        marginTop: theme.spacing(2),
        position: 'relative',
    },
    deleteButton: {
        opacity: 0.25,
        transition: 'opacity 0.25s ease',
        '&:hover': {
            opacity: 1,
        },
    },
}));

const BOOLEAN_VALUES = [
    { value: true, label: 'True' },
    { value: false, label: 'False' },
];

const BooleanRow = ({ className, name, disabled }) => {
    const classes = useStyles();

    return (
        <Grid
            className={classnames(className, classes.root)}
            container
            spacing={1}
            alignItems="flex-end"
        >
            <Grid item xs={3}>
                <Field
                    component={Select}
                    name={`${name}.value`}
                    label="Value"
                    options={BOOLEAN_VALUES}
                    disabled
                    margin="dense"
                />
            </Grid>
            <Grid item xs={5}>
                <Field
                    component={TextInput}
                    name={`${name}.label`}
                    label="Category"
                    fullWidth
                    disabled={disabled}
                    margin="dense"
                />
            </Grid>
        </Grid>
    );
};

BooleanRow.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    input: PropTypes.shape({
        value: PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.bool,
        }).isRequired,
        onChange: PropTypes.func.isRequired,
    }),
};

BooleanRow.defaultProps = {
    className: null,
    disabled: false,
    input: null,
};

export default BooleanRow;
