import { get } from 'lodash';
import { parseFilters } from './buildQuery';

const reactAdminParamsToQueryParams = (queryParams, resource) => ({
    ...queryParams.pagination,
    filter: parseFilters(resource, queryParams.filter),
    sortBy: get(queryParams, 'sort.field'),
    sortDir: get(queryParams, 'sort.order'),
});

export default reactAdminParamsToQueryParams;
