import PropTypes from 'prop-types';
import { OnChange } from 'react-final-form-listeners';

import {
    makeStyles,
    Grid,
    Select,
    MenuItem,
    ListItemIcon,
    ListItemText,
    FormControl,
    InputLabel,
    Tab,
} from '@material-ui/core';
import { Field, useForm } from 'react-final-form';

import { get } from 'lodash';
import { TextInput } from '../../../../forms';
import DecisionIcon from '../../blocks/icons/DecisionIcon';
import {
    OUTPUT_TYPE_APPROVED,
    OUTPUT_TYPE_MANUAL_REVIEW,
    OUTPUT_TYPE_REJECTED,
} from '../../outputTypes';

export const OutputTabs = [<Tab key="output" label="Output" />];

const useStyles = makeStyles(() => ({
    outputTypeSelect: {
        display: 'flex',
        alignItems: 'center',
    },
}));

// eslint-disable-next-line react/prop-types
const OutputTypeSelect = ({ input, modelOutputType, ...props }) => {
    return (
        <Select {...props} {...input}>
            <MenuItem value={OUTPUT_TYPE_APPROVED} style={{ display: 'flex' }}>
                <ListItemIcon>
                    <DecisionIcon
                        outputType={OUTPUT_TYPE_APPROVED}
                        modelOutputType={modelOutputType}
                    />
                </ListItemIcon>
                <ListItemText primary="Approved" />
            </MenuItem>
            <MenuItem value={OUTPUT_TYPE_MANUAL_REVIEW}>
                <ListItemIcon>
                    <DecisionIcon
                        outputType={OUTPUT_TYPE_MANUAL_REVIEW}
                        modelOutputType={modelOutputType}
                    />
                </ListItemIcon>
                <ListItemText primary="Manual Review" />
            </MenuItem>
            <MenuItem value={OUTPUT_TYPE_REJECTED}>
                <ListItemIcon>
                    <DecisionIcon
                        outputType={OUTPUT_TYPE_REJECTED}
                        modelOutputType={modelOutputType}
                    />
                </ListItemIcon>
                <ListItemText primary="Rejected" />
            </MenuItem>
        </Select>
    );
};

const DecisionConfiguration = ({ disabled }) => {
    const classes = useStyles();
    const form = useForm();

    return (
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <OnChange name="node.configuration.value">
                    {(value, previousValue) => {
                        const currentName = get(
                            form.getState(),
                            'values.node.name',
                        );

                        if (
                            !currentName ||
                            currentName === 'New Block' ||
                            previousValue === currentName
                        ) {
                            form.change('node.name', value);
                        }
                    }}
                </OnChange>
                <Field
                    name="node.configuration.value"
                    label="Output"
                    component={TextInput}
                    required
                    fullWidth
                    placeholder="APPROVED"
                    disabled={disabled}
                    autoFocus
                />
            </Grid>
            <Grid item>
                <FormControl>
                    <InputLabel id="output-type-label">Output Type</InputLabel>
                    <Field
                        labelId="output-type-label"
                        name="editor.outputType"
                        label="Output Type"
                        required
                        component={OutputTypeSelect}
                        defaultValue="approved"
                        fullWidth
                        disabled={disabled}
                        classes={{
                            root: classes.outputTypeSelect,
                        }}
                    />
                </FormControl>
            </Grid>
        </Grid>
    );
};

DecisionConfiguration.propTypes = {
    disabled: PropTypes.bool,
};

DecisionConfiguration.defaultProps = {
    disabled: false,
};

export default DecisionConfiguration;
