import validator from './validate';

const timeDifferenceProcessor = {
    name: 'Time Difference',
    slug: 'time-difference',
    description: 'Return the duration between two dates in the unit you want',
    validate: validator,
    // output = left - right
    inputs: [
        {
            id: 'left',
            name: 'Left',
            schema: {
                oneOf: [
                    { type: 'string', format: 'date' },
                    { type: 'string', format: 'date-time' },
                ],
            },
        },
        {
            id: 'right',
            name: 'Right',
            schema: {
                oneOf: [
                    { type: 'string', format: 'date' },
                    { type: 'string', format: 'date-time' },
                ],
            },
        },
    ],
    outputs: [{ id: 'output', name: 'Output', schema: { type: 'number' } }],
};

export default timeDifferenceProcessor;
