import PropTypes from 'prop-types';

import { PathSelect } from './PathSelect';

export const DateRangePathSelect = ({
    onChange,
    startDatePath,
    endDatePath,
}) => {
    const onStartDatePathChange = (startDatePath) => {
        onChange({ startDatePath, endDatePath });
    };
    const onEndDatePathChange = (endDatePath) => {
        onChange({ startDatePath, endDatePath });
    };

    return (
        <>
            <PathSelect
                label="Start Date Path"
                value={startDatePath}
                onChange={onStartDatePathChange}
                pathFilter={{ type: 'string', format: 'date' }}
            />
            <PathSelect
                label="End Date Path"
                value={endDatePath}
                onChange={onEndDatePathChange}
                pathFilter={{ type: 'string', format: 'date' }}
            />
        </>
    );
};

DateRangePathSelect.propTypes = {
    onChange: PropTypes.func.isRequired,
    startDatePath: PropTypes.string.isRequired,
    endDatePath: PropTypes.string.isRequired,
};
