import type { FunctionComponent } from 'react';
import type { ListProps, FilterProps } from 'react-admin';
import { TextInput, Filter, List, Datagrid, TextField } from 'react-admin';
import OrganizationNameField from './OrganizationNameField';

export const OrganizationFilters: FunctionComponent<Partial<FilterProps>> = (
    props,
) => (
    <Filter {...props}>
        <TextInput label="Name" source="name" />
    </Filter>
);

type Props = Omit<
    ListProps,
    'title' | 'filters' | 'bulkActionButtons' | 'perPage'
>;

export const OrganizationList: FunctionComponent<Props> = (props) => (
    <List
        title="Organizations"
        filters={<OrganizationFilters />}
        bulkActionButtons={false}
        perPage={25}
        {...props}
    >
        <Datagrid rowClick="edit">
            <OrganizationNameField />
            <TextField source="createdAt" />
            <TextField source="lastUpdatedAt" />
        </Datagrid>
    </List>
);
