import type { FunctionComponent } from 'react';
import PropTypes from 'prop-types';

import { Toolbar, SaveButton, DeleteWithConfirmButton } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});

type Props = {
    deletable?: boolean;
    saveable?: boolean;
    deleteConfirmTitle: (value: Record<string, unknown>) => string;
    record?: Record<string, unknown>;
};

export const CustomToolbar: FunctionComponent<Props> = ({
    deletable,
    saveable,
    deleteConfirmTitle,
    ...otherProps
}) => (
    <Toolbar {...otherProps} classes={{ ...useStyles() }}>
        {saveable && <SaveButton undoable={false} />}
        {deletable && (
            <DeleteWithConfirmButton
                confirmTitle={deleteConfirmTitle(otherProps.record)}
                undoable={false}
            />
        )}
    </Toolbar>
);

CustomToolbar.propTypes = {
    deletable: PropTypes.bool,
    saveable: PropTypes.bool,
    deleteConfirmTitle: PropTypes.func,
};

CustomToolbar.defaultProps = {
    deletable: true,
    saveable: true,
    deleteConfirmTitle: (record) => `Delete ${record.id} confirmation`,
};
