import { createValidator } from '../../validation/validatorFactory';

const validator = createValidator();

const validate = validator.compile({
    type: 'object',
    required: ['value', 'decisionType'],
    properties: {
        value: { type: 'string' },
        reason: {
            type: 'string',
        },
        decisionType: {
            type: 'string',
            enum: ['APPROVED', 'REJECTED', 'MANUAL_REVIEW'],
        },
    },
    additionalProperties: false,
});

export default validate;
