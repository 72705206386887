import { v4 as uuid4 } from 'uuid';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {
    makeStyles,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    icon: {
        right: theme.spacing(1),
        '& path': {
            fill: theme.palette.lighterGray,
        },
    },
    select: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
    },
}));

const IconComponent = (props) => {
    const classes = useStyles();

    return (
        <ExpandMore
            {...props}
            // eslint-disable-next-line react/prop-types
            className={classnames(classes.icon, props.className)}
        />
    );
};

const FlatSelect = ({
    label,
    input: { value, onChange },
    options,
    className,
    classes,
    ...props
}) => {
    const internalClasses = useStyles();
    const id = props.id || uuid4();
    const labelId = `${id}-label`;

    const handleChange = (evt) => {
        evt.stopPropagation();
        onChange(evt.target.value);
    };

    return (
        <FormControl fullWidth={props.fullWidth}>
            {label && <InputLabel id={labelId}>{label}</InputLabel>}
            <Select
                {...props}
                classes={{
                    ...classes,
                    select: classnames(internalClasses.select, className),
                }}
                labelId={label ? labelId : undefined}
                id={id}
                value={value}
                onChange={handleChange}
                IconComponent={IconComponent}
            >
                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

FlatSelect.propTypes = {
    id: PropTypes.string,
    InputProps: PropTypes.object,
    fullWidth: PropTypes.bool,
    label: PropTypes.string,
    input: PropTypes.shape({
        value: PropTypes.any,
        onChange: PropTypes.func.isRequired,
    }).isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any.isRequired,
            label: PropTypes.node.isRequired,
        }),
    ).isRequired,
    className: PropTypes.string,
    classes: PropTypes.object,
};

FlatSelect.defaultProps = {
    id: null,
    InputProps: {},
    fullWidth: false,
    label: null,
    className: null,
    classes: {},
};

export default FlatSelect;
