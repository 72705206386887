import PropTypes from 'prop-types';

import { Grid, ThemeProvider, Typography } from '@material-ui/core';
import { theme } from '../v4';
import Auth0Logo from './auth0.svg';

const Auth0Link = ({ record }) => {
    const profileUrl = `https://manage.auth0.com/dashboard/us/${process.env.REACT_APP_AUTH0_TENANT}/users/${record.auth0Id}`;

    return (
        <ThemeProvider theme={theme}>
            <Grid container spacing={2} alignItems="center">
                <Grid item>
                    <a
                        href={profileUrl}
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <img
                            width={16}
                            height={18}
                            style={{ marginTop: 3 }}
                            src={Auth0Logo}
                            alt="Auth0 Logo"
                        />
                    </a>
                </Grid>
                <a href={profileUrl} target="_blank" rel="noreferrer noopener">
                    <Typography variant="body1">Auth0 Profile</Typography>
                </a>
            </Grid>
        </ThemeProvider>
    );
};

Auth0Link.propTypes = {
    record: PropTypes.object.isRequired,
};

export default Auth0Link;
