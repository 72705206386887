import PropTypes from 'prop-types';

import { Drawer, makeStyles } from '@material-ui/core';

import ProcessorMenu from './ProcessorMenu';

const useStyles = makeStyles(() => ({
    drawerPaper: {
        minWidth: 670,
        padding: 0,
        overflowX: 'hidden', // or the delete row button from the Switch will be trigger the scroll bar
    },
}));

const ProcessorChoiceDrawer = ({
    opened,
    onSubmit,
    editorContainer,
    onClose,
    showRestrictedProcessors,
}) => {
    const classes = useStyles();

    return (
        <Drawer
            container={editorContainer}
            open={opened}
            onClose={onClose}
            anchor="right"
            classes={{ paper: classes.drawerPaper }}
        >
            <ProcessorMenu
                showRestrictedProcessors={showRestrictedProcessors}
                onSubmit={onSubmit}
                onClose={onClose}
            />
        </Drawer>
    );
};

ProcessorChoiceDrawer.propTypes = {
    editorContainer: PropTypes.element,
    opened: PropTypes.bool,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    showRestrictedProcessors: PropTypes.bool,
};

ProcessorChoiceDrawer.defaultProps = {
    editorContainer: null,
    opened: false,
    onClose: () => {},
    showRestrictedProcessors: false,
};

export default ProcessorChoiceDrawer;
