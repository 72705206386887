import { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles, Button } from '@material-ui/core';

import { MODEL_TYPES } from '@tint/core/src/models/types';
import {
    blueprintPropType,
    editorDataPropType,
} from '../blueprintEditorPropTypes';

import PopoverMenu from '../../../PopoverMenu';
import { PlusIcon } from '../../../../v4/icons';

import ContextMenuNodes from './ContextMenuNodes';

const useStyles = makeStyles(() => ({}));

const NewBlockButton = ({
    blueprint,
    editorData,
    onOpenProcessorMenu,
    dispatchEvent,
    cameraPosition,
    onSelect,
    disabled,
    viewport,
    modelOutputType,
}) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleOpenChange = (value) => setOpen(value);
    const handleClick = () => setOpen(false);

    return (
        <>
            <PopoverMenu
                open={open}
                onChange={handleOpenChange}
                button={
                    <Button
                        classes={{ iconSizeLarge: classes.icon }}
                        color="primary"
                        startIcon={<PlusIcon />}
                        disabled={disabled}
                    >
                        New Block
                    </Button>
                }
                PopperProps={{
                    style: {
                        // Above the Blueprint Editor - let the z-index inflation apocalypse begin!
                        zIndex: 10,
                    },
                }}
            >
                <ContextMenuNodes
                    blueprint={blueprint}
                    editorData={editorData}
                    modelOutputType={modelOutputType}
                    onOpenProcessorMenu={onOpenProcessorMenu}
                    dispatchEvent={dispatchEvent}
                    cameraPosition={cameraPosition}
                    onSelect={onSelect}
                    viewport={viewport}
                    onClick={handleClick}
                />
            </PopoverMenu>
        </>
    );
};

NewBlockButton.propTypes = {
    blueprint: PropTypes.shape(blueprintPropType).isRequired,
    editorData: PropTypes.shape(editorDataPropType).isRequired,
    onOpenProcessorMenu: PropTypes.func.isRequired,
    dispatchEvent: PropTypes.func.isRequired,
    cameraPosition: PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired,
        zoom: PropTypes.number.isRequired,
    }).isRequired,
    onSelect: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    viewport: PropTypes.shape({
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
    }),
    modelOutputType: PropTypes.oneOf(MODEL_TYPES),
};

NewBlockButton.defaultProps = {
    disabled: false,
    viewport: null,
    modelOutputType: null,
};

export default NewBlockButton;
