import PropTypes from 'prop-types';

import { FEATURE_BLOCK, OUTPUT_BLOCK, PROCESSOR_BLOCK } from './blocks';

const edge = {
    node: PropTypes.string.isRequired,
    pin: PropTypes.string.isRequired,
};

export const blueprintPropType = {
    nodes: PropTypes.object.isRequired,
    edges: PropTypes.arrayOf(
        PropTypes.shape({
            start: PropTypes.shape(edge).isRequired,
            end: PropTypes.shape(edge).isRequired,
        }),
    ).isRequired,
    newBlock: PropTypes.oneOf([FEATURE_BLOCK, OUTPUT_BLOCK, PROCESSOR_BLOCK]),
};

export const editorDataPropType = {
    // Contains position (and output node type if needed) for each node
    nodes: PropTypes.object,
    // Position of the camera
    position: PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired,
        zoom: PropTypes.number.isRequired,
    }),
    // Nodes position when the simplified view is enabled
    simplifiedNodes: PropTypes.object,
};
