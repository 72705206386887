import apolloClientFactory from './apolloClientFactory';
import { camelize } from 'inflection';
import { get } from 'lodash';
import { CREATE, DELETE, GET_LIST, GET_MANY, GET_ONE } from 'react-admin';
import { introspectSchema } from 'ra-data-graphql';
import { buildQuery } from './graphql/buildQuery';
import queries from './graphql/queries';
import { uncapitalize } from './stringUtils';
import reactAdminParamsToQueryParams from './graphql/reactAdminParamsToQueryParams';
import dataProviders from './dataProviders';

const isQuery = (raFetchType) =>
    [GET_LIST, GET_MANY, GET_ONE].includes(raFetchType);

const dataProvider = async (introspectionResults) => {
    const client = apolloClientFactory({
        uri: process.env.REACT_APP_GRAPHQL_ENDPOINT_URL,
    });

    const introspection = await introspectSchema(client, introspectionResults);

    const raDataProvider = async (raFetchType, resource, params) => {
        const resourceProvider = dataProviders(client)[resource];
        if (resourceProvider && resourceProvider[raFetchType]) {
            return resourceProvider[raFetchType](params, client);
        }

        // `getList` instead of `GET_LIST`
        const queryOperation = uncapitalize(
            camelize(raFetchType.toLowerCase()),
        );

        const manualQuery = get(queries, `${resource}.${queryOperation}`);

        const dataToInput = get(
            queries,
            `${resource}.dataToInput`,
            ({ data }) => data,
        );

        let apolloQuery;
        if (manualQuery) {
            let queryParams = {};

            if ([GET_LIST, GET_MANY].includes(raFetchType)) {
                queryParams = reactAdminParamsToQueryParams(params, resource);
            } else if (raFetchType === GET_ONE) {
                queryParams = params;
            } else {
                if (raFetchType !== CREATE) {
                    queryParams.id = params.id;
                }

                if (raFetchType !== DELETE) {
                    queryParams.input = dataToInput(params);
                }
            }

            apolloQuery = {
                query: manualQuery,
                variables: queryParams,
            };
        } else {
            apolloQuery = buildQuery(introspection)(
                raFetchType,
                resource,
                params,
            );
        }

        const response = await (isQuery(raFetchType)
            ? client.query({
                  ...apolloQuery,
                  fetchPolicy: 'network-only',
              })
            : client.mutate({
                  mutation: apolloQuery.query,
                  variables: apolloQuery.variables,
              }));

        const queryResults = Object.values(response.data)[0];
        if (raFetchType === GET_LIST || raFetchType === GET_MANY) {
            return {
                data: queryResults.items,
                total: queryResults.count,
            };
        }

        return { data: queryResults };
    };

    raDataProvider.saga = () => {};

    return raDataProvider;
};

export default dataProvider;
