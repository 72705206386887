import { TopToolbar } from 'react-admin';
import { UserActiveField } from './UserActiveField';

type UserEditActionsProps = {
    data: {
        id: string;
        email: string;
        archived: boolean;
    };
};

export const UserEditActions = ({
    data,
}: UserEditActionsProps): JSX.Element => (
    <TopToolbar>
        <UserActiveField record={data} />
    </TopToolbar>
);
