import PropTypes from 'prop-types';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { Field } from 'react-final-form';
import ViolationTable from './ViolationTable';
import SimpleSelect from '../../../../../forms/SimpleSelect';
import { Checkbox } from '../../../../../forms';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 640,
    },
    switchRoot: {
        marginRight: theme.spacing(1),
    },
}));

const ConfigurationTabs = ({ disabled }) => {
    const classes = useStyles();

    return (
        <Grid container direction="column" spacing={2} className={classes.root}>
            <Grid item xs={12}>
                <Field
                    name="node.configuration.hint"
                    component={SimpleSelect}
                    label="Activity Hint"
                    disabled={disabled}
                    defaultValue="Full"
                    options={[
                        {
                            label: 'Full',
                            value: 'Full',
                        },
                        {
                            label: 'Clean',
                            value: 'Clean',
                        },
                        {
                            label: 'Activity',
                            value: 'Activity',
                        },
                    ]}
                    helper={
                        <>
                            Please refer to your point of contact at Samba
                            Safety for more information on which value to use
                        </>
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <Field
                    name="node.configuration.alwaysUseTintTriggers"
                    label="Always use Tint configured rules"
                    defaultValue={true}
                    component={Checkbox}
                    disabled={disabled}
                    type="checkbox"
                    helper={
                        <>
                            Configurable triggers can be defined on Samba Safety
                            to return <code>CLEAN</code> or{' '}
                            <code>ACTIVITY</code>. However, some states are not
                            eligible and in that case, the triggers configured
                            below will be applied.
                            <br />
                            Check this box if you want to use the Tint rules for
                            all states, including the ones normally handled by
                            Samba Safety.
                        </>
                    }
                />
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h3">Violation Thresholds</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body2">
                        Set up thresholds for all possible MVR violations. If
                        the number of violations is higher or equal to the
                        configured threshold, then the output of this block
                        would be <code>ACTIVITY</code>. Otherwise, it would be{' '}
                        <code>CLEAN</code>.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <ViolationTable disabled={disabled} />
                </Grid>
            </Grid>
        </Grid>
    );
};

ConfigurationTabs.propTypes = {
    disabled: PropTypes.bool,
};

ConfigurationTabs.defaultProps = {
    disabled: false,
};

export default ConfigurationTabs;
