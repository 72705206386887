import { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import {
    Paper,
    TableContainer,
    Table as MuiTable,
    TableHead,
    TableBody,
    TableRow,
    makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    row: {
        cursor: ({ clickable }) => (clickable ? 'pointer' : undefined),
        '&:focus': {
            backgroundColor: theme.palette.grays[200],
        },
    },
}));

const Table = ({
    data,
    handleSortChange,
    sortBy,
    sortDir,
    onRowClick,
    children,
}) => {
    const classes = useStyles({ clickable: !!onRowClick });
    return (
        <TableContainer component={Paper} elevation={0}>
            <MuiTable>
                <TableHead>
                    <TableRow>
                        {Children.map(children, (cell) => {
                            if (!cell) {
                                return null;
                            }
                            return cloneElement(cell, {
                                ...cell.props,
                                isHeader: true,
                                handleSortChange,
                                sortBy,
                                sortDir,
                            });
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((datum) => (
                        <TableRow
                            key={datum.id}
                            data-testid="table-row"
                            tabIndex="0"
                            className={classes.row}
                            hover={!!onRowClick}
                            onMouseDown={(event) => {
                                if (!onRowClick || event.button > 1) {
                                    return;
                                }
                                onRowClick(datum)(event);
                            }}
                            onKeyPress={(event) => {
                                if (!onRowClick || event.which !== 32) {
                                    return;
                                }

                                onRowClick(datum)(event);
                            }}
                        >
                            {Children.map(children, (cell) => {
                                if (!cell) {
                                    return null;
                                }
                                return cloneElement(cell, {
                                    ...cell.props,
                                    data: datum,
                                });
                            })}
                        </TableRow>
                    ))}
                </TableBody>
            </MuiTable>
        </TableContainer>
    );
};
Table.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.string).isRequired,
    data: PropTypes.arrayOf(
        PropTypes.shape({ id: PropTypes.string.isRequired }),
    ).isRequired,
    handleSortChange: PropTypes.func.isRequired,
    sortBy: PropTypes.string.isRequired,
    sortDir: PropTypes.oneOf(['asc', 'dir']).isRequired,
    onRowClick: PropTypes.func.isRequired,
    children: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default Table;
