import {
    TextInput,
    Filter,
    List,
    Datagrid,
    TextField,
    SelectInput,
} from 'react-admin';
import OrganizationReferenceInput from '../organizations/OrganizationReferenceInput';
import OrganizationRolesField from './OrganizationRolesField';
import { UserActiveField } from './UserActiveField';

export const UserFilters = (props) => (
    <Filter {...props}>
        <TextInput label="Email" source="email" alwaysOn />
        <OrganizationReferenceInput
            label="Organization"
            source="organizationId"
            alwaysOn
        />
        <SelectInput
            source="archived"
            choices={[
                { id: false, name: 'Active' },
                { id: true, name: 'Archived' },
            ]}
            allowEmpty={false}
            alwaysOn
        />
    </Filter>
);

export const UserList = (props) => {
    return (
        <List
            title="Users"
            filters={<UserFilters />}
            filterDefaultValues={{ archived: false }}
            bulkActionButtons={false}
            perPage={25}
            {...props}
        >
            <Datagrid rowClick="edit">
                <TextField source="email" />
                <OrganizationRolesField label="Organizations" />
                <TextField source="createdAt" />
                <TextField source="lastUpdatedAt" />
                <UserActiveField />
            </Datagrid>
        </List>
    );
};
