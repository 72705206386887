import classnames from 'classnames';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { IconButton, makeStyles } from '@material-ui/core';
import { legacyFormatNumber } from '@tint/core/src/formatters';
import { DoubleRightArrowIcon, DownIcon } from '../icons';
import { TextInput } from '../forms';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        margin: theme.spacing(4, 0),
    },
    iconButton: {
        '& svg path': {
            fill: theme.palette.mainBlue,
        },
        '&:disabled': {
            '& svg path': {
                fill: theme.palette.darkerGray,
            },
        },
    },
    icon: {
        position: 'relative',
        fontSize: 10,
    },
    firstPageIcon: {
        fontSize: 12,
        transform: 'rotate(180deg)',
    },
    previousPageIcon: {
        transform: 'rotate(90deg)',
    },
    nextPageIcon: {
        transform: 'rotate(-90deg)',
        left: 1,
    },
    lastPageIcon: {
        fontSize: 12,
        left: 1,
    },
    numberItems: {
        paddingRight: 19,
        alignSelf: 'center',
    },
    pageNumber: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 5px',
    },
    pageNumberInput: {
        width: 60,
        marginRight: 12,
        fontSize: '0.875rem',
        color: theme.palette.darkerGray,
        '& input': {
            textAlign: 'center',
        },
    },
}));

const TablePagination = ({ className, page, onPageChange, perPage, total }) => {
    const classes = useStyles();
    const numberPages = Math.ceil(total / perPage) || 1;

    const [inputValue, setInputValue] = useState(page);

    useEffect(() => {
        setInputValue(page);
    }, [page]);

    return (
        <div className={classnames(classes.root, className)}>
            <span className={classes.numberItems}>
                {legacyFormatNumber(total)} items
            </span>
            <IconButton
                aria-label="Go to first page"
                className={classes.iconButton}
                onClick={onPageChange(1)}
                disabled={page <= 1}
            >
                <DoubleRightArrowIcon
                    className={classnames(classes.icon, classes.firstPageIcon)}
                />
            </IconButton>
            <IconButton
                aria-label="Go to previous page"
                className={classes.iconButton}
                onClick={onPageChange(page - 1)}
                disabled={page <= 1}
            >
                <DownIcon
                    className={classnames(
                        classes.icon,
                        classes.previousPageIcon,
                    )}
                />
            </IconButton>
            <span className={classes.pageNumber}>
                <TextInput
                    className={classes.pageNumberInput}
                    fullWidth={false}
                    type="number"
                    meta={{}}
                    inputProps={{
                        min: 1,
                        max: numberPages,
                        'aria-label': 'Page Number',
                        onBlur: onPageChange(inputValue),
                        onKeyDown: (event) => {
                            if (event.keyCode == 13) {
                                onPageChange(inputValue)();
                            }
                        },
                    }}
                    input={{
                        value: inputValue,
                        onChange: ({ target: { value } }) => {
                            const newPage = +value;
                            setInputValue(
                                newPage > numberPages ? numberPages : newPage,
                            );
                        },
                    }}
                />
                of {legacyFormatNumber(numberPages)}
            </span>
            <IconButton
                aria-label="Go to next page"
                className={classes.iconButton}
                onClick={onPageChange(page + 1)}
                disabled={page >= numberPages}
            >
                <DownIcon
                    className={classnames(classes.icon, classes.nextPageIcon)}
                />
            </IconButton>
            <IconButton
                aria-label="Go to last page"
                className={classes.iconButton}
                onClick={onPageChange(numberPages)}
                disabled={page >= numberPages}
            >
                <DoubleRightArrowIcon
                    className={classnames(classes.icon, classes.lastPageIcon)}
                />
            </IconButton>
        </div>
    );
};

TablePagination.propTypes = {
    className: PropTypes.string,
    page: PropTypes.number,
    perPage: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
};

TablePagination.defaultProps = {
    className: null,
    page: 1,
};

export default TablePagination;
