import PropTypes from 'prop-types';

import {
    FormControl,
    FormHelperText,
    InputLabel,
    NativeSelect as NativeSelectBase,
} from '@material-ui/core';
import { uniqueId } from 'lodash';

const NativeSelect = ({
    label,
    helper,
    input: { name, onChange, value },
    ...otherProps
}) => {
    const id = uniqueId();

    return (
        <FormControl>
            <InputLabel htmlFor={id}>{label}</InputLabel>
            <NativeSelectBase
                value={value}
                onChange={onChange}
                inputProps={{
                    name,
                    id,
                }}
                {...otherProps}
            />
            {helper && <FormHelperText>{helper}</FormHelperText>}
        </FormControl>
    );
};

NativeSelect.propTypes = {
    label: PropTypes.string.isRequired,
    helper: PropTypes.string,
    input: PropTypes.shape({
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        value: PropTypes.any,
    }).isRequired,
};

NativeSelect.defaultProps = {};

export default NativeSelect;
