import omit from 'lodash/omit';
import type { FunctionComponent } from 'react';
import { useState } from 'react';
import { useNotify, ResettableTextField } from 'react-admin';
import { useMutation, gql } from '@apollo/client';
import { useFormState } from 'react-final-form';
import CircularProgress from '@material-ui/core/CircularProgress';
import MailIcon from '@material-ui/icons/Mail';
import Grid from '@material-ui/core/Grid';

import { Button } from '../v4';
import { FormControl, FormLabel } from '@material-ui/core';

const TEST_MAIL_CONFIG = gql`
    mutation testMailConfig(
        $id: ID!
        $email: String!
        $mailConfig: MailConfigInput!
    ) {
        testMailConfig(id: $id, email: $email, mailConfig: $mailConfig)
    }
`;

const TestMailConfig: FunctionComponent = () => {
    const notify = useNotify();
    const { values } = useFormState();
    const [email, setEmail] = useState<string>('');
    const [testMailConfig, { loading }] = useMutation(TEST_MAIL_CONFIG, {
        onError: (error) => {
            notify(error.message, 'error');
        },
        onCompleted: () => {
            notify(`Mail sent with success. Check ${email} inbox`);
        },
    });

    return (
        <FormControl fullWidth>
            <FormLabel>Test the email provider configuration</FormLabel>
            <Grid container xs={12} spacing={1} alignItems="center">
                <Grid item xs={8}>
                    <ResettableTextField
                        label="Recipient"
                        placeholder="email"
                        source="testEmail"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={
                            loading ? (
                                <CircularProgress size={16} />
                            ) : (
                                <MailIcon />
                            )
                        }
                        onClick={() =>
                            testMailConfig({
                                variables: {
                                    id: values.id,
                                    email,
                                    mailConfig: {
                                        ...omit(values.mailConfig, [
                                            '__typename',
                                        ]),
                                        auth: omit(values.mailConfig.auth, [
                                            '__typename',
                                        ]),
                                    },
                                },
                            })
                        }
                    >
                        Send an email
                    </Button>
                </Grid>
            </Grid>
        </FormControl>
    );
};

export default TestMailConfig;
