import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Field } from 'react-final-form';

import { Select } from '../../../../../forms';

import TimeoutField from '../fields/TimeoutField';

const DEFAULT_TIMEOUT = 6000;
const DEFAULT_FALLBACK_VALUE = null;

const parseFallbackValue = (value) => (value === 'null' ? null : value);
const formatFallbackValue = (value) => (value === null ? 'null' : value);

const options = [
    {
        value: 'ACTIVITY',
        label: 'Activity',
    },
    {
        value: 'CLEAN',
        label: 'Clean',
    },
    {
        value: 'null',
        label: 'Null',
    },
];

const AdvancedTab = ({ disabled }) => {
    return (
        <Grid container direction="column" spacing={3}>
            <Grid item xs={8}>
                <TimeoutField
                    disabled={disabled}
                    defaultTimeout={DEFAULT_TIMEOUT}
                    helperText="Max wait time for Samba Safety response before raising an error"
                />
            </Grid>
            <Grid item xs={8}>
                <Field
                    component={Select}
                    disabled={disabled}
                    name="node.configuration.fallbackValue"
                    label="Default Value on Error"
                    options={options}
                    defaultValue={DEFAULT_FALLBACK_VALUE}
                    helperText={
                        <>
                            Samba Safety may sometimes be too slow to respond or
                            return an error,
                            <br />
                            in those cases, this default value will be returned
                        </>
                    }
                    disableClearable
                    parse={parseFallbackValue}
                    format={formatFallbackValue}
                />
            </Grid>
        </Grid>
    );
};

AdvancedTab.propTypes = {
    disabled: PropTypes.bool,
};

AdvancedTab.defaultProps = {
    disabled: false,
};

export default AdvancedTab;
