import PropTypes from 'prop-types';

import { makeStyles, Typography } from '@material-ui/core';

import { get } from 'lodash';
import { dataPropType, blockIoDescription } from '../blockPropTypes';
import { getPinPositionFromTop } from '../DefaultPins';
import ConnectorPin from '../ConnectorPin';
import {
    VIEW_MODE_ADVANCED,
    VIEW_MODE_SIMPLIFIED,
} from '../../viewModes/viewModes';

const useStyles = makeStyles((theme) => ({
    section: {
        position: 'relative',
        '&:not(:last-child)': {
            borderBottom: `1px dashed ${theme.palette.lighterGray}`,
        },
    },
    label: {
        padding: theme.spacing(2),
        fontSize: 12,
    },
    addOption: {
        padding: theme.spacing(1, 2),
        fontSize: 12,
        color: theme.palette.primary.main,
        cursor: 'pointer',
    },
}));

export const SwitchHeaderPins = ({ data, inputs, ...props }) => {
    const { viewMode, connectedInputs, disabled } = data;
    const getInputPinPosition = getPinPositionFromTop(inputs.length);

    return (
        <>
            {(!disabled || !!connectedInputs.length) && (
                <ConnectorPin
                    {...props}
                    selector={`${data.id}-conditions[0]`}
                    nodeId={data.id}
                    type="target"
                    position={
                        viewMode === VIEW_MODE_SIMPLIFIED ? 'left' : 'top'
                    }
                    id="conditions[0]"
                    name="Trigger"
                />
            )}
            {viewMode === VIEW_MODE_ADVANCED &&
                inputs.map((input, i) => (
                    <ConnectorPin
                        {...props}
                        key={input.id}
                        selector={`${data.id}-${input.id}`}
                        nodeId={data.id}
                        type="target"
                        position="left"
                        id={input.id}
                        name={input.name}
                        style={{
                            top: getInputPinPosition(i),
                        }}
                    />
                ))}
        </>
    );
};

SwitchHeaderPins.propTypes = {
    data: PropTypes.shape(dataPropType).isRequired,
    inputs: blockIoDescription.isRequired,
    outputs: blockIoDescription.isRequired,
};

SwitchHeaderPins.defaultProps = {};

const SwitchBlockContent = ({ data, outputs, ...props }) => {
    const classes = useStyles();
    const values = get(data, 'configuration.conditions', [])
        .map((condition) => condition.label)
        .filter(Boolean);

    const { viewMode, disabled } = data;
    const catchAll = get(data, 'configuration.catchAll');

    return (
        <>
            {values.map((value, i) => (
                <div key={value} className={classes.section}>
                    <Typography
                        component="div"
                        variant="body1"
                        className={classes.label}
                    >
                        {value}
                    </Typography>
                    <ConnectorPin
                        {...props}
                        key={outputs[i].id}
                        selector={`${data.id}-${outputs[i].id}`}
                        nodeId={data.id}
                        type="source"
                        position="right"
                        id={outputs[i].id}
                        name={outputs[i].name}
                        style={{
                            top: '50%',
                        }}
                    />
                </div>
            ))}
            {catchAll !== undefined && (
                <div className={classes.section}>
                    <Typography
                        component="div"
                        variant="body1"
                        className={classes.label}
                    >
                        {catchAll === null ? 'null' : catchAll}
                    </Typography>
                    <ConnectorPin
                        {...props}
                        selector={`${data.id}-catchAll`}
                        nodeId={data.id}
                        type="source"
                        position="right"
                        id="catchAll"
                        name={catchAll === null ? 'null' : catchAll}
                        style={{
                            top: '50%',
                        }}
                    />
                </div>
            )}
            {!disabled && viewMode === VIEW_MODE_ADVANCED && (
                <div className={classes.section}>
                    <Typography
                        component="div"
                        variant="body1"
                        className={classes.addOption}
                    >
                        + Add Option
                    </Typography>
                </div>
            )}
        </>
    );
};

SwitchBlockContent.propTypes = {
    data: PropTypes.shape(dataPropType).isRequired,
    inputs: blockIoDescription.isRequired,
    outputs: blockIoDescription.isRequired,
};

SwitchBlockContent.defaultProps = {};

export default SwitchBlockContent;
