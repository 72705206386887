import validator from './validate';

const fuzzyMatchingProcessor = {
    name: 'Fuzzy Matching',
    slug: 'fuzzy-matching',
    description: 'Return the percentage of matching between two strings.',
    validate: validator,
    inputs: [
        {
            id: 'left',
            name: 'String 1',
            schema: { type: 'string' },
        },
        {
            id: 'right',
            name: 'String 2',
            schema: { type: 'string' },
        },
    ],
    outputs: [
        {
            id: 'output',
            name: 'Output',
            schema: { type: 'number' },
        },
    ],
};

export default fuzzyMatchingProcessor;
