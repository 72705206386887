import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import CursorSvg from './cursor.svg';
import { ThumbComponent } from './ThumbComponent';
import theme from '../../../theme';

const useStyles = makeStyles({
    thumb: {
        position: 'relative',
        display: 'block',
        borderRadius: 0,
        background: `transparent url("${CursorSvg}") no-repeat center center`,
        color: '#fff',
        height: 64,
        lineHeight: '46px',
        textAlign: 'center',
        width: 94,
        marginTop: -22,
        marginLeft: -46,
        '&:focus,&:hover,&$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        color: ({ trackColor }) => trackColor,
        height: 5,
        borderRadius: 4,
    },
    rail: {
        color: ({ railColor }) => railColor,
        opacity: 1,
        height: 5,
        borderRadius: 4,
        marginRight: 40,
    },
});

const RangeInput = ({
    ariaLabel,
    className,
    trackColor,
    railColor,
    ...otherProps
}) => {
    const classes = useStyles({ className, trackColor, railColor });

    return (
        <div className={className}>
            <Slider
                aria-label={ariaLabel}
                classes={classes}
                valueLabelDisplay="on"
                ThumbComponent={ThumbComponent}
                {...otherProps}
            />
        </div>
    );
};

RangeInput.propTypes = {
    className: PropTypes.string,
    ariaLabel: PropTypes.string.isRequired,
    railColor: PropTypes.string,
    trackColor: PropTypes.string,
};

RangeInput.defaultProps = {
    className: '',
    railColor: theme.palette.secondarBlue15Percent,
    trackColor: theme.palette.secondary.main,
};

export default RangeInput;
