import { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography/Typography';
import useTheme from '@material-ui/core/styles/useTheme';
import EditIcon from '@material-ui/icons/Edit';
import TextInput from './TextInput';
import Loader from '../Loader';
import { IconButton } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    loader: {
        marginLeft: theme.spacing(1),
    },
    editButton: {
        padding: 4,
        marginLeft: theme.spacing(),
    },
    icon: {
        fontSize: '1rem',
    },
    iconError: {
        marginLeft: theme.spacing(),
        cursor: 'pointer',
        color: theme.palette.error.main,
        '&:hover': {
            color: theme.palette.error.dark,
        },
    },
}));

const EditableTextInput = ({
    className,
    disabled,
    input,
    loading,
    variant,
    formatValue,
    parseValue,
    ariaLabelledBy,
    ...otherProps
}) => {
    const classes = useStyles({ variant });
    const theme = useTheme();
    const [editMode, setEditMode] = useState(false);

    const { onChange, onBlur, value } = input;

    const handleChange = (event) => {
        onChange && onChange(parseValue(event.target.value));
    };

    const handleBlur = (event) => {
        const {
            target: { value: newValue },
        } = event;

        const isTextInputOnError = !!otherProps?.meta?.error;
        setEditMode(isTextInputOnError);
        if (onBlur) {
            onBlur(newValue === '' ? value : parseValue(newValue));
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();

            const isTextInputOnError = !!otherProps?.meta?.error;
            setEditMode(isTextInputOnError);
            if (onBlur) {
                onBlur(parseValue(event.target.value));
            }
        }
    };

    const handleClick = () => {
        setEditMode(true);
    };

    if (!disabled && editMode) {
        return (
            <TextInput
                {...input}
                {...otherProps}
                inputProps={{
                    'aria-labelledby': ariaLabelledBy,
                }}
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyPress={handleKeyPress}
                variant="outlined"
                autoFocus
            />
        );
    }

    const { InputProps, ...typographyProps } = otherProps;

    return (
        <Typography
            className={classnames(classes.root, className)}
            variant={variant}
            component="div"
            aria-labelledby={ariaLabelledBy}
            {...typographyProps}
            onClick={handleClick}
        >
            {formatValue(value)}
            {loading && (
                <Loader
                    className={classes.loader}
                    inline
                    size={16}
                    color={theme.palette.darkerGray}
                />
            )}
            {!loading && !disabled && (
                <IconButton
                    size="small"
                    onClick={handleClick}
                    aria-label={ariaLabelledBy}
                    className={classes.editButton}
                >
                    <EditIcon className={classes.icon} />
                </IconButton>
            )}
        </Typography>
    );
};

EditableTextInput.propTypes = {
    className: PropTypes.string,
    input: PropTypes.shape({
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        value: PropTypes.any.isRequired,
    }).isRequired,
    otherProps: PropTypes.shape({
        meta: PropTypes.shape({
            touched: PropTypes.bool,
            error: PropTypes.string,
        }),
    }),
    loading: PropTypes.bool,
    variant: PropTypes.string,
    disabled: PropTypes.bool,
    formatValue: PropTypes.func,
    parseValue: PropTypes.func,
    ariaLabelledBy: PropTypes.string,
};

EditableTextInput.defaultProps = {
    className: null,
    variant: 'body1',
    loading: false,
    disabled: false,
    formatValue: (v) => v,
    parseValue: (v) => v,
};

export default EditableTextInput;
