import validator from './validate';

const logarithmProcessor = {
    name: 'Logarithm',
    slug: 'logarithm',
    description: 'Return the decimal logarithm of the input',
    validate: validator,
    inputs: [
        {
            id: 'input',
            name: 'Input',
            schema: { type: 'number' },
        },
    ],
    outputs: [
        {
            id: 'output',
            name: 'Output',
            schema: { type: 'number' },
        },
    ],
};

export default logarithmProcessor;
