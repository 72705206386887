import PropTypes from 'prop-types';

import {
    ArrayInput,
    SimpleForm,
    SimpleFormIterator,
    TextInput,
    SelectInput,
    BooleanInput,
    ReferenceInput,
} from 'react-admin';
import { get, set } from 'lodash';
import { CustomToolbar } from '../forms/CustomToolbar';
import Auth0Link from './Auth0Link';
import OrganizationRoleRow from './OrganizationRoleRow';
import { Typography, makeStyles } from '@material-ui/core';
import { mainRed } from '../v4/theme';
import { jobTitleChoices } from '@tint/core/src/users/types';

const useStyles = makeStyles(() => ({
    archivedMessage: {
        color: mainRed,
        fontWeight: 'bold',
    },
}));

export const validateForm = (values) => {
    const errors = {};

    const { organizationRoles = [] } = values;

    const assignedOrganizations = [];
    for (let i = 0; i < organizationRoles.length; i++) {
        const organizationRole = organizationRoles[i];
        if (!organizationRole) {
            set(errors, `organizationRoles[${i}]`, {
                organization: { id: 'Required field' },
                role: { id: 'Required field' },
            });

            continue;
        }

        const organizationId = get(
            values,
            `organizationRoles[${i}].organization.id`,
        );
        if (!organizationId) {
            set(
                errors,
                `organizationRoles[${i}].organization.id`,
                'Required field',
            );
        }

        if (assignedOrganizations.includes(organizationId)) {
            set(
                errors,
                `organizationRoles[${i}].organization.id`,
                'Already assigned organization',
            );
        }

        assignedOrganizations.push(organizationId);

        if (!get(values, `organizationRoles[${i}].role.id`)) {
            set(errors, `organizationRoles[${i}].role.id`, 'Required field');
        }
    }

    return errors;
};

export const UserForm = (props) => {
    const styles = useStyles();

    return (
        <SimpleForm
            validate={validateForm}
            toolbar={<CustomToolbar deletable={false} />}
            {...props}
        >
            {props.record.archived && (
                <Typography className={styles.archivedMessage}>
                    This user is archived
                </Typography>
            )}
            <TextInput source="email" disabled />
            <TextInput source="firstName" />
            <TextInput source="lastName" />

            <BooleanInput source="admin" />
            <ReferenceInput
                label="Global superadmin role"
                reference="Role"
                perPage={500}
                source="role.id"
                allowEmpty
                emptyText="Remove superadmin role"
            >
                <SelectInput />
            </ReferenceInput>

            <SelectInput
                label="Job Title"
                source="jobTitle"
                choices={jobTitleChoices.map((choice) => ({
                    id: choice,
                    name: choice,
                }))}
                allowEmpty
                alwaysOn
            />

            <ArrayInput source="organizationRoles" label="Organizations">
                <SimpleFormIterator>
                    <OrganizationRoleRow />
                </SimpleFormIterator>
            </ArrayInput>
            <Auth0Link />
        </SimpleForm>
    );
};

UserForm.propTypes = {
    record: PropTypes.object.isRequired,
};
