import OutputTypes from '../outputTypes';
import { ModelOutputType } from '../../models/types';
import validator from './validate';

interface ReferencedModelConfiguration {
    modelId: string;
    outputType?: ModelOutputType;
}

interface GetType {
    configuration: ReferencedModelConfiguration;
}

export const getType = ({ configuration }: GetType): OutputTypes => {
    if (configuration.outputType === ModelOutputType.DECISION) {
        return OutputTypes.STRING;
    }

    return OutputTypes.NUMBER;
};

const referencedModelProcessor = {
    name: 'Referenced Model',
    slug: 'referenced-model',
    description: 'Use the output of another model into your model',
    validate: validator,
    inputs: [] as never[],
    outputs: [
        {
            id: 'output',
            name: 'Output',
            getType,
        },
    ],
};

export default referencedModelProcessor;
