import {
    OUTPUT_TYPE_APPROVED,
    OUTPUT_TYPE_MANUAL_REVIEW,
    OUTPUT_TYPE_REJECTED,
} from '../outputTypes';

const getColorFromOutputType = (theme, outputType, withErrors = false) => {
    if (withErrors) {
        return theme.palette.mainRed;
    }

    switch (outputType) {
        case OUTPUT_TYPE_APPROVED:
            return theme.palette.mainGreen;

        case OUTPUT_TYPE_REJECTED:
            return theme.palette.mainRed;

        case OUTPUT_TYPE_MANUAL_REVIEW:
            return theme.palette.warning.main;

        default:
            return theme.palette.cardOutlineGray;
    }
};

export default getColorFromOutputType;
