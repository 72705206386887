// Need help for typing the validation/processors directory
import validator from './validate';

export type EkataIdentityCheckProcessorNode = {
    processor: 'ekata-identity-check';
    configuration?: {
        timeout?: number;
        fallbackIdentityCheckScore?: number;
        fallbackIdentityNetworkScore?: number;
        fallbackEstimatedAge?: number;
        fallbackPhoneNumberIsValid?: boolean;
        fallbackPhoneNumberCarrier?: string;
        fallbackPhoneNumberIsPrepaid?: boolean;
        fallbackPhoneNumberMatchToName?: string;
        fallbackPhoneNumberMatchToAddress?: string;
        fallbackAddressMatchToName?: string;
        fallbackEmailFirstSeenDays?: number;
        fallbackEmailMatchToName?: string;

        fallbackEmailIsAutogenerated?: boolean;
        fallbackEmailIsDisposable?: boolean;
        fallbackEmailDomainCreationDays?: number;
        fallbackIpDistanceFromAddress?: number;
        fallbackIpDistanceFromPhoneNumber?: number;
    };
};

const ekataIdentityCheckProcessor = {
    name: 'Ekata Identity Check',
    slug: 'ekata-identity-check',
    color: '#3f3694',
    description:
        'Global standard for identity verification, based on 5 key consumer data attributes of email, phone, name (person or business), physical address, and IP',
    validate: validator,
    inputs: [
        {
            id: 'name',
            name: 'Full Name',
            schema: { type: 'string' },
            group: 'inputs',
            required: false,
        },
        {
            id: 'emailAddress',
            name: 'Email Address',
            schema: { type: 'string' },
            group: 'inputs',
            required: false,
        },
        {
            id: 'ipAddress',
            name: 'IP Address',
            schema: { type: 'string' },
            group: 'inputs',
            required: false,
        },
        {
            id: 'phone',
            name: 'Phone Number',
            schema: { type: 'string' },
            group: 'inputs',
            required: false,
        },
        {
            id: 'addressStreetLine1',
            name: 'Address (Street Line 1)',
            schema: { type: 'string' },
            group: 'inputs',
            required: false,
        },
        {
            id: 'addressStreetLine2',
            name: 'Address (Street Line 2)',
            schema: { type: 'string' },
            group: 'inputs',
            required: false,
        },
        {
            id: 'city',
            name: 'City',
            schema: { type: 'string' },
            group: 'inputs',
            required: false,
        },
        {
            id: 'postalCode',
            name: 'Postal Code',
            schema: { type: 'string' },
            group: 'inputs',
            required: false,
        },
        {
            id: 'stateCode',
            name: 'State Code',
            schema: { type: 'string' },
            group: 'inputs',
            required: false,
        },
        {
            id: 'countryCode',
            name: 'Country Code',
            schema: { type: 'string' },
            group: 'inputs',
            required: false,
        },
    ],
    outputs: [
        {
            id: 'identity_check_score',
            name: 'Identity Check Score',
            schema: { type: 'number' },
            required: false,
        },
        {
            id: 'identity_network_score',
            name: 'Identity Network Score',
            schema: { type: 'number' },
            required: false,
        },
        {
            id: 'estimatedAge',
            name: 'Estimated Age',
            schema: { type: 'number' },
            required: false,
        },
        {
            id: 'primary_phone_checks.carrier',
            name: 'Phone Number: Carrier',
            schema: { type: 'string' },
            required: false,
        },
        {
            id: 'primary_phone_checks.is_valid',
            name: 'Phone Number: Is Valid',
            schema: { type: 'boolean' },
            required: false,
        },
        {
            id: 'primary_phone_checks.is_prepaid',
            name: 'Phone Number: Is Prepaid',
            schema: { type: 'boolean' },
            required: false,
        },
        {
            id: 'primary_phone_checks.match_to_name',
            name: 'Phone Number: Match To Name',
            schema: { type: 'string' },
            required: false,
        },
        {
            id: 'primary_phone_checks.match_to_address',
            name: 'Phone Number: Match To Address',
            schema: { type: 'string' },
            required: false,
        },
        {
            id: 'primary_address_checks.match_to_name',
            name: 'Address: Match To Name',
            schema: { type: 'string' },
            required: false,
        },
        {
            id: 'primary_email_address_checks.email_first_seen_days',
            name: 'Email Address: First Seen Days',
            schema: { type: 'number' },
            required: false,
        },
        {
            id: 'primary_email_address_checks.match_to_name',
            name: 'Email Address: Match To Name',
            schema: { type: 'string' },
            required: false,
        },
        {
            id: 'primary_email_address_checks.is_autogenerated',
            name: 'Email Address: Is Autogenerated',
            schema: { type: 'boolean' },
            required: false,
        },
        {
            id: 'primary_email_address_checks.is_disposable',
            name: 'Email Address: Is Disposable',
            schema: { type: 'boolean' },
            required: false,
        },
        {
            id: 'primary_email_address_checks.email_domain_creation_days',
            name: 'Email Address: Domain Creation Days',
            schema: { type: 'number' },
            required: false,
        },
        {
            id: 'ip_address_checks.distance_from_primary_address',
            name: 'IP Address: Distance From Address',
            schema: { type: 'number' },
            required: false,
        },
        {
            id: 'ip_address_checks.distance_from_primary_phone',
            name: 'IP Address: Distance From Phone Number',
            schema: { type: 'number' },
            required: false,
        },
    ],
};

export default ekataIdentityCheckProcessor;
