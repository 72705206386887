import PropTypes from 'prop-types';
import {
    FormTab,
    TextField,
    FormDataConsumer,
    BooleanInput,
    ReferenceField,
} from 'react-admin';
import { Field } from 'react-final-form';

import SummaryDisplaySchemaInput from '../v4/insuranceProducts/SummaryDisplaySchemaInput';

import { JsonInput } from '../inputs/JsonInput';
import PiiAttributesInput from '../inputs/PiiAttributesInput';
import { CustomToolbar } from '../forms/CustomToolbar';
import {
    checkRequiredFieldsFactory,
    checkJsonFieldsFactory,
} from '../forms/validation';

import TabbedForm from '../controllers/TabbedForm';
import { omit } from 'lodash';

const validateInsuranceProductForm = () => (values) => {
    const errors = {
        ...checkRequiredFieldsFactory(['name', 'organization.id', 'piiMask'])(
            values,
        ),
        ...checkJsonFieldsFactory([
            'piiMask',
            'summaryDisplaySchema',
            'filtersSchema',
        ])(values),
    };

    return errors;
};

const getProductTabForm = () => [
    <TextField key="name" source="name" />,
    <ReferenceField
        key="organization"
        label="Organization"
        source="organization.id"
        reference="Organization"
    >
        <TextField source="name" />
    </ReferenceField>,
    <BooleanInput
        key="deletionProtection"
        name="deletionProtection"
        label="Enable Deletion Protection"
    />,
    <FormDataConsumer key="piiAttributes">
        {({ formData, ...rest }) => (
            <PiiAttributesInput
                validationSchema={formData.validationSchema}
                label="PII Attributes"
                source="piiMask"
                {...rest}
            />
        )}
    </FormDataConsumer>,
    <JsonInput
        key="filtersSchema"
        label="Filters Schema"
        source="filtersSchema"
    />,
    <Field
        key="summaryDisplaySchema"
        label="Summary Display Schema"
        name="summaryDisplaySchema"
        component={SummaryDisplaySchemaInput}
    />,
];

export const InsuranceProductForm = ({ ...props }) => {
    const onUpdate = (...args) => {
        const [data, ...rest] = args;
        const saveData = {
            ...omit(data, [
                'validationSchema',
                'versions',
                'lastUpdatedAt',
                'organization',
                '__typename',
                'id',
                'slug',
                'insurancePlans',
                'createdAt',
                'attributeDefinitions',
            ]),
        };
        const saveArgs = [saveData, ...rest];
        props.save(...saveArgs);
    };
    return (
        <TabbedForm
            toolbar={
                <CustomToolbar
                    deleteConfirmTitle={(record) => `${record.name} removal`}
                />
            }
            validate={validateInsuranceProductForm()}
            initialValues={{
                piiMask: [],
                renewalSchedule: 'NONE',
                renewalDay: 0,
                renewalDayType: 'EFFECTIVE_DATE',
            }}
            {...props}
            save={onUpdate}
        >
            <FormTab label="Product">{getProductTabForm()}</FormTab>
        </TabbedForm>
    );
};

InsuranceProductForm.propTypes = {
    record: PropTypes.object,
    save: PropTypes.func,
};
