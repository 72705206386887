import type { FunctionComponent } from 'react';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { Avatar, ThemeProvider } from '@material-ui/core';
import { Labeled } from 'react-admin';
import { useField } from 'react-final-form';
import { theme } from '../v4';

const getPreviewUrl = (preview: File | string): string => {
    if (preview instanceof File) {
        return URL.createObjectURL(preview);
    }

    return preview;
};

type Props = {
    label: string;
    source: string;
    urlName: string;
    record?: Record<string, string>;
    isIcon?: boolean;
};

const PictureInput: FunctionComponent<Props> = (props) => {
    const { label, source, record, urlName, isIcon } = props;

    const pictureUrl = record[urlName];
    const [preview, setPreview] = useState(pictureUrl);

    const { input } = useField(source);
    const onDrop = (acceptedFiles) => {
        setPreview(acceptedFiles[0]);
        input.onChange(acceptedFiles[0]);
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
    });

    return (
        <ThemeProvider theme={theme}>
            <Labeled label={label}>
                <div {...getRootProps()} data-cy="dropzone">
                    <input {...getInputProps()} />
                    {isIcon ? (
                        <Avatar src={getPreviewUrl(preview)}>?</Avatar>
                    ) : (
                        <img alt="mail logo" src={getPreviewUrl(preview)} />
                    )}
                </div>
            </Labeled>
        </ThemeProvider>
    );
};

export default PictureInput;
