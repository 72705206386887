import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Divider, makeStyles, Typography } from '@material-ui/core';
import Select from '../../../../../../forms/primitives/Select';

import { get } from 'lodash';
import IntervalRow from './IntervalRow';
import CatchAllInput from '../CatchAllInput';

const useStyles = makeStyles((theme) => ({
    input: {
        width: '100%',
    },
    newRow: {
        display: 'flex',
        marginTop: theme.spacing(1),
    },
    operationSelect: {
        width: '50%',
    },
    operationHelper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingLeft: theme.spacing(2),
    },
    operationHelpText: {
        flex: '0 0 auto',
        maxHeight: 20,
    },
}));

const AVAILABLE_OPERATIONS = {
    lt: {
        name: 'Less than',
        operators: ['lt'],
    },
    between: {
        name: 'Between',
        operators: ['gte', 'lt'],
    },
    gte: {
        name: 'Higher than',
        operators: ['gte'],
    },
    eq: {
        name: 'Equal to',
        operators: ['eq'],
    },
};

const options = Object.keys(AVAILABLE_OPERATIONS).map((operation) => ({
    label: AVAILABLE_OPERATIONS[operation].name,
    value: operation,
}));

const IntervalSwitch = ({
    input: { value, onChange },
    disabled,
    maximumDisplayableThresholds,
}) => {
    const classes = useStyles();

    const [configuration, setConfiguration] = useState(
        get(value, 'type') === 'interval'
            ? value
            : {
                  type: 'interval',
                  conditions: [],
              },
    );

    useEffect(() => {
        onChange(configuration);
    }, [configuration, onChange]);

    const handleOperationChange = (index) => (newValue) => {
        const updatedOperations = get(configuration, 'conditions', []).slice();
        updatedOperations[index] = newValue;

        setConfiguration({ ...configuration, conditions: updatedOperations });
    };

    const handleOperationCreate = (operation) => {
        const { operators } = AVAILABLE_OPERATIONS[operation];

        const updatedConditions = get(configuration, 'conditions', []).slice();
        updatedConditions.push(
            operators.reduce((acc, operator) => {
                acc[operator] = null;
                return acc;
            }, {}),
        );

        setConfiguration({
            ...configuration,
            conditions: updatedConditions,
        });
    };

    const handleOperationDelete = (index) => () => {
        const updatedOperations = get(configuration, 'conditions', []).slice();
        updatedOperations.splice(index, 1);

        setConfiguration({
            ...configuration,
            conditions: updatedOperations,
        });
    };

    const handleCatchAllChange = ({ target: { value: newCatchAllValue } }) => {
        setConfiguration({
            ...configuration,
            catchAll: newCatchAllValue,
        });
    };

    return (
        <>
            {get(configuration, 'conditions', []).map((operation, index) => (
                <IntervalRow
                    key={index}
                    value={operation}
                    onChange={handleOperationChange(index)}
                    onDelete={handleOperationDelete(index)}
                    disabled={disabled}
                />
            ))}
            {!disabled &&
                get(configuration, 'conditions', []).length <=
                    maximumDisplayableThresholds && (
                    <div className={classes.newRow}>
                        <Select
                            className={classes.operationSelect}
                            label="Operation"
                            input={{
                                value: null,
                                onChange: handleOperationCreate,
                            }}
                            meta={{}}
                            options={options}
                            margin="dense"
                        />
                        <div className={classes.operationHelper}>
                            <Typography
                                variant="body2"
                                component="div"
                                className={classes.operationHelpText}
                            >
                                Select to add a new condition
                            </Typography>
                        </div>
                    </div>
                )}
            <Divider />
            <CatchAllInput
                disabled={disabled}
                input={{
                    value: configuration.catchAll,
                    onChange: handleCatchAllChange,
                }}
            />
        </>
    );
};

IntervalSwitch.propTypes = {
    disabled: PropTypes.bool,
    input: PropTypes.shape({
        value: PropTypes.shape({
            configuration: PropTypes.shape({
                type: PropTypes.string,
                conditions: PropTypes.array.isRequired,
                catchAll: PropTypes.string,
            }),
            editor: PropTypes.object.isRequired,
        }).isRequired,
        onChange: PropTypes.func.isRequired,
    }).isRequired,
    maximumDisplayableThresholds: PropTypes.number,
};

IntervalSwitch.defaultProps = {
    disabled: false,
    maximumDisplayableThresholds: 100,
};

export default IntervalSwitch;
