import { isValidElement, cloneElement } from 'react';
import PropTypes from 'prop-types';

import { makeStyles, useTheme } from '@material-ui/core';
import { FunctionIcon } from '../../../icons';

const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.primary.main,
    },
}));

const ProcessorIcon = ({ processor, withErrors }) => {
    const classes = useStyles();
    const theme = useTheme();

    const icon = processor?.icon || <FunctionIcon />;

    if (isValidElement(icon)) {
        return cloneElement(icon, {
            className: classes.icon,
            style: {
                fontSize: processor?.iconSizes?.editor,
                width: processor?.iconSizes?.editor,
                height: processor?.iconSizes?.editor,
                color: withErrors
                    ? theme.palette.mainRed
                    : theme.palette.primary.main,
            },
            color: withErrors
                ? theme.palette.mainRed
                : theme.palette.primary.main,
        });
    }

    if (typeof icon === 'string') {
        return (
            <img
                src={icon}
                alt=""
                style={{
                    width: processor?.iconSizes?.editor,
                    height: processor?.iconSizes?.editor,
                }}
            />
        );
    }

    return null;
};

ProcessorIcon.propTypes = {
    processor: PropTypes.shape({
        icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
        iconSizes: PropTypes.shape({
            list: PropTypes.number,
            editor: PropTypes.number,
        }),
    }),
    withErrors: PropTypes.bool,
};

ProcessorIcon.defaultProps = {
    processor: null,
    withErrors: false,
};

export default ProcessorIcon;
