import validator from './validate';

const minimumProcessor = {
    name: 'Minimum',
    slug: 'minimum',
    description: 'Return the lowest value of all given inputs',
    validate: validator,
    inputs: {
        id: 'inputs',
        dynamic: true,
        min: 2,
        schema: { type: 'number' },
    },
    outputs: [
        {
            id: 'output',
            name: 'Output',
            schema: { type: 'number' },
        },
    ],
};

export default minimumProcessor;
