import omit from 'lodash/omit';
import { gql } from '@apollo/client';
import { GET_LIST, GET_ONE, CREATE, UPDATE } from 'react-admin';

import { pick } from 'lodash';
import classifyDatasourceConfigurations from './classifyDatasourceConfigurations';

const ORGANIZATION_FRAGMENT = gql`
    fragment ORGANIZATION_FRAGMENT on Organization {
        id
        name
        datasourceConfigurations {
            id
            sandboxDevelopmentMode
            datasource {
                id
                slug
            }
        }
        logoUrl
        mailLogoUrl
        mailConfig {
            host
            port
            secure
            auth {
                type
                user
                clientId
                clientSecret
                refreshToken
                accessUrl
                customParams
            }
            provider
            domain
            apiKey
            sender
            replyTo
        }
        homePage
        createdAt
        lastUpdatedAt
    }
`;

const updateDatasourceConfigurations = (
    graphqlClient,
    currentOrganization,
    newDatasourceConfigurations,
) => {
    const { toCreate, toUpdate, toDelete } = classifyDatasourceConfigurations(
        currentOrganization.datasourceConfigurations,
        newDatasourceConfigurations,
    );

    const datasourceQueries = [
        toCreate.map((datasourceConfiguration) =>
            graphqlClient.mutate({
                mutation: gql`
                    mutation createDatasourceConfiguration(
                        $organizationId: ID!
                        $datasourceId: ID!
                        $sandboxDevelopmentMode: Boolean!
                    ) {
                        createDatasourceConfiguration(
                            input: {
                                datasourceId: $datasourceId
                                sandboxDevelopmentMode: $sandboxDevelopmentMode
                                organizationId: $organizationId
                            }
                        ) {
                            id
                            datasource {
                                id
                                slug
                            }
                            sandboxDevelopmentMode
                            createdAt
                            lastUpdatedAt
                        }
                    }
                `,
                variables: {
                    organizationId: currentOrganization.id,
                    datasourceId: datasourceConfiguration.datasource.id,
                    ...pick(datasourceConfiguration, [
                        'sandboxDevelopmentMode',
                    ]),
                },
            }),
        ),
        toUpdate.map((datasourceConfiguration) =>
            graphqlClient.mutate({
                mutation: gql`
                    mutation updateDatasourceConfiguration(
                        $datasourceConfigurationId: ID!
                        $organizationId: ID!
                        $datasourceId: ID!
                        $sandboxDevelopmentMode: Boolean!
                    ) {
                        updateDatasourceConfiguration(
                            id: $datasourceConfigurationId
                            input: {
                                datasourceId: $datasourceId
                                sandboxDevelopmentMode: $sandboxDevelopmentMode
                                organizationId: $organizationId
                            }
                        ) {
                            id
                            datasource {
                                id
                                slug
                            }
                            sandboxDevelopmentMode
                            createdAt
                            lastUpdatedAt
                        }
                    }
                `,
                variables: {
                    datasourceConfigurationId: datasourceConfiguration.id,
                    organizationId: currentOrganization.id,
                    datasourceId: datasourceConfiguration.datasource.id,
                    ...pick(datasourceConfiguration, [
                        'sandboxDevelopmentMode',
                    ]),
                },
            }),
        ),
        toDelete.map((datasourceConfiguration) =>
            graphqlClient.mutate({
                mutation: gql`
                    mutation deleteDatasourceConfiguration($id: ID!) {
                        deleteDatasourceConfiguration(id: $id) {
                            id
                        }
                    }
                `,
                variables: {
                    id: datasourceConfiguration.id,
                },
            }),
        ),
    ];

    return Promise.all(datasourceQueries);
};

const organizationDataProviderFactory = (client) => ({
    [GET_LIST]: async (params) => {
        const {
            data: {
                organizations: { items: organizations, count: total },
            },
        } = await client.query({
            query: gql`
                ${ORGANIZATION_FRAGMENT}

                query getOrganizations(
                    $sortBy: String
                    $sortDir: String
                    $perPage: Int
                    $page: Int
                    $filter: OrganizationFilter
                ) {
                    organizations(
                        page: $page
                        perPage: $perPage
                        sortBy: $sortBy
                        sortDir: $sortDir
                        filter: $filter
                    ) {
                        count
                        items {
                            ...ORGANIZATION_FRAGMENT
                        }
                    }
                }
            `,
            variables: {
                page: params.pagination.page,
                perPage: params.pagination.perPage,
                sortBy: params.sort.field,
                sortDir: params.sort.order,
                filter: params.filter,
            },
        });

        return { data: organizations, total };
    },

    [GET_ONE]: async ({ id }) => {
        const {
            data: { organization },
        } = await client.query({
            query: gql`
                ${ORGANIZATION_FRAGMENT}

                query getOrganization($id: ID!) {
                    organization(id: $id) {
                        ...ORGANIZATION_FRAGMENT
                    }
                }
            `,
            variables: { id },
        });

        return { data: organization };
    },

    [CREATE]: async (params) => {
        const {
            data: { datasourceConfigurations, ...organizationData },
        } = params;

        const {
            data: { createOrganization: createdOrganization },
        } = await client.mutate({
            mutation: gql`
                ${ORGANIZATION_FRAGMENT}

                mutation createOrganization($input: OrganizationInput!) {
                    createOrganization(input: $input) {
                        ...ORGANIZATION_FRAGMENT
                    }
                }
            `,
            variables: {
                input: {
                    ...pick(organizationData, [
                        'name',
                        'logo',
                        'mailLogo',
                        'mailConfig',
                        'homePage',
                    ]),
                },
            },
        });

        await updateDatasourceConfigurations(
            client,
            createdOrganization,
            datasourceConfigurations,
        );

        return { data: createdOrganization };
    },

    [UPDATE]: async (params) => {
        const {
            id,
            data: { datasourceConfigurations, ...organizationData },
        } = params;

        const {
            data: { updateOrganization: updatedOrganization },
        } = await client.mutate({
            mutation: gql`
                ${ORGANIZATION_FRAGMENT}

                mutation updateOrganization(
                    $id: ID!
                    $name: String!
                    $logo: Upload
                    $mailLogo: Upload
                    $mailConfig: MailConfigInput
                    $homePage: String
                ) {
                    updateOrganization(
                        id: $id
                        input: {
                            name: $name
                            logo: $logo
                            mailLogo: $mailLogo
                            mailConfig: $mailConfig
                            homePage: $homePage
                        }
                    ) {
                        ...ORGANIZATION_FRAGMENT
                    }
                }
            `,
            variables: {
                id,
                ...pick(organizationData, [
                    'name',
                    'logo',
                    'mailLogo',
                    'homePage',
                ]),
                mailConfig: organizationData.mailConfig
                    ? {
                          ...omit(organizationData.mailConfig, [
                              '__typename',
                              'auth',
                          ]),
                          auth: omit(organizationData.mailConfig.auth, [
                              '__typename',
                          ]),
                      }
                    : null,
            },
        });

        await updateDatasourceConfigurations(
            client,
            updatedOrganization,
            datasourceConfigurations,
        );

        const result = await client.query({
            query: gql`
                ${ORGANIZATION_FRAGMENT}

                query getOrganization($id: ID!) {
                    organization(id: $id) {
                        ...ORGANIZATION_FRAGMENT
                    }
                }
            `,
            variables: { id },
        });

        // eslint-disable-next-line no-console
        console.log(
            'I have no idea why, but if you remove this console.log, the update-an-organization test will break, because the fetching will not happen',
        );

        return result;
    },
});

export default organizationDataProviderFactory;
