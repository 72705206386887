import PropTypes from 'prop-types';

import { Avatar, Chip, makeStyles } from '@material-ui/core';
import { get } from 'lodash';

const useStyles = makeStyles((theme) => ({
    chip: {
        marginRight: theme.spacing(1),
    },
}));

const OrganizationRolesField = ({ record }) => {
    const classes = useStyles();

    const organizationRoles = get(record, 'organizationRoles', []);

    return (
        <div>
            {organizationRoles.map(({ organization }) => (
                <Chip
                    key={organization.id}
                    classes={{ root: classes.chip }}
                    avatar={
                        <Avatar
                            alt={organization.name}
                            src={organization.logoUrl}
                        />
                    }
                    label={organization.name}
                />
            ))}
        </div>
    );
};

OrganizationRolesField.propTypes = {
    record: PropTypes.object.isRequired,
};

OrganizationRolesField.defaultProps = {};

export default OrganizationRolesField;
