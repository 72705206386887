import PropTypes from 'prop-types';
import { useQuery, useLazyQuery, gql } from '@apollo/client';

import {
    Button,
    CircularProgress,
    Grid,
    makeStyles,
    Paper,
    ThemeProvider,
    Typography,
} from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { theme } from '../v4';
import { get } from 'lodash';

import { Alert } from '@material-ui/lab';
import { isGraphBlueprint } from '@tint/core/src/models/isGraphBlueprint';
import { ComputationTrace } from '../v4/models/blueprintEditor';
import { Link } from 'react-router-dom';
import { ArrowBack } from '@material-ui/icons';
import { JsonInput } from '../inputs/JsonInput';
import { JsonField } from '../fields';

const useStyles = makeStyles((t) => ({
    root: {
        padding: t.spacing(4),
    },
    predictionError: {
        marginTop: t.spacing(4),
    },
    loadingWrapper: {
        marginTop: t.spacing(4),
    },
    loading: {
        marginRight: t.spacing(1),
    },
    detailsWrapper: {
        textAlign: 'right',
    },
}));

const GET_MODEL_QUERY = gql`
    query getModelForTest($id: ID!) {
        model(id: $id) {
            id
            name
            blueprint
            editorData
            outputType
            insuranceProduct {
                id
                name
            }
        }
    }
`;

const SIMULATE_PREDICTION = gql`
    query simulatePrediction($modelId: ID!, $attributes: JSON!) {
        simulatePrediction(modelId: $modelId, policyDetails: $attributes) {
            output
            trace
        }
    }
`;

const ModelTest = ({
    match: {
        params: { modelId },
    },
}) => {
    const classes = useStyles();
    const { loading, data } = useQuery(GET_MODEL_QUERY, {
        variables: {
            id: modelId,
        },
    });

    const [
        calculatePrediction,
        { loading: calculating, data: predictionData, error: predictionError },
    ] = useLazyQuery(SIMULATE_PREDICTION, { fetchPolicy: 'no-cache' });

    const onSubmit = ({ attributes }) =>
        calculatePrediction({
            variables: {
                attributes: JSON.parse(attributes),
                modelId,
            },
        });

    const model = get(data, 'model', null);
    const prediction = get(predictionData, 'simulatePrediction', null);

    return (
        <ThemeProvider theme={theme}>
            <Paper className={classes.root}>
                {loading ? null : (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography variant="h1">
                                        Testing "{model.name}" model
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    className={classes.detailsWrapper}
                                >
                                    <Link
                                        component={Button}
                                        to={`/Model/${model.id}`}
                                        startIcon={<ArrowBack />}
                                    >
                                        Back to model
                                    </Link>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Form
                                onSubmit={onSubmit}
                                initialValues={{ attributes: '{}' }}
                                render={({ handleSubmit }) => (
                                    <form onSubmit={handleSubmit}>
                                        <Field
                                            label="Attributes"
                                            name="attributes"
                                            component={JsonInput}
                                        />
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            loading={calculating}
                                        >
                                            Predict
                                        </Button>
                                    </form>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {calculating && (
                                <Typography
                                    variant="body2"
                                    className={classes.loadingWrapper}
                                >
                                    <CircularProgress
                                        size={16}
                                        className={classes.loading}
                                    />
                                    Calculating...
                                </Typography>
                            )}
                            {predictionError && (
                                <Alert
                                    severity="error"
                                    className={classes.predictionError}
                                >
                                    {predictionError.message}
                                </Alert>
                            )}
                            {prediction && !predictionError && (
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography variant="body2">
                                            Prediction:{' '}
                                        </Typography>
                                        <Typography variant="body1">
                                            {prediction.output}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {isGraphBlueprint(model.blueprint) ? (
                                            <ComputationTrace
                                                model={model}
                                                trace={prediction.trace}
                                                insuranceProduct={
                                                    model.insuranceProduct
                                                }
                                            />
                                        ) : (
                                            <JsonField
                                                height={300}
                                                record={prediction}
                                                source="trace"
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                )}
            </Paper>
        </ThemeProvider>
    );
};

ModelTest.propTypes = {
    match: PropTypes.shape({
        params: {
            modelId: PropTypes.string.isRequired,
        },
    }).isRequired,
};

ModelTest.defaultProps = {};

export default ModelTest;
