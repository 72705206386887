import { get } from 'lodash';

export const checkRequiredFieldsFactory =
    <Values extends Record<string, unknown>>(
        requiredFields: (keyof Values)[],
    ) =>
    (values: Partial<Values>): Partial<Record<keyof Values, string>> => {
        const errors: Partial<Record<keyof Values, string>> = {};

        requiredFields.forEach((requiredField) => {
            if (
                get(values, requiredField) === undefined ||
                get(values, requiredField) === null ||
                get(values, requiredField) === ''
            ) {
                errors[requiredField] = 'Required field';
            }
        });

        return errors;
    };

export const checkJsonFieldsFactory =
    <Values extends Record<string, unknown>>(jsonFields: (keyof Values)[]) =>
    (values: Partial<Values>): Partial<Record<keyof Values, string>> => {
        const errors: Partial<Record<keyof Values, string>> = {};

        jsonFields.forEach((jsonField) => {
            const value = values[jsonField];
            if (typeof value !== 'string') {
                return;
            }

            try {
                JSON.parse(value);
            } catch {
                if (!errors[jsonField]) {
                    errors[jsonField] = 'Invalid JSON input';
                }
            }
        });

        return errors;
    };
