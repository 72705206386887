import { get, zipWith } from 'lodash';
import { useState } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import PropTypes from 'prop-types';

import {
    makeStyles,
    Grid,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Tooltip,
    TableContainer,
    Paper,
} from '@material-ui/core';

import { Field } from 'react-final-form';
import TablePagination from '../../../../../tables/TablePagination';
import { blueprintPropType } from '../../../blueprintEditorPropTypes';
import { legacyFormatNumber } from '@tint/core/src/formatters';
import { TextInput, Select } from '../../../../../forms';
import FilterInput from '../../../../../forms/FilterInput';

export const getDimensionPossibleValues = (dimension) =>
    [
        ...get(dimension, 'conditions', []).map((c) => c.label),
        get(dimension, 'catchAll'),
    ].filter((x) => x !== undefined);

const useStyles = makeStyles((theme) => ({
    dimensionHeader: {
        display: 'flex',
        alignItems: 'center',
    },
    pagination: {
        marginTop: theme.spacing(2),
        justifyContent: 'flex-end',
    },
    rateColumn: {
        width: 100,
    },
    rateInputRoot: {
        margin: 0,
        '& input': {
            padding: 4,
            textAlign: 'center',
            fontSize: '0.875rem',
        },
    },
}));

const RateRow = ({ node, field, combination, dimensions, disabled }) => {
    const classes = useStyles();
    const rate = get({ node }, field);

    return (
        <TableRow>
            {dimensions.map((dimension, i) => (
                <TableCell key={dimension.input}>
                    {combination[i] === null ? 'null' : combination[i]}
                </TableCell>
            ))}
            <TableCell className={classes.rateColumn}>
                {disabled ? (
                    <Tooltip placement="left" arrow title={rate}>
                        <span>
                            {legacyFormatNumber(rate, {
                                maximumFractionDigits: 4,
                            })}
                        </span>
                    </Tooltip>
                ) : (
                    <Field
                        name={field}
                        component={TextInput}
                        size="small"
                        inputProps={{
                            type: 'number',
                            step: 0.001,
                        }}
                        classes={{
                            root: classes.rateInputRoot,
                        }}
                        parse={(value) => parseFloat(value)}
                    />
                )}
            </TableCell>
        </TableRow>
    );
};

RateRow.propTypes = {
    node: PropTypes.object.isRequired,
    field: PropTypes.string.isRequired,
    dimensions: PropTypes.array.isRequired,
    combination: PropTypes.array.isRequired,
    disabled: PropTypes.bool.isRequired,
};

const filterRates = (rates, dimensions, filters) => {
    const dimensionIndexes = dimensions.reduce((acc, dimension, index) => {
        acc[dimension.input] = index;
        return acc;
    }, {});

    return rates.filter((rate) =>
        Object.entries(filters).every(
            ([filter, value]) =>
                rate.combination[dimensionIndexes[filter]] === value,
        ),
    );
};

const getPaginatedRates = ({
    combinations,
    dimensions,
    fields,
    filters,
    page,
    perPage,
}) => {
    const rates = zipWith(
        combinations,
        fields.map((f) => f), // Fields is not an array, but an iterator
        (combination, field) => ({
            combination,
            field,
        }),
    );

    return filterRates(rates, dimensions, filters).slice(
        (page - 1) * perPage,
        page * perPage,
    );
};

const RatesTable = ({ blueprint, node, combinations, perPage, disabled }) => {
    const classes = useStyles();
    const [page, setPage] = useState(1);
    const [filters, setFilters] = useState({});

    const dimensions = get(node, 'configuration.dimensions', []);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <FilterInput
                    title="Filters"
                    label="Filters"
                    onChange={setFilters}
                    filters={filters}
                    fields={dimensions.map((dimension) => (
                        <Field
                            key={dimension}
                            component={Select}
                            label={
                                blueprint.nodes[dimension.input].name ||
                                'New Block'
                            }
                            name={dimension.input}
                            options={getDimensionPossibleValues(dimension).map(
                                (value) => ({
                                    value,
                                    label: value,
                                }),
                            )}
                        />
                    ))}
                />
            </Grid>
            <FieldArray name="node.configuration.rates">
                {({ fields }) => (
                    <Grid item xs={12}>
                        <TableContainer component={Paper} elevation={0}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        {dimensions.map((dimension) => (
                                            <TableCell key={dimension.input}>
                                                <div
                                                    className={
                                                        classes.dimensionHeader
                                                    }
                                                >
                                                    {blueprint.nodes[
                                                        dimension.input
                                                    ].name || 'New Block'}
                                                </div>
                                            </TableCell>
                                        ))}
                                        <TableCell
                                            className={classes.rateColumn}
                                        >
                                            Rate
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {getPaginatedRates({
                                        combinations,
                                        dimensions,
                                        fields,
                                        page,
                                        perPage,
                                        filters,
                                    }).map((rate) => (
                                        <RateRow
                                            key={rate.field}
                                            node={node}
                                            field={rate.field}
                                            dimensions={dimensions}
                                            combination={rate.combination}
                                            disabled={disabled}
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            className={classes.pagination}
                            onPageChange={(newPage) => () => setPage(newPage)}
                            page={page}
                            perPage={perPage}
                            total={fields.length}
                        />
                    </Grid>
                )}
            </FieldArray>
        </Grid>
    );
};

RatesTable.propTypes = {
    blueprint: PropTypes.shape(blueprintPropType).isRequired,
    node: PropTypes.object.isRequired,
    combinations: PropTypes.array.isRequired,
    perPage: PropTypes.number,
    disabled: PropTypes.bool.isRequired,
};

RatesTable.defaultProps = {
    perPage: 20,
};

export default RatesTable;
