import { SambaSafetyProcessorOutputs } from './sambaSafetyProcessorOutputs';
import validator from './validate';

export enum SambaSafetyHint {
    FULL = 'Full',
    ACTIVITY = 'Activity',
    CLEAN = 'Clean',
}

type EventConfiguration = {
    threshold: number;
    lookoutPeriodInYear: number;
};

export type SambaSafetyNode = {
    processor: 'samba-safety';
    configuration: {
        alwaysUseTintTriggers: boolean;
        hint: SambaSafetyHint;
        events: Record<string, EventConfiguration>;
    };
};

const sambaSafetyProcessor = {
    name: 'Samba Safety',
    slug: 'samba-safety',
    color: '#1c75bc',
    description:
        'Retrieve Motor Vehicle Records and implement underwriting requirements',
    validate: validator,
    inputs: [
        {
            id: 'firstName',
            name: 'First Name',
            schema: { type: 'string' },
            required: false,
            group: 'inputs',
        },
        {
            id: 'lastName',
            name: 'Last Name',
            schema: { type: 'string' },
            group: 'inputs',
        },
        {
            id: 'stateCode',
            name: 'State Code',
            schema: { type: 'string' },
            group: 'inputs',
        },
        {
            id: 'licenseNumber',
            name: 'License Number',
            schema: { type: 'string' },
            group: 'inputs',
        },
        {
            id: 'birthDate',
            name: 'Birth Date',
            schema: {
                oneOf: [
                    { type: 'string', format: 'date' },
                    { type: 'string', format: 'date-time' },
                ],
            },
            required: false,
            group: 'inputs',
        },
    ],
    outputs: [
        {
            id: 'output',
            name: 'Output',
            schema: {
                type: 'string',
                enum: Object.values(SambaSafetyProcessorOutputs),
            },
        },
    ],
};

export default sambaSafetyProcessor;
