import classnames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { RangeLeftArrows, RangeRightArrows } from '../../../icons';

const useStyles = makeStyles({
    // root style should be configured in `index.js` file
    arrows: {
        position: 'absolute',
        top: 15,
        width: 15,
        height: 17,
        fill: '#fff',
    },
    left: {
        left: 16,
    },
    right: {
        right: 16,
    },
});

export const ThumbComponent = ({ 'aria-valuenow': value, ...props }) => {
    const classes = useStyles();

    return (
        <div aria-valuenow={value} {...props}>
            <RangeLeftArrows
                className={classnames(classes.arrows, classes.left)}
            />
            {+value}
            <RangeRightArrows
                className={classnames(classes.arrows, classes.right)}
            />
        </div>
    );
};

ThumbComponent.propTypes = {
    'aria-valuenow': PropTypes.any,
    className: PropTypes.string,
};

ThumbComponent.defaultProps = {
    'aria-valuenow': '',
    className: '',
};
