import OneSectionPerOutputContent, {
    OneSectionPerOutputHeaderPins,
} from './OneSectionPerOutputContent';
import ReferencedModelContent, {
    ReferencedModelHeaderPins,
} from './ReferencedModelContent';
import ExternalDataReportContent from './ExternalDataReportContent';
import SwitchBlockContent, { SwitchHeaderPins } from './SwitchBlockContent';

const HEADER_PINS = {
    'ekata-identity-check': OneSectionPerOutputHeaderPins,
    'referenced-model': ReferencedModelHeaderPins,
    switch: SwitchHeaderPins,
};

const BLOCK_CONTENT_BY_PROCESSOR = {
    'data-robot': ExternalDataReportContent,
    'ekata-identity-check': OneSectionPerOutputContent,
    'equifax-digital-identity-trust': ExternalDataReportContent,
    'referenced-model': ReferencedModelContent,
    'samba-safety': ExternalDataReportContent,
    switch: SwitchBlockContent,
};

export const getBlockHeaderPinsByProcessor = (processor) =>
    HEADER_PINS[processor];

export const getBlockContentByProcessor = (processor) =>
    BLOCK_CONTENT_BY_PROCESSOR[processor];
