import OUTPUT_TYPES from '../outputTypes';
import validator from './validate';

const datePartProcessor = {
    name: 'Date Part',
    slug: 'date-part',
    description:
        'Extract a part of the input date: day of week, month, year, etc.',
    validate: validator,
    inputs: [
        {
            id: 'input',
            name: 'Input',
            schema: {
                oneOf: [
                    { type: 'string', format: 'date' },
                    { type: 'string', format: 'date-time' },
                ],
            },
        },
    ],
    outputs: [
        {
            id: 'output',
            name: 'Output',
            getType: ({
                configuration,
            }: {
                configuration: { date_part?: string };
            }): OUTPUT_TYPES.DATE | OUTPUT_TYPES.NUMBER | null =>
                configuration.date_part
                    ? configuration.date_part === 'iso_date'
                        ? OUTPUT_TYPES.DATE
                        : OUTPUT_TYPES.NUMBER
                    : null,
        },
    ],
};

export default datePartProcessor;
