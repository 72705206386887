import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import classnames from 'classnames';

const useStyles = makeStyles((theme) => ({
    bar: {
        backgroundColor: ({ type }) =>
            ({
                info: theme.palette.menuInactive,
                warning: '#fff5e5',
            })[type],
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(2, 3),
        color: ({ type }) => (type === 'warning' ? '#d98a00' : 'white'),
    },
    description: {
        paddingLeft: theme.spacing(2),
    },
}));

const ActionBar = ({ children, action, className, type }) => {
    const classes = useStyles({ type });

    return (
        <div className={classnames(classes.bar, className)}>
            <div className={classes.description}>{children}</div>
            {action && <div>{action}</div>}
        </div>
    );
};

ActionBar.propTypes = {
    children: PropTypes.node,
    action: PropTypes.node,
    className: PropTypes.string,
    type: PropTypes.oneOf(['info', 'warning']),
};

ActionBar.defaultProps = {
    children: null,
    action: null,
    className: null,
    type: 'info',
};

export default ActionBar;
