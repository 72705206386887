import validator from './validate';

const sumProcessor = {
    name: 'Sum',
    slug: 'addition',
    description: 'Return the sum of the inputs',
    validate: validator,
    inputs: {
        id: 'inputs',
        dynamic: true,
        min: 2,
        schema: { type: 'number' },
    },
    outputs: [
        {
            id: 'output',
            name: 'Output',
            schema: { type: 'number' },
        },
    ],
};

export default sumProcessor;
