import { useState, useLayoutEffect } from 'react';

const getBrowserFullscreenElementProp = () => {
    if (typeof document.fullscreenElement !== 'undefined') {
        return 'fullscreenElement';
    } else if (typeof document.mozFullScreenElement !== 'undefined') {
        return 'mozFullScreenElement';
    } else if (typeof document.msFullscreenElement !== 'undefined') {
        return 'msFullscreenElement';
    } else if (typeof document.webkitFullscreenElement !== 'undefined') {
        return 'webkitFullscreenElement';
    } else {
        throw new Error('fullscreenElement is not supported by this browser');
    }
};

export default function useFullscreenStatus(elRef) {
    let error, fullscreenElementProp;
    try {
        fullscreenElementProp = getBrowserFullscreenElementProp();
    } catch (e) {
        error = e;
    }

    const [isFullscreen, setIsFullscreen] = useState(
        document[fullscreenElementProp] !== null,
    );
    const [shouldFitViewFullScreen, setShouldFitViewFullScreen] =
        useState(false);

    const setFullscreen = () => {
        if (elRef.current !== null) {
            elRef.current
                .requestFullscreen()
                .then(() => {
                    setIsFullscreen(document[fullscreenElementProp] !== null);
                })
                .catch(() => {
                    setIsFullscreen(false);
                });
        }
    };

    useLayoutEffect(() => {
        document.onfullscreenchange = () => {
            setIsFullscreen(
                document[getBrowserFullscreenElementProp()] !== null,
            );
            setShouldFitViewFullScreen(true);
        };

        return () => {
            document.onfullscreenchange = undefined;
        };
    });

    return {
        isFullscreen,
        setFullscreen,
        shouldFitViewFullScreen,
        setShouldFitViewFullScreen,
        error,
    };
}
