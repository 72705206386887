// @see https://www.caktusgroup.com/blog/2020/07/01/usekeypress-hook-react/
import { useEffect } from 'react';

export default function useKeyPress(key, action, dependencies = []) {
    useEffect(() => {
        function onKeyup(e) {
            e.preventDefault();
            e.stopPropagation();
            if (e.key === key) {
                action();
            }
        }

        window.addEventListener('keyup', onKeyup);

        return () => window.removeEventListener('keyup', onKeyup);
    }, dependencies);
}
