import PropTypes from 'prop-types';
import { ReferenceField, useNotify } from 'react-admin';
import { Button } from '../v4';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import RefreshIcon from '@material-ui/icons/Refresh';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useMutation, gql } from '@apollo/client';

const REFRESH_TOKEN = gql`
    mutation refreshToken($formId: ID!) {
        refreshFormToken(formId: $formId) {
            id
            apiToken {
                id
                token
            }
        }
    }
`;

const UrlField = ({ slug, record }) => {
    const value = `${process.env.REACT_APP_WEBHOOKS_ENDPOINT_URL}/paperform/v1/${slug}?token=${record.token}`;
    return (
        <Input
            fullWidth
            disabled
            value={value}
            endAdornment={
                <IconButton
                    onClick={() => {
                        navigator.clipboard.writeText(value);
                    }}
                >
                    <FileCopyIcon />
                </IconButton>
            }
        />
    );
};

UrlField.propTypes = {
    record: PropTypes.shape({
        token: PropTypes.string,
    }),
    slug: PropTypes.string,
};

const WebhookUrlField = ({ record }) => {
    const notify = useNotify();
    const { id, slug } = record;
    const [refreshToken, { loading: refreshing }] = useMutation(REFRESH_TOKEN, {
        onError: (error) => {
            notify(error.message, 'error');
        },
        onCompleted: () => {
            notify('Token refreshed with success');
        },
    });

    return (
        <Grid container direction="column" spacing={1}>
            <Grid item>
                {!refreshing ? (
                    <ReferenceField
                        reference="ApiToken"
                        perPage={null}
                        sort={{ field: 'created_at' }}
                        source="apiToken.id"
                        link={false}
                        fullWidth
                    >
                        <UrlField slug={slug} />
                    </ReferenceField>
                ) : (
                    <div style={{ height: '3rem' }}>
                        <LinearProgress />
                    </div>
                )}
            </Grid>
            <Grid item>
                <Button
                    variant="outlined"
                    onClick={() => refreshToken({ variables: { formId: id } })}
                    startIcon={
                        refreshing ? (
                            <CircularProgress size={16} />
                        ) : (
                            <RefreshIcon />
                        )
                    }
                >
                    Refresh Token
                </Button>
            </Grid>
        </Grid>
    );
};

WebhookUrlField.propTypes = {
    record: PropTypes.shape({
        id: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
    }).isRequired,
};

export default WebhookUrlField;
