import PropTypes from 'prop-types';
import ArchiveIcon from '@material-ui/icons/Archive';

import { makeStyles, Tooltip } from '@material-ui/core';
import { Lock } from '@material-ui/icons';

import { mainRed, lighterGray } from '../v4/theme';

const useStyles = makeStyles(() => ({
    archivedIcon: {
        fill: mainRed,
        opacity: 0.5,
    },
    lock: {
        color: lighterGray,
    },
}));

const UsageField = ({ record }) => {
    const classes = useStyles();

    if (record.archived) {
        return (
            <Tooltip title="Archived Model">
                <ArchiveIcon classes={{ root: classes.archivedIcon }} />
            </Tooltip>
        );
    }

    if (record.readOnly) {
        return (
            <Tooltip title="This model is used on a live or available version, and the user cannot edit it.">
                <Lock classes={{ root: classes.lock }} />
            </Tooltip>
        );
    }

    return null;
};

UsageField.propTypes = {
    record: PropTypes.object,
};

UsageField.defaultProps = {
    record: {},
};

export default UsageField;
