import { gql } from '@apollo/client';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Field, useForm } from 'react-final-form';

import ModelsSelect from '../../../../forms/ModelsSelect';

export const GET_MODELS_QUERY = gql`
    query getModels($filter: ModelFilter) {
        models(filter: $filter, perPage: null) {
            items {
                id
                name
                outputType
            }
        }
    }
`;

const ReferencedModelConfiguration = ({ disabled, modelId, ReadOnlyLink }) => {
    const form = useForm();

    const onSelectOption = (model) => {
        if (model) {
            form.change('node.configuration.outputType', model.outputType);
        }
    };

    return (
        <Grid container direction="column" spacing={1}>
            <Grid item>
                <Field
                    component={ModelsSelect}
                    filter={{
                        isValid: true,
                        excludeReferrersOf: modelId,
                    }}
                    readOnly={disabled}
                    disabled={disabled}
                    name="node.configuration.modelId"
                    label="Referenced Model"
                    ReadOnlyLink={ReadOnlyLink}
                    query={GET_MODELS_QUERY}
                    onSelectOption={onSelectOption}
                />
            </Grid>
        </Grid>
    );
};

ReferencedModelConfiguration.propTypes = {
    disabled: PropTypes.bool,
    modelId: PropTypes.string.isRequired,
    ReadOnlyLink: PropTypes.elementType,
};

ReferencedModelConfiguration.defaultProps = {
    disabled: false,
    ReadOnlyLink: null,
};

export default ReferencedModelConfiguration;
