import { TabbedForm as RaTabbedForm } from 'react-admin';

const TabbedForm = ({ children, ...props }) => (
    <RaTabbedForm sanitizeEmptyValues={false} {...props}>
        {children}
    </RaTabbedForm>
);

TabbedForm.propTypes = RaTabbedForm.propTypes;

export default TabbedForm;
