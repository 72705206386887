import PropTypes from 'prop-types';

import { useTheme } from '@material-ui/core';
import { ExitToApp } from '@material-ui/icons';

export const OutputIcon = ({ fontSize }) => {
    const theme = useTheme();

    return (
        <ExitToApp
            fontSize="small"
            style={{ fontSize, fill: theme.palette.darkerGray }}
        />
    );
};

OutputIcon.propTypes = {
    fontSize: PropTypes.number,
};

OutputIcon.defaultProps = {
    fontSize: undefined,
};

export default OutputIcon;
