import { titleize } from 'inflection';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';

import { Grid, Typography, Tab } from '@material-ui/core';

import { ExternalDataCredentialInput, TextInput } from '../../../../forms';
import { insuranceProductProptype } from '../../../../../../../tint-app/src/insuranceProduct/versions/propTypes';
import TimeoutField from './fields/TimeoutField';

const DEFAULT_TIMEOUT = 1000;
const DEFAULT_FALLBACK_VALUE = null;

export const getDataRobotConfigurationTabs = () => [
    <Tab key="configuration" label="Configuration" />,
    <Tab key="advanced" label="Advanced" />,
];

export const getLabel = (input) => {
    const { name, pin } = input;
    if (pin === 'output') {
        return name;
    }

    return titleize(`${name} - ${pin}`);
};

const parseFallbackValue = (value) => {
    if (typeof value === 'undefined' || value === '') {
        return null;
    }

    return parseFloat(value);
};

const formatFallbackValue = (value) => {
    if (value === null) {
        return null;
    }

    return value.toString();
};

const DataRobotConfiguration = ({
    disabled,
    inputs,
    insuranceProduct,
    tab,
    ...otherProps
}) => {
    if (tab === 1) {
        return (
            <Grid container direction="column" spacing={2}>
                <Grid item xs={8}>
                    <TimeoutField
                        disabled={disabled}
                        defaultTimeout={DEFAULT_TIMEOUT}
                        helperText="Max wait time for DataRobot response before raising an error"
                    />
                </Grid>
                <Grid item xs={8}>
                    <Field
                        component={TextInput}
                        disabled={disabled}
                        name="node.configuration.fallbackValue"
                        label="Default Value on Error"
                        type="number"
                        defaultValue={DEFAULT_FALLBACK_VALUE}
                        helperText={
                            <>
                                DataRobot may sometimes be too slow to respond
                                or return an error,
                                <br />
                                in those cases, this default value will be
                                returned
                            </>
                        }
                        parse={parseFallbackValue}
                        format={formatFallbackValue}
                        inputProps={{
                            min: 0,
                            max: 1,
                            step: 0.05,
                        }}
                    />
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid container direction="column" spacing={3} {...otherProps}>
            <Grid item>
                <Field
                    disabled={disabled}
                    name="node.configuration.deploymentId"
                    label="Deployment ID"
                    component={TextInput}
                    required
                    autoFocus
                    fullWidth
                />
            </Grid>
            <Grid item>
                <ExternalDataCredentialInput
                    insuranceProduct={insuranceProduct}
                    providerSlug="data-robot"
                    name="dataRobotApiKey"
                    label="DataRobot Api Key"
                    variant="outlined"
                    fullWidth
                    required
                    type="password"
                    disabled={disabled}
                />
            </Grid>
            <Grid item>
                <ExternalDataCredentialInput
                    insuranceProduct={insuranceProduct}
                    providerSlug="data-robot"
                    name="dataRobotKey"
                    label="DataRobot Key"
                    variant="outlined"
                    fullWidth
                    required
                    type="password"
                    disabled={disabled}
                />
            </Grid>
            <Grid item>
                <Grid container spacing={2} direction="column">
                    <Grid item>
                        <Typography variant="h3">Mapping</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body2">
                            For each input, specify the expected model variable
                            name.
                        </Typography>
                    </Grid>
                    {inputs.map((input, index) => (
                        <Grid key={input.id} item>
                            <Field
                                fullWidth
                                disabled={disabled}
                                name={`node.configuration.mapping.${index}`}
                                label={getLabel(input)}
                                component={TextInput}
                                required
                            />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );
};

DataRobotConfiguration.propTypes = {
    disabled: PropTypes.bool,
    inputs: PropTypes.arrayOf(PropTypes.object),
    insuranceProduct: insuranceProductProptype.isRequired,
    tab: PropTypes.number.isRequired,
};

DataRobotConfiguration.defaultProps = {
    disabled: false,
    inputs: [],
};

export default DataRobotConfiguration;
