import PropTypes from 'prop-types';

import { useTheme } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/ReportProblemOutlined';
import { TickIcon, CrossIcon as TakIcon } from '../../../../icons';
import {
    OUTPUT_TYPE_APPROVED,
    OUTPUT_TYPE_MANUAL_REVIEW,
    OUTPUT_TYPE_REJECTED,
} from '../../outputTypes';
import getColorFromOutputType from '../getColorFromOutputType';

const DecisionIcon = ({ fontSize, outputType, withErrors }) => {
    const theme = useTheme();
    const color = getColorFromOutputType(theme, outputType, withErrors);

    switch (outputType) {
        case OUTPUT_TYPE_APPROVED:
            return <TickIcon style={{ fontSize, fill: color }} />;

        case OUTPUT_TYPE_REJECTED:
            return <TakIcon color={color} style={{ fontSize }} />;

        case OUTPUT_TYPE_MANUAL_REVIEW:
            return (
                <WarningIcon
                    style={{
                        fill: color,
                        fontSize,
                        marginTop: -4,
                        marginLeft: -4,
                    }}
                />
            );

        default:
            return (
                <TickIcon style={{ fontSize, fill: theme.palette.mainGreen }} />
            );
    }
};

DecisionIcon.propTypes = {
    outputType: PropTypes.string,
    fontSize: PropTypes.number,
    withErrors: PropTypes.bool,
};

DecisionIcon.defaultProps = {
    outputType: null,
    fontSize: undefined,
    withErrors: false,
};

export default DecisionIcon;
