import dataRobotProcessor from './dataRobot/dataRobotProcessor';
import attributeProcessor from './attribute/attributeProcessor';
import categoriesProcessor from './categories/categoriesProcessor';
import coalesceProcessor from './coalesce/coalesceProcessor';
import concatenateProcessor from './concatenate/concatenateProcessor';
import constantProcessor from './constant/constantProcessor';
import datePartProcessor from './datePart/datePartProcessor';
import decisionProcessor from './decision/decisionProcessor';
import divisionProcessor from './division/divisionProcessor';
import ekataIdentityCheckProcessor from './ekataIdentityCheck/ekataIdentityCheckProcessor';
import equifaxDigitalIdentityTrustProcessor from './equifax/digitalIdentityTrust/equifaxDigitalIdentityTrustProcessor';
import equifaxConsumerCreditReportProcessor from './equifax/consumerCreditReport/equifaxConsumerCreditReportProcessor';
import functionProcessor from './function/functionProcessor';
import fuzzyMatchingProcessor from './fuzzyMatching/fuzzyMatchingProcessor';
import logarithmProcessor from './logarithm/logarithmProcessor';
import maximumProcessor from './maximum/maximumProcessor';
import minimumProcessor from './minimum/minimumProcessor';
import multiplicationProcessor from './multiplication/multiplicationProcessor';
import passthroughProcessor from './passthrough/passthroughProcessor';
import rangeProcessor from './range/rangeProcessor';
import ratingTableProcessor from './ratingTable/ratingTableProcessor';
import referencedModelProcessor from './referencedModel/referencedModelProcessor';
import roundProcessor from './round/roundProcessor';
import sambaSafetyProcessor from './sambaSafety/sambaSafetyProcessor';
import sumProcessor from './sum/sumProcessor';
import switchProcessor from './switch/switchProcessor';
import timeDifferenceProcessor from './timeDifference/timeDifferenceProcessor';
import moduloProcessor from './modulo/moduloProcessor';
import equalityProcessor from './equality/equalityProcessor';
import orProcessor from './logicalOperators/or/orProcessor';
import andProcessor from './logicalOperators/and/andProcessor';
import btcPriceProcessor from './btc/btcPriceProcessor';
import powerProcessor from './power/powerProcessor';
import previousQuoteProcessor from './previousQuote/previousQuoteProcessor';
import currentDateProcessor from './currentDate/currentDateProcessor';

const processors = {
    'btc-price': btcPriceProcessor,
    'current-date': currentDateProcessor,
    'data-robot': dataRobotProcessor,
    'date-part': datePartProcessor,
    'ekata-identity-check': ekataIdentityCheckProcessor,
    'equifax-consumer-credit-report': equifaxConsumerCreditReportProcessor,
    'equifax-digital-identity-trust': equifaxDigitalIdentityTrustProcessor,
    'fuzzy-matching': fuzzyMatchingProcessor,
    'min-max': rangeProcessor, // @deprecated
    'previous-quote': previousQuoteProcessor,
    'rating-table': ratingTableProcessor,
    'referenced-model': referencedModelProcessor,
    'samba-safety': sambaSafetyProcessor,
    'time-difference': timeDifferenceProcessor,
    'tint-managed': functionProcessor,
    addition: sumProcessor, // @deprecated
    and: andProcessor,
    attribute: attributeProcessor,
    categories: categoriesProcessor,
    coalesce: coalesceProcessor,
    concatenate: concatenateProcessor,
    constant: constantProcessor,
    decision: decisionProcessor,
    division: divisionProcessor,
    equality: equalityProcessor,
    function: functionProcessor,
    logarithm: logarithmProcessor,
    maximum: maximumProcessor,
    minimum: minimumProcessor,
    modulo: moduloProcessor,
    multiplication: multiplicationProcessor,
    or: orProcessor,
    passthrough: passthroughProcessor,
    power: powerProcessor,
    range: rangeProcessor,
    round: roundProcessor,
    sum: sumProcessor,
    switch: switchProcessor,
} as const;

export type ProcessorName = keyof typeof processors;

export default processors;
