import PropTypes from 'prop-types';
import { FieldArray } from 'react-final-form-arrays';

import { makeStyles, Button, Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import { Field } from 'react-final-form';
import StringRow from './StringRow';
import CatchAllInput from '../CatchAllInput';

const useStyles = makeStyles((theme) => ({
    defaultLabelWrapper: {
        marginLeft: theme.spacing(2),
    },
    row: {
        padding: theme.spacing(3, 2),
        '&:nth-child(odd)': {
            background: theme.palette.fieldBackground,
        },
    },
    input: {
        width: '100%',
    },
    newRow: {
        display: 'flex',
        marginTop: theme.spacing(1),
    },
}));

const StringSwitch = ({ disabled, maximumDisplayableThresholds, input }) => {
    const classes = useStyles();

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <FieldArray name={`${input.name}.conditions`}>
                    {({ fields }) => (
                        <div>
                            {fields.map((field, index) => (
                                <StringRow
                                    key={field}
                                    className={classes.row}
                                    name={field}
                                    disabled={disabled}
                                    onDelete={() => fields.remove(index)}
                                />
                            ))}

                            {!disabled && (
                                <div className={classes.newRow}>
                                    <Button
                                        disabled={
                                            fields.length >
                                            maximumDisplayableThresholds
                                        }
                                        onClick={() =>
                                            fields.push({
                                                operation: 'match',
                                                values: [],
                                                label: '',
                                            })
                                        }
                                        startIcon={<AddIcon />}
                                    >
                                        Add a new condition
                                    </Button>
                                </div>
                            )}
                        </div>
                    )}
                </FieldArray>
            </Grid>
            <Grid item xs={12} className={classes.defaultLabelWrapper}>
                <Field
                    component={CatchAllInput}
                    allowNull
                    name={`${input.name}.catchAll`}
                    disabled={disabled}
                    variant="outlined"
                    label="Default Category (optional)"
                />
            </Grid>
        </Grid>
    );
};

StringSwitch.propTypes = {
    disabled: PropTypes.bool,
    input: PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.shape({
            type: PropTypes.string.isRequired,
            conditions: PropTypes.array.isRequired,
            catchAll: PropTypes.string,
        }),
        onChange: PropTypes.func.isRequired,
    }),
    maximumDisplayableThresholds: PropTypes.number,
};

StringSwitch.defaultProps = {
    disabled: false,
    maximumDisplayableThresholds: 100,
    input: {},
};

export default StringSwitch;
