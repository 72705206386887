import { cloneElement } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        position: 'relative',
        height: '100%',
        paddingBottom: ({ actionBar }) => (actionBar ? 88 : 0),
    },
    actionBar: {
        position: 'fixed',
        width: ({ isMenuOpen }) => `calc(100% - ${isMenuOpen ? 220 : 80}px)`, // SideMenu width
        bottom: 0,
        left: ({ isMenuOpen }) => (isMenuOpen ? '220px' : '70px'), // padding left
        transition: theme.transitions.create(),
    },
}));

const ContentWrapper = ({ children, actionBar, isMenuOpen }) => {
    const classes = useStyles({ actionBar, isMenuOpen });

    return (
        <div className={classes.wrapper}>
            {children}
            {actionBar &&
                cloneElement(actionBar, {
                    className: classes.actionBar,
                })}
        </div>
    );
};

ContentWrapper.propTypes = {
    children: PropTypes.node,
    actionBar: PropTypes.node,
    isMenuOpen: PropTypes.boolean,
};

ContentWrapper.defaultProps = {
    children: null,
    actionBar: null,
};

export default ContentWrapper;
