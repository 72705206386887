import type { FunctionComponent } from 'react';
import { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import formatDuration from 'date-fns/formatDuration';
import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
} from '@material-ui/core';
import Button from '../Button';

interface Props {
    timeoutInMs: number;
    promptTimeoutInMs: number;
    onIdle: () => void;
}

const IdleSessionModal: FunctionComponent<Props> = ({
    timeoutInMs,
    promptTimeoutInMs,
    onIdle,
}) => {
    const [open, setOpen] = useState(false);

    const onPrompt = () => {
        setOpen(true);
    };

    const { getRemainingTime, reset } = useIdleTimer({
        promptTimeout: promptTimeoutInMs,
        timeout: timeoutInMs,
        onPrompt,
        onIdle,
    });

    const onResume = () => {
        setOpen(false);
        reset();
    };

    return (
        <Dialog open={open} onClose={onResume}>
            <DialogTitle>Are you still there?</DialogTitle>
            <DialogContent>
                Your session will be closed after{' '}
                {formatDuration(
                    { seconds: Math.round(getRemainingTime() / 1000) },
                    { format: ['minutes', 'seconds'] },
                )}{' '}
                of inactivity.
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={onResume}>
                    Resume
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default IdleSessionModal;
