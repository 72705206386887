import { Create } from 'react-admin';
import ModelForm from './Form';

export const ModelCreate = (props) => (
    <Create
        title="New Model"
        {...props}
        // Feature blueprints creation might be slow
        // and we don't want the admin to quit the page before it's created
        undoable={false}
    >
        <ModelForm />
    </Create>
);
