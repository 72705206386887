import PropTypes from 'prop-types';
import {
    useQueryWithStore,
    Edit,
    Toolbar,
    SaveButton,
    DeleteButton,
} from 'react-admin';
import { makeStyles } from '@material-ui/core';

import ExternalDataProviderCredentialsForm from './Form';

const useStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});

const redirect = (record) => (basePath, id, data) =>
    data || record
        ? `/Organization/${(data || record).organizationId}/2`
        : undefined;

const EditExternalDataProviderCredentials = (props) => {
    const classes = useStyles();

    const { data: record } = useQueryWithStore({
        type: 'getOne',
        resource: 'ExternalDataProviderCredential',
        payload: {
            id: props.id,
        },
    });

    return (
        <Edit title="Edit Credentials" {...props}>
            <ExternalDataProviderCredentialsForm
                valueHelperText="Leave empty if you don't want to change the value"
                valueRequired={false}
                providerDisabled
                redirect={redirect(record)}
                toolbar={
                    <Toolbar className={classes.toolbar}>
                        <SaveButton />
                        <DeleteButton redirect={redirect(record)} />
                    </Toolbar>
                }
            />
        </Edit>
    );
};

EditExternalDataProviderCredentials.propTypes = {
    id: PropTypes.string.isRequired,
};

export default EditExternalDataProviderCredentials;
