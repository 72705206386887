import { pick } from 'lodash';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Fuse from 'fuse.js';

import {
    Grid,
    InputAdornment,
    makeStyles,
    Typography,
} from '@material-ui/core';

import PROCESSOR_GROUPS from './processorGroups';
import ProcessorItem from './ProcessorItem';
import { TextInput } from '../../../forms';
import { SearchIcon } from '../../../icons';
import useKeyPress from '../../../hooks/useKeyPress';

const useStyles = makeStyles((theme) => ({
    search: {
        margin: theme.spacing(0, 3),
    },
    groupName: {
        padding: theme.spacing(0, 3),
        marginBottom: theme.spacing(2),
    },
    list: {
        listStyle: 'none',
        paddingLeft: 0,
    },
}));

const getProcessorsWithoutRestricted = PROCESSOR_GROUPS.reduce((acc, group) => {
    acc.push({
        ...group,
        processors: group.processors.filter(
            (processor) => !processor.restricted,
        ),
    });

    return acc;
}, []);

const getProcessorsForSearch = (showRestrictedProcessors) =>
    PROCESSOR_GROUPS.reduce((acc, group) => {
        for (const processor of group.processors) {
            if (!(!showRestrictedProcessors && processor.restricted === true)) {
                acc.push({
                    processor,
                    group: group.name,
                });
            }
        }

        return acc;
    }, []);

const getProcessorsFromSearch = (searchResult) =>
    searchResult.reduce((acc, result) => {
        const { group: groupName, processor } = result.item;
        let group = acc.find((gr) => gr.name === groupName);

        if (!group) {
            group = {
                name: groupName,
                processors: [],
            };
            acc.push(group);
        }

        group.processors.push(processor);
        return acc;
    }, []);

const filterProcessorsBySearchedValue = (query, showRestrictedProcessors) => {
    if (!query) {
        return !showRestrictedProcessors
            ? getProcessorsWithoutRestricted
            : PROCESSOR_GROUPS;
    }

    const fuse = new Fuse(getProcessorsForSearch(showRestrictedProcessors), {
        keys: [
            {
                name: 'processor.name',
                weight: 2,
            },
            {
                name: 'processor.description',
                weight: 1,
            },
        ],
    });
    const searchResult = fuse.search(query);
    return getProcessorsFromSearch(searchResult);
};

const ProcessorSelect = ({
    onChange,
    showRestrictedProcessors,
    ...otherProps
}) => {
    const classes = useStyles();

    const [selectedIndex, setSelectedIndex] = useState(0);
    const [searchedValue, setSearchedValue] = useState(null);

    const handleClick = (processor) => () => onChange(processor);

    const filteredProcessorGroups = filterProcessorsBySearchedValue(
        searchedValue,
        showRestrictedProcessors,
    );

    const numberProcessors = filteredProcessorGroups.reduce(
        (total, group) => total + group.processors.length,
        0,
    );

    // Disable scroll on top and down arrows
    useEffect(() => {
        const onKeyDown = (e) => {
            if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                e.preventDefault();
            }
        };

        window.addEventListener('keydown', onKeyDown);

        return () => window.removeEventListener('keydown', onKeyDown);
    });

    const flattenProcessors = filteredProcessorGroups
        .map((g) => g.processors)
        .flat();

    useKeyPress(
        'Enter',
        () => {
            handleClick(flattenProcessors[selectedIndex])();
        },
        [selectedIndex, handleClick],
    );

    useKeyPress(
        'ArrowDown',
        () => {
            if (selectedIndex < numberProcessors - 1) {
                setSelectedIndex(selectedIndex + 1);
            }
        },
        [selectedIndex, filteredProcessorGroups],
    );

    useKeyPress(
        'ArrowUp',
        () => {
            if (selectedIndex > 0) {
                setSelectedIndex(selectedIndex - 1);
            }
        },
        [selectedIndex],
    );

    const onSearchedValueChange = (e) => {
        setSearchedValue(e.target.value);
        setSelectedIndex(0);
    };

    let processorIndex = 0;

    return (
        <Grid container direction="column" spacing={4} {...otherProps}>
            <Grid item xs={12} classes={{ root: classes.search }}>
                <TextInput
                    autoFocus
                    type="search"
                    size="small"
                    label="Search"
                    input={{
                        value: searchedValue,
                        onChange: onSearchedValueChange,
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
            {filteredProcessorGroups.map((processorGroup) => (
                <Grid item key={processorGroup.name}>
                    <Typography variant="h3" className={classes.groupName}>
                        {processorGroup.name}
                    </Typography>
                    <ul className={classes.list}>
                        {processorGroup.processors.map((processor) => (
                            <ProcessorItem
                                key={processor.slug}
                                selected={selectedIndex === processorIndex++}
                                onClick={handleClick(processor)}
                                {...pick(processor, [
                                    'name',
                                    'description',
                                    'icon',
                                    'iconSizes',
                                ])}
                            />
                        ))}
                    </ul>
                </Grid>
            ))}
        </Grid>
    );
};

ProcessorSelect.propTypes = {
    onChange: PropTypes.func.isRequired,
    showRestrictedProcessors: PropTypes.bool,
};

ProcessorSelect.defaultProps = {
    showRestrictedProcessors: false,
};

export default ProcessorSelect;
