import PropTypes from 'prop-types';
import { TextInput, SimpleForm, BooleanInput } from 'react-admin';

import { JsonInput } from '../inputs/JsonInput';
import { CustomToolbar } from '../forms/CustomToolbar';
import InsuranceProductInput from '../insuranceProducts/InsuranceProductInput';
import {
    checkRequiredFieldsFactory,
    checkJsonFieldsFactory,
} from '../forms/validation';
import WebhookUrlField from './WebhookUrlField';

const validateClaimForm = (values) => {
    const errors = {
        ...checkRequiredFieldsFactory([
            'name',
            'insuranceProduct.id',
            'slug',
            'url',
            'mappingSchema',
            'sandbox',
        ])(values),
        ...checkJsonFieldsFactory(['mappingSchema'])(values),
    };

    return errors;
};

export const Form = ({ mode, ...props }) => {
    return (
        <SimpleForm
            toolbar={
                <CustomToolbar
                    deleteConfirmTitle={(record) => `${record.name} removal`}
                />
            }
            validate={validateClaimForm}
            {...props}
        >
            <TextInput source="name" required />
            <TextInput source="slug" required />
            <TextInput label="Paperform Url" source="url" required />
            <InsuranceProductInput
                source="insuranceProduct.id"
                fullWidth
                required
            />
            <BooleanInput source="sandbox" required defaultValue={false} />
            {mode === 'edition' && (
                <WebhookUrlField fullWidth addLabel label="Webhook Url" />
            )}
            <JsonInput label="Mapping" source="mappingSchema" />
        </SimpleForm>
    );
};

Form.propTypes = {
    mode: PropTypes.oneOf(['edition', 'creation']),
};
