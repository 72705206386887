import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    root: {
        paddingTop: 32,
    },
}));

export const H2 = ({ children, ...restProps }) => {
    const classes = useStyles();

    return (
        <Typography variant="h2" className={classes.root} {...restProps}>
            {children}
        </Typography>
    );
};

H2.propTypes = {
    children: PropTypes.node.isRequired,
};

export default H2;
