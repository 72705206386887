import { memo } from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, ListItemIcon } from '@material-ui/core';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { Draggable } from 'react-beautiful-dnd';

import inputPropTypes from './inputProptypes';
import InputItem from './InputItem';

const InputList = memo(function InputList({ inputs, disconnect, disabled }) {
    const getStyle = (style) => {
        if (style.transform) {
            const axisLockY = `translate(0px${style.transform.slice(
                style.transform.indexOf(','),
                style.transform.length,
            )}`;
            return {
                ...style,
                transform: axisLockY,
            };
        }

        return {
            ...style,
            cursor: 'pointer',
        };
    };

    return (
        <List>
            {inputs.map((input, index) => (
                <Draggable
                    key={`${input.id}-${input.pin}`}
                    draggableId={`p-${input.id}-${input.pin}`}
                    index={index}
                >
                    {(provided) => (
                        <ListItem
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getStyle(
                                provided.draggableProps.style,
                                provided,
                            )}
                        >
                            <ListItemIcon>
                                <DragIndicatorIcon />
                            </ListItemIcon>
                            <InputItem
                                input={input}
                                disconnect={disconnect}
                                disabled={disabled}
                            />
                        </ListItem>
                    )}
                </Draggable>
            ))}
        </List>
    );
});

InputList.propTypes = {
    inputs: PropTypes.arrayOf(inputPropTypes).isRequired,
    disconnect: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
};

export default InputList;
