// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// Ignore because v4
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ArchiveIcon from '@material-ui/icons/Archive';
import { useMutation, gql } from '@apollo/client';
import { makeStyles, ThemeProvider, Typography } from '@material-ui/core';
import theme, { mainRed } from '../v4/theme';
import { useNotify, useRefresh } from 'react-admin';
import { Button, Confirm } from '../v4';

const useStyles = makeStyles(() => ({
    archiveButton: {
        color: mainRed,
    },
    archiveConfirmationButton: {
        background: mainRed,
    },
}));

const ARCHIVE_USER = gql`
    mutation archiveUser($id: ID!) {
        archiveUser(id: $id) {
            id
            archived
        }
    }
`;

type ArchiveUserButtonProps = {
    data: {
        id: string;
        email: string;
    };
};

export const ArchiveUserButton = ({
    data,
}: ArchiveUserButtonProps): JSX.Element => {
    const classes = useStyles();
    const history = useHistory();
    const notify = useNotify();
    const refresh = useRefresh();

    const [showConfirmation, setShowConfirmation] = useState(false);

    const [
        archiveUser,
        { data: archivedData, loading: archiving, error: archiveError },
    ] = useMutation(ARCHIVE_USER);

    useEffect(() => {
        if (!archiveError) {
            return;
        }

        notify(archiveError.message, 'error');
    }, [archiveError, notify]);

    useEffect(() => {
        if (!archivedData) {
            return;
        }

        notify('User has been archived');
        refresh();
    }, [archivedData, notify, history]);

    const onArchiveConfirmation = () => {
        setShowConfirmation(false);
        archiveUser({ variables: { id: data.id } });
    };

    return (
        <ThemeProvider theme={theme}>
            <Button
                startIcon={<ArchiveIcon />}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setShowConfirmation(true);
                }}
                className={classes.archiveButton}
                loading={archiving}
                disabled={archiving}
            >
                Archive
            </Button>
            <Confirm
                danger
                open={showConfirmation}
                onClose={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setShowConfirmation(false);
                }}
                onConfirm={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onArchiveConfirmation();
                }}
                title="Archiving a user"
                confirmClassName={classes.archiveConfirmationButton}
            >
                <>
                    <Typography variant="body1">
                        You are about to archive the user{' '}
                        <strong>{data.email}</strong>.
                    </Typography>
                    <Typography variant="body1">
                        The user won't be able to login to Tint anymore.
                    </Typography>
                    <br />
                    <Typography variant="body1">
                        All claims assigned to the user will become unassigned.
                    </Typography>
                </>
            </Confirm>
        </ThemeProvider>
    );
};
