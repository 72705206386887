import validator from './validate';

const roundProcessor = {
    name: 'Round',
    slug: 'round',
    description:
        'Return the closest, the ceiled value, or the floored number of the given value',
    validate: validator,
    inputs: [
        {
            id: 'input',
            name: 'Input',
            schema: { type: 'number' },
        },
    ],
    outputs: [
        {
            id: 'output',
            name: 'Output',
            schema: { type: 'number' },
        },
    ],
};

export default roundProcessor;
