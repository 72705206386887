import { createValidator } from '../../validation/validatorFactory';

const validator = createValidator();

const validate = validator.compile({
    type: ['object', 'null'],
    required: [],
    properties: {
        delimiter: { type: 'string' },
    },
    additionalProperties: false,
});

export default validate;
