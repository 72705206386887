import { Edit, useNotify } from 'react-admin';
import ModelForm from './Form';

import ModelEditActions from './ModelEditActions';

export const ModelEdit = (props) => {
    const notify = useNotify();

    const onSuccess = () => {
        notify('Model updated!');
    };

    return (
        <Edit
            title="Edit Model"
            {...props}
            actions={<ModelEditActions />}
            // Feature blueprints creation might be slow
            // and we don't want the admin to quit the page before it's created
            undoable={false}
            onSuccess={onSuccess}
        >
            <ModelForm />
        </Edit>
    );
};
