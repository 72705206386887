import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Field } from 'react-final-form';
import { Select } from '../../../../forms';

const options = [
    {
        value: 'seconds',
        label: 'Seconds',
    },
    {
        value: 'minutes',
        label: 'Minutes',
    },
    {
        value: 'hours',
        label: 'Hours',
    },
    {
        value: 'days',
        label: 'Days',
    },
    {
        value: 'year',
        label: 'Years',
    },
];

const TimeDifferenceConfiguration = ({ disabled }) => {
    return (
        <Grid container direction="column" spacing={1}>
            <Grid item>
                <Field
                    component={Select}
                    name="node.configuration.unit"
                    defaultValue="seconds"
                    label="Unit"
                    options={options}
                    disabled={disabled}
                />
            </Grid>
        </Grid>
    );
};

TimeDifferenceConfiguration.propTypes = {
    disabled: PropTypes.bool,
};

TimeDifferenceConfiguration.defaultProps = {
    disabled: false,
};

export default TimeDifferenceConfiguration;
