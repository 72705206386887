import validator from './validate';

const categoriesProcessor = {
    name: 'Categorization',
    slug: 'categories',
    description: 'Classify an input into a category and return its name',
    validate: validator,
    inputs: [
        {
            id: 'input',
            name: 'Input',
        },
    ],
    outputs: [
        {
            id: 'output',
            name: 'Output',
            schema: { type: ['null', 'string'] },
        },
    ],
};

export default categoriesProcessor;
