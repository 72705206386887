import { omit } from 'lodash';

const SIMPLIFIED_VIEW_PROCESSORS = ['switch', 'referenced-model'];

const shouldRemoveNode = ([, node]) =>
    !SIMPLIFIED_VIEW_PROCESSORS.includes(node.processor) && !node.outputType;

const doesEdgeAlreadyExist = (edges, edge) =>
    !!edges.find(
        (e) =>
            e.start.node === edge.start.node &&
            e.start.pin === edge.start.pin &&
            e.end.node === edge.end.node &&
            e.end.pin === edge.end.pin,
    );

const replaceEdges = (edges, nodeId) => {
    const simplifiedEdges = [];
    const inputEdges = edges.filter((edge) => edge.end.node === nodeId);
    const outputEdges = edges.filter((edge) => edge.start.node === nodeId);

    for (const edge of edges) {
        if (edge.start.node === nodeId) {
            inputEdges.forEach((inputEdge) => {
                const newEdge = {
                    start: inputEdge.start,
                    end: edge.end,
                };

                if (!doesEdgeAlreadyExist(simplifiedEdges, newEdge)) {
                    simplifiedEdges.push(newEdge);
                }
            });

            continue;
        }

        if (edge.end.node === nodeId) {
            outputEdges.forEach((outputEdge) => {
                const newEdge = {
                    start: edge.start,
                    end: outputEdge.end,
                };

                if (!doesEdgeAlreadyExist(simplifiedEdges, newEdge)) {
                    simplifiedEdges.push(newEdge);
                }
            });

            continue;
        }

        simplifiedEdges.push(edge);
    }

    return simplifiedEdges;
};

const getSimplifiedBlueprint = (blueprint, editorData) => {
    const nodeToRemove = Object.entries(blueprint.nodes).find(shouldRemoveNode);
    if (!nodeToRemove) {
        return blueprint;
    }

    const [nodeId] = nodeToRemove;

    return getSimplifiedBlueprint(
        {
            nodes: omit(blueprint.nodes, [nodeId]),
            edges: replaceEdges(blueprint.edges, nodeId),
        },
        editorData,
    );
};

export default getSimplifiedBlueprint;
