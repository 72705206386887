const defaultEvents = {
    AA01: { threshold: 1 },
    AA02: { threshold: 1 },
    AA03: { threshold: 1 },
    AA04: { threshold: 1 },
    AA05: { threshold: 1 },
    AA06: { threshold: 1 },
    AE04: { threshold: 1 },
    AE05: { threshold: 1 },
    AE06: { threshold: 1 },
    MK02: { threshold: 1 },
    MK06: { threshold: 1 },
    MK03: { threshold: 1 },
    MK04: { threshold: 1 },
    MK07: { threshold: 1 },
    MK08: { threshold: 1 },
    MK01: { threshold: 1 },
    UG22: { threshold: 1 },
    UG20: { threshold: 1 },
    MA08: { threshold: 1 },
    UG15: { threshold: 1 },
    UG21: { threshold: 1 },
    CB10: { threshold: 1 },
    CA02: { threshold: 1 },
    CB13: { threshold: 1 },
    CB01: { threshold: 1 },
    CB09: { threshold: 1 },
    CB02: { threshold: 1 },
    CB03: { threshold: 1 },
    CB04: { threshold: 1 },
    CB05: { threshold: 1 },
    CB06: { threshold: 1 },
    CB07: { threshold: 1 },
    CB08: { threshold: 1 },
    CC01: { threshold: 1 },
    CA06: { threshold: 1 },
    CA04: { threshold: 1 },
    CA09: { threshold: 1 },
    CA08: { threshold: 1 },
    CB18: { threshold: 1 },
    CA05: { threshold: 1 },
    CA01: { threshold: 1 },
    CB11: { threshold: 1 },
    CA03: { threshold: 1 },
    DB03: { threshold: 1 },
    DB04: { threshold: 1 },
    DB05: { threshold: 1 },
    DB06: { threshold: 1 },
    DB07: { threshold: 1 },
    DB08: { threshold: 1 },
    DB02: { threshold: 1 },
    DB09: { threshold: 1 },
    CA07: { threshold: 1 },
    CB14: { threshold: 1 },
    CB16: { threshold: 1 },
    CB12: { threshold: 1 },
    CC02: { threshold: 1 },
    CC03: { threshold: 1 },
    UE02: { threshold: 1 },
    UE01: { threshold: 1 },
    UE07: { threshold: 1 },
    UE03: { threshold: 1 },
    UE04: { threshold: 1 },
    UE05: { threshold: 1 },
    UE06: { threshold: 1 },
    MA19: { threshold: 1 },
    MC01: { threshold: 1 },
    MC10: { threshold: 1 },
    MC15: { threshold: 1 },
    MC11: { threshold: 1 },
    MC12: { threshold: 1 },
    MC13: { threshold: 1 },
    MC14: { threshold: 1 },
    MC16: { threshold: 1 },
    MC20: { threshold: 1 },
    MC02: { threshold: 1 },
    MC03: { threshold: 1 },
    MC04: { threshold: 1 },
    MC05: { threshold: 1 },
    MC06: { threshold: 1 },
    MC07: { threshold: 1 },
    MC08: { threshold: 1 },
    MC19: { threshold: 1 },
    MC09: { threshold: 1 },
    MC18: { threshold: 1 },
    MC17: { threshold: 1 },
    DE05: { threshold: 1 },
    DE06: { threshold: 1 },
    DE08: { threshold: 1 },
    DE09: { threshold: 1 },
    DE01: { threshold: 1 },
    DE02: { threshold: 1 },
    DE03: { threshold: 1 },
    DE10: { threshold: 1 },
    ME05: { threshold: 1 },
    AD02: { threshold: 1 },
    AD03: { threshold: 1 },
    AD04: { threshold: 1 },
    AD01: { threshold: 1 },
    AE02: { threshold: 1 },
    AB01: { threshold: 1 },
    AB03: { threshold: 1 },
    AB02: { threshold: 1 },
    AB04: { threshold: 1 },
    AC04: { threshold: 1 },
    AC02: { threshold: 1 },
    AC03: { threshold: 1 },
    AC01: { threshold: 1 },
    MB02: { threshold: 1 },
    MB01: { threshold: 1 },
    MB12: { threshold: 1 },
    MB08: { threshold: 1 },
    MB18: { threshold: 1 },
    MB14: { threshold: 1 },
    MB20: { threshold: 1 },
    MB21: { threshold: 1 },
    MB16: { threshold: 1 },
    MB22: { threshold: 1 },
    MB07: { threshold: 1 },
    MB05: { threshold: 1 },
    MB06: { threshold: 1 },
    MB11: { threshold: 1 },
    MB24: { threshold: 1 },
    MB09: { threshold: 1 },
    MB23: { threshold: 1 },
    MB03: { threshold: 1 },
    MB10: { threshold: 1 },
    MB15: { threshold: 1 },
    MB13: { threshold: 1 },
    VA09: { threshold: 1 },
    MB04: { threshold: 1 },
    UH20: { threshold: 1 },
    UH21: { threshold: 1 },
    UH19: { threshold: 1 },
    DB36: { threshold: 1 },
    DB34: { threshold: 1 },
    KC21: { threshold: 1 },
    KC02: { threshold: 1 },
    KC05: { threshold: 1 },
    KC06: { threshold: 1 },
    KC08: { threshold: 1 },
    KC11: { threshold: 1 },
    KC12: { threshold: 1 },
    KC14: { threshold: 1 },
    KC17: { threshold: 1 },
    KC20: { threshold: 1 },
    KC07: { threshold: 1 },
    KC01: { threshold: 1 },
    KC09: { threshold: 1 },
    KC10: { threshold: 1 },
    KC13: { threshold: 1 },
    KC15: { threshold: 1 },
    KC16: { threshold: 1 },
    KC03: { threshold: 1 },
    KC04: { threshold: 1 },
    KC18: { threshold: 1 },
    KC19: { threshold: 1 },
    DB59: { threshold: 1 },
    DB28: { threshold: 1 },
    DB01: { threshold: 1 },
    DB13: { threshold: 1 },
    DB37: { threshold: 1 },
    DB55: { threshold: 1 },
    DB56: { threshold: 1 },
    DH01: { threshold: 1 },
    DB14: { threshold: 1 },
    DH06: { threshold: 1 },
    DB15: { threshold: 1 },
    DB57: { threshold: 1 },
    DB54: { threshold: 1 },
    UH29: { threshold: 1 },
    UH25: { threshold: 1 },
    UH27: { threshold: 1 },
    UH28: { threshold: 1 },
    UH24: { threshold: 1 },
    UH26: { threshold: 1 },
    DB38: { threshold: 1 },
    DB17: { threshold: 1 },
    DB33: { threshold: 1 },
    DB12: { threshold: 1 },
    DB20: { threshold: 1 },
    DB42: { threshold: 1 },
    DB61: { threshold: 1 },
    DB11: { threshold: 1 },
    DB35: { threshold: 1 },
    DB29: { threshold: 1 },
    DB30: { threshold: 1 },
    DB60: { threshold: 1 },
    DB31: { threshold: 1 },
    DB32: { threshold: 1 },
    UH12: { threshold: 1 },
    UH23: { threshold: 1 },
    DB27: { threshold: 1 },
    DB26: { threshold: 1 },
    DB45: { threshold: 1 },
    DB43: { threshold: 1 },
    DB62: { threshold: 1 },
    UH06: { threshold: 1 },
    DB44: { threshold: 1 },
    DB58: { threshold: 1 },
    DB51: { threshold: 1 },
    DB53: { threshold: 1 },
    DB50: { threshold: 1 },
    DB48: { threshold: 1 },
    DB52: { threshold: 1 },
    DB47: { threshold: 1 },
    DB46: { threshold: 1 },
    DB49: { threshold: 1 },
    UG04: { threshold: 1 },
    VG13: { threshold: 1 },
    VG08: { threshold: 1 },
    VH05: { threshold: 1 },
    VG01: { threshold: 1 },
    EL09: { threshold: 1 },
    UG25: { threshold: 1 },
    VH13: { threshold: 1 },
    DA07: { threshold: 1 },
    UG23: { threshold: 1 },
    CB15: { threshold: 1 },
    DB21: { threshold: 1 },
    DD04: { threshold: 1 },
    DC10: { threshold: 1 },
    UG19: { threshold: 1 },
    DB23: { threshold: 1 },
    UG24: { threshold: 1 },
    DB22: { threshold: 1 },
    DB24: { threshold: 1 },
    DB25: { threshold: 1 },
    DD05: { threshold: 1 },
    SA03: { threshold: 1 },
    MK05: { threshold: 1 },
    CA10: { threshold: 1 },
    CA11: { threshold: 1 },
    SA02: { threshold: 1 },
    SA05: { threshold: 1 },
    SA07: { threshold: 1 },
    SB09: { threshold: 1 },
    SB10: { threshold: 1 },
    SB15: { threshold: 1 },
    SB08: { threshold: 1 },
    SB11: { threshold: 1 },
    SB12: { threshold: 1 },
    SB13: { threshold: 1 },
    SB14: { threshold: 1 },
    SB16: { threshold: 1 },
    SA95: { threshold: 1 },
    SA98: { threshold: 1 },
    SA90: { threshold: 1 },
    SB07: { threshold: 1 },
    SA99: { threshold: 1 },
    SA20: { threshold: 1 },
    SA26: { threshold: 1 },
    SA32: { threshold: 1 },
    SA38: { threshold: 1 },
    SA45: { threshold: 1 },
    SA52: { threshold: 1 },
    SA59: { threshold: 1 },
    SA66: { threshold: 1 },
    SA72: { threshold: 1 },
    SA79: { threshold: 1 },
    SA84: { threshold: 1 },
    SA91: { threshold: 1 },
    SB01: { threshold: 1 },
    SA21: { threshold: 1 },
    SA27: { threshold: 1 },
    SA33: { threshold: 1 },
    SA39: { threshold: 1 },
    SA46: { threshold: 1 },
    SA53: { threshold: 1 },
    SA60: { threshold: 1 },
    SA67: { threshold: 1 },
    SA73: { threshold: 1 },
    SA80: { threshold: 1 },
    SA85: { threshold: 1 },
    SA92: { threshold: 1 },
    SB02: { threshold: 1 },
    SA22: { threshold: 1 },
    SA28: { threshold: 1 },
    SA34: { threshold: 1 },
    SA40: { threshold: 1 },
    SA47: { threshold: 1 },
    SA54: { threshold: 1 },
    SA61: { threshold: 1 },
    SA68: { threshold: 1 },
    SA74: { threshold: 1 },
    SA86: { threshold: 1 },
    SA93: { threshold: 1 },
    SB03: { threshold: 1 },
    SA29: { threshold: 1 },
    SA35: { threshold: 1 },
    SA41: { threshold: 1 },
    SA48: { threshold: 1 },
    SA55: { threshold: 1 },
    SA62: { threshold: 1 },
    SA69: { threshold: 1 },
    SA75: { threshold: 1 },
    SA87: { threshold: 1 },
    SB04: { threshold: 1 },
    SA23: { threshold: 1 },
    SA42: { threshold: 1 },
    SA49: { threshold: 1 },
    SA56: { threshold: 1 },
    SA63: { threshold: 1 },
    SA76: { threshold: 1 },
    SA81: { threshold: 1 },
    SA88: { threshold: 1 },
    SB05: { threshold: 1 },
    SA94: { threshold: 1 },
    SA97: { threshold: 1 },
    SB06: { threshold: 1 },
    SA04: { threshold: 1 },
    SA01: { threshold: 1 },
    SB17: { threshold: 1 },
    SA08: { threshold: 1 },
    SA10: { threshold: 1 },
    SA11: { threshold: 1 },
    SA12: { threshold: 1 },
    SA13: { threshold: 1 },
    SA14: { threshold: 1 },
    SA15: { threshold: 1 },
    SA16: { threshold: 1 },
    SA09: { threshold: 1 },
    SA06: { threshold: 1 },
    SB18: { threshold: 1 },
    VG07: { threshold: 1 },
    VF05: { threshold: 1 },
    VF06: { threshold: 1 },
    MA01: { threshold: 1 },
    MA02: { threshold: 1 },
    MA04: { threshold: 1 },
    MA11: { threshold: 1 },
    MA12: { threshold: 1 },
    MA17: { threshold: 1 },
    MA03: { threshold: 1 },
    MA05: { threshold: 1 },
    MA07: { threshold: 1 },
    MA13: { threshold: 1 },
    MA14: { threshold: 1 },
    MA16: { threshold: 1 },
    MA18: { threshold: 1 },
    MH09: { threshold: 1 },
    VG05: { threshold: 1 },
    ED04: { threshold: 1 },
    ML02: { threshold: 1 },
    ML01: { threshold: 1 },
    VG02: { threshold: 1 },
    MG06: { threshold: 1 },
    MG04: { threshold: 1 },
    ML05: { threshold: 1 },
    MG02: { threshold: 1 },
    ED05: { threshold: 1 },
    MG03: { threshold: 1 },
    EF06: { threshold: 1 },
    EF07: { threshold: 1 },
    MG01: { threshold: 1 },
    MG05: { threshold: 1 },
    ML03: { threshold: 1 },
    VH15: { threshold: 1 },
    ML04: { threshold: 1 },
    VH14: { threshold: 1 },
    VG09: { threshold: 1 },
    VG04: { threshold: 1 },
    VG03: { threshold: 1 },
    VA05: { threshold: 1 },
    VA03: { threshold: 1 },
    VA02: { threshold: 1 },
    VA08: { threshold: 1 },
    VG06: { threshold: 1 },
    EF02: { threshold: 1 },
    VB02: { threshold: 1 },
    VB01: { threshold: 1 },
    VH06: { threshold: 1 },
    ML06: { threshold: 1 },
    VH04: { threshold: 1 },
    UG02: { threshold: 1 },
    VH09: { threshold: 1 },
    VG12: { threshold: 1 },
    VG10: { threshold: 1 },
    UG01: { threshold: 1 },
    VH10: { threshold: 1 },
    VH12: { threshold: 1 },
    UG08: { threshold: 1 },
    VH11: { threshold: 1 },
    MI05: { threshold: 1 },
    MJ01: { threshold: 1 },
    MJ02: { threshold: 1 },
    MJ03: { threshold: 1 },
    MI01: { threshold: 1 },
    MI03: { threshold: 1 },
    MI04: { threshold: 1 },
    MI02: { threshold: 1 },
    MJ04: { threshold: 1 },
    MB19: { threshold: 1 },
    MJ05: { threshold: 1 },
    MJ06: { threshold: 1 },
};

export default defaultEvents;
