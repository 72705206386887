import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import {
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    makeStyles,
} from '@material-ui/core';

import { get } from 'lodash';
import { blueprintPropType } from '../../../blueprintEditorPropTypes';

import IntervalSwitch from '../categorization/interval/IntervalSwitch';
import StringSwitch from '../categorization/string/StringSwitch';
import BooleanSwitch from '../categorization/boolean/BooleanSwitch';

const CONDITIONS_FORM_BY_TYPE = {
    interval: IntervalSwitch,
    string: StringSwitch,
    boolean: BooleanSwitch,
};

const useStyles = makeStyles({ details: { width: '100%' } });

const DimensionAccordion = ({
    field,
    disabled,
    blueprint,
    dimension,
    maximumRatesNumber,
}) => {
    const classes = useStyles();
    const ConditionsForm = CONDITIONS_FORM_BY_TYPE[dimension.type];
    const tooMuchConditions =
        get(dimension, 'conditions', []).length > maximumRatesNumber;

    const node = blueprint.nodes[dimension.input];

    if (!node) {
        // This is an error/edge case, and it is handled by the RatingTableConfiguration component effects
        // but in the meantime the effect is processed, there is a render that end in error
        return null;
    }

    return (
        <Accordion defaultExpanded elevation={0}>
            <AccordionSummary>
                <Typography variant="h3">{node.name || 'New Block'}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <div className={classes.details}>
                    {tooMuchConditions ? (
                        <Typography variant="body1">
                            There are too many conditions to be displayed. They
                            can't be edited on the app yet.
                            <br />
                            Please{' '}
                            <a href="https://tintai.slack.com/">
                                contact the team
                            </a>{' '}
                            if you need any changes.
                        </Typography>
                    ) : ConditionsForm ? (
                        <Field
                            component={ConditionsForm}
                            name={field}
                            disabled={disabled}
                        />
                    ) : (
                        <Typography variant="body1">
                            Impossible to split the values of this input. Please
                            connect another block as an input.
                        </Typography>
                    )}
                </div>
            </AccordionDetails>
        </Accordion>
    );
};

DimensionAccordion.propTypes = {
    field: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    blueprint: PropTypes.shape(blueprintPropType).isRequired,
    dimension: PropTypes.shape({
        input: PropTypes.string.isRequired,
        type: PropTypes.oneOf(['string', 'interval']).isRequired,
    }).isRequired,
    maximumRatesNumber: PropTypes.number.isRequired,
};

DimensionAccordion.defaultProps = {
    disabled: false,
};

export default DimensionAccordion;
