import create from './create.graphql';
import deleteModel from './delete.graphql';
import getList from './getList.graphql';
import getOne from './getOne.graphql';
import getMany from './getMany.graphql';
import update from './update.graphql';
import { omit } from 'lodash';

const dataToInput = ({ data: { insuranceProduct, ...otherData } }) => ({
    ...omit(otherData, [
        'outputType',
        'readOnly',
        'archived',
        'isValid',
        'createdAt',
        'lastUpdatedAt',
        'archivedAt',
        '__typename',
    ]),
    insuranceProductId: insuranceProduct.id,
});

const modelQueries = {
    dataToInput,
    create,
    delete: deleteModel,
    getOne,
    getList,
    getMany,
    update,
};

export default modelQueries;
