import PropTypes from 'prop-types';
import { useFormState } from 'react-final-form';

import PiiAttributeSelector from '../../forms/PiiAttributeSelector';

export const PathMultiSelect = ({ id, label, value, onChange }) => {
    const { values } = useFormState();
    const { validationSchema } = values;
    return (
        <PiiAttributeSelector
            id={id}
            label={label}
            validationSchema={validationSchema}
            onChange={onChange}
            value={value}
        />
    );
};

PathMultiSelect.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};
