import { createValidator } from '../../../validation/validatorFactory';

const validator = createValidator();

const validate = validator.compile({
    type: 'object',
    properties: { nullAsFalse: { type: 'boolean' } },
    additionalProperties: false,
    nullable: true,
});

export default validate;
