import { makeStyles } from '@material-ui/core';
import ArrowRightAlt from '@material-ui/icons/ArrowRightAlt';

import inputPropTypes from './inputProptypes';
import { getNodeInputLabel } from '../processors';

const useStyles = makeStyles(() => ({
    arrowIcon: {
        verticalAlign: '-7px',
    },
}));

const InputText = ({ input }) => {
    const classes = useStyles();

    return (
        <span>
            {getNodeInputLabel(input)}
            {input.targetName && (
                <>
                    <ArrowRightAlt className={classes.arrowIcon} />{' '}
                    {input.targetName}
                </>
            )}
        </span>
    );
};

InputText.propTypes = {
    input: inputPropTypes.isRequired,
};

export default InputText;
