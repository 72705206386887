import { useState } from 'react';
import PropTypes from 'prop-types';
import FormHelperText from '@material-ui/core/FormHelperText';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import { Labeled, InputHelperText } from 'react-admin';

import { JsonModeInput } from './JsonModeInput';

export const JsonWithWizardInput = ({ input, label, meta, children }) => {
    const { error, touched } = meta;

    const [mode, setMode] = useState('wizard');

    return (
        <>
            <Labeled label={label} fullWidth>
                <ToggleButtonGroup
                    value={mode}
                    exclusive
                    size="small"
                    onChange={(_, newMode) => newMode && setMode(newMode)}
                >
                    <ToggleButton value="wizard">Wizard Mode</ToggleButton>
                    <ToggleButton value="json">JSON Mode</ToggleButton>
                </ToggleButtonGroup>
            </Labeled>
            {mode === 'wizard' && children}
            {mode === 'json' && (
                <JsonModeInput name={input.name} source={input.name} />
            )}
            {touched &&
                error &&
                error.split('\n').map((chunk) => (
                    <FormHelperText key={chunk} error>
                        <InputHelperText error={chunk} touched />
                    </FormHelperText>
                ))}
        </>
    );
};

JsonWithWizardInput.propTypes = {
    input: PropTypes.shape({
        value: PropTypes.any,
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
    }).isRequired,
    meta: PropTypes.shape({
        error: PropTypes.string,
        touched: PropTypes.bool,
    }),
    label: PropTypes.string.isRequired,
    children: PropTypes.element.isRequired,
};
