import type { FunctionComponent } from 'react';
import { ReferenceInput, AutocompleteInput } from 'react-admin';

type Props = {
    source: string;
    label: string;
    disabled?: string;
};

const OrganizationReferenceInput: FunctionComponent<Props> = (props) => {
    return (
        <ReferenceInput
            {...props}
            reference="Organization"
            sort={{ field: 'name', order: 'ASC' }}
            filterToQuery={(searchText) => ({ name: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
    );
};

export default OrganizationReferenceInput;
