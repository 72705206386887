import validator from './validate';

const rangeProcessor = {
    name: 'Range',
    slug: 'min-max',
    description: 'Cap the input between a minimum and a maximum values',
    validate: validator,
    inputs: [
        {
            id: 'value',
            name: 'Value',
            schema: { type: 'number' },
        },
        {
            id: 'min',
            name: 'Minimum',
            required: false,
            schema: { type: 'number' },
        },
        {
            id: 'max',
            name: 'Maximum',
            required: false,
            schema: { type: 'number' },
        },
    ],
    outputs: [
        {
            id: 'output',
            name: 'Output',
            schema: { type: 'number' },
        },
    ],
};

export default rangeProcessor;
