import { gql, useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import Button from '../v4/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import { ThemeProvider } from '@material-ui/core';
import theme from '../v4/theme';
import { useEffect } from 'react';
import { get } from 'lodash';
import { useNotify } from 'ra-core';

const POSTMAN_COLLECTION_QUERY = gql`
    query getPostmanCollection($id: ID!) {
        insuranceProduct(id: $id) {
            postmanCollection
        }
    }
`;

const ExportPostmanCollectionButton = ({ insuranceProductId }) => {
    const [getPostmanCollection, { data, loading, error }] = useLazyQuery(
        POSTMAN_COLLECTION_QUERY,
        {
            variables: {
                id: insuranceProductId,
            },
        },
    );

    const notify = useNotify();

    useEffect(() => {
        if (!error) {
            return;
        }

        notify(error.message, 'error');
    }, [notify, error]);

    useEffect(() => {
        const collection = get(
            data,
            'insuranceProduct.postmanCollection',
            null,
        );

        if (!collection) {
            return;
        }

        // Download the collection as a JSON file
        const blob = new Blob([JSON.stringify(collection, null, 2)], {
            type: 'application/json',
        });

        const elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(blob);
        elem.download = 'postman-collection.json';
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
    }, [data]);

    return (
        <ThemeProvider theme={theme}>
            <Button
                loading={loading}
                color="primary"
                variant="contained"
                startIcon={<GetAppIcon />}
                onClick={getPostmanCollection}
            >
                Get Postman Collection
            </Button>
        </ThemeProvider>
    );
};

ExportPostmanCollectionButton.propTypes = {
    insuranceProductId: PropTypes.string.isRequired,
};

export default ExportPostmanCollectionButton;
