import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import { get } from 'lodash';
import { Select } from './primitives';
import { Alert } from '@material-ui/lab';

export const GET_VERSIONS_LIST_QUERY = gql`
    query getVersionsList($filter: InsuranceProductVersionFilter) {
        insuranceProductVersions(filter: $filter, perPage: null) {
            items {
                id
                name
                finalizedAt
            }
        }
    }
`;

const VersionsSelect = ({ filter, ...otherProps }) => {
    const {
        data: versionsData,
        loading,
        error,
    } = useQuery(GET_VERSIONS_LIST_QUERY, {
        fetchPolicy: 'cache-and-network',
        variables: {
            filter,
        },
    });

    if (error) {
        return <Alert severity="error">Unable to fetch your versions</Alert>;
    }

    const versions = get(
        versionsData,
        'insuranceProductVersions.items',
        [],
    ).map((version) => ({
        label: version.name,
        value: version.id,
    }));

    return <Select loading={loading} {...otherProps} options={versions} />;
};

VersionsSelect.propTypes = {
    filter: PropTypes.object,
};

VersionsSelect.defaultProps = {
    filter: {},
};

export default VersionsSelect;
