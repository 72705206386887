import validator from './validate';

const orProcessor = {
    name: 'Logical OR',
    description: 'Return true if at least one input is truthy',
    slug: 'or',
    validate: validator,
    inputs: {
        id: 'inputs',
        dynamic: true,
        min: 2,
        schema: {
            oneOf: [
                { type: 'boolean' },
                { type: 'string' },
                { type: 'number' },
            ],
        },
    },
    outputs: [
        {
            id: 'output',
            name: 'Output',
            schema: { type: 'boolean' },
        },
    ],
};

export default orProcessor;
