import 'codemirror';
import { get } from 'lodash';
import PropTypes from 'prop-types';

import { Controlled as CodeMirror } from 'react-codemirror2';

import { Labeled } from 'react-admin';

import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/addon/fold/brace-fold';
import 'codemirror/addon/fold/foldgutter';
import 'codemirror/addon/fold/foldgutter.css';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
    },
    codeMirror: {
        '& .CodeMirror': {
            height: ({ height = 300 }) => `${height}px !important`, // remove CodeMirror 300px default CSS
        },
    },
}));

const JsonField = ({ source, record, label, height }) => {
    const classes = useStyles({ height });

    const codeMirror = (
        <CodeMirror
            className={classes.codeMirror}
            value={JSON.stringify(get(record, source), null, 4)}
            label={label}
            options={{
                mode: {
                    name: 'javascript',
                    json: true,
                },
                theme: 'material',
                lineNumbers: true,
                readOnly: true,
                foldGutter: true,
                gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter'],
            }}
        />
    );

    if (!label) {
        return codeMirror;
    }

    return (
        <Labeled className={classes.root} label={label}>
            {codeMirror}
        </Labeled>
    );
};

JsonField.propTypes = {
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
    label: PropTypes.string,
    height: PropTypes.number,
};

JsonField.defaultProps = {
    record: {},
    label: null,
    height: 300,
};

export default JsonField;
