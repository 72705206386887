import PropTypes from 'prop-types';
import { Grid, Typography, Tab } from '@material-ui/core';
import { Field } from 'react-final-form';

import {
    ExternalDataCredentialInput,
    TextInput,
    Select,
} from '../../../../forms';
import { insuranceProductProptype } from '../../../../../../../tint-app/src/insuranceProduct/versions/propTypes';
import TimeoutField from './fields/TimeoutField';

const DEFAULT_TIMEOUT = 1500;
const DEFAULT_FALLBACK_VALUE = null;

export const getEkataIdentityCheckConfigurationTabs = () => [
    <Tab key="configuration" label="Configuration" />,
    <Tab key="advanced" label="Advanced" />,
];

const parseNumericFallbackValue = (value) => {
    if (typeof value === 'undefined' || value === '') {
        return null;
    }

    return parseFloat(value);
};

const formatNumericFallbackValue = (value) => {
    if (value === null) {
        return null;
    }

    return value.toString();
};

const parseStringFallbackValue = (value) => {
    if (typeof value === 'undefined' || value === '') {
        return null;
    }

    return value;
};

const formatStringFallbackValue = (value) => value;

const parseBooleanFallbackValue = (value) => {
    if (value === 'true') {
        return true;
    }

    if (value === 'false') {
        return false;
    }

    return null;
};

const formatBooleanFallbackValue = formatNumericFallbackValue;

const booleanOptions = [
    {
        value: 'true',
        label: 'True',
    },
    {
        value: 'false',
        label: 'False',
    },
    {
        value: 'null',
        label: 'Null',
    },
];

const EkataIdentityCheckConfiguration = ({
    tab,
    disabled,
    insuranceProduct,
}) => {
    if (tab === 1) {
        return (
            <Grid container direction="column" spacing={2}>
                <Grid item xs={8}>
                    <TimeoutField
                        disabled={disabled}
                        defaultTimeout={DEFAULT_TIMEOUT}
                        helperText="Max wait time for Ekata response before raising an error"
                    />
                </Grid>
                <Grid item xs={8} style={{ marginTop: 12 }}>
                    <Typography variant="h3">
                        Default Values on Error
                    </Typography>
                    <Typography variant="body2">
                        Ekata may sometimes be too slow to respond or return an
                        error,
                        <br />
                        in those cases, the following values will be returned.
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Field
                        component={TextInput}
                        disabled={disabled}
                        name="node.configuration.fallbackIdentityCheckScore"
                        label="Identity Check Score"
                        type="number"
                        defaultValue={DEFAULT_FALLBACK_VALUE}
                        parse={parseNumericFallbackValue}
                        format={formatNumericFallbackValue}
                        inputProps={{
                            min: 0,
                            max: 500,
                            step: 1,
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={8}>
                    <Field
                        component={TextInput}
                        disabled={disabled}
                        name="node.configuration.fallbackIdentityNetworkScore"
                        label="Identity Network Score"
                        type="number"
                        defaultValue={DEFAULT_FALLBACK_VALUE}
                        parse={parseNumericFallbackValue}
                        format={formatNumericFallbackValue}
                        inputProps={{
                            min: 0,
                            max: 1,
                            step: 0.05,
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={8}>
                    <Field
                        component={TextInput}
                        disabled={disabled}
                        name="node.configuration.fallbackEstimatedAge"
                        label="Estimated Age"
                        type="number"
                        defaultValue={DEFAULT_FALLBACK_VALUE}
                        parse={parseNumericFallbackValue}
                        format={formatNumericFallbackValue}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={8}>
                    <Field
                        component={Select}
                        disabled={disabled}
                        name="node.configuration.fallbackPhoneNumberIsValid"
                        label="Phone Number Is Valid"
                        defaultValue={DEFAULT_FALLBACK_VALUE}
                        options={booleanOptions}
                        parse={parseBooleanFallbackValue}
                        format={formatBooleanFallbackValue}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={8}>
                    <Field
                        component={TextInput}
                        disabled={disabled}
                        name="node.configuration.fallbackPhoneNumberCarrier"
                        label="Phone Number Carrier"
                        defaultValue={DEFAULT_FALLBACK_VALUE}
                        parse={parseStringFallbackValue}
                        format={formatStringFallbackValue}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={8}>
                    <Field
                        component={Select}
                        disabled={disabled}
                        name="node.configuration.fallbackPhoneNumberIsPrepaid"
                        label="Phone Number Is Prepaid"
                        defaultValue={DEFAULT_FALLBACK_VALUE}
                        options={booleanOptions}
                        parse={parseBooleanFallbackValue}
                        format={formatBooleanFallbackValue}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={8}>
                    <Field
                        component={TextInput}
                        disabled={disabled}
                        name="node.configuration.fallbackPhoneNumberMatchToName"
                        label="Phone Number Match to Name"
                        defaultValue={DEFAULT_FALLBACK_VALUE}
                        parse={parseStringFallbackValue}
                        format={formatStringFallbackValue}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={8}>
                    <Field
                        component={TextInput}
                        disabled={disabled}
                        name="node.configuration.fallbackPhoneNumberMatchToAddress"
                        label="Phone Number Match to Address"
                        defaultValue={DEFAULT_FALLBACK_VALUE}
                        parse={parseStringFallbackValue}
                        format={formatStringFallbackValue}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={8}>
                    <Field
                        component={TextInput}
                        disabled={disabled}
                        name="node.configuration.fallbackAddressMatchToName"
                        label="Address Match to Name"
                        defaultValue={DEFAULT_FALLBACK_VALUE}
                        parse={parseStringFallbackValue}
                        format={formatStringFallbackValue}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={8}>
                    <Field
                        component={TextInput}
                        disabled={disabled}
                        name="node.configuration.fallbackEmailFirstSeenDays"
                        label="Email First Seen Days"
                        type="number"
                        defaultValue={DEFAULT_FALLBACK_VALUE}
                        parse={parseNumericFallbackValue}
                        format={formatNumericFallbackValue}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={8}>
                    <Field
                        component={TextInput}
                        disabled={disabled}
                        name="node.configuration.fallbackEmailMatchToName"
                        label="Email Match to Name"
                        defaultValue={DEFAULT_FALLBACK_VALUE}
                        parse={parseStringFallbackValue}
                        format={formatStringFallbackValue}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={8}>
                    <Field
                        component={Select}
                        disabled={disabled}
                        name="node.configuration.fallbackEmailIsAutogenerated"
                        label="Email Is Autogenerated"
                        defaultValue={DEFAULT_FALLBACK_VALUE}
                        options={booleanOptions}
                        parse={parseBooleanFallbackValue}
                        format={formatBooleanFallbackValue}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={8}>
                    <Field
                        component={Select}
                        disabled={disabled}
                        name="node.configuration.fallbackEmailIsDisposable"
                        label="Email Is Disposable"
                        defaultValue={DEFAULT_FALLBACK_VALUE}
                        options={booleanOptions}
                        parse={parseBooleanFallbackValue}
                        format={formatBooleanFallbackValue}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={8}>
                    <Field
                        component={TextInput}
                        disabled={disabled}
                        name="node.configuration.fallbackEmailDomainCreationDays"
                        label="Email Domain Creation Days"
                        type="number"
                        defaultValue={DEFAULT_FALLBACK_VALUE}
                        parse={parseNumericFallbackValue}
                        format={formatNumericFallbackValue}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={8}>
                    <Field
                        component={TextInput}
                        disabled={disabled}
                        name="node.configuration.fallbackIpDistanceFromAddress"
                        label="IP Distance From Address"
                        type="number"
                        defaultValue={DEFAULT_FALLBACK_VALUE}
                        parse={parseNumericFallbackValue}
                        format={formatNumericFallbackValue}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={8}>
                    <Field
                        component={TextInput}
                        disabled={disabled}
                        name="node.configuration.fallbackIpDistanceFromPhoneNumber"
                        label="IP Distance From Phone Number"
                        type="number"
                        defaultValue={DEFAULT_FALLBACK_VALUE}
                        parse={parseNumericFallbackValue}
                        format={formatNumericFallbackValue}
                        fullWidth
                    />
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <Typography variant="body2">
                    The following credentials will be encrypted before being
                    saved, and nobody in your team will be able to read them.
                </Typography>
            </Grid>
            <Grid item>
                <ExternalDataCredentialInput
                    insuranceProduct={insuranceProduct}
                    providerSlug="ekata-identity-check"
                    name="apiKey"
                    label="API Key"
                    variant="outlined"
                    fullWidth
                    required
                    type="password"
                    disabled={disabled}
                />
            </Grid>
        </Grid>
    );
};

EkataIdentityCheckConfiguration.propTypes = {
    disabled: PropTypes.bool,
    insuranceProduct: insuranceProductProptype.isRequired,
    tab: PropTypes.number.isRequired,
};

EkataIdentityCheckConfiguration.defaultProps = {
    disabled: false,
};

export default EkataIdentityCheckConfiguration;
