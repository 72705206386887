import { createValidator } from '../../validation/validatorFactory';

const validator = createValidator();

const validate = validator.compile({
    type: 'object',
    required: ['deploymentId', 'mapping'],
    properties: {
        lowercaseInputs: { type: 'boolean' },
        deploymentId: { type: 'string' },
        mapping: { type: 'array', items: { type: 'string' } },
        timeout: {
            type: 'number',
            minimum: 0,
            maximum: 29000,
        },
        fallbackValue: {
            type: ['number', 'null'],
            minimum: 0,
            maximum: 1,
        },
    },
    additionalProperties: false,
});

export default validate;
