import { cloneElement } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { makeStyles, useTheme } from '@material-ui/core';
import Button from './Button';

const getColor =
    (theme) =>
    ({ variant }) =>
        theme.palette[variant === 'contained' ? 'backgroundWhite' : 'mainRed'];

const useStyles = makeStyles((theme) => ({
    root: {
        color: getColor(theme),
        backgroundColor: ({ variant }) =>
            variant === 'contained' ? theme.palette.mainRed : null,
        '&:hover': {
            backgroundColor: ({ variant }) =>
                variant === 'contained' ? theme.palette.mainRedHover : null,
        },
        borderColor: ({ variant }) =>
            variant === 'outlined' ? theme.palette.mainRed : null,
    },
}));

const DangerButton = ({ className, startIcon, variant, ...otherProps }) => {
    const classes = useStyles({ variant });
    const theme = useTheme();

    return (
        <Button
            className={classnames(className, classes.root)}
            {...otherProps}
            variant={variant}
            startIcon={
                startIcon
                    ? cloneElement(startIcon, {
                          color: getColor(theme)({ variant }),
                      })
                    : null
            }
            color="primary" // to use same contrast level
        />
    );
};

DangerButton.propTypes = {
    className: PropTypes.string,
    startIcon: PropTypes.node,
    variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
};

DangerButton.defaultProps = {
    className: '',
    startIcon: null,
    variant: 'text',
};

export default DangerButton;
