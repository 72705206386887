import validator from './validate';

export type BtcPriceNode = {
    processor: 'btc-price';
    configuration?: {
        timeout?: number;
    };
};

const btcPriceProcessor = {
    name: 'BTC Price',
    slug: 'btc-price',
    description: 'Fetch real time BTC price in $',
    validate: validator,
    inputs: [] as never[],
    outputs: [
        {
            id: 'output',
            name: 'Output',
            schema: { type: 'number' },
        },
    ],
};

export default btcPriceProcessor;
