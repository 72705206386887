import { useRef } from 'react';
import { SnackbarProvider as SnackbarProviderBase } from 'notistack';
import { IconButton, useTheme } from '@material-ui/core';
import { CrossIcon } from '../icons';

const SnackbarProvider = ({ ...otherProps }) => {
    const notistackRef = useRef(null);
    const theme = useTheme();

    const onClickDismiss = (key) => () => {
        notistackRef.current.closeSnackbar(key);
    };

    return (
        <SnackbarProviderBase
            ref={notistackRef}
            autoHideDuration={3000}
            action={(key) => (
                <IconButton
                    aria-label="Dismiss notification"
                    onClick={onClickDismiss(key)}
                    size="small"
                >
                    <CrossIcon color={theme.palette.backgroundWhite} />
                </IconButton>
            )}
            {...otherProps}
        />
    );
};

SnackbarProvider.propTypes = {};

SnackbarProvider.defaultProps = {};

export default SnackbarProvider;
