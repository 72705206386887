import validator from './validate';

const ratingTableProcessor = {
    name: 'Rating Table',
    slug: 'rating-table',
    description: 'Return a number depending on categorized input values',
    validate: validator,
    inputs: {
        id: 'inputs',
        dynamic: true,
        min: 1,
        schema: {
            oneOf: [
                { type: 'number' },
                { type: 'string' },
                { type: 'boolean' },
            ],
        },
    },
    outputs: [
        {
            id: 'output',
            name: 'Output',
            schema: { type: 'number' },
        },
    ],
};

export default ratingTableProcessor;
