import getOutputType from '@tint/core/src/processors/outputValidation/getOutputType';

const getCategorizationType = ({ nodeId, blueprint, validationSchema }) => {
    const inputEdge = blueprint.edges.find(
        (edge) => edge.end.node === nodeId && edge.end.pin === 'input',
    );

    const inputNode = inputEdge && blueprint.nodes[inputEdge.start.node];

    return inputNode
        ? getOutputType(
              blueprint,
              validationSchema,
              inputEdge.start.node,
              inputEdge.start.pin,
          )
        : null;
};

export default getCategorizationType;
