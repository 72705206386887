import { get } from 'lodash';
import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Breadcrumbs, Button, makeStyles } from '@material-ui/core';

import BlueprintEditor from './BlueprintEditor';
import ViewModeInput from './viewModes/ViewModeInput';

import { MODEL_TYPES, MODEL_TYPE_DECISION } from '@tint/core/src/models/types';

import {
    blueprintPropType,
    editorDataPropType,
} from './blueprintEditorPropTypes';
import useBlueprintEditorState from './state/useBlueprintEditorState';
import {
    VIEW_MODE_ADVANCED,
    VIEW_MODE_SIMPLIFIED,
} from './viewModes/viewModes';
import BlockConfigurationForm from './Drawer/BlockConfigurationForm';
import { PaginationCaret } from '../../icons';

const VIEW_MODE_INPUT_HEIGHT = 38;

const BreadcrumbLink = ({ model, onClick, isLast }) => (
    <Button
        key={model.id}
        variant="text"
        color={isLast ? 'default' : 'primary'}
        onClick={isLast ? undefined : onClick}
    >
        {model.name}
    </Button>
);

BreadcrumbLink.propTypes = {
    model: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    }).isRequired,
    onClick: PropTypes.func.isRequired,
    isLast: PropTypes.bool.isRequired,
};

const useStyles = makeStyles((theme) => ({
    breadcrumbKaren: {
        transform: 'rotate(180deg)',
        width: theme.typography.body2.fontSize,
        height: theme.typography.body2.fontSize,
    },
}));

const ComputationTrace = ({
    model: initialModel,
    trace: initialTrace,
    showRestrictedConfiguration,
    minimap,
    insuranceProduct,
}) => {
    const classes = useStyles();

    const [displayedModel, setDisplayedModel] = useState(initialModel);
    const [displayedTrace, setDisplayedTrace] = useState(initialTrace);
    const [modelsChain, setModelsChain] = useState([
        { model: initialModel, trace: initialTrace },
    ]);

    // The simplified view is really broken for non-decision models
    // Since we don't have a solution for now, let's display the full trace for numeric models
    const shouldDisableSimplifiedView =
        displayedModel.outputType !== MODEL_TYPE_DECISION;

    const editorRef = useRef();
    const [selectedBlockId, setSelectedBlockId] = useState(null);
    const [viewMode, setViewMode] = useState(
        shouldDisableSimplifiedView ? VIEW_MODE_ADVANCED : VIEW_MODE_SIMPLIFIED,
    );
    const [cameraPosition, setCameraPosition] = useState({
        x: 0,
        y: 0,
        zoom: 1,
    });

    const [shouldFitView, setShouldFitView] = useState(false);
    const onViewFitted = () => setShouldFitView(false);

    const {
        blueprint,
        editorData,
        dispatch: dispatchEvent,
        reset: resetBlueprintState,
    } = useBlueprintEditorState(
        displayedModel.blueprint,
        displayedModel.editorData,
    );

    useEffect(() => {
        setShouldFitView(true);
    }, [displayedModel]);

    const onViewModeChange = (value) => {
        setViewMode(value);
    };

    const selectedNode = get(blueprint, `nodes.${selectedBlockId}`);
    const onClose = () => setSelectedBlockId(null);

    const reset = ({ model, trace }) => {
        setDisplayedModel(model);
        setSelectedBlockId(null);
        setViewMode(
            model.outputType !== MODEL_TYPE_DECISION
                ? VIEW_MODE_ADVANCED
                : VIEW_MODE_SIMPLIFIED,
        );
        setCameraPosition({ x: 0, y: 0, zoom: 1 });
        resetBlueprintState(model.blueprint, model.editorData);
        setDisplayedTrace(trace);
    };

    useEffect(() => {
        setDisplayedModel(initialModel);
        setDisplayedTrace(initialTrace);
    }, [initialModel, initialTrace]);

    const onReferenceModelViewDetail = (model, trace) => {
        reset({ model, trace });
        setModelsChain([...modelsChain, { model, trace }]);
    };

    const onBreadcrumbClick = (i) => () => {
        const { model, trace } = modelsChain[i];
        reset({ model, trace });
        setModelsChain(modelsChain.slice(0, i + 1));
    };

    return (
        <>
            {!shouldDisableSimplifiedView && (
                <ViewModeInput
                    viewMode={viewMode}
                    onChange={onViewModeChange}
                />
            )}
            {modelsChain.length > 1 && (
                <Breadcrumbs
                    separator={
                        <PaginationCaret className={classes.breadcrumbKaren} />
                    }
                >
                    {modelsChain.map(({ model }, i) => (
                        <BreadcrumbLink
                            key={model.id}
                            model={model}
                            isLast={i === modelsChain.length - 1}
                            onClick={onBreadcrumbClick(i)}
                        />
                    ))}
                </Breadcrumbs>
            )}
            <BlueprintEditor
                ref={editorRef}
                background={false}
                blueprint={blueprint}
                editorData={editorData}
                dispatchEvent={dispatchEvent}
                viewMode={viewMode}
                disabled
                trace={displayedTrace}
                minimap={minimap}
                height={`calc(100% - ${
                    shouldDisableSimplifiedView ? 0 : VIEW_MODE_INPUT_HEIGHT
                }px)`}
                width={800}
                cameraPosition={cameraPosition}
                onCameraMove={setCameraPosition}
                shouldFitView={shouldFitView}
                onViewFitted={onViewFitted}
                onSelect={setSelectedBlockId}
                modelOutputType={displayedModel.outputType}
                onViewDetails={onReferenceModelViewDetail}
            />
            <BlockConfigurationForm
                editorContainer={editorRef.current}
                blueprint={blueprint}
                editorData={editorData}
                insuranceProduct={insuranceProduct}
                onClose={onClose}
                disabled
                nodeId={selectedBlockId}
                node={selectedNode}
                isOutputNode={
                    !!selectedNode &&
                    get(editorData, 'outputNodes', []).includes(selectedBlockId)
                }
                modelId={displayedModel.id}
                modelOutputType={displayedModel.outputType}
                showRestrictedConfiguration={showRestrictedConfiguration}
            />
        </>
    );
};

ComputationTrace.propTypes = {
    model: PropTypes.shape({
        blueprint: PropTypes.shape(blueprintPropType).isRequired,
        editorData: PropTypes.shape(editorDataPropType).isRequired,
        outputType: PropTypes.oneOf(MODEL_TYPES).isRequired,
    }).isRequired,
    trace: PropTypes.object.isRequired,
    insuranceProduct: PropTypes.shape({ id: PropTypes.string.isRequired })
        .isRequired,
    minimap: PropTypes.bool,
    showRestrictedConfiguration: PropTypes.bool,
};

ComputationTrace.defaultProps = {
    minimap: true,
    showRestrictedConfiguration: false,
};

export default ComputationTrace;
