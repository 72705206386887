import PropTypes from 'prop-types';

import { Grid } from '@material-ui/core';
import { Field, useFormState } from 'react-final-form';

import { get } from 'lodash';
import { TextInput } from '../../../../forms';

const ConcatenateConfiguration = ({ disabled }) => {
    const { values } = useFormState();
    const delimiter = get(values, 'node.configuration.delimiter', '');

    return (
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <Field
                    name="node.configuration.delimiter"
                    label="Delimiter"
                    component={TextInput}
                    fullWidth
                    disabled={disabled}
                    initialValue=""
                    autoFocus
                    helperText={`Output example: ${['First', 'Second'].join(
                        delimiter,
                    )}`}
                />
            </Grid>
        </Grid>
    );
};

ConcatenateConfiguration.propTypes = {
    disabled: PropTypes.bool,
};

ConcatenateConfiguration.defaultProps = {
    disabled: false,
};

export default ConcatenateConfiguration;
