import slugify from '@tint/core/src/slugify';

export const findAvailableNodeId = (blueprint, node, i = 1) => {
    const slug = slugify(node.name || 'New Block');
    const id = i === 1 ? slug : `${slug}-${i}`;

    if (!blueprint.nodes[id]) {
        return id;
    }

    return findAvailableNodeId(blueprint, node, i + 1);
};
