import validator from './validate';

export type EquifaxConsumerCreditReportNode = {
    processor: 'equifax-consumer-credit-report';
    configuration?: {
        timeout?: number;
        fallbackValue?: string;
    };
};

export const DEFAULT_TIMEOUT = 3000;

const equifaxConsumerCreditReportProcessor = {
    name: 'Equifax Consumer Credit Report',
    slug: 'equifax-consumer-credit-report',
    color: '#981e32',
    description: 'Determine credit worthiness of customers using Equifax API',
    validate: validator,
    inputs: [
        {
            id: 'firstName',
            name: 'First Name',
            schema: { type: 'string' },
            group: 'inputs',
        },
        {
            id: 'lastName',
            name: 'Last Name',
            schema: { type: 'string' },
            group: 'inputs',
        },
        {
            id: 'birthDate',
            name: 'Birth Date',
            schema: { type: 'string', format: 'date' },
            group: 'inputs',
        },
        {
            id: 'address',
            name: 'Address',
            schema: { type: 'string' },
            group: 'inputs',
        },
        {
            id: 'city',
            name: 'City',
            schema: { type: 'string' },
            group: 'inputs',
        },
        {
            id: 'stateCode',
            name: 'State Code',
            schema: { type: 'string' },
            group: 'inputs',
        },
        {
            id: 'zipCode',
            name: 'Zip Code',
            schema: { type: 'string' },
            group: 'inputs',
        },
        {
            id: 'phoneNumber',
            name: 'Phone Number',
            schema: { type: 'string' },
            group: 'inputs',
        },
    ],
    outputs: [
        {
            id: 'score',
            name: 'Score',
            schema: { type: 'number' },
        },
    ],
};

export default equifaxConsumerCreditReportProcessor;
