// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// Ignore because v4
import type {
    ForwardedRef,
    FunctionComponent,
    ReactChild,
    ReactElement,
} from 'react';
import { cloneElement, forwardRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { animated, useSpring } from 'react-spring';

import MuiButton from '@material-ui/core/Button/Button';
import useTheme from '@material-ui/core/styles/useTheme';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import type { Theme } from '@material-ui/core';

const getIconColor = (
    theme: Theme,
    variant: 'outlined' | 'contained' | 'text',
    color:
        | 'primary'
        | 'secondary'
        | 'default'
        | 'danger'
        | 'sandbox'
        | 'selected',
) => {
    if (color === 'default') {
        return undefined;
    }

    if (variant === 'contained') {
        return theme.palette.backgroundWhite;
    }

    return theme.palette[color].main;
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        background: ({ color }: { color: string; size: string }) => {
            switch (color) {
                case 'sandbox':
                    return theme.palette.warning.main;
                case 'selected':
                    return theme.palette.grays[700];
                default:
                    return null;
            }
        },
        color: ({ color }: { color: string; size: string }) => {
            switch (color) {
                case 'sandbox':
                    return theme.palette.common.white;
                case 'selected':
                    return theme.palette.grays[200];
                case 'white':
                    return theme.palette.primary.main;
                default:
                    return null;
            }
        },
        fontSize: ({ size }: { color: string; size: string }) => {
            switch (size) {
                case 'small':
                    return '0.75rem';
                case 'large':
                    return '1rem';
                default:
                    return '0.875rem';
            }
        },
        padding: ({ size }: { color: string; size: string }) => {
            switch (size) {
                case 'small':
                    return theme.spacing(1, 1.5);
                case 'large':
                    return theme.spacing(2, 3);
                default:
                    return theme.spacing(1.5, 2);
            }
        },
        '&:hover': {
            background: ({ color }: { color: string; size: string }) => {
                switch (color) {
                    case 'sandbox':
                        return theme.palette.warning.dark;
                    case 'selected':
                        return theme.palette.grays[700];
                    case 'white':
                        return theme.palette.grayHover;
                    default:
                        return null;
                }
            },
        },
    },
}));

interface Props {
    className?: string;
    children: ReactChild;
    color:
        | 'primary'
        | 'secondary'
        | 'default'
        | 'danger'
        | 'sandbox'
        | 'selected';
    startIcon?: ReactElement;
    endIcon?: ReactElement;
    variant?: 'outlined' | 'contained' | 'text';
    loading?: boolean;
    style?: Record<string, unknown>;
    size?: string;
    fullWidth?: boolean;
    type?: 'button' | 'reset' | 'submit';
    onClick?: () => void;
    disabled?: boolean;
}

const Button: FunctionComponent<Props> = forwardRef(function Button(
    {
        className,
        children,
        color,
        startIcon,
        variant,
        loading,
        style,
        size,
        endIcon,
        ...otherProps
    },
    ref: ForwardedRef<any>,
) {
    const buttonLoadingSpring = useSpring({
        from: { opacity: loading ? 1 : 0.3 },
        to: { opacity: loading ? 0.3 : 1 },
    });

    const loaderLoadingSpring = useSpring({
        from: {
            display: 'inline-block',
            position: 'relative',
            top: 1,
            marginLeft: 0,
            width: loading ? 0 : 24,
            opacity: loading ? 1 : 0,
        },
        to: {
            opacity: loading ? 1 : 0,
            width: loading ? 24 : 0,
            marginLeft: loading ? 4 : 0,
        },
    });

    const theme = useTheme();
    const classes = useStyles({ color, size });

    return (
        <MuiButton
            className={classnames(classes.root, className)}
            component="button"
            color={
                ['danger', 'sandbox', 'selected'].includes(color)
                    ? 'default'
                    : (color as 'primary' | 'secondary' | 'default')
            }
            variant={variant}
            startIcon={
                !startIcon
                    ? null
                    : cloneElement(startIcon, {
                          ...startIcon.props,
                          color: getIconColor(theme, variant, color),
                      })
            }
            endIcon={endIcon}
            style={{
                ...style,
                ...buttonLoadingSpring,
            }}
            {...otherProps}
            ref={ref}
        >
            {children}
            {typeof loading !== 'undefined' && (
                <animated.span style={loaderLoadingSpring}>
                    <CircularProgress color="inherit" size={12} />
                </animated.span>
            )}
        </MuiButton>
    );
});

Button.propTypes = {
    className: PropTypes.string,
    children: PropTypes.element,
    color: PropTypes.oneOf([
        'primary',
        'secondary',
        'default',
        'danger',
        'sandbox',
        'selected',
    ]),
    startIcon: PropTypes.element,
    endIcon: PropTypes.element,
    variant: PropTypes.oneOf(['outlined', 'contained', 'text']),
    loading: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'normal', 'large']),
    style: PropTypes.shape({}),
};

Button.defaultProps = {
    className: null,
    children: null,
    color: 'default',
    startIcon: null,
    variant: 'text',
    loading: undefined,
    size: 'normal',
    style: {},
};

export default Button;
