import PropTypes from 'prop-types';
import TextField from './TextField';

const ScoreField = ({ value, ...otherProps }) => {
    return <TextField value={value} {...otherProps} />;
};

ScoreField.propTypes = {
    value: PropTypes.number,
};

ScoreField.defaultProps = {
    value: null,
};

export default ScoreField;
