import classnames from 'classnames';
import { useCallback } from 'react';
import PropTypes from 'prop-types';

import {
    FormControl,
    FormLabel,
    RadioGroup as RadioGroupBase,
    FormControlLabel,
    Radio,
    makeStyles,
} from '@material-ui/core';
import { P } from '../../typography';

const useStyles = makeStyles((theme) => ({
    label: {
        marginBottom: theme.spacing(2),
    },
    error: {
        color: `${theme.palette.mainRed} !important`,
    },
}));

const RadioGroup = ({
    label,
    options,
    input,
    fullWidth,
    meta,
    ...otherProps
}) => {
    const classes = useStyles();

    const { onChange } = input;
    const { error, touched } = meta;

    const handleOnChange = useCallback(
        (e) => onChange(e.target.value),
        [onChange],
    );

    return (
        <FormControl>
            {label && (
                <FormLabel
                    className={classnames(classes.label, {
                        [classes.error]: touched && error,
                    })}
                >
                    {label}
                </FormLabel>
            )}

            <RadioGroupBase onChange={handleOnChange} {...otherProps}>
                {options.map((option) => (
                    <FormControlLabel
                        key={option.value}
                        value={option.value}
                        control={<Radio color="primary" />}
                        label={option.label}
                    />
                ))}
            </RadioGroupBase>
            {touched && error && <P className={classes.error}>{error}</P>}
        </FormControl>
    );
};

RadioGroup.propTypes = {
    label: PropTypes.string,
    fullWidth: PropTypes.bool,
    input: PropTypes.shape({
        value: PropTypes.any,
        onChange: PropTypes.func.isRequired,
    }).isRequired,
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.string,
    }).isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.any.isRequired,
        }),
    ).isRequired,
};

RadioGroup.defaultProps = {
    fullWidth: false,
};

export default RadioGroup;
