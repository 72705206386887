import getList from './getList.graphql';
import getOne from './getOne.graphql';
import update from './update.graphql';

const dataToInput = ({ data }) => ({
    organizationRoles: data.organizationRoles.map(({ organization, role }) => ({
        organizationId: organization.id,
        roleId: role.id,
    })),
    admin: data.admin,
    firstName: data.firstName,
    lastName: data.lastName,
    jobTitle: data.jobTitle,
    roleId: data.role?.id,
});

const userQueries = {
    dataToInput,
    getList,
    getOne,
    update,
};

export default userQueries;
