import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Field } from 'react-final-form';

import { Select, TextInput } from '../../../../forms';

const options = [
    {
        value: 'closest',
        label: 'Closest',
    },
    {
        value: 'ceil',
        label: 'Round up (ceil)',
    },
    {
        value: 'floor',
        label: 'Round down (floor)',
    },
];

const RoundConfiguration = ({ disabled }) => (
    <Grid container direction="column" spacing={1}>
        <Grid item>
            <Field
                component={Select}
                disabled={disabled}
                name="node.configuration.method"
                label="Method"
                options={options}
                initialValue="closest"
            />
        </Grid>
        <Grid item>
            <Field
                component={TextInput}
                disabled={disabled}
                name="node.configuration.digits"
                label="Digits"
                type="number"
                variant="outlined"
                parse={(v) => parseInt(v, 10)}
                min="0"
                step="1"
                initialValue={0}
            />
        </Grid>
    </Grid>
);

RoundConfiguration.propTypes = {
    disabled: PropTypes.bool,
};

RoundConfiguration.defaultProps = {
    disabled: false,
};

export default RoundConfiguration;
