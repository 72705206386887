import PropTypes from 'prop-types';

import { makeStyles, Typography } from '@material-ui/core';
import { formatHrDuration } from '@tint/core/src/formatters';

const useStyles = makeStyles((theme) => ({
    root: {
        marginLeft: theme.spacing(2),
        borderLeft: `2px solid #fff`,
        borderLeftColor: ({ rootFeature }) =>
            rootFeature
                ? theme.palette.primary.main
                : theme.palette.secondary.main,
        paddingLeft: theme.spacing(1),
        marginTop: theme.spacing(2),
    },
    name: {
        display: 'inline-block',
        marginRight: theme.spacing(1),
    },
    output: {
        display: 'inline-block',
        fontWeight: 'bold',
        marginRight: theme.spacing(1),
    },
    duration: {
        display: 'inline-block',
        color: theme.palette.darkerGray,
    },
    valueCell: {
        textAlign: 'right',
    },
}));

const ModelTrace = ({ trace, rootFeature, ...otherProps }) => {
    const classes = useStyles({ rootFeature });

    return (
        <div className={classes.root} {...otherProps}>
            <Typography variant="body1" className={classes.name}>
                {trace.blueprint}:
            </Typography>
            <Typography variant="body1" className={classes.output}>
                {trace.output}
            </Typography>
            <Typography variant="body1" className={classes.duration}>
                {formatHrDuration(trace.computation_duration)}
            </Typography>
            {trace.features &&
                trace.features.map((feature) => (
                    <ModelTrace
                        trace={feature}
                        key={feature.blueprint}
                        rootFeature={false}
                    />
                ))}
        </div>
    );
};

ModelTrace.propTypes = {
    trace: PropTypes.shape({
        blueprint: PropTypes.string.isRequired,
        output: PropTypes.any.isRequired,
        computation_duration: PropTypes.number.isRequired,
        features: PropTypes.array,
    }).isRequired,
    rootFeature: PropTypes.bool,
};

ModelTrace.defaultProps = {
    rootFeature: true,
};

export default ModelTrace;
