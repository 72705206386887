import { createValidator } from '../../validation/validatorFactory';

const validator = createValidator();

const validate = validator.compile({
    type: 'object',
    required: ['value'],
    properties: {
        value: {
            type: 'string',
            enum: ['maximumRefund', 'protectionFee', 'customerShare'],
        },
    },
    additionalProperties: false,
});

export default validate;
