import type { FunctionComponent } from 'react';
import type { EditProps } from 'react-admin';
import { Edit } from 'react-admin';
import { OrganizationForm } from './Form';

type Props = Omit<EditProps, 'title'>;

export const OrganizationEdit: FunctionComponent<Props> = (props) => (
    <Edit title="Organization Edition" {...props}>
        <OrganizationForm />
    </Edit>
);
