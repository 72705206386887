import PropTypes from 'prop-types';

import { Select } from '../Select';

const AVAILABLE_TYPES = {
    string: 'String',
    status: 'Status',
    customId: 'Custom ID',
    template: 'Template',
    currency: 'Currency',
    number: 'Number',
    date: 'Date',
    'date-range': 'Date Range',
    score: 'Score',
};

const typeChoices = Object.entries(AVAILABLE_TYPES);

export const TypeSelect = ({ id, label, value, onChange, ...otherProps }) => {
    return (
        <Select
            id={id}
            label={label}
            value={value}
            onChange={onChange}
            choices={typeChoices}
            {...otherProps}
        />
    );
};

TypeSelect.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};
