import PropTypes from 'prop-types';
import { List, ListItem } from '@material-ui/core';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import inputPropTypes from './inputProptypes';
import InputItem from './InputItem';
import InputList from './InputList';

const InputsConfiguration = ({
    canBeReordered,
    target,
    inputs,
    onDisconnect,
    onReorderEdges,
    disabled,
}) => {
    const disconnect = (input) => () => {
        onDisconnect({
            source: input.id,
            sourceHandle: input.pin,
            target,
        });
    };

    const onDragEnd = (result) => {
        if (
            result.destination &&
            result.destination.index !== result.source.index
        ) {
            onReorderEdges({
                source: inputs[result.source.index],
                destination: inputs[result.destination.index],
                target,
            });
        }
    };

    return canBeReordered ? (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="inputs-list">
                {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                        <InputList
                            inputs={inputs}
                            disconnect={disconnect}
                            disabled={disabled}
                        />
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    ) : (
        <List>
            {inputs.map((input) => (
                <ListItem key={`${input.id}-${input.pin}`}>
                    <InputItem
                        input={input}
                        disconnect={disconnect}
                        disabled={disabled}
                    />
                </ListItem>
            ))}
        </List>
    );
};

InputsConfiguration.propTypes = {
    canBeReordered: PropTypes.bool,
    target: PropTypes.string,
    inputs: PropTypes.arrayOf(inputPropTypes),
    onDisconnect: PropTypes.func,
    onReorderEdges: PropTypes.func,
    disabled: PropTypes.bool,
};

InputsConfiguration.defaultProps = {
    canBeReordered: true,
    target: '',
    inputs: [],
    onDisconnect: () => {},
    onReorderEdges: () => {},
    disabled: false,
};

export default InputsConfiguration;
