import type { FunctionComponent } from 'react';
import { Avatar, Grid, ThemeProvider, Typography } from '@material-ui/core';
import theme from '../v4/theme';

type Props = {
    record?: { id: string; logoUrl: string; name: string };
};

const OrganizationNameField: FunctionComponent<Props> = ({ record }) => {
    return (
        <ThemeProvider theme={theme}>
            <Grid container spacing={2} alignItems="center">
                <Grid item>
                    <Avatar src={`${record.logoUrl}`}>?</Avatar>
                </Grid>
                <Grid item>
                    <Typography variant="body1">{record.name}</Typography>
                    <Typography variant="body2">{record.id}</Typography>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
};

export default OrganizationNameField;
