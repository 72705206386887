// Need help for typing the validation/processors directory
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { get, range } from 'lodash';
import processors from '../processors';
import {
    getInputTypes,
    getNodeInputs,
    getNodeOutputs,
    hasDynamicInputs,
    hasDynamicOutputs,
    getOutputNodeIds,
    isConditionEdge,
    getMinimumRequiredInputs,
    isGroupedInput,
} from '../processors/io';

import OUTPUT_TYPES from '../processors/outputTypes';
import getOutputType from '../processors/outputValidation/getOutputType';

import ratingTableValidator from '../processors/ratingTable/ratingTableValidator';
import dataRobotValidator from '../processors/dataRobot/dataRobotValidator';
import { VALIDATION_CODES } from './validationCodes';
import validateEkataIdentityCheck from '../processors/ekataIdentityCheck/validateEkataIdentityCheck';
import { createValidator } from './validatorFactory';

const validatorByProcessor = {
    'data-robot': dataRobotValidator,
    'ekata-identity-check': validateEkataIdentityCheck,
    'rating-table': ratingTableValidator,
} as const;

const ajv = createValidator();

export const validateNodeConfiguration = (blueprint, id, node) => {
    if (!node.name) {
        return [
            {
                id,
                code: VALIDATION_CODES.NODE_CONFIGURATION_ERROR,
                message: 'This block does not have a valid name',
            },
        ];
    }

    if (!node.processor) {
        return [
            {
                id,
                code: VALIDATION_CODES.NODE_CONFIGURATION_ERROR,
                message: 'This block does not have a valid processor',
            },
        ];
    }

    const processor = processors[node.processor];

    if (!processor) {
        return `"${
            node.processor
        }" is an invalid processor. Valid processors are: ${Object.keys(
            processors,
        ).join(', ')}`;
    }

    if (!processor.validate(node.configuration || null)) {
        return [
            {
                id,
                code: VALIDATION_CODES.NODE_JSON_SCHEMA_ERROR,
                message: ajv.errorsText(processor.validate.errors, {
                    dataVar: 'configuration',
                    separator: '\n',
                }),
            },
        ];
    }

    const processorValidator = validatorByProcessor[node.processor];

    if (processorValidator) {
        return processorValidator(blueprint, id);
    }

    return [];
};

const validateConditionEdge =
    (blueprint) =>
    ({ start, end }) => {
        const source = blueprint.nodes[start.node];

        if (source.processor !== 'switch') {
            return [
                {
                    id: `${start.node}-${start.pin}-${end.node}-${end.pin}`,
                    code: VALIDATION_CODES.EDGE_ERROR,
                    source: start.node,
                    target: end.node,
                    message: `This connection is invalid as a Switch block can only be triggered by another Switch output`,
                },
            ];
        }

        return [];
    };

const validateGraphOutputSource =
    (blueprint) =>
    ({ start, end }) => {
        const source = blueprint.nodes[start.node];
        const target = blueprint.nodes[end.node];

        if (target.processor === 'constant' && source.processor !== 'switch') {
            return [
                {
                    id: `${start.node}-${start.pin}-${end.node}-${end.pin}`,
                    code: VALIDATION_CODES.EDGE_ERROR,
                    source: start.node,
                    target: end.node,
                    message: `This connection is invalid as decision nodes can only be triggered by a Switch output`,
                },
            ];
        }

        if (
            target.processor === 'passthrough' &&
            source.processor === 'switch'
        ) {
            return [
                {
                    id: `${start.node}-${start.pin}-${end.node}-${end.pin}`,
                    code: VALIDATION_CODES.EDGE_ERROR,
                    source: start.node,
                    target: end.node,
                    message: `This connection is invalid as exit nodes cannot be triggered by a Switch output`,
                },
            ];
        }

        return [];
    };

const validateSwitchIsConnectedToDecisionOrCondition =
    (blueprint, outputNodeIds) => (edge) => {
        const errors: Record<string, string>[] = [];

        const { start, end } = edge;
        const target = blueprint.nodes[end.node];

        const isConnectedOutput = outputNodeIds.includes(end.node);
        const isConnectedToCondition =
            target.processor === 'switch' && isConditionEdge(edge);

        if (!isConnectedOutput && !isConnectedToCondition) {
            errors.push({
                id: `${start.node}-${start.pin}-${end.node}-${end.pin}`,
                code: VALIDATION_CODES.EDGE_ERROR,
                source: start.node,
                target: end.node,
                message: `A Switch can only be connected to an output node or to another Switch condition`,
            });
        }

        return errors;
    };

export const validateEdge = (blueprint, validationSchema) => (edge) => {
    const { nodes } = blueprint;
    const { start, end } = edge;
    const id = `${start.node}-${start.pin}-${end.node}-${end.pin}`;

    const errors: Record<string, string>[] = [];

    const source = nodes[start.node];
    const target = nodes[end.node];

    if (!source) {
        errors.push({
            id,
            code: VALIDATION_CODES.EDGE_ERROR,
            message: `The source block with id "${start.node}" do not exists`,
        });
    }

    if (!target) {
        errors.push({
            id,
            code: VALIDATION_CODES.EDGE_ERROR,
            message: `The target block with id "${end.node} do not exists`,
        });
    }

    if (errors.length > 0) {
        return errors;
    }

    if (isConditionEdge(edge)) {
        return validateConditionEdge(blueprint)(edge);
    }

    const outputNodeIds = getOutputNodeIds(blueprint);

    if (source.processor === 'switch') {
        const switchOutputErrors =
            validateSwitchIsConnectedToDecisionOrCondition(
                blueprint,
                outputNodeIds,
            )(edge);

        if (switchOutputErrors.length > 0) {
            return switchOutputErrors;
        }
    }

    const isSourceValid =
        validateNodeConfiguration(blueprint, start.node, source).length === 0;
    const isTargetValid =
        validateNodeConfiguration(blueprint, end.node, target).length === 0;

    if (!isSourceValid || !isTargetValid) {
        // Ignoring invalid nodes here since the a previous validation must already have returned an error
        return [];
    }

    if (isGroupedInput(target, end.pin)) {
        // Ignore input groups here, it will be validated later
        return [];
    }

    const isTargetOutput = outputNodeIds.includes(edge.end.node);

    const sourceOutputs = getNodeOutputs(source, false);
    const sourceOutput = hasDynamicOutputs(source)
        ? sourceOutputs[0]
        : sourceOutputs.find((output) => output.id === start.pin);

    const targetInputs = getNodeInputs(target, false);
    const targetInput = hasDynamicInputs(target)
        ? targetInputs[0]
        : targetInputs.find((input) => input.id === end.pin);

    if (!sourceOutput) {
        errors.push({
            id,
            code: VALIDATION_CODES.EDGE_ERROR,
            source: start.node,
            target: end.node,
            message: `Unable to find the pin "${start.pin}" of the block "${source.name}"`,
        });
    }

    if (!isTargetOutput && !targetInput) {
        errors.push({
            id,
            code: VALIDATION_CODES.EDGE_ERROR,
            source: start.node,
            target: end.node,
            message: `Unable to find the pin "${end.pin}" of the block "${target.name}"`,
        });
    }

    if (errors.length > 0) {
        return errors;
    }

    if (isTargetOutput) {
        return validateGraphOutputSource(blueprint)(edge);
    }

    const sourceOutputType = getOutputType(
        blueprint,
        validationSchema,
        start.node,
        start.pin,
    );

    const targetInputTypes = getInputTypes(blueprint, end.node, end.pin);

    if (
        sourceOutputType === OUTPUT_TYPES.ANY ||
        targetInputTypes.includes(OUTPUT_TYPES.ANY)
    ) {
        return [];
    }

    if (!targetInputTypes.includes(sourceOutputType)) {
        const allowedInputTypes = targetInputTypes.map((t) => t.toLowerCase());

        return [
            {
                id,
                code: VALIDATION_CODES.EDGE_ERROR,
                source: start.node,
                target: end.node,
                message: `This connection is invalid as "${
                    target.name
                }" only accepts ${allowedInputTypes.join(', ')} inputs`,
            },
        ];
    }

    return [];
};

const getRequiredInput = (blueprint, nodeId, outputNodeIds) => {
    if (outputNodeIds.includes(nodeId)) {
        return [{ id: 'input', name: 'Input' }];
    }

    const node = blueprint.nodes[nodeId];

    if (hasDynamicInputs(node)) {
        const length = getMinimumRequiredInputs(node);

        return range(0, length).map((i) => ({
            id: i.toString(),
        }));
    }

    return getNodeInputs(node, false).filter((inp) => inp.required !== false);
};

const validateAllRequiredInputsAreConnected = (blueprint) => {
    const errors: Record<string, string>[] = [];
    const outputNodeIds = getOutputNodeIds(blueprint);

    for (const [nodeId, node] of Object.entries<Record<'name', string>>(
        get(blueprint, 'nodes', {}),
    )) {
        if (validateNodeConfiguration(blueprint, nodeId, node).length > 0) {
            continue;
        }

        const requiredInputs = getRequiredInput(
            blueprint,
            nodeId,
            outputNodeIds,
        );

        const notConnectedInputs = requiredInputs.filter(({ id: inputId }) => {
            const inputEdge = blueprint.edges.find(
                (edge) => edge.end.node === nodeId && edge.end.pin === inputId,
            );

            return !inputEdge;
        });

        if (notConnectedInputs.length > 0) {
            const message = hasDynamicInputs(node)
                ? `The block "${
                      node.name
                  }" needs at least ${getMinimumRequiredInputs(node)} inputs`
                : `These inputs of "${
                      node.name
                  }" needs to be connected: ${notConnectedInputs
                      .map((inp) => inp.name)
                      .join(', ')}`;

            errors.push({
                id: nodeId,
                code: VALIDATION_CODES.GLOBAL_ERROR,
                message,
            });
        }
    }

    return errors;
};

const validateAllRequiredOutputsAreConnected = (blueprint) => {
    const errors: Record<string, string>[] = [];
    const outputNodeIds = getOutputNodeIds(blueprint);

    for (const [nodeId, node] of Object.entries<Record<'name', string>>(
        get(blueprint, 'nodes', {}),
    )) {
        if (validateNodeConfiguration(blueprint, nodeId, node).length > 0) {
            continue;
        }

        if (outputNodeIds.includes(nodeId)) {
            continue;
        }

        const requiredOutputs = getNodeOutputs(node, false).filter(
            (output) => output.required !== false,
        );

        const notConnectedOutputs = requiredOutputs.filter((output) => {
            const outputEdge = blueprint.edges.find(
                (edge) =>
                    edge.start.node === nodeId && edge.start.pin === output.id,
            );

            return !outputEdge;
        });

        if (notConnectedOutputs.length > 0) {
            const message = `These outputs of "${
                node.name
            }" needs to be connected: ${notConnectedOutputs
                .map((output) => output.name)
                .join(', ')}`;

            errors.push({
                id: nodeId,
                code: VALIDATION_CODES.GLOBAL_ERROR,
                message,
            });
        }
    }

    return errors;
};

const validateOutputNodes = (blueprint) => {
    const outputNodes = getOutputNodeIds(blueprint);

    if (outputNodes.length === 0) {
        return [
            {
                id: '__GLOBAL__',
                code: VALIDATION_CODES.GLOBAL_ERROR,
                message: 'At least one decision is required',
            },
        ];
    }

    return [];
};

const validateModelBlueprint = (blueprint, validationSchema) => {
    if (!Object.keys(blueprint.nodes || {}).length) {
        return [
            {
                id: '__GLOBAL__',
                code: VALIDATION_CODES.GLOBAL_ERROR,
                message: 'A model needs at least one block',
            },
        ];
    }

    return [
        ...Object.entries(get(blueprint, 'nodes', {}))
            .map(([id, node]) => validateNodeConfiguration(blueprint, id, node))
            .flat(),
        ...(blueprint.edges || [])
            .map(validateEdge(blueprint, validationSchema))
            .flat(),
        ...validateAllRequiredInputsAreConnected(blueprint),
        ...validateAllRequiredOutputsAreConnected(blueprint),
        ...validateOutputNodes(blueprint),
    ];
};

export default validateModelBlueprint;
