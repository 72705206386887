import PropTypes from 'prop-types';

import {
    Grid,
    Typography,
    Switch as SwitchBase,
    makeStyles,
} from '@material-ui/core';
import { uniqueId } from 'lodash';

const useStyles = makeStyles(() => ({
    label: {
        cursor: ({ disabled }) => (disabled ? 'default' : 'pointer'),
        userSelect: 'none',
    },
    helper: {
        cursor: ({ disabled }) => (disabled ? 'default' : 'pointer'),
        userSelect: 'none',
    },
    switchBase: ({ color }) => ({
        '&.Mui-checked': {
            '& + .MuiSwitch-track': {
                background: `${color} !important`,
            },
        },
    }),
}));

const Switch = ({
    label,
    helper,
    input: { onChange, checked },
    switchClasses,
    labelClass,
    disabled,
    title,
    color,
    ...otherProps
}) => {
    const id = uniqueId();
    const classes = useStyles({ disabled, color });

    return (
        <Grid
            container
            spacing={1}
            alignItems={helper ? 'flex-start' : 'center'}
            item
            {...otherProps}
        >
            <Grid item>
                <SwitchBase
                    id={id}
                    classes={{
                        root: switchClasses.switchRoot,
                        switchBase: `${classes.switchBase} ${switchClasses.switchBase}`,
                        track: switchClasses.switchTrack,
                    }}
                    checked={checked}
                    onChange={onChange}
                    disabled={disabled}
                    title={title}
                />
            </Grid>
            <Grid item>
                <Typography variant="body1" className={labelClass}>
                    <label className={classes.label} htmlFor={id}>
                        {label}
                    </label>
                </Typography>
                {helper && (
                    <Typography variant="body2">
                        <label className={classes.helper} htmlFor={id}>
                            {helper}
                        </label>
                    </Typography>
                )}
            </Grid>
        </Grid>
    );
};

Switch.propTypes = {
    label: PropTypes.string.isRequired,
    helper: PropTypes.element,
    input: PropTypes.shape({
        checked: PropTypes.bool,
        onChange: PropTypes.func.isRequired,
    }),
    switchClasses: PropTypes.object,
    labelClass: PropTypes.string,
    disabled: PropTypes.bool,
    title: PropTypes.string,
    color: PropTypes.string,
};

Switch.defaultProps = {
    helper: null,
    value: null,
    switchClasses: {},
    labelClass: '',
    disabled: false,
    title: undefined,
    color: 'primary',
};

export default Switch;
