import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Avatar, Grid, makeStyles, ThemeProvider } from '@material-ui/core';
import {
    RadioButtonGroupInput,
    ReferenceInput,
    SelectInput,
} from 'ra-ui-materialui';
import { theme } from '../v4';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(2),
    },
}));

const OrganizationRoleRow = ({ className, record, source, ...otherProps }) => {
    const classes = useStyles();

    return (
        <ThemeProvider theme={theme}>
            <Grid
                item
                container
                xs={12}
                spacing={4}
                alignItems="center"
                className={classnames(classes.root, className)}
                {...otherProps}
            >
                <Grid item xs={4}>
                    <ReferenceInput
                        label="Organization"
                        record={record}
                        source={`${source}.organization.id`}
                        reference="Organization"
                        fullWidth
                        sort={{ field: 'name', order: 'ASC' }}
                        perPage={500}
                    >
                        <SelectInput
                            optionText={(record) => (
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item>
                                        <Avatar
                                            src={record.logoUrl}
                                            alt=""
                                            className={classes.logo}
                                        >
                                            {record.name[0].toUpperCase()}
                                        </Avatar>
                                    </Grid>
                                    <Grid>{record.name}</Grid>
                                </Grid>
                            )}
                        />
                    </ReferenceInput>
                </Grid>
                <Grid item xs={8}>
                    <ReferenceInput
                        label="Role"
                        reference="Role"
                        perPage={500}
                        record={record}
                        source={`${source}.role.id`}
                    >
                        <RadioButtonGroupInput />
                    </ReferenceInput>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
};

OrganizationRoleRow.propTypes = {
    className: PropTypes.string,
    record: PropTypes.object.isRequired,
    source: PropTypes.string.isRequired,
};

OrganizationRoleRow.defaultProps = {
    className: '',
};

export default OrganizationRoleRow;
