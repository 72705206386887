import PropTypes from 'prop-types';

import { FormControlLabel, Switch } from '@material-ui/core';
import { VIEW_MODE_ADVANCED, VIEW_MODE_SIMPLIFIED } from './viewModes';

const ViewModeInput = ({ viewMode, onChange, disabled }) => {
    const handleChange = (evt) => {
        onChange(
            evt.target.checked ? VIEW_MODE_SIMPLIFIED : VIEW_MODE_ADVANCED,
        );
    };

    return (
        <FormControlLabel
            control={
                <Switch
                    checked={viewMode === VIEW_MODE_SIMPLIFIED}
                    onChange={handleChange}
                    name="viewMode"
                    color="primary"
                    disabled={disabled}
                />
            }
            label="Simplified View"
        />
    );
};

ViewModeInput.propTypes = {
    viewMode: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

ViewModeInput.defaultProps = {
    disabled: false,
};

export default ViewModeInput;
