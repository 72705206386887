import { createValidator } from '../../validation/validatorFactory';

const validator = createValidator();

const validate = validator.compile({
    type: 'object',
    required: ['value'],
    properties: {
        value: {
            anyOf: [
                { type: 'number' },
                { type: 'string' },
                { type: 'boolean' },
            ],
        },
    },
    additionalProperties: false,
});

export default validate;
