import PropTypes from 'prop-types';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select as MuiSelect,
} from '@material-ui/core';

export const Select = ({
    id,
    label,
    value,
    choices,
    onChange,
    ...otherProps
}) => {
    const onTypeChange = (e) => {
        const selectedType = e.target.value;
        onChange(selectedType);
    };

    return (
        <FormControl {...otherProps}>
            <InputLabel htmlFor={id}>{label}</InputLabel>
            <MuiSelect
                inputProps={{ id }}
                value={value}
                onChange={onTypeChange}
            >
                {choices.map(([value, label]) => (
                    <MenuItem key={value} value={value}>
                        {label}
                    </MenuItem>
                ))}
            </MuiSelect>
        </FormControl>
    );
};

Select.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    choices: PropTypes.arrayOf(PropTypes.array).isRequired,
    onChange: PropTypes.func.isRequired,
};
