import type { FunctionComponent } from 'react';
import {
    FormDataConsumer,
    FormTab,
    ArrayInput,
    SimpleFormIterator,
    TextField,
} from 'react-admin';

import DatasourceConfigurationRow from './DatasourceConfigurationRow';
import TabbedForm from '../controllers/TabbedForm';
import { CustomToolbar } from '../forms/CustomToolbar';
import PictureInput from '../inputs/PictureInput';
import ExternalDataProviderCredentialsField from './ExternalDataProviderCredentialsField';
import EmailProviderConfig from './EmailProviderConfig';

type Props = {
    record?: { id: string };
};

export const OrganizationForm: FunctionComponent<Props> = (props) => {
    return (
        <TabbedForm
            toolbar={
                <CustomToolbar
                    deleteConfirmTitle={(record) => `${record.name} removal`}
                />
            }
            {...props}
        >
            <FormTab label="Organization">
                <TextField source="name" />
            </FormTab>
            <FormTab label="External Datasources">
                <ArrayInput source="datasourceConfigurations">
                    <SimpleFormIterator>
                        <DatasourceConfigurationRow />
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>
            <FormTab label="Credentials">
                <FormDataConsumer>
                    {({ formData }) => (
                        <ExternalDataProviderCredentialsField
                            organization={formData}
                        />
                    )}
                </FormDataConsumer>
            </FormTab>
            <FormTab label="Email Provider">
                <PictureInput
                    source="mailLogo"
                    urlName="mailLogoUrl"
                    label="Customer logo used in the emails"
                />
                <EmailProviderConfig />
            </FormTab>
        </TabbedForm>
    );
};
