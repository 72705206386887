import { useRef } from 'react';
import PropTypes from 'prop-types';

import {
    makeStyles,
    MenuItem,
    ListItemIcon,
    ListItemText,
    Grid,
} from '@material-ui/core';
import { DeleteForever } from '@material-ui/icons';

import PopoverMenu from '../../../PopoverMenu';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        fontSize: '0.875rem',
        padding: theme.spacing(2),
        paddingRight: ({ hasMenu }) => theme.spacing(hasMenu ? 0.5 : 2),
        paddingBottom: theme.spacing(1.5),
    },
    icon: {
        marginTop: 2,
        marginRight: theme.spacing(1.5),
    },
    button: {
        marginTop: -6,
        '& svg': {
            fill: ({ color }) => color,
        },
    },
    menu: {
        marginLeft: 'auto',
    },
}));

const stopPropagation = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
};

const BlockHeader = ({
    id,
    title,
    icon,
    menuIcon,
    menuButtonRef,
    onRemove,
    color,
    pins,
    hasError,
}) => {
    const hasMenu = !!onRemove;
    const classes = useStyles({ color, hasMenu, hasError });
    const headerRef = useRef();

    return (
        <Grid
            className={classes.root}
            container
            ref={headerRef}
            alignItems="center"
        >
            {pins}
            <Grid item className={classes.icon}>
                {icon}
            </Grid>
            <Grid item>{title}</Grid>
            <Grid item className={classes.menu}>
                {hasMenu && (
                    <PopoverMenu
                        buttonIcon={menuIcon}
                        buttonClassName={classes.button}
                        // the menu will appear on the top of the header (no need of z-index magic)
                        container={headerRef.current}
                        data-testid={`${id}-menu`}
                        buttonAnchorRef={menuButtonRef}
                    >
                        <MenuItem
                            onClick={onRemove}
                            onMouseUp={stopPropagation} // Prevent from opening the drawer
                        >
                            <ListItemIcon>
                                <DeleteForever fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Delete" />
                        </MenuItem>
                    </PopoverMenu>
                )}
            </Grid>
        </Grid>
    );
};

BlockHeader.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.node.isRequired,
    menuButtonRef: PropTypes.any.isRequired,
    color: PropTypes.string.isRequired,
    icon: PropTypes.node,
    menuIcon: PropTypes.node,
    onRemove: PropTypes.func,
    pins: PropTypes.any,
    hasError: PropTypes.bool,
};

BlockHeader.defaultProps = {
    icon: null,
    menuIcon: null,
    onRemove: null,
    pins: null,
    hasError: false,
};

export default BlockHeader;
