import { createValidator } from '../../validation/validatorFactory';

const validator = createValidator();

const validate = validator.compile({
    type: ['object', 'null'],
    properties: {
        default: { type: 'number' },
    },
});

export default validate;
