import classnames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
    root: {
        marginTop: theme.spacing(1.5),
        fontWeight: '600',
    },
});

export const SectionTitle = ({
    children,
    classes,
    className,
    ...otherProps
}) => (
    <Typography
        variant="h1"
        className={classnames(classes.root, className)}
        {...otherProps}
    >
        {children}
    </Typography>
);

SectionTitle.propTypes = {
    children: PropTypes.node.isRequired,
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
};

SectionTitle.defaultProps = {
    className: '',
};

export default withStyles(styles)(SectionTitle);
