import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { Field } from 'react-final-form';
import { TextInput } from '../../../../forms';

const DivisionConfiguration = ({ disabled }) => (
    <Grid container direction="column" spacing={1}>
        <Grid item xs={12}>
            <Field
                component={TextInput}
                disabled={disabled}
                name="node.configuration.default"
                parse={(v) => v && parseFloat(v)}
                label="Default value"
                required
                autoFocus
                type="number"
                variant="outlined"
            />
        </Grid>
        <Grid item xs={12}>
            <Typography variant="body2">
                Percentage (between 0 and 100) returned when one of the two
                inputs is not provided.
            </Typography>
        </Grid>
    </Grid>
);

DivisionConfiguration.propTypes = {
    disabled: PropTypes.bool,
};

DivisionConfiguration.defaultProps = {
    disabled: false,
};

export default DivisionConfiguration;
