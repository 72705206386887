import { createValidator } from '../../validation/validatorFactory';

const validator = createValidator();

const validate = validator.compile({
    type: ['object', 'null'],
    required: [],
    properties: {
        timeout: {
            type: 'number',
            minimum: 0,
            maximum: 6000,
        },
    },
    additionalProperties: false,
});

export default validate;
