import validator from './validate';

const divisionProcessor = {
    name: 'Division',
    slug: 'division',
    description: 'Return the quotient of the given inputs',
    validate: validator,
    inputs: [
        {
            id: 'numerator',
            name: 'Numerator',
            schema: { type: 'number' },
        },
        {
            id: 'divisor',
            name: 'Divisor',
            schema: { type: 'number' },
        },
    ],
    outputs: [
        {
            id: 'output',
            name: 'Output',
            schema: { type: 'number' },
        },
    ],
};

export default divisionProcessor;
