import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Chip, makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { TextInput } from '..';
import useDebounce from '../hooks/useDebounce';

const useStyles = makeStyles(() => ({
    popper: {
        display: 'none',
    },
}));

const ChipsInput = ({ label, disabled, inputProps, input, ...otherProps }) => {
    const classes = useStyles();

    const [value, setValue] = useState(input.value);

    const handleKeyDown = (event) => {
        switch (event.key) {
            case ',': {
                event.preventDefault();
                event.stopPropagation();

                if (event.target.value.length > 0) {
                    setValue(
                        Array.from(
                            new Set([...value, event.target.value.trim()]),
                        ),
                    );
                }
                break;
            }
            default:
        }
    };

    const debouncedValue = useDebounce(value, 300);
    useEffect(() => {
        input.onChange(debouncedValue);
    }, [debouncedValue, input]);

    return (
        <Autocomplete
            classes={classes}
            data-testid="chips-input"
            multiple
            freeSolo
            options={[]}
            getOptionLabel={(option) => option.title || option}
            value={value}
            disabled={disabled}
            onChange={(e, newValue) => setValue(newValue)}
            filterSelectedOptions
            renderTags={(tags, getTagProps) =>
                tags.map((option, index) => (
                    <Chip
                        key={option}
                        size="small"
                        variant="outlined"
                        label={option}
                        color="primary"
                        {...getTagProps({ index })}
                    />
                ))
            }
            renderInput={(params) => (
                <TextInput
                    {...params}
                    {...inputProps}
                    onKeyDown={handleKeyDown}
                    variant="outlined"
                    label={label}
                    input={{ value }}
                    fullWidth
                    shrink
                />
            )}
            {...otherProps}
        />
    );
};

ChipsInput.propTypes = {
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    inputProps: PropTypes.object,
    input: PropTypes.shape({
        value: PropTypes.arrayOf(PropTypes.string),
        onChange: PropTypes.func.isRequired,
    }).isRequired,
};

ChipsInput.defaultProps = {
    disabled: false,
    inputProps: {},
};

export default ChipsInput;
