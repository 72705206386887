import { createValidator } from '../../validation/validatorFactory';

const validator = createValidator();

const validate = validator.compile({
    type: 'object',
    required: ['date_part'],
    properties: {
        date_part: {
            type: 'string',
            enum: [
                'day_of_month',
                'day_of_week',
                'day_of_year',
                'iso_date',
                'month',
                'quarter',
                'week_number',
                'year',
            ],
        },
    },
    additionalProperties: false,
});

export default validate;
