import classnames from 'classnames';
import PropTypes from 'prop-types';

import { makeStyles, Button, useTheme } from '@material-ui/core';

import { DownIcon } from '../icons';

const useIconStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        fontSize: '7px !important',
        flexDirection: 'column',
        height: 14,
        width: 14,
    },
    icon: {
        flex: '1 0 0',
    },
    upArrow: {
        transform: 'rotate(180deg)',
    },
}));

const SortIcon = ({ active, direction }) => {
    const classes = useIconStyles({ active, direction });
    const theme = useTheme();

    return (
        <span className={classes.root}>
            <DownIcon
                color={
                    active && direction === 'desc'
                        ? theme.palette.primary.main
                        : theme.palette.darkerGray
                }
                className={classnames(classes.icon, classes.upArrow)}
            />
            <DownIcon
                color={
                    active && direction === 'asc'
                        ? theme.palette.primary.main
                        : theme.palette.darkerGray
                }
                className={classnames(classes.icon, classes.downArrow)}
            />
        </span>
    );
};

SortIcon.propTypes = {
    active: PropTypes.bool,
    direction: PropTypes.oneOf(['asc', 'desc']),
};

SortIcon.defaultProps = {
    active: false,
    direction: 'asc',
};

const useStyles = makeStyles((theme) => ({
    button: {
        textTransform: 'none',
        whiteSpace: 'nowrap',
        paddingLeft: 0,
    },
    buttonLabel: {
        ...theme.typography.h3,
        fontSize: '0.875rem',
    },
    sortIcon: {
        fontSize: '12px !important',
        transform: ({ direction }) =>
            direction === 'desc' ? 'rotate(180deg)' : 'rotate(0deg)',
    },
}));

const TableSortLabel = ({ children, active, direction, onClick }) => {
    const classes = useStyles({ direction });

    return (
        <Button
            className={classes.button}
            classes={{ label: classes.buttonLabel }}
            onClick={onClick}
            endIcon={<SortIcon active={active} direction={direction} />}
        >
            {children}
        </Button>
    );
};

TableSortLabel.propTypes = {
    active: PropTypes.bool,
    children: PropTypes.node.isRequired,
    direction: PropTypes.oneOf(['asc', 'desc']),
    onClick: PropTypes.func,
};

TableSortLabel.defaultProps = {
    active: false,
    direction: 'asc',
    onClick: () => {},
};

export default TableSortLabel;
