import PropTypes from 'prop-types';

import { JsonWithWizardInput } from '../JsonWithWizardInput';
import { SummaryDisplaySchemaWizardInput } from './SummaryDisplaySchemaWizardInput';

const SummaryDisplaySchemaInput = ({ input, label, meta }) => {
    return (
        <JsonWithWizardInput input={input} label={label} meta={meta}>
            <SummaryDisplaySchemaWizardInput input={input} />
        </JsonWithWizardInput>
    );
};

SummaryDisplaySchemaInput.propTypes = {
    input: PropTypes.shape({
        value: PropTypes.array,
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
    }).isRequired,
    meta: PropTypes.shape({
        error: PropTypes.string,
        touched: PropTypes.bool,
    }),
    label: PropTypes.string.isRequired,
};

SummaryDisplaySchemaInput.defaultProps = {};

export default SummaryDisplaySchemaInput;
