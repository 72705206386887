import { useState, useEffect } from 'react';
import omit from 'lodash/omit';
import { v4 as uuid } from 'uuid';

import { useReducerWithAction } from '../useReducerWithAction';
import { stateToSchema } from './stateToSchema';
import { schemaToState } from './schemaToState';

export const selectDisplaySchema = (state, { id }) => {
    return {
        ...state,
        selectedId: id,
    };
};

export const updateDisplaySchema = (state, values) => {
    return {
        ...state,
        byId: {
            ...state.byId,
            [state.selectedId]: {
                ...state.byId[state.selectedId],
                ...values,
            },
        },
    };
};

export const removeDisplaySchema = (state, id) => {
    return {
        ...state,
        byId: omit(state.byId, [id]),
        list: state.list.filter((value) => value !== id),
        selectedId: state.selectedId === id ? null : state.selectedId,
    };
};

export const addDisplaySchema = (state, index, id = uuid()) => {
    return {
        ...state,
        byId: {
            ...state.byId,
            [id]: { id, label: '', type: null, options: {} },
        },
        list: [...state.list.slice(0, index), id, ...state.list.slice(index)],
        selectedId: id,
    };
};

export const moveDisplaySchema = (state, { source, destination }) => {
    if (!destination) {
        return state;
    }
    const list = Array.from(state.list);
    const [removed] = list.splice(source.index, 1);
    if (!removed) {
        return state;
    }
    list.splice(destination.index, 0, removed);
    return {
        ...state,
        list,
    };
};

const handlers = {
    selectDisplaySchema,
    updateDisplaySchema,
    removeDisplaySchema,
    addDisplaySchema,
    moveDisplaySchema,
};

export const useSummaryDisplaySchemaState = (value) => {
    const { state, actions } = useReducerWithAction(
        handlers,
        schemaToState(Array.isArray(value) ? value : []),
    );
    const [summaryDisplaySchema, setsummaryDisplaySchema] = useState(value);

    useEffect(() => {
        setsummaryDisplaySchema(stateToSchema(state));
    }, [state]);

    return {
        selectDisplaySchema: actions.selectDisplaySchema,
        updateDisplaySchema: actions.updateDisplaySchema,
        removeDisplaySchema: actions.removeDisplaySchema,
        addDisplaySchema: actions.addDisplaySchema,
        moveDisplaySchema: actions.moveDisplaySchema,
        selectedDisplaySchema:
            state.selectedId || state.selectedId >= 0
                ? state.byId[state.selectedId]
                : null,
        summaryDisplayList: state.list.map((id) => state.byId[id]),
        state,
        summaryDisplaySchema,
    };
};
