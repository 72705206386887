import validator from './validate';

const functionProcessor = {
    name: 'Custom',
    slug: 'function',
    color: '#003280',
    validate: validator,
    inputs(node: {
        configuration: { inputs: Record<string, unknown>[] };
    }): Record<string | 'group', unknown>[] {
        return (node.configuration.inputs || []).map((input) => ({
            ...input,
            group: 'inputs',
        }));
    },
    outputs(node: {
        configuration: { outputs: Record<string, unknown>[] };
    }): unknown[] {
        return node.configuration.outputs || [];
    },
};

export default functionProcessor;
