import { isValidElement, cloneElement, useEffect, useRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { Grid, makeStyles, Typography, useTheme } from '@material-ui/core';
import useOnScreen from '../../../hooks/useOnScreen';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2, 3),
        cursor: 'pointer',
        '&:hover': {
            background: theme.palette.cardOutlineGray,
            '& $icon': {
                filter: 'grayscale(0)',
                opacity: 1,
            },
        },
    },
    selected: {
        background: `${theme.palette.secondaryBlue}11 !important`,
        '& $icon': {
            filter: 'grayscale(0)',
            opacity: 1,
        },
    },
    iconContainer: {
        width: theme.spacing(5),
        textAlign: 'center',
        marginRight: theme.spacing(2),
    },
    icon: {
        transition: theme.transitions.create(['filter', 'opacity']),
        fontSize: 24,
        filter: 'grayscale(100%)',
        opacity: 0.5,
        color: theme.palette.secondaryBlue,
    },
    description: {
        maxWidth: 600,
    },
}));

const ProcessorItem = ({
    icon,
    iconSizes,
    name,
    description,
    selected,
    ...otherProps
}) => {
    const classes = useStyles();
    const theme = useTheme();

    const ref = useRef();
    const isVisible = useOnScreen(ref, { threshold: 1 });

    useEffect(() => {
        if (!selected || !ref.current) {
            return;
        }

        if (!isVisible) {
            ref.current.scrollIntoView({ block: 'nearest' });
        }
    }, [isVisible, selected]);

    return (
        <li
            className={classnames(classes.root, {
                [classes.selected]: selected,
            })}
            ref={ref}
            {...otherProps}
        >
            <Grid container alignItems="center">
                <Grid item className={classes.iconContainer}>
                    {icon && isValidElement(icon) ? (
                        cloneElement(icon, {
                            className: classes.icon,
                            color: theme.palette.secondaryBlue,
                            width: iconSizes?.list || 24,
                            height: iconSizes?.list || 24,
                        })
                    ) : (
                        <img
                            alt=""
                            src={icon}
                            className={classes.icon}
                            style={{
                                width: iconSizes?.list || 24,
                                height: iconSizes?.list || 24,
                            }}
                        />
                    )}
                </Grid>
                <Grid item>
                    <Typography
                        variant="body1"
                        className={classes.name}
                        data-testid="processorName"
                    >
                        {name}
                    </Typography>
                    {description && (
                        <Typography
                            variant="body2"
                            className={classes.description}
                        >
                            {description}
                        </Typography>
                    )}
                </Grid>
            </Grid>
        </li>
    );
};

ProcessorItem.propTypes = {
    icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    selected: PropTypes.bool,
    iconSizes: PropTypes.object,
};

ProcessorItem.defaultProps = {
    icon: null,
    description: null,
    selected: false,
    iconSizes: null,
};

export default ProcessorItem;
