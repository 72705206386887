import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';

const styles = () => ({
    root: {
        display: 'inline',
    },
});

export const Span = ({ classes, children, ...restProps }) => (
    <Typography
        component="span"
        variant="body1"
        className={classes.root}
        {...restProps}
    >
        {children}
    </Typography>
);

Span.propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
};

export default withStyles(styles)(Span);
