import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
    FormGroup,
    FormHelperText,
    InputLabel,
    makeStyles,
    Paper,
} from '@material-ui/core';
import { humanize, titleize, underscore } from 'inflection';
import getValidationSchemaAttributes from '@tint/core/src/insuranceProducts/getValidationSchemaAttributes';
import { isEqual } from 'lodash';
import TextInput from './TextInput';
import { Checkbox } from './primitives';

export const getSubSchema = (validationSchema, path) => {
    let subSchema = validationSchema;

    if (!path) {
        return subSchema;
    }

    const parts = path.split('.');
    for (const part of parts) {
        subSchema = subSchema.properties[part];
    }

    return subSchema;
};

export const humanizePath = (path) =>
    path.split('.').map(underscore).map(humanize).map(titleize).join(' > ');

export const attributeFilter = (searchString) => (path) => {
    const sanitizedSearch = searchString.trim();

    return sanitizedSearch
        .split(' ')
        .map((t) => t.toLowerCase())
        .every(
            (term) =>
                path.toLowerCase().includes(term) ||
                humanizePath(path).toLowerCase().includes(term),
        );
};

const useStyles = makeStyles((theme) => ({
    search: {
        margin: theme.spacing(1, 0),
    },
    choices: {
        maxHeight: 330,
        overflow: 'auto',
    },
}));

const PiiAttributeSelector = ({
    label,
    onChange,
    validationSchema,
    value,
    error,
}) => {
    const classes = useStyles();

    const [searchString, setSearchString] = useState(null);
    const [selectedPii, setSelectedPii] = useState(value);

    const attributes = getValidationSchemaAttributes(validationSchema);
    const filteredAttributes = searchString
        ? attributes.filter(attributeFilter(searchString))
        : attributes;

    useEffect(() => {
        if (!selectedPii || !validationSchema) {
            return;
        }

        const synchronizedPiiMask = [];
        const availablePaths = getValidationSchemaAttributes(validationSchema);

        if (availablePaths) {
            for (const path of selectedPii) {
                if (!availablePaths.includes(path)) {
                    continue;
                }
                synchronizedPiiMask.push(path);
            }
        }

        setSelectedPii(synchronizedPiiMask);

        if (!isEqual(synchronizedPiiMask, selectedPii)) {
            onChange(synchronizedPiiMask);
        }
    }, [onChange, validationSchema]);

    const onCheckboxClick = (e) => {
        const {
            target: { checked, value: checkboxValue },
        } = e;

        const updatedPii = checked
            ? [...selectedPii, checkboxValue]
            : selectedPii.filter((p) => p !== checkboxValue);

        setSelectedPii(updatedPii);
        onChange(updatedPii);
    };

    return (
        <>
            <InputLabel>{label}</InputLabel>
            <TextInput
                placeholder="Search"
                type="search"
                size="small"
                value={searchString || ''}
                onChange={(e) => setSearchString(e.target.value)}
                className={classes.search}
            />
            <Paper className={classes.choices} elevation={0}>
                <FormGroup>
                    {(filteredAttributes || []).map((path) => (
                        <Checkbox
                            key={path}
                            label={humanizePath(path)}
                            value={path}
                            checked={selectedPii.includes(path)}
                            onChange={onCheckboxClick}
                        />
                    ))}
                </FormGroup>
            </Paper>
            {error && <FormHelperText error>{error}</FormHelperText>}
        </>
    );
};

PiiAttributeSelector.propTypes = {
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    validationSchema: PropTypes.object.isRequired,
    value: PropTypes.arrayOf(PropTypes.string),
    error: PropTypes.string,
};

PiiAttributeSelector.defaultProps = {
    value: [],
    error: null,
};

export default PiiAttributeSelector;
