import { ListItemText, makeStyles, useTheme } from '@material-ui/core';

import processors from '@tint/core/src/processors';
import { getNodeInputs, getNodeOutputs } from '@tint/core/src/processors/io';

import blockPropType from './blockPropTypes';
import BaseBlock from './BaseBlock';
import DefaultPins from './DefaultPins';
import ProcessorIcon from './ProcessorIcon';

import {
    getBlockHeaderPinsByProcessor,
    getBlockContentByProcessor,
} from './processors';
import { getMatchingProcessor } from '../ProcessorSelect/processorGroups';

export const getProcessorBlockColor = (theme, { processor }) =>
    processors[processor].color || theme.palette.mainBlue;

const useStyles = makeStyles((theme) => ({
    title: {
        margin: 0,
    },
    titlePrimary: {
        color: theme.palette.mainBlack,
    },
}));

const ProcessorBlock = ({ id, data, ...props }) => {
    const theme = useTheme();
    const classes = useStyles();

    const inputs = getNodeInputs(data);
    const outputs = getNodeOutputs(data);
    const color = getProcessorBlockColor(theme, data);

    const HeaderPins = getBlockHeaderPinsByProcessor(data.processor);
    const BlockContent = getBlockContentByProcessor(data.processor);

    const matchingProcessor = getMatchingProcessor(data.processor);

    return (
        <BaseBlock
            {...props}
            id={id}
            data={data}
            disabled={data.disabled}
            color={color}
            icon={
                <ProcessorIcon
                    processor={matchingProcessor}
                    withErrors={data.errors.length > 0}
                />
            }
            title={
                <ListItemText
                    primary={data.name}
                    secondary={processors[data.processor].name}
                    classes={{
                        root: classes.title,
                        primary: classes.titlePrimary,
                    }}
                />
            }
            pins={
                HeaderPins ? (
                    <HeaderPins
                        {...props}
                        data={data}
                        inputs={inputs}
                        outputs={outputs}
                        color={color}
                        connecting={data.connecting}
                        isValidConnection={data.isValidConnection}
                    />
                ) : (
                    <DefaultPins
                        {...props}
                        data={data}
                        nodeId={id}
                        color={color}
                        connecting={data.connecting}
                        isValidConnection={data.isValidConnection}
                    />
                )
            }
        >
            {BlockContent ? (
                <BlockContent
                    {...props}
                    data={data}
                    inputs={inputs}
                    outputs={outputs}
                    color={color}
                    connecting={data.connecting}
                    isValidConnection={data.isValidConnection}
                    onViewDetails={data.onViewDetails}
                />
            ) : null}
        </BaseBlock>
    );
};

ProcessorBlock.propTypes = blockPropType;

export default ProcessorBlock;
