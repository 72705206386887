import { cloneDeep } from 'lodash';
import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import TextInput from './TextInput';
import Button from '../Button';
import DangerButton from '../DangerButton';

const TagsInput = ({ input: { onChange, value }, disabled }) => {
    // `Object.entries` does not guarantee order of elements, hence the `sort` by key names
    const [entries, setEntries] = useState(Object.entries(value).sort());

    useEffect(() => {
        onChange(
            entries.reduce(
                (newValue, entry) => ({
                    ...newValue,
                    [entry[0]]: entry[1],
                }),
                {},
            ),
        );
    }, [entries]);

    const handleNameChange =
        (index) =>
        ({ target: { value: newName } }) => {
            const updatedEntries = cloneDeep(entries);
            updatedEntries.splice(index, 1, [newName, entries[index][1]]);

            setEntries(updatedEntries);
        };

    const handleValueChange =
        (index) =>
        ({ target: { value: newTagValue } }) => {
            const updatedEntries = cloneDeep(entries);
            updatedEntries.splice(index, 1, [entries[index][0], newTagValue]);

            setEntries(updatedEntries);
        };

    const handleTagRemoval = (index) => (evt) => {
        evt.preventDefault();
        evt.stopPropagation();

        const updatedEntries = [...entries];
        updatedEntries.splice(index, 1);

        setEntries(updatedEntries);
    };

    const [newTagName, setNewTagName] = useState('');
    const [newTagValue, setNewTagValue] = useState('');

    const newTagNameInput = useRef(null);

    const handleNewTag = (evt) => {
        evt.preventDefault();
        evt.stopPropagation();

        if (!newTagName) {
            return;
        }

        setEntries([...entries, [newTagName, newTagValue]]);
        setNewTagName('');
        setNewTagValue('');

        newTagNameInput.current.focus();
    };

    const handleEnterKeyOnNewTag = (e) => {
        const { key } = e;

        if (key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();

            handleNewTag();
        }
    };

    return (
        <Table>
            <TableBody>
                {entries.map(([tagName, tagValue], index) => (
                    <TableRow key={index}>
                        <TableCell>
                            <TextInput
                                value={tagName}
                                label="Name"
                                onChange={handleNameChange(index)}
                                meta={{}}
                                required
                                disabled={disabled}
                            />
                        </TableCell>
                        <TableCell>
                            <TextInput
                                value={tagValue}
                                label="Value"
                                onChange={handleValueChange(index)}
                                meta={{}}
                                disabled={disabled}
                            />
                        </TableCell>
                        <TableCell>
                            {!disabled && (
                                <DangerButton
                                    color="default"
                                    variant="outlined"
                                    onClick={handleTagRemoval(index)}
                                >
                                    Remove
                                </DangerButton>
                            )}
                        </TableCell>
                    </TableRow>
                ))}

                {!disabled && (
                    <TableRow>
                        <TableCell>
                            <TextInput
                                onChange={({ target: { value: newName } }) =>
                                    setNewTagName(newName)
                                }
                                onKeyDown={handleEnterKeyOnNewTag}
                                value={newTagName}
                                inputProps={{
                                    ref: newTagNameInput,
                                }}
                                label="Name"
                                meta={{}}
                            />
                        </TableCell>
                        <TableCell>
                            <TextInput
                                onChange={({ target: { value: newValue } }) =>
                                    setNewTagValue(newValue)
                                }
                                onKeyDown={handleEnterKeyOnNewTag}
                                value={newTagValue}
                                label="Value"
                                meta={{}}
                            />
                        </TableCell>
                        <TableCell>
                            {!disabled && (
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    onClick={handleNewTag}
                                    disabled={!newTagName}
                                >
                                    Add
                                </Button>
                            )}
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    );
};

TagsInput.propTypes = {
    input: PropTypes.shape({
        onChange: PropTypes.func.isRequired,
        value: PropTypes.object,
    }).isRequired,
    disabled: PropTypes.bool,
};

TagsInput.defaultProps = {
    disabled: false,
};

export default TagsInput;
