import PropTypes from 'prop-types';
import { Grid, Typography, Tab } from '@material-ui/core';
import { Field } from 'react-final-form';
import { ExternalDataCredentialInput } from '../../../../forms';
import { insuranceProductProptype } from '../../../../../../../tint-app/src/insuranceProduct/versions/propTypes';

import TimeoutField from './fields/TimeoutField';
import { DEFAULT_TIMEOUT } from '@tint/core/src/processors/equifax/consumerCreditReport/equifaxConsumerCreditReportProcessor';
import TextInput from '../../../../forms/TextInput';

const parseFallbackValue = (value) => (value === 'null' ? null : +value);
const formatFallbackValue = (value) => (value === null ? 'null' : value);

export const getEquifaxConsumerCreditReportTabs = () => [
    <Tab key="credentials" label="Credentials" />,
    <Tab key="configuration" label="Configuration" />,
];

const EquifaxConsumerCreditReportConfiguration = ({
    insuranceProduct,
    disabled,
    tab,
}) => {
    if (tab === 1) {
        return (
            <Grid container direction="column" spacing={2}>
                <Grid item xs={8}>
                    <TimeoutField
                        disabled={disabled}
                        defaultTimeout={DEFAULT_TIMEOUT}
                        helperText="Max wait time for Equifax response before returning the default values"
                    />
                </Grid>
                {Object.entries({
                    modelNumber: { label: 'Model Number' },
                    modelField: { label: 'Model Field' },
                    vendorIdentificationCode: {
                        label: 'Vendor Identification Code',
                    },
                    fallbackValue: {
                        label: 'Fallback Value',
                        type: 'number',
                        parse: parseFallbackValue,
                        format: formatFallbackValue,
                    },
                }).map(([name, fieldProps]) => (
                    <Grid item xs={8} key={name}>
                        <Field
                            name={`node.configuration.${name}`}
                            component={TextInput}
                            {...fieldProps}
                        />
                    </Grid>
                ))}
            </Grid>
        );
    }

    return (
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <Typography variant="body2">
                    The following credentials will be encrypted before being
                    saved, and nobody in your team will be able to read them.
                </Typography>
            </Grid>
            {Object.entries({
                username: 'Username',
                password: 'Password',
                memberNumber: 'Member Number',
                securityCode: 'Security Code',
                endUsersName: "End User's Name",
                permissiblePurposeCode: 'Permissible Purpose Code',
                customerCode: 'Customer Code',
            }).map(([fieldName, label]) => (
                <Grid item key={fieldName}>
                    <ExternalDataCredentialInput
                        insuranceProduct={insuranceProduct}
                        providerSlug="equifax-consumer-credit-report"
                        name={fieldName}
                        label={label}
                        variant="outlined"
                        fullWidth
                        required
                        type="password"
                        disabled={disabled}
                    />
                </Grid>
            ))}
        </Grid>
    );
};

EquifaxConsumerCreditReportConfiguration.propTypes = {
    disabled: PropTypes.bool,
    insuranceProduct: insuranceProductProptype.isRequired,
    tab: PropTypes.number.isRequired,
};

EquifaxConsumerCreditReportConfiguration.defaultProps = {
    disabled: false,
};

export default EquifaxConsumerCreditReportConfiguration;
