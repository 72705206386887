import type { JSONSchema7 } from 'json-schema';
import InternalError from '../../InternalError';

import UnknownAttributeError from './UnknownAttributeError';

const getJsonSchemaPartByPath = (
    schema: JSONSchema7,
    path: string,
    initialPath: string | null = null,
): JSONSchema7 => {
    const [firstStep, ...steps] = path.split('.');
    const currentSchema = schema.properties?.[firstStep || ''];
    if (!currentSchema) {
        throw new UnknownAttributeError(initialPath || path);
    }

    if (typeof currentSchema === `boolean`) {
        throw new InternalError(
            `Assertion failed: Expected the definition not to be a boolean.`,
        );
    }

    const schemaType = Array.isArray(currentSchema.type)
        ? currentSchema.type.find((v) => v !== 'null')
        : currentSchema.type;

    if (schemaType === 'object') {
        return getJsonSchemaPartByPath(
            currentSchema,
            steps.join('.'),
            initialPath || path,
        );
    }

    return currentSchema;
};

export default getJsonSchemaPartByPath;
