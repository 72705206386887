import { useMemo, isValidElement, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import classnames from 'classnames';
import { makeStyles, Typography, Grid, useTheme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { isNil } from 'lodash';

const useStyles = makeStyles((theme) => ({
    label: {
        color: theme.palette.text.secondary,
        fontWeight: '500',
        fontSize: '0.75rem',
        lineHeight: 1,
        marginBottom: theme.spacing(1),
    },
    value: {
        color: theme.palette.mainBlack,
        display: 'inline-block',
        marginTop: theme.spacing(1),
        fontSize: '1rem',
    },
}));

const ScalarValue = ({ loading, value, className, ...rest }) => (
    <Typography className={className} {...rest}>
        {loading ? <Skeleton width={80} /> : isNil(value) ? 'N/A' : value}
    </Typography>
);

ScalarValue.propTypes = {
    loading: PropTypes.bool,
    value: PropTypes.any,
    className: PropTypes.string,
};

const LabelledValue = ({
    icon,
    label,
    loading,
    value,
    classes,
    ...otherProps
}) => {
    const theme = useTheme();
    const componentClasses = useStyles();

    const valueClassName = classnames(componentClasses.value, classes.value);
    const id = useMemo(uuid, []);

    return (
        <div {...otherProps}>
            <Grid container spacing={1} alignItems="center">
                {icon && (
                    <Grid item xs="auto">
                        {cloneElement(icon, {
                            ...icon.props,
                            width: '16',
                            height: '16',
                            color: theme.palette.text.secondary,
                        })}
                    </Grid>
                )}
                <Grid item>
                    <Typography
                        id={id}
                        className={classnames(
                            componentClasses.label,
                            classes.label,
                        )}
                    >
                        {label}
                    </Typography>
                </Grid>
            </Grid>
            {typeof value === 'function' ? (
                value({
                    loading,
                    className: valueClassName,
                    'aria-labelledby': id,
                })
            ) : isValidElement(value) ? (
                cloneElement(value, {
                    'aria-labelledby': id,
                    className: valueClassName,
                })
            ) : (
                <ScalarValue
                    aria-labelledby={id}
                    loading={loading}
                    value={value}
                    className={valueClassName}
                />
            )}
        </div>
    );
};

LabelledValue.propTypes = {
    classes: PropTypes.object,
    icon: PropTypes.node,
    label: PropTypes.string.isRequired,
    value: PropTypes.any,
    loading: PropTypes.bool,
};

LabelledValue.defaultProps = {
    classes: {},
    value: 'N/A',
    loading: false,
};

export default LabelledValue;
