// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// Ignore because v4
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, gql } from '@apollo/client';
import { ThemeProvider } from '@material-ui/core';
import theme from '../v4/theme';
import { useNotify } from 'react-admin';
import { Button } from '../v4';

const RESTORE_USER = gql`
    mutation restoreUser($id: ID!) {
        restoreUser(id: $id) {
            id
            archived
        }
    }
`;

interface RestoreUserButtonProps {
    data: {
        id: string;
        email: string;
    };
}

export const RestoreUserButton = ({
    data,
}: RestoreUserButtonProps): JSX.Element => {
    const history = useHistory();
    const notify = useNotify();

    const [
        restoreUser,
        { data: restoredData, loading: restoring, error: restoreError },
    ] = useMutation(RESTORE_USER);

    useEffect(() => {
        if (!restoreError) {
            return;
        }

        notify(restoreError.message, 'error');
    }, [restoreError, notify]);

    useEffect(() => {
        if (!restoredData) {
            return;
        }

        notify('User has been restored');
        history.push(
            '/User?displayedFilters=%7B%7D&filter=%7B"archived"%3Afalse%7D',
        );
    }, [restoredData, notify, history]);

    const onRestore = () => {
        restoreUser({ variables: { id: data.id } });
    };

    if (!data) {
        return null;
    }

    return (
        <ThemeProvider theme={theme}>
            <Button
                color="primary"
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onRestore();
                }}
                loading={restoring}
                disabled={restoring}
            >
                Restore
            </Button>
        </ThemeProvider>
    );
};
