import PropTypes from 'prop-types';
import { useQuery, gql } from '@apollo/client';
import { titleize } from 'inflection';

import { get } from 'lodash';

import { Select } from './primitives';
import { Alert } from '@material-ui/lab';

export const GET_ATTRIBUTES_LIST = gql`
    query getAttributesList($insuranceProductId: ID!) {
        insuranceProduct(id: $insuranceProductId) {
            id
            attributeDefinitions {
                id
                name
            }
        }
    }
`;

const AttributesSelect = ({ insuranceProduct, ...otherProps }) => {
    const { data, error, loading } = useQuery(GET_ATTRIBUTES_LIST, {
        variables: {
            insuranceProductId: insuranceProduct.id,
        },
    });

    if (error) {
        return (
            <Alert severity="error">
                text="Unable to fetch your product attributes."
            </Alert>
        );
    }

    const attributes = get(
        data,
        'insuranceProduct.attributeDefinitions',
        [],
    ).map(({ id, name }) => ({
        label: titleize(name),
        value: id,
    }));

    return <Select loading={loading} {...otherProps} options={attributes} />;
};

AttributesSelect.propTypes = {
    insuranceProduct: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
};

AttributesSelect.defaultProps = {};

export default AttributesSelect;
