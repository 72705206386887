import {
    createContext,
    forwardRef,
    useContext,
    cloneElement,
    useRef,
    useEffect,
    Children,
    isValidElement,
} from 'react';
import PropTypes from 'prop-types';
import { VariableSizeList } from 'react-window';

import Autocomplete from '@material-ui/lab/Autocomplete';

import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import ListSubheader from '@material-ui/core/ListSubheader/ListSubheader';
import ExpandMore from '@material-ui/icons/ExpandMore';

import Loader from '../../Loader';
import TextInput from '../TextInput';
import { pick } from 'lodash';

const useStyles = makeStyles((theme) => ({
    carret: {
        position: 'relative',
        '& path': {
            fill: theme.palette.lighterGray,
        },
    },
    loader: {
        marginLeft: theme.spacing(1),
    },
}));

const LISTBOX_PADDING = 8;

const OuterElementContext = createContext({});

const OuterElementType = forwardRef(function OuterElementType(props, ref) {
    const outerProps = useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function renderRow(props) {
    const { data, index, style } = props;
    return cloneElement(data[index], {
        style: {
            ...style,
            top: style.top + LISTBOX_PADDING,
        },
    });
}

function useResetCache(data) {
    const ref = useRef(null);
    useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

// Adapter for react-window
const ListboxComponent = forwardRef(function ListboxComponent(
    // eslint-disable-next-line react/prop-types
    { children, ...other },
    ref,
) {
    const itemData = Children.toArray(children);
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child) => {
        if (isValidElement(child) && child.type === ListSubheader) {
            return 48;
        }

        return itemSize;
    };

    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index) => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

const Select = ({
    label,
    loading,
    getOptionLabel,
    getOptionSelected,
    options,
    input,
    meta: { touched, error },
    margin,
    InputProps,
    variant,
    autoFocus,
    fixedSize,
    onSelectOption,
    helperText,
    ...restProps
}) => {
    const classes = useStyles();
    const theme = useTheme();

    const value = options.find((o) => o.value === input.value);

    return (
        <Autocomplete
            options={options}
            disabled={loading && !options.length}
            ListboxComponent={fixedSize ? undefined : ListboxComponent}
            getOptionLabel={getOptionLabel}
            getOptionSelected={getOptionSelected}
            popupIcon={<ExpandMore className={classes.carret} />}
            renderInput={(params) => (
                <TextInput
                    error={!!(touched && error)}
                    helperText={touched && error ? error : helperText}
                    {...params}
                    margin={margin}
                    variant={variant}
                    label={
                        loading ? (
                            <>
                                {label}
                                <Loader
                                    inline
                                    color={theme.palette.lighterGray}
                                    size={12}
                                    className={classes.loader}
                                />
                            </>
                        ) : (
                            label
                        )
                    }
                    InputProps={{
                        ...params.InputProps,
                        ...InputProps,
                    }}
                    autoFocus={autoFocus}
                    input={pick(params.inputProps, ['value', 'onChange'])}
                    shrink={!!value}
                />
            )}
            {...input}
            value={value || null}
            onChange={(e, selectedOption) => {
                input.onChange(selectedOption ? selectedOption.value : null);
                onSelectOption(selectedOption);
            }}
            {...restProps}
        />
    );
};

Select.propTypes = {
    label: PropTypes.string,
    loading: PropTypes.bool,
    input: PropTypes.shape({
        value: PropTypes.any,
        onChange: PropTypes.func.isRequired,
    }).isRequired,
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.string,
    }).isRequired,
    getOptionLabel: PropTypes.func,
    getOptionSelected: PropTypes.func,
    options: PropTypes.array.isRequired,
    margin: PropTypes.string,
    InputProps: PropTypes.object,
    variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
    autoFocus: PropTypes.bool,
    fixedSize: PropTypes.bool,
    onSelectOption: PropTypes.func,
    helperText: PropTypes.node,
};

Select.defaultProps = {
    loading: false,
    getOptionLabel: (option) => option.label,
    getOptionSelected: (option, selectedOption) => {
        if (!selectedOption) {
            return null;
        }

        return option.value === selectedOption.value;
    },
    label: null,
    margin: undefined,
    InputProps: {},
    variant: 'outlined',
    autoFocus: undefined,
    fixedSize: false,
    onSelectOption: () => {},
    helperText: null,
};

export default Select;
