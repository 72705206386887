import PropTypes from 'prop-types';

import { PathMultiSelect } from './PathMultiSelect';
import { PathSelect } from './PathSelect';
import { DateRangePathSelect } from './DateRangePathSelect';

export const OptionsInput = ({ type, value, onChange }) => {
    const onPathsChange = (paths) => onChange({ paths });
    const onPathChange = (path) => onChange({ path });
    const onDatePathChange = ({ startDatePath, endDatePath }) =>
        onChange({ startDatePath, endDatePath });
    switch (type) {
        case 'string':
            return (
                <PathMultiSelect
                    label="Paths"
                    value={value.paths}
                    onChange={onPathsChange}
                />
            );
        case 'template':
        case 'currency':
        case 'number':
        case 'date':
        case 'boolean':
            return (
                <PathSelect
                    label="Path"
                    value={value.path}
                    onChange={onPathChange}
                />
            );
        case 'date-range':
        case 'period':
            return (
                <DateRangePathSelect
                    onChange={onDatePathChange}
                    startDatePath={value.startDatePath}
                    endDatePath={value.endDatePath}
                />
            );
        case 'customId':
        case 'status':
        case 'score':
        default:
            return null;
    }
};

OptionsInput.propTypes = {
    type: PropTypes.string,
    value: PropTypes.shape({
        paths: PropTypes.arrayOf(PropTypes.string),
        path: PropTypes.string,
        startDatePath: PropTypes.string,
        endDatePath: PropTypes.string,
    }).isRequired,
    onChange: PropTypes.func.isRequired,
};
