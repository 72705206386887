import PropTypes from 'prop-types';

import { TableCell, makeStyles } from '@material-ui/core';
import TableSortLabel from './TableSortLabel';

const useStyles = makeStyles({
    root: {
        fontWeight: 'normal',
    },
});

const TableCellHeader = ({
    name,
    label,
    sortBy,
    sortDir,
    handleSortChange,
}) => {
    const classes = useStyles();
    return (
        <TableCell className={classes.root}>
            {handleSortChange ? (
                <TableSortLabel
                    active={sortBy === name}
                    direction={sortDir}
                    onClick={handleSortChange(name)}
                >
                    {label}
                </TableSortLabel>
            ) : (
                label
            )}
        </TableCell>
    );
};

TableCellHeader.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    sortDir: PropTypes.oneOf(['asc', 'desc']),
    sortBy: PropTypes.string,
    handleSortChange: PropTypes.func,
};

export default TableCellHeader;
