import { ReferenceInput, SelectInput } from 'react-admin';

import InsuranceProductOptionText from './InsuranceProductOptionText';

const InsuranceProductInput = (props) => (
    <ReferenceInput
        label="Insurance Product"
        reference="InsuranceProduct"
        perPage={null}
        sort={{ field: 'created_at' }}
        style={{ minWidth: 300 }}
        {...props}
    >
        <SelectInput optionText={<InsuranceProductOptionText />} />
    </ReferenceInput>
);

export default InsuranceProductInput;
