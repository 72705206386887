import { ArchiveUserButton } from './ArchiveUserButton';
import { RestoreUserButton } from './RestoreUserButton';

type UserActiveFieldProps = {
    record: {
        id: string;
        email: string;
        archived: boolean;
    };
};

export const UserActiveField = ({
    record,
}: UserActiveFieldProps): JSX.Element => {
    if (!record) {
        return null;
    }

    return (
        <>
            {record.archived ? (
                <RestoreUserButton data={record} />
            ) : (
                <ArchiveUserButton data={record} />
            )}
        </>
    );
};
