import PropTypes from 'prop-types';
import processors from '@tint/core/src/processors';
import { getNodeInputs, getNodeOutputs } from '@tint/core/src/processors/io';

import { dataPropType } from './blockPropTypes';
import ConnectorPin from './ConnectorPin';

export const getPinPositionFromTop = (pinNumber) => (position) => {
    const offsetPercentPerPin = 100 / (pinNumber + 1);
    const pinOffsetPercent = offsetPercentPerPin * (position + 1);

    return `${Math.round(pinOffsetPercent)}%`;
};

const DefaultPins = ({
    nodeId,
    data,
    selected,
    color,
    connecting,
    isValidConnection,
}) => {
    const processor = processors[data.processor];

    if (!processor) {
        throw new Error(`Unable to find the processor "${data.processor}"`);
    }

    const inputs = getNodeInputs(data);
    const outputs = getNodeOutputs(data);

    const getInputPinPosition = getPinPositionFromTop(inputs.length);
    const getOutputPinPosition = getPinPositionFromTop(outputs.length);

    return (
        <>
            {inputs.map((input, i) => (
                <ConnectorPin
                    key={input.id}
                    selector={`${nodeId}-${input.id}`}
                    nodeId={nodeId}
                    type="target"
                    position="left"
                    id={input.id}
                    name={input.name}
                    style={{
                        top: getInputPinPosition(i),
                    }}
                    blockSelected={selected}
                    color={color}
                    connecting={connecting}
                    isValidConnection={isValidConnection}
                />
            ))}
            {outputs.map((output, i) => (
                <ConnectorPin
                    key={output.id}
                    selector={`${nodeId}-${output.id}`}
                    nodeId={nodeId}
                    type="source"
                    position="right"
                    id={output.id}
                    name={output.name}
                    style={{
                        top: getOutputPinPosition(i),
                    }}
                    blockSelected={selected}
                    color={color}
                    connecting={connecting}
                    isValidConnection={isValidConnection}
                />
            ))}
        </>
    );
};

DefaultPins.propTypes = {
    nodeId: PropTypes.string,
    data: PropTypes.shape(dataPropType).isRequired,
    selected: PropTypes.bool.isRequired,
    color: PropTypes.string.isRequired,
    connecting: PropTypes.object,
    isValidConnection: PropTypes.func.isRequired,
};

DefaultPins.defaultProps = {
    connecting: null,
    nodeId: null,
};

export default DefaultPins;
