import { createValidator } from '../../validation/validatorFactory';

const validator = createValidator();

const validate = validator.compile({
    type: 'object',
    required: ['default'],
    properties: { default: { type: 'number' } },
    additionalProperties: false,
});

export default validate;
