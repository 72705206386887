import validator from './validate';

const attributeProcessor = {
    name: 'Policy Details',
    slug: 'attribute',
    validate: validator,
    inputs: [] as never[],
    outputs: [{ id: 'output', name: 'Output' }],
};

export default attributeProcessor;
