import validator from './validate';

const powerProcessor = {
    name: 'Power',
    slug: 'power',
    description: 'Return the power of a number',
    validate: validator,
    inputs: [
        {
            id: 'number',
            name: 'Number',
            schema: { type: 'number' },
        },
        {
            id: 'power',
            name: 'Power',
            schema: { type: 'number' },
        },
    ],
    outputs: [
        {
            id: 'output',
            name: 'Output',
            schema: { type: 'number' },
        },
    ],
};

export default powerProcessor;
