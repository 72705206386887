import { gql, useQuery } from '@apollo/client';
import { Alert } from '@material-ui/lab';
import { get } from 'lodash';
import PropTypes from 'prop-types';

import Select from './primitives/Select';
import ReadOnlyInput from './ReadOnlyInput';

export const GET_MODELS_QUERY = gql`
    query getModels($filter: ModelFilter) {
        models(filter: $filter, perPage: null) {
            items {
                id
                name
            }
        }
    }
`;

const ModelsSelect = ({
    filter,
    label,
    input,
    readOnly,
    ReadOnlyLink,
    query,
    ...props
}) => {
    const {
        data: modelsData,
        loading,
        error,
    } = useQuery(query || GET_MODELS_QUERY, {
        fetchPolicy: 'cache-and-network',
        variables: {
            filter,
        },
    });

    if (error) {
        return (
            <Alert severity="error">
                We were unable to fetch your models. Please retry later.
            </Alert>
        );
    }

    const models = get(modelsData, 'models.items', []);

    if (readOnly) {
        const matchingModel = models.find((model) => model.id === input.value);

        if (!matchingModel) {
            return null;
        }

        return (
            <ReadOnlyInput
                label={label}
                value={
                    ReadOnlyLink ? (
                        <ReadOnlyLink model={matchingModel} />
                    ) : (
                        matchingModel.name
                    )
                }
            />
        );
    }

    return (
        <Select
            loading={loading}
            input={input}
            label={label}
            options={models.map((model) => ({
                ...model,
                value: model.id,
                label: model.name,
            }))}
            {...props}
        />
    );
};

ModelsSelect.propTypes = {
    filter: PropTypes.shape({
        insuranceProductId: PropTypes.string.isRequired,
        outputType: PropTypes.string,
        isValid: PropTypes.bool,
        tintManaged: PropTypes.bool,
    }).isRequired,
    label: PropTypes.string.isRequired,
    input: PropTypes.shape({
        value: PropTypes.any,
    }).isRequired,
    readOnly: PropTypes.bool,
    ReadOnlyLink: PropTypes.elementType,
    query: PropTypes.object,
};

ModelsSelect.defaultProps = {
    filter: {},
    readOnly: false,
    ReadOnlyLink: null,
    query: undefined,
};

export default ModelsSelect;
