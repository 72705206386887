import { Create } from 'react-admin';
import { QuestionnaireForm } from './Form';

const QuestionnaireCreate = (props) => (
    <Create title="Create Questionnaire" {...props}>
        <QuestionnaireForm />
    </Create>
);

export default QuestionnaireCreate;
