import PropTypes from 'prop-types';

import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
} from '@material-ui/core';
import { uniqueId } from 'lodash';

const SimpleSelect = ({ label, input, options, helper }) => {
    const id = uniqueId();

    return (
        <FormControl>
            {label && <InputLabel htmlFor={id}>{label}</InputLabel>}
            <Select inputProps={{ id }} {...input} displayEmpty>
                {options.map((option) => (
                    <MenuItem value={option.value} key={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
            {helper && <FormHelperText>{helper}</FormHelperText>}
        </FormControl>
    );
};

SimpleSelect.propTypes = {
    label: PropTypes.string,
    input: PropTypes.shape({
        value: PropTypes.any,
        onChange: PropTypes.func.isRequired,
    }).isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.any,
        }),
    ),
    helper: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

SimpleSelect.defaultProps = {
    label: null,
    options: [],
    helper: null,
};

export default SimpleSelect;
