import { gql, useLazyQuery } from '@apollo/client';
import { get } from 'lodash';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { BooleanInput, TextInput } from 'react-admin';

import validateModelBlueprint from '@tint/core/src/validation/validateModelBlueprint';

import { CustomToolbar } from '../forms/CustomToolbar';
import InsuranceProductInput from '../insuranceProducts/InsuranceProductInput';
import { checkRequiredFieldsFactory } from '../forms/validation';

import SimpleForm from '../controllers/SimpleForm';
import { ModelEditorInput } from '../inputs/ModelEditorInput';
import { Alert } from '@material-ui/lab';

export const validateForm = (validationSchema) => (values) => {
    const errors = {
        ...checkRequiredFieldsFactory(['insuranceProduct.id'])(values),
    };

    if (values && values.blueprint && validationSchema && values.readOnly) {
        errors.modelEditor = validateModelBlueprint(
            values.blueprint,
            validationSchema,
        );
    }

    return errors;
};

const GET_SELECTED_INSURANCE_PRODUCT = gql`
    query getInsuranceProduct($id: ID!) {
        insuranceProduct(id: $id) {
            id
            name
            organization {
                id
            }
            validationSchema
        }
    }
`;

const ModelForm = ({ record, ...props }) => {
    const [getInsuranceProduct, { data: insuranceProductData }] = useLazyQuery(
        GET_SELECTED_INSURANCE_PRODUCT,
    );

    const [insuranceProductId, setInsuranceProductId] = useState(
        get(record, 'insuranceProduct.id'),
    );
    const insuranceProduct = get(insuranceProductData, 'insuranceProduct');
    const validationSchema = insuranceProduct?.validationSchema;

    useEffect(() => {
        getInsuranceProduct({
            variables: {
                id: insuranceProductId,
            },
        });
    }, [insuranceProductId]);

    const onProductChange = ({ target: { value } }) => {
        if (!value) {
            return;
        }

        setInsuranceProductId(value);
    };

    const { archived } = record;
    const modelEditorSource = {
        blueprint: 'blueprint',
        editorData: 'editorData',
        name: 'name',
    };

    return (
        <SimpleForm
            validate={validateForm(validationSchema)}
            toolbar={
                <CustomToolbar
                    saveable={!archived}
                    deletable={!archived}
                    deleteConfirmTitle={() => `Model removal`}
                />
            }
            record={record}
            {...props}
        >
            <TextInput source="name" required disabled={archived} />
            <InsuranceProductInput
                disabled={archived}
                source="insuranceProduct.id"
                required
                fullWidth
                onChange={onProductChange}
            />
            {insuranceProduct && !validationSchema && (
                <Alert style={{ width: 'calc(100% - 32px)' }} severity="error">
                    Unable to retrieve product validation schema: blueprint
                    validation is disabled.
                </Alert>
            )}
            {insuranceProduct && (
                <ModelEditorInput
                    disabled={archived}
                    label="Model Editor"
                    source={modelEditorSource}
                    height={800}
                    record={record}
                    insuranceProduct={insuranceProduct}
                />
            )}
            <BooleanInput source="tintManaged" />
        </SimpleForm>
    );
};

ModelForm.propTypes = {
    record: PropTypes.object.isRequired,
    children: PropTypes.node,
};

ModelForm.defaultProps = {
    children: null,
};

export default ModelForm;
