import validator from './validate';

export type CoalesceProcessor = () => <Value>(
    inputs: Record<string, Value | null>,
) => { output: Value | null };

const coalesceProcessor = {
    name: 'Coalesce',
    slug: 'coalesce',
    description: 'Return the first non null input',
    validate: validator,
    inputs: { id: 'inputs', dynamic: true, min: 2 },
    outputs: [
        {
            id: 'output',
            name: 'Output',
        },
    ],
};

export default coalesceProcessor;
