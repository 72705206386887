import { Create } from 'react-admin';
import ExternalDataProviderCredentialsForm from './Form';

const CreateExternalDataProviderCredentials = (props) => (
    <Create title="New Credentials" {...props}>
        <ExternalDataProviderCredentialsForm
            redirect={(basePath, id, data) =>
                `/Organization/${data.organizationId}/2`
            }
        />
    </Create>
);

export default CreateExternalDataProviderCredentials;
