import { InMemoryCache } from '@apollo/client';

// Same as packages/tint-app/src/graphqlCache.ts
export default new InMemoryCache({
    typePolicies: {
        QuoteCoverage: {
            keyFields: ['id', 'quote', ['id']],
        },
        Subscription: {
            keyFields: ['quote', ['id']],
        },
        InsuranceProductVersion: {
            fields: {
                insurancePlans: {
                    merge: false,
                },
                decisionModels: {
                    merge: false,
                },
            },
        },
    },
});
