// Same as packages/tint-app/src/requireAuthentication.ts
const requireAuthentication = (): void => {
    const currentUrl = global.location.href;

    if (process.env.SSO_URL) {
        global.location.replace(
            `${process.env.SSO_URL}/?redirect=${encodeURIComponent(
                currentUrl,
            )}`,
        );
    }
};

export default requireAuthentication;
