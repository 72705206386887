import { useReducer } from 'react';

/**
 * Take an object with action name as key and action handler as value along with an initial state
 * Returns the current state and an actions object containing all action creator for each handler
 * This avoid all the usual scaffolding with action creator.
 * The action creator are automatically derived from the action handler.
 */
export function useReducerWithAction(actionHandlers, initialState) {
    const reducer = (state, action) => {
        const handler = actionHandlers[action.type];
        if (!handler) {
            return state;
        }

        return handler(state, action.payload);
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    const actions = Object.keys(actionHandlers).reduce((acc, key) => {
        return {
            ...acc,
            [key]: (payload) =>
                dispatch({
                    type: key,
                    payload,
                }),
        };
    }, {});

    return { state, actions };
}
