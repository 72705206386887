import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        display: ({ inline }) => (inline ? 'inline' : 'flex'),
        alignItems: 'center',
        justifyContent: 'center',
    },
    loader: {
        margin: ({ inline }) => (inline ? 0 : theme.spacing(2)),
        color: ({ color }) => color,
    },
}));

const Loader = ({ className, inline, size, color, ...otherProps }) => {
    const classes = useStyles({ color, inline });

    return (
        <div className={`${classes.root} ${className}`} {...otherProps}>
            <CircularProgress
                aria-busy="true"
                className={classes.loader}
                size={size}
            />
        </div>
    );
};

Loader.propTypes = {
    className: PropTypes.string,
    inline: PropTypes.bool,
    size: PropTypes.number,
    color: PropTypes.string,
};

Loader.defaultProps = {
    className: '',
    inline: false,
    size: 32,
    color: 'primary',
};

export default Loader;
