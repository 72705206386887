import type { JSONSchema7Definition } from 'json-schema';
import { flatten } from 'lodash';

interface ValidationSchemaFilter {
    type: string;
    format?: string;
}

const getValidationSchemaAttributes = (
    validationSchema: JSONSchema7Definition,
    rootPath?: string,
    filter?: ValidationSchemaFilter,
): string[] => {
    if (!validationSchema || typeof validationSchema == 'boolean') {
        return [];
    }

    const { type: rawType } = validationSchema;

    const type = Array.isArray(rawType)
        ? rawType.find((t) => t !== 'null')
        : rawType;

    if (type === 'object') {
        if (!validationSchema.properties) {
            return [];
        }

        return flatten(
            Object.keys(validationSchema.properties).map((key) =>
                getValidationSchemaAttributes(
                    validationSchema.properties?.[key] || false,
                    rootPath ? `${rootPath}.${key}` : key,
                    filter,
                ),
            ),
        );
    }

    if (
        filter &&
        (filter.type !== type ||
            (filter.format && filter.format !== validationSchema.format))
    ) {
        return [];
    }

    return rootPath ? [rootPath] : [];
};

export default getValidationSchemaAttributes;
