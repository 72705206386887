import PropTypes from 'prop-types';

import makeStyles from '@material-ui/styles/makeStyles/makeStyles';
import Typography from '@material-ui/core/Typography/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(0, 2, 1, 2),
    },
    label: {
        marginTop: -1,
        fontSize: 12 * 0.875,
        padding: theme.spacing(0, 0, 1, 0),
        color: theme.palette.darkerGray,
    },
    value: {
        fontSize: '0.875rem',
    },
}));

const ReadOnlyInput = ({ label, value, ...otherProps }) => {
    const classes = useStyles();

    return (
        <div className={classes.root} {...otherProps}>
            <Typography className={classes.label} variant="body2">
                {label}
            </Typography>
            <div aria-label={label} className={classes.value}>
                {value}
            </div>
        </div>
    );
};

ReadOnlyInput.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.node,
};

ReadOnlyInput.defaultProps = {
    value: null,
};

export default ReadOnlyInput;
