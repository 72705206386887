import type { FunctionComponent } from 'react';
import { useQuery, CreateButton, EditButton } from 'react-admin';
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
} from '@material-ui/core';

import EXTERNAL_DATA_PROVIDERS from '../externalDataProviderCredentials/providers';

interface Props {
    organization?: { id: string };
}

const ExternalDataProviderCredentialsField: FunctionComponent<Props> = ({
    organization,
}) => {
    const { data: credentials } = useQuery({
        type: 'getList',
        resource: 'ExternalDataProviderCredential',
        payload: {
            filter: {
                organizationId: organization?.id,
            },
            sort: {},
            pagination: { page: 1, perPage: null },
        },
    });

    if (!organization || !credentials) {
        return null;
    }

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Provider</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>
                        <CreateButton
                            variant="contained"
                            to={`/ExternalDataProviderCredential/create?source=${encodeURIComponent(
                                JSON.stringify({
                                    organizationId: organization.id,
                                }),
                            )}`}
                        />
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {credentials.map((credential) => (
                    <TableRow key={credential.id}>
                        <TableCell>
                            {EXTERNAL_DATA_PROVIDERS[credential.providerSlug]}
                        </TableCell>
                        <TableCell>{credential.name}</TableCell>
                        <TableCell>
                            <EditButton
                                basePath="/ExternalDataProviderCredential"
                                record={credential}
                            />
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default ExternalDataProviderCredentialsField;
