import classnames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { formatDuration, formatDate } from '@tint/core/src/formatters';
import FieldLabel from './FieldLabel';

const useStyles = makeStyles((theme) => ({
    root: {
        display: ({ noWrap }) => (noWrap ? 'flex' : undefined),
        flexWrap: ({ noWrap }) => (noWrap ? 'nowrap' : undefined),
        whiteSpace: ({ noWrap }) => (noWrap ? 'nowrap' : undefined),
    },
    label: {
        margin: 0,
    },
    dates: {
        display: 'flex',
        alignItems: 'center',
    },
    arrow: {
        fontSize: '1rem',
        margin: theme.spacing(0, 1),
    },
    duration: {
        color: theme.palette.lighterGray,
        paddingLeft: ({ noWrap }) => (noWrap ? theme.spacing(1) : undefined),
    },
}));

const DateRangeField = ({
    className,
    label,
    from,
    to,
    withDuration,
    noWrap,
    ...otherProps
}) => {
    const classes = useStyles({ noWrap });
    const duration = withDuration ? formatDuration(from, to) : null;

    return (
        <div className={classnames(classes.root, className)} {...otherProps}>
            {label && <FieldLabel>{label}</FieldLabel>}
            <div className={classes.dates}>
                {from ? formatDate(from) : 'N/A'}
                <ArrowForward className={classes.arrow} />
                {to ? formatDate(to) : 'N/A'}
            </div>
            {duration !== null && (
                <div className={classes.duration} data-testid="duration">
                    ({duration})
                </div>
            )}
        </div>
    );
};

DateRangeField.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    from: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string])
        .isRequired,
    to: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string])
        .isRequired,
    withDuration: PropTypes.bool,
    noWrap: PropTypes.bool,
};

DateRangeField.defaultProps = {
    className: null,
    label: null,
    withDuration: true,
    noWrap: false,
};

export default DateRangeField;
