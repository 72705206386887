import { gql } from '@apollo/client';
import { GET_ONE, GET_LIST, CREATE, UPDATE } from 'react-admin';

import { pick } from 'lodash';

const QUESTIONNAIRE_FRAGMENT = gql`
    fragment QUESTIONNAIRE_FRAGMENT on Questionnaire {
        id
        name
        initialQuestionId
        questions
        insuranceProduct {
            id
            name
        }
        createdAt
        lastUpdatedAt
    }
`;

const questionnaireDataProviderFactory = (client) => ({
    [GET_LIST]: async (params) => {
        const {
            data: {
                questionnaires: { items: questionnaires, count: total },
            },
        } = await client.query({
            query: gql`
                query getQuestionnaireAdminList(
                    $sortBy: String
                    $sortDir: String
                    $perPage: Int
                    $page: Int
                    $filter: QuestionnaireFilter
                ) {
                    questionnaires(
                        page: $page
                        perPage: $perPage
                        sortBy: $sortBy
                        sortDir: $sortDir
                        filter: $filter
                    ) {
                        count
                        items {
                            id
                            name
                            questions # required to prevent some cache issues when going on questionnaire details
                            insuranceProduct {
                                id
                                name
                            }
                            createdAt
                            lastUpdatedAt
                        }
                    }
                }
            `,
            variables: params,
        });

        return { data: questionnaires, total };
    },
    [GET_ONE]: async (params) => {
        const {
            data: { questionnaire },
        } = await client.query({
            query: gql`
                ${QUESTIONNAIRE_FRAGMENT}

                query getQuestionnaire($id: ID!) {
                    questionnaire(id: $id) {
                        ...QUESTIONNAIRE_FRAGMENT
                    }
                }
            `,
            variables: params,
        });

        return { data: questionnaire };
    },

    [CREATE]: async (params) => {
        const { data } = params;

        const {
            data: { createQuestionnaire: createdQuestionnaire },
        } = await client.mutate({
            mutation: gql`
                ${QUESTIONNAIRE_FRAGMENT}

                mutation createQuestionnaire($data: QuestionnaireInput!) {
                    createQuestionnaire(input: $data) {
                        ...QUESTIONNAIRE_FRAGMENT
                    }
                }
            `,
            variables: {
                data: {
                    ...pick(data, ['questions', 'initialQuestionId', 'name']),
                    insuranceProductId: data.insuranceProduct.id,
                },
            },
        });

        return { data: createdQuestionnaire };
    },

    [UPDATE]: async (params) => {
        const { id, data } = params;

        const {
            data: { updateQuestionnaire: updatedQuestionnaire },
        } = await client.mutate({
            mutation: gql`
                ${QUESTIONNAIRE_FRAGMENT}

                mutation updateQuestionnaire(
                    $id: ID!
                    $data: QuestionnaireInput!
                ) {
                    updateQuestionnaire(id: $id, input: $data) {
                        ...QUESTIONNAIRE_FRAGMENT
                    }
                }
            `,
            variables: {
                id,
                data: {
                    ...pick(data, ['questions', 'initialQuestionId', 'name']),
                    insuranceProductId: data.insuranceProduct.id,
                },
            },
        });

        return { data: updatedQuestionnaire };
    },
});

export default questionnaireDataProviderFactory;
