export type ErrorType = 'INTERNAL' | 'CUSTOMER_FACING';

class BaseError extends Error {
    type: ErrorType;
    details?: unknown;
    constructor(message: string, type: ErrorType, stack?: string) {
        super(message);
        this.type = type;
        if (stack) {
            this.stack = stack;
            this.name = 'BaseError';
        }
    }
}

export const isBaseError = (value: unknown): value is BaseError => {
    return typeof value === 'object' && value !== null && 'type' in value;
};

export default BaseError;
