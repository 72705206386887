import {
    TextInput,
    DateField,
    Filter,
    List,
    Datagrid,
    TextField,
    ReferenceField,
} from 'react-admin';
import OrganizationReferenceInput from '../organizations/OrganizationReferenceInput';

export const InsuranceProductsFilter = (props) => (
    <Filter {...props}>
        <OrganizationReferenceInput
            label="Organization"
            source="organizationId"
            alwaysOn
        />
        <TextInput label="Product Name" source="name" />
    </Filter>
);

export const InsuranceProductsList = (props) => (
    <List
        title="Insurance Products"
        filters={<InsuranceProductsFilter />}
        bulkActionButtons={false}
        perPage={25}
        {...props}
    >
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <ReferenceField
                label="Organization"
                source="organization.id"
                reference="Organization"
            >
                <TextField source="name" />
            </ReferenceField>
            <TextField source="name" />
            <DateField showTime source="createdAt" />
            <DateField showTime source="lastUpdatedAt" />
        </Datagrid>
    </List>
);
