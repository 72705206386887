import PropTypes from 'prop-types';

import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
} from '@material-ui/core';

import Button from './Button';
import DangerButton from './DangerButton';

export const Confirm = ({
    children,
    onClose,
    onConfirm,
    loading,
    danger,
    title,
    ...otherProps
}) => {
    const ConfirmButton = danger ? DangerButton : Button;

    return (
        <Dialog onClose={onClose} {...otherProps}>
            {title && <DialogTitle>{title}</DialogTitle>}
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                <Button variant="text" onClick={onClose} disabled={loading}>
                    Cancel
                </Button>
                <ConfirmButton
                    variant="contained"
                    color="primary"
                    onClick={onConfirm}
                    loading={loading}
                >
                    Confirm
                </ConfirmButton>
            </DialogActions>
        </Dialog>
    );
};

Confirm.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    danger: PropTypes.bool,
    title: PropTypes.node,
};

Confirm.defaultProps = {
    className: '',
    loading: false,
    danger: false,
    title: null,
};
