export type User = {
    id: string;
    auth0Id?: string;
    signupToken?: string;
    email: string;
    firstName: string;
    lastName: string;
    createdAt: Date;
    lastUpdatedAt: Date;
    /**
     * @deprecated The `admin` flag on users has been deprecated. Instead either use a resource from the permission system (if you are trying to prevent access to an entity) or a feature flag (if you are trying to prevent access to a feature or a new version of an existing feature)
     */
    admin: boolean;
    archived: boolean;
    jobTitle?: string;
    roleId?: string;
};

export const jobTitleChoices = [
    'Executive',
    'Finance / Accounting',
    'Software Engineering',
    'Operations / Customer support',
    'Data',
    'Product',
    'Compliance / Legal',
    'Marketing / Sales',
    'Other',
] as const;
