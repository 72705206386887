import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { makeStyles, InputAdornment, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import TextInput from '../../../../../../forms/TextInput';

const useStyles = makeStyles((theme) => ({
    row: {
        marginTop: theme.spacing(2),
        position: 'relative',
        '&:hover $deleteRow': {
            opacity: 1,
            right: 0,
        },
    },
    reduceSizeOnHover: {
        '&:hover $value': {
            width: 'calc(50% - 25px)',
        },
    },
    operators: {
        width: '50%',
        display: 'inline-block',
        paddingRight: 0,
    },
    operatorsContainer: {
        width: '100%',
        display: 'flex',
    },
    operator: {
        flex: '1 0 0',
        paddingRight: theme.spacing(1),
    },
    value: {
        display: 'inline-block',
        width: '50%',
        transition: 'width 0.3s',
        '& > *': {
            width: '100%',
        },
    },
    deleteRow: {
        position: 'absolute',
        height: 40,
        width: 25,
        opacity: 0,
        transition: 'opacity 0.3s, right 0.3s',
        right: -25,
        top: 8,
        cursor: 'pointer',
        '&:hover': {
            opacity: 1,
            right: 0,
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    deleteRowIcon: {
        width: 16,
        height: 16,
        fill: theme.palette.mainRed,
    },
}));

const adornments = {
    lt: '<',
    lte: '≤',
    gt: '>',
    gte: '≥',
    eq: '=',
};

const labels = {
    lt: 'Less than',
    lte: 'Less than',
    gt: 'Greater than',
    gte: 'Greater than',
    eq: 'Equal to',
};

const IntervalRow = ({ value, onChange, onDelete, disabled }) => {
    const classes = useStyles({ disabled });
    const firstOperatorRef = useRef();

    useEffect(() => {
        if (firstOperatorRef.current) {
            firstOperatorRef.current.focus();
        }
    }, [firstOperatorRef]);

    const handleChangeValue = (key) => (evt) => {
        onChange({
            ...value,
            [key]:
                key === 'label'
                    ? evt.target.value
                    : parseFloat(evt.target.value),
        });
    };

    const handleDeleteKeyPress = (evt) => {
        evt.preventDefault();
        evt.stopPropagation();

        if (['Enter', ' '].includes(evt.key)) {
            onDelete();
        }
    };

    return (
        <div
            className={classnames(classes.row, {
                [classes.reduceSizeOnHover]: !disabled,
            })}
        >
            <div className={classes.operators}>
                <div className={classes.operatorsContainer}>
                    {Object.keys(value)
                        .filter((op) => op !== 'label')
                        .map((operator, i) => (
                            <TextInput
                                label={labels[operator]}
                                key={operator}
                                name={operator}
                                type="number"
                                className={classes.operator}
                                input={{
                                    value: value[operator],
                                    onChange: handleChangeValue(operator),
                                }}
                                disabled={disabled}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {adornments[operator]}
                                        </InputAdornment>
                                    ),
                                }}
                                margin="dense"
                                inputProps={{
                                    'data-testid': `operator-${operator}`,
                                }}
                                inputRef={
                                    i === 0 ? firstOperatorRef : undefined
                                }
                            />
                        ))}
                </div>
            </div>
            <TextInput
                name="label"
                label="Label"
                className={classes.value}
                input={{
                    value: value.label,
                    onChange: handleChangeValue('label'),
                }}
                disabled={disabled}
                margin="dense"
            />
            {!disabled && onDelete && (
                <Tooltip title="Delete this row">
                    <div
                        role="button"
                        tabIndex={0}
                        className={classes.deleteRow}
                        onClick={onDelete}
                        onKeyPress={handleDeleteKeyPress}
                    >
                        <DeleteIcon className={classes.deleteRowIcon} />
                    </div>
                </Tooltip>
            )}
        </div>
    );
};

IntervalRow.propTypes = {
    disabled: PropTypes.bool,
    value: PropTypes.shape({
        label: PropTypes.string,
        lt: PropTypes.number,
        lte: PropTypes.number,
        gt: PropTypes.number,
        gte: PropTypes.number,
        eq: PropTypes.number,
    }),
    onChange: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
};

IntervalRow.defaultProps = {
    disabled: false,
    value: null,
    onDelete: null,
};

export default IntervalRow;
