import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField/TextField';

import { TypeSelect } from './TypeSelect';
import { OptionsInput } from './OptionsInput';

export const SummaryDisplayItemInput = ({ summaryDisplayItem, onChange }) => {
    if (!summaryDisplayItem) {
        return null;
    }

    const onLabelChange = (event) => {
        const newLabel = event.target.value;
        onChange({ label: newLabel });
    };

    const onTypeChange = (type) => {
        onChange({ type });
    };

    const onOptionsChange = (options) => {
        onChange({ options });
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    id={`${summaryDisplayItem.id}.label`}
                    label="Label"
                    fullWidth
                    value={summaryDisplayItem.label}
                    onChange={onLabelChange}
                />
            </Grid>
            <Grid item xs={12}>
                <TypeSelect
                    id={`${summaryDisplayItem.id}.type`}
                    label="Summary Display Type"
                    fullWidth
                    value={summaryDisplayItem.type}
                    onChange={onTypeChange}
                />
            </Grid>
            <Grid item xs={12}>
                <OptionsInput
                    id={`${summaryDisplayItem.id}.options`}
                    fullWidth
                    type={summaryDisplayItem.type}
                    value={summaryDisplayItem.options}
                    onChange={onOptionsChange}
                />
            </Grid>
        </Grid>
    );
};

SummaryDisplayItemInput.propTypes = {
    summaryDisplayItem: PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        type: PropTypes.string,
        options: PropTypes.shape({}),
    }),
    onChange: PropTypes.func.isRequired,
};
