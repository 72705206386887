import { get, isEqual } from 'lodash';
import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Prompt } from 'react-router-dom';

import { Grid, Typography, makeStyles } from '@material-ui/core';
import { Redo, Undo, Grain } from '@material-ui/icons';

import { validateEdge } from '@tint/core/src/validation/validateModelBlueprint';

import getModelOutputType from '@tint/core/src/models/getModelOutputType';
import { MODEL_TYPE_DECISION } from '@tint/core/src/models/types';
import Button from '../Button';
import BlueprintEditor, {
    NewBlockButton,
    useBlueprintEditorState,
    ViewModeInput,
} from './blueprintEditor';
import {
    VIEW_MODES,
    VIEW_MODE_ADVANCED,
} from './blueprintEditor/viewModes/viewModes';
import {
    autoLayout,
    createNode,
    disconnectNodes,
    reorderEdges,
    updateNode,
} from './blueprintEditor/state/actions';
import BlockConfigurationForm from './blueprintEditor/Drawer/BlockConfigurationForm';
import { findAvailableNodeId } from './blueprintEditor/NewBlock/helpers';
import ProcessorChoiceDrawer from './blueprintEditor/Drawer/ProcessorChoiceDrawer';
import {
    EMPTY_BLUEPRINT,
    EMPTY_EDITOR_DATA,
} from './blueprintEditor/state/aggregateBlueprintState';

const isValidConnection =
    (blueprint, insuranceProduct) =>
    ({ source, target, sourceHandle, targetHandle }) => {
        const edge = {
            start: { node: source, pin: sourceHandle },
            end: { node: target, pin: targetHandle },
        };

        const errors = validateEdge(
            blueprint,
            insuranceProduct.validationSchema,
        )(edge);

        return errors.length === 0;
    };

const useStyles = makeStyles((theme) => ({
    toolbar: {
        margin: theme.spacing(2, 2, 0, 2),
    },
    saving: {
        padding: '3px 0',
    },
}));

const initModel = (model) => ({
    ...model,
    blueprint: {
        ...EMPTY_BLUEPRINT,
        ...model.blueprint,
    },
    editorData: {
        ...EMPTY_EDITOR_DATA,
        ...model.editorData,
    },
});

const ModelEditor = ({
    model,
    readOnly,
    onSave,
    insuranceProduct,
    initialViewMode,
    editorClassName,
    showRestrictedProcessors,
    saving,
    ReadOnlyLink,
}) => {
    const initializedModel = initModel(model);

    const classes = useStyles();
    const editorRef = useRef();
    const [selectedBlockId, setSelectedBlockId] = useState(null);
    const [processorMenu, setProcessorMenu] = useState({
        enabled: false,
    });
    const [viewMode, setViewMode] = useState(initialViewMode);
    const [connectingInputGroup, setConnectingInputGroup] = useState(null);
    const [cameraPosition, setCameraPosition] = useState({
        x: 0,
        y: 0,
        zoom: 1,
    });

    const [displayValidation, setDisplayValidation] = useState(
        Object.keys(initializedModel.blueprint.nodes).length > 0,
    );

    const {
        blueprint,
        editorData,
        dispatch: dispatchEvent,
        undo: undoEvent,
        redo: redoEvent,
        undoAble,
        redoAble,
        errors,
    } = useBlueprintEditorState(
        initializedModel.blueprint,
        initializedModel.editorData,
        insuranceProduct.validationSchema,
    );

    const selectedNode = get(blueprint, `nodes["${selectedBlockId}"]`, null);

    const onViewModeChange = (value) => {
        setViewMode(value);
    };

    useEffect(() => {
        if (
            (!isEqual(blueprint, initializedModel.blueprint) ||
                !isEqual(editorData, initializedModel.editorData)) &&
            !readOnly &&
            onSave
        ) {
            onSave({ blueprint, editorData });
            if (!displayValidation) {
                setDisplayValidation(true);
            }
        }
    }, [blueprint, editorData]);

    useEffect(() => {
        const handleSpecialKeys = (evt) => {
            const key = (evt.key || '').toLowerCase();

            if (
                (evt.shiftKey && evt.ctrlKey && key === 'z') ||
                (evt.ctrlKey && key === 'y')
            ) {
                redoEvent();
                return;
            }

            if (evt.ctrlKey && key === 'z') {
                undoEvent();
            }
        };

        window.document.addEventListener('keydown', handleSpecialKeys);

        return () => {
            window.document.removeEventListener('keydown', handleSpecialKeys);
        };
    }, [blueprint, editorData]);

    const openProcessorMenu = (coordinates) =>
        setProcessorMenu({ enabled: true, coordinates });

    const onAutoLayoutClick = () => {
        dispatchEvent(autoLayout({ viewMode }));
    };

    const onNewProcessorNode = (node) => {
        if (readOnly) {
            return;
        }

        const id = findAvailableNodeId(blueprint, node);

        dispatchEvent(createNode(id, node, processorMenu.coordinates));
        setProcessorMenu({ enabled: false });
        setSelectedBlockId(id);
    };

    const onNewProcessorDrawerClose = () => {
        setProcessorMenu({ enabled: false });
    };

    const onNodeConfigurationSubmit = ({ node, editor }) => {
        setSelectedBlockId(null);
        if (!isEqual(node, selectedNode)) {
            dispatchEvent(updateNode(selectedBlockId, node, editor));
        }
    };

    const onDisconnect = (connection) => {
        dispatchEvent(disconnectNodes(connection));
    };

    const onReorderEdges = (connection) => {
        dispatchEvent(reorderEdges(connection));
    };

    const modelOutputType = getModelOutputType(blueprint);

    return (
        <>
            <Prompt
                when={saving}
                message="The model has not been saved, are you sure you want to leave the editor? Latest changes might be lost."
            />
            <Grid
                container
                direction="column"
                spacing={2}
                className={classes.root}
            >
                <Grid item xs={12} className={classes.toolbar}>
                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item>
                            {!readOnly && (
                                <Grid container spacing={1} alignItems="center">
                                    {viewMode === VIEW_MODE_ADVANCED && (
                                        <Grid item>
                                            <NewBlockButton
                                                blueprint={blueprint}
                                                editorData={editorData}
                                                onOpenProcessorMenu={
                                                    openProcessorMenu
                                                }
                                                modelOutputType={
                                                    modelOutputType
                                                }
                                                dispatchEvent={dispatchEvent}
                                                cameraPosition={cameraPosition}
                                                viewport={
                                                    editorRef.current
                                                        ? editorRef.current.getBoundingClientRect()
                                                        : null
                                                }
                                                onSelect={setSelectedBlockId}
                                                disabled={
                                                    !!connectingInputGroup
                                                }
                                            />
                                        </Grid>
                                    )}
                                    {undoAble && (
                                        <Grid item>
                                            <Button
                                                onClick={undoEvent}
                                                startIcon={
                                                    <Undo fontSize="large" />
                                                }
                                            >
                                                Undo
                                            </Button>
                                        </Grid>
                                    )}
                                    {redoAble && (
                                        <Grid item>
                                            <Button
                                                onClick={redoEvent}
                                                startIcon={
                                                    <Redo fontSize="large" />
                                                }
                                            >
                                                Redo
                                            </Button>
                                        </Grid>
                                    )}
                                    <Grid item>
                                        <Button
                                            onClick={onAutoLayoutClick}
                                            startIcon={
                                                <Grain fontSize="large" />
                                            }
                                        >
                                            Autolayout
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        {saving && (
                                            <Typography
                                                className={classes.saving}
                                            >
                                                Saving...
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                        {modelOutputType === MODEL_TYPE_DECISION && (
                            <Grid item>
                                <ViewModeInput
                                    viewMode={viewMode}
                                    onChange={onViewModeChange}
                                    disabled={!!connectingInputGroup}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid item className={editorClassName}>
                    <BlueprintEditor
                        ref={editorRef}
                        blueprint={blueprint}
                        editorData={editorData}
                        disabled={readOnly}
                        onSelect={setSelectedBlockId}
                        viewMode={viewMode}
                        dispatchEvent={dispatchEvent}
                        cameraPosition={cameraPosition}
                        onCameraMove={setCameraPosition}
                        connectingInputGroup={connectingInputGroup}
                        setConnectingInputGroup={setConnectingInputGroup}
                        errors={errors}
                        isValidConnection={isValidConnection(
                            blueprint,
                            insuranceProduct,
                        )}
                        displayValidation={displayValidation}
                        onOpenProcessorMenu={openProcessorMenu}
                    />
                    <BlockConfigurationForm
                        editorContainer={editorRef.current}
                        nodeId={selectedBlockId}
                        node={selectedNode}
                        blueprint={blueprint}
                        editorData={editorData}
                        errors={errors}
                        insuranceProduct={insuranceProduct}
                        disabled={readOnly}
                        onClose={onNodeConfigurationSubmit}
                        onDisconnect={onDisconnect}
                        onReorderEdges={onReorderEdges}
                        isOutputNode={
                            !!selectedNode &&
                            get(editorData, 'outputNodes', []).includes(
                                selectedBlockId,
                            )
                        }
                        showRestrictedConfiguration={showRestrictedProcessors}
                        modelId={model.id}
                        ReadOnlyLink={ReadOnlyLink}
                    />
                    <ProcessorChoiceDrawer
                        opened={processorMenu.enabled}
                        onSubmit={onNewProcessorNode}
                        onClose={onNewProcessorDrawerClose}
                        showRestrictedProcessors={showRestrictedProcessors}
                    />
                </Grid>
            </Grid>
        </>
    );
};

ModelEditor.propTypes = {
    model: PropTypes.object.isRequired,
    readOnly: PropTypes.bool,
    onSave: PropTypes.func,
    saving: PropTypes.bool,
    insuranceProduct: PropTypes.shape({
        id: PropTypes.string.isRequired,
        validationSchema: PropTypes.object.isRequired,
    }).isRequired,
    initialViewMode: PropTypes.oneOf(VIEW_MODES),
    editorClassName: PropTypes.string,
    showRestrictedProcessors: PropTypes.bool,
    ReadOnlyLink: PropTypes.elementType,
};

ModelEditor.defaultProps = {
    readOnly: false,
    saving: false,
    onSave: () => {},
    initialViewMode: VIEW_MODE_ADVANCED,
    editorClassName: null,
    showRestrictedProcessors: false,
    ReadOnlyLink: null,
};

export default ModelEditor;
