// Need help for typing the validation/processors directory
import { isNil } from 'lodash';
import OUTPUT_TYPES from '../outputTypes';
import validator from './validate';

export type ConstantProcessorConfig<Value> = {
    value?: Value;
};

export type ConstantProcessorOutput<Value> = {
    output: Value;
};

export type ConstantProcessor = <Value>(
    config?: ConstantProcessorConfig<Value>,
) => () => ConstantProcessorOutput<Value>;

export function getType(arg: {
    configuration: ConstantProcessorConfig<null>;
}): null;
export function getType(arg: {
    configuration: ConstantProcessorConfig<undefined>;
}): null;
export function getType(arg: {
    configuration: ConstantProcessorConfig<''>;
}): null;
export function getType(arg: {
    configuration: ConstantProcessorConfig<string>;
}): OUTPUT_TYPES.STRING;
export function getType(arg: {
    configuration: ConstantProcessorConfig<number>;
}): OUTPUT_TYPES.NUMBER;
export function getType(arg: {
    configuration: ConstantProcessorConfig<boolean>;
}): OUTPUT_TYPES.BOOLEAN;
export function getType({
    configuration,
}: {
    configuration: ConstantProcessorConfig<unknown>;
}): OUTPUT_TYPES | null {
    const { value } = configuration;
    if (isNil(value) || value === '') {
        return null;
    }

    if (typeof configuration.value === 'boolean') {
        return OUTPUT_TYPES.BOOLEAN;
    }

    return typeof configuration.value === 'string'
        ? OUTPUT_TYPES.STRING
        : OUTPUT_TYPES.NUMBER;
}

const constantProcessor = {
    name: 'Constant',
    slug: 'constant',
    description: 'Static value, useful to specify rates, flat values, etc.',
    validate: validator,
    inputs: [] as never[],
    outputs: [
        {
            id: 'output',
            name: 'Output',
            getType,
        },
    ],
};

export default constantProcessor;
