const violationsPerTypes = {
    Accident: [
        {
            'Risk Type': 'ACC',
            'Record Type': 'Accident',
            'Record Name': 'ACCIDENT',
            'Record Code': 'AA01',
        },
        {
            'Risk Type': 'ACC',
            'Record Type': 'Accident',
            'Record Name': 'ACCIDENT - FATAL',
            'Record Code': 'AA02',
        },
        {
            'Risk Type': 'ACC',
            'Record Type': 'Accident',
            'Record Name': 'ACCIDENT - PERSONAL INJURY',
            'Record Code': 'AA03',
        },
        {
            'Risk Type': 'ACC',
            'Record Type': 'Accident',
            'Record Name': 'ACCIDENT - PROPERTY DAMAGE',
            'Record Code': 'AA04',
        },
        {
            'Risk Type': 'ACC',
            'Record Type': 'Accident',
            'Record Name': 'ACCIDENT AT FAULT - PERSONAL INJURY',
            'Record Code': 'AA05',
        },
        {
            'Risk Type': 'ACC',
            'Record Type': 'Accident',
            'Record Name': 'ACCIDENT AT FAULT - PROPERTY DAMAGE',
            'Record Code': 'AA06',
        },
        {
            'Risk Type': 'ACC',
            'Record Type': 'Accident',
            'Record Name': 'MISC ACCIDENT RELATED',
            'Record Code': 'AE01',
        },
        {
            'Risk Type': 'ACC',
            'Record Type': 'Accident',
            'Record Name': 'VIOL RESULTING IN FATAL ACCIDENT',
            'Record Code': 'AE04',
        },
        {
            'Risk Type': 'ACC',
            'Record Type': 'Accident',
            'Record Name': 'VIOL RESULTING IN INJURY ACCIDENT',
            'Record Code': 'AE05',
        },
        {
            'Risk Type': 'ACC',
            'Record Type': 'Accident',
            'Record Name': 'VIOL RESULTING IN PROP DMG ACCIDENT',
            'Record Code': 'AE06',
        },
        {
            'Risk Type': 'ACC',
            'Record Type': 'Accident',
            'Record Name': 'VIOLATION RESULTING IN ACCIDENT',
            'Record Code': 'AE03',
        },
    ],
    Bicycle: [
        {
            'Risk Type': 'N/A',
            'Record Type': 'Bicycle',
            'Record Name': 'BICYCLE-HANDLEBAR HEIGHT VIOLATION',
            'Record Code': 'UC04',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Bicycle',
            'Record Name': 'BICYCLE-HITCHING RIDE ON OTHER VEH',
            'Record Code': 'UC06',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Bicycle',
            'Record Name': 'BICYCLE-LICENSE REQMNT VIOLATION',
            'Record Code': 'UC09',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Bicycle',
            'Record Name': 'BICYCLE-OP WHERE UNSAFE OR PROH',
            'Record Code': 'UC05',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Bicycle',
            'Record Name': 'BICYCLE-OP WITHOUT REQUIRED EQUIP',
            'Record Code': 'UC03',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Bicycle',
            'Record Name': 'BICYCLE-RIDING UNDER THE INFLUENCE',
            'Record Code': 'UC02',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Bicycle',
            'Record Name': 'BICYCLE-TRAVELING AGAINST TRAFFIC',
            'Record Code': 'UC08',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Bicycle',
            'Record Name': 'MISCELLANEOUS BICYCLE',
            'Record Code': 'UC01',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Bicycle',
            'Record Name': 'TOO MANY ON BICYCLE',
            'Record Code': 'UC07',
        },
    ],
    'Careless / Distracted': [
        {
            'Risk Type': 'Minor',
            'Record Type': 'Careless / Distracted',
            'Record Name': 'CARELESS DRIVING',
            'Record Code': 'MK02',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Careless / Distracted',
            'Record Name': 'DRIVING TO ENDANGER',
            'Record Code': 'MK06',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Careless / Distracted',
            'Record Name': 'INATTENTIVE DRIVING',
            'Record Code': 'MK03',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Careless / Distracted',
            'Record Name': 'NEGLIGENT DRIVING',
            'Record Code': 'MK04',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Careless / Distracted',
            'Record Name': 'NEGLIGENT DRIVING REDUCED FROM DUI',
            'Record Code': 'MK07',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Careless / Distracted',
            'Record Name': 'NEGLIGENT EMER VEH - DUE CARE TO PE',
            'Record Code': 'MK08',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Careless / Distracted',
            'Record Name': 'RECKLESS, CARELESS, OR NEGLIGENT DR',
            'Record Code': 'MK01',
        },
    ],
    'Disobey Police / Fire': [
        {
            'Risk Type': 'Minor',
            'Record Type': 'Disobey Police / Fire',
            'Record Name': 'EVADING ARREST',
            'Record Code': 'UG22',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Disobey Police / Fire',
            'Record Name': 'FLEE/EVADING POLICE OR ROADBLOCK',
            'Record Code': 'UG20',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Disobey Police / Fire',
            'Record Name': 'FTO POLICE OR PEACE OFFICER',
            'Record Code': 'MA08',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Disobey Police / Fire',
            'Record Name': 'RESIST/DELAY/OBSTRUCT OFFICER',
            'Record Code': 'UG15',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Disobey Police / Fire',
            'Record Name': 'RESISTING ARREST',
            'Record Code': 'UG21',
        },
    ],
    'Driving Under the Influence': [
        {
            'Risk Type': 'Major',
            'Record Type': 'Driving Under the Influence',
            'Record Name': 'ADMINISTRATIVE PER SE FOR .XX BAC',
            'Record Code': 'CB10',
        },
        {
            'Risk Type': 'Major',
            'Record Type': 'Driving Under the Influence',
            'Record Name': 'DRIVING UNDER THE INFLUENCE',
            'Record Code': 'CA02',
        },
        {
            'Risk Type': 'Major',
            'Record Type': 'Driving Under the Influence',
            'Record Name': 'DRIVING WHILE IMPAIRED',
            'Record Code': 'CB13',
        },
        {
            'Risk Type': 'Major',
            'Record Type': 'Driving Under the Influence',
            'Record Name': 'DUI - ALCOHOL',
            'Record Code': 'CB01',
        },
        {
            'Risk Type': 'Major',
            'Record Type': 'Driving Under the Influence',
            'Record Name': 'DUI - BAC LESS THAN .15%',
            'Record Code': 'CB09',
        },
        {
            'Risk Type': 'Major',
            'Record Type': 'Driving Under the Influence',
            'Record Name': 'DUI - BAC OVER .04%',
            'Record Code': 'CB02',
        },
        {
            'Risk Type': 'Major',
            'Record Type': 'Driving Under the Influence',
            'Record Name': 'DUI - BAC OVER .08%',
            'Record Code': 'CB03',
        },
        {
            'Risk Type': 'Major',
            'Record Type': 'Driving Under the Influence',
            'Record Name': 'DUI - BAC OVER .10%',
            'Record Code': 'CB04',
        },
        {
            'Risk Type': 'Major',
            'Record Type': 'Driving Under the Influence',
            'Record Name': 'DUI - BAC OVER .12%',
            'Record Code': 'CB05',
        },
        {
            'Risk Type': 'Major',
            'Record Type': 'Driving Under the Influence',
            'Record Name': 'DUI - BAC OVER .15%',
            'Record Code': 'CB06',
        },
        {
            'Risk Type': 'Major',
            'Record Type': 'Driving Under the Influence',
            'Record Name': 'DUI - BAC OVER .20%',
            'Record Code': 'CB07',
        },
        {
            'Risk Type': 'Major',
            'Record Type': 'Driving Under the Influence',
            'Record Name': 'DUI - BAC OVER XX',
            'Record Code': 'CB08',
        },
        {
            'Risk Type': 'Major',
            'Record Type': 'Driving Under the Influence',
            'Record Name': 'DUI - DRUGS',
            'Record Code': 'CC01',
        },
        {
            'Risk Type': 'Major',
            'Record Type': 'Driving Under the Influence',
            'Record Name': 'DUI - SUBST NOT INTENDED TO INTOX',
            'Record Code': 'CA06',
        },
        {
            'Risk Type': 'Major',
            'Record Type': 'Driving Under the Influence',
            'Record Name': 'DUI OF ALCOHOL AND/OR DRUGS',
            'Record Code': 'CA04',
        },
        {
            'Risk Type': 'Major',
            'Record Type': 'Driving Under the Influence',
            'Record Name': 'DUI RESULTING IN DEATH/INJURY',
            'Record Code': 'CA09',
        },
        {
            'Risk Type': 'Major',
            'Record Type': 'Driving Under the Influence',
            'Record Name': 'DUI WITH ACCIDENT',
            'Record Code': 'CA08',
        },
        {
            'Risk Type': 'Major',
            'Record Type': 'Driving Under the Influence',
            'Record Name': 'FAILED CHEMICAL TEST',
            'Record Code': 'CB18',
        },
        {
            'Risk Type': 'Major',
            'Record Type': 'Driving Under the Influence',
            'Record Name': 'REFUSED TO SUBMIT TO TEST',
            'Record Code': 'CA05',
        },
        {
            'Risk Type': 'Major',
            'Record Type': 'Driving Under the Influence',
            'Record Name': 'SPECIAL DUI',
            'Record Code': 'CA01',
        },
        {
            'Risk Type': 'Major',
            'Record Type': 'Driving Under the Influence',
            'Record Name': 'UNDERAGE CONVICTION OF DUI .02%',
            'Record Code': 'CB11',
        },
        {
            'Risk Type': 'Major',
            'Record Type': 'Driving Under the Influence',
            'Record Name': 'UNDERAGE DRIVING UNDER THE INFLUENC',
            'Record Code': 'CA03',
        },
    ],
    'Driving While Invalid': [
        {
            'Risk Type': 'Minor',
            'Record Type': 'Driving While Invalid',
            'Record Name': 'DRIVING WHILE LICENSE BARRED',
            'Record Code': 'DB03',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Driving While Invalid',
            'Record Name': 'DRIVING WHILE LICENSE CANCELLED',
            'Record Code': 'DB04',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Driving While Invalid',
            'Record Name': 'DRIVING WHILE LICENSE DENIED',
            'Record Code': 'DB05',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Driving While Invalid',
            'Record Name': 'DRIVING WHILE LICENSE DISQUALIFIED',
            'Record Code': 'DB06',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Driving While Invalid',
            'Record Name': 'DRIVING WHILE LICENSE REVOKED',
            'Record Code': 'DB07',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Driving While Invalid',
            'Record Name': 'DRIVING WHILE LICENSE SUSPENDED',
            'Record Code': 'DB08',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Driving While Invalid',
            'Record Name': 'DRIVING WHILE LICENSE WITHDRAWN',
            'Record Code': 'DB02',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Driving While Invalid',
            'Record Name': 'DRV WHILE LICENSE SUSP OR RVKD',
            'Record Code': 'DB09',
        },
    ],
    'Drugs / Alcohol': [
        {
            'Risk Type': 'N/A',
            'Record Type': 'Drugs / Alcohol',
            'Record Name': 'ALCOHOL/DRUGS POSSESSION/SALE',
            'Record Code': 'CA07',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Drugs / Alcohol',
            'Record Name': 'DRINK ALCOHOL WHILE OP VEHICLE',
            'Record Code': 'CB14',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Drugs / Alcohol',
            'Record Name': 'ILL SALE/POSS/TRANSPORT ALCOHOL',
            'Record Code': 'CB16',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Drugs / Alcohol',
            'Record Name': 'POSSESSION OF OPEN CONTAINER',
            'Record Code': 'CB12',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Drugs / Alcohol',
            'Record Name': 'SALE/POSS/TRANSPORT DRUGS',
            'Record Code': 'CC02',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Drugs / Alcohol',
            'Record Name': 'SALE/POSS/USE DRUG PARAPHERNALIA',
            'Record Code': 'CC03',
        },
    ],
    'Failure To Appear': [
        {
            'Risk Type': 'N/A',
            'Record Type': 'Failure To Appear',
            'Record Name': 'FAILURE TO APPEAR',
            'Record Code': 'UE02',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Failure To Appear',
            'Record Name': 'FAILURE TO COMPLY WITH COURT ORDER',
            'Record Code': 'UE01',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Failure To Appear',
            'Record Name': 'FTA FOR TRIAL OR COURT',
            'Record Code': 'UE07',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Failure To Appear',
            'Record Name': 'FTA-HEARING/MANDATORY APPEARANCE',
            'Record Code': 'UE03',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Failure To Appear',
            'Record Name': 'FTA/COMPLETE DEPT INVESTIGATION',
            'Record Code': 'UE04',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Failure To Appear',
            'Record Name': 'FTA/COMPLETE EXAM/RE-EXAM',
            'Record Code': 'UE05',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Failure To Appear',
            'Record Name': 'FTA/COMPLETE REQUIRED COURSES',
            'Record Code': 'UE06',
        },
    ],
    'Failure To Yield': [
        {
            'Risk Type': 'Minor',
            'Record Type': 'Failure To Yield',
            'Record Name': 'FAILURE TO OBEY YIELD SIGN',
            'Record Code': 'MA19',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Failure To Yield',
            'Record Name': 'FAILURE TO YIELD RIGHT OF WAY',
            'Record Code': 'MC01',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Failure To Yield',
            'Record Name': 'FTY ROW AT CROSSWALK',
            'Record Code': 'MC10',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Failure To Yield',
            'Record Name': 'FTY ROW AT INTERSECTION',
            'Record Code': 'MC15',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Failure To Yield',
            'Record Name': 'FTY ROW AT ROTARY INTERSECTION',
            'Record Code': 'MC11',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Failure To Yield',
            'Record Name': 'FTY ROW AT STOP SIGN',
            'Record Code': 'MC12',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Failure To Yield',
            'Record Name': 'FTY ROW AT TRAFFIC SIGN',
            'Record Code': 'MC13',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Failure To Yield',
            'Record Name': 'FTY ROW AT TRAFFIC SIGNAL',
            'Record Code': 'MC14',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Failure To Yield',
            'Record Name': 'FTY ROW AT YIELD SIGN',
            'Record Code': 'MC16',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Failure To Yield',
            'Record Name': 'FTY ROW ENTER FROM PRIVATE ROAD/DRI',
            'Record Code': 'MC20',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Failure To Yield',
            'Record Name': 'FTY ROW TO ANIMAL RIDER/VEH',
            'Record Code': 'MC02',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Failure To Yield',
            'Record Name': 'FTY ROW TO CYCLIST',
            'Record Code': 'MC03',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Failure To Yield',
            'Record Name': 'FTY ROW TO EMERGENCY VEHICLE',
            'Record Code': 'MC04',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Failure To Yield',
            'Record Name': 'FTY ROW TO FUNERAL OR PARADE',
            'Record Code': 'MC05',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Failure To Yield',
            'Record Name': 'FTY ROW TO OTHER VEHICLE',
            'Record Code': 'MC06',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Failure To Yield',
            'Record Name': 'FTY ROW TO OVERTAKING VEHICLE',
            'Record Code': 'MC07',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Failure To Yield',
            'Record Name': 'FTY ROW TO PEDESTRIAN',
            'Record Code': 'MC08',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Failure To Yield',
            'Record Name': 'FTY ROW TO PEDESTRIAN-BLIND',
            'Record Code': 'MC19',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Failure To Yield',
            'Record Name': 'FTY ROW TO SCHOOL BUS',
            'Record Code': 'MC09',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Failure To Yield',
            'Record Name': 'FTY ROW WHEN TURNING',
            'Record Code': 'MC18',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Failure To Yield',
            'Record Name': 'FTY ROW WHEN WARNING DISPLAYED',
            'Record Code': 'MC17',
        },
    ],
    'Financial Responsibility': [
        {
            'Risk Type': 'N/A',
            'Record Type': 'Financial Responsibility',
            'Record Name': 'FAIL TO COMPLY WITH FR LAWS',
            'Record Code': 'DE05',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Financial Responsibility',
            'Record Name': 'FAIL TO MAINTAIN REQD INSURANCE',
            'Record Code': 'DE06',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Financial Responsibility',
            'Record Name': 'FAIL TO PAY DAMAGES/MAKE INSTL PYMT',
            'Record Code': 'DE08',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Financial Responsibility',
            'Record Name': 'FAIL TO POST SEC/GET REL FROM LIAB',
            'Record Code': 'DE09',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Financial Responsibility',
            'Record Name': 'FAILED TO FILE FUTURE PROOF OF FR',
            'Record Code': 'DE01',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Financial Responsibility',
            'Record Name': 'FAILED TO FILE INS CERTIFICATION',
            'Record Code': 'DE02',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Financial Responsibility',
            'Record Name': 'FAILED TO SHOW EVIDENCE OF FR/INS',
            'Record Code': 'DE03',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Financial Responsibility',
            'Record Name': 'UNSATISFIED JUDGEMENT',
            'Record Code': 'DE10',
        },
    ],
    'Following Too Closely': [
        {
            'Risk Type': 'Minor',
            'Record Type': 'Following Too Closely',
            'Record Name': 'FAIL TO LEAVE SUFFICIENT DISTANCE',
            'Record Code': 'ME02',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Following Too Closely',
            'Record Name': 'FOLLOW EMERGENCY VEHICLE UNLAWFULLY',
            'Record Code': 'ME03',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Following Too Closely',
            'Record Name': 'FOLLOWING FIRE EQUIPMENT UNLAWFULLY',
            'Record Code': 'ME04',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Following Too Closely',
            'Record Name': 'FOLLOWING IMPROPERLY',
            'Record Code': 'ME01',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Following Too Closely',
            'Record Name': 'FOLLOWING TOO CLOSELY',
            'Record Code': 'ME05',
        },
    ],
    'Hazardous Materials': [
        {
            'Risk Type': 'N/A',
            'Record Type': 'Hazardous Materials',
            'Record Name': 'DEF/IMPR/INADQ HAZMAT SFTY DEVICES',
            'Record Code': 'EI01',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Hazardous Materials',
            'Record Name': 'FAILURE TO OBEY HAZMAT REQUIREMENTS',
            'Record Code': 'MA20',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Hazardous Materials',
            'Record Name': 'FAILURE TO USE HAZMAT SAFETY DEVICE',
            'Record Code': 'EI04',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Hazardous Materials',
            'Record Name': 'IMP TRANS HAZMAT/EXPLOSIVES',
            'Record Code': 'EI05',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Hazardous Materials',
            'Record Name': 'OP W/OUT HAZMAT PLACARDS/MARKS/LIC',
            'Record Code': 'EI03',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Hazardous Materials',
            'Record Name': 'OP WITHOUT HAZMAT SAFETY EQUIPMENT',
            'Record Code': 'EI02',
        },
    ],
    'Hit And Run': [
        {
            'Risk Type': 'Major',
            'Record Type': 'Hit And Run',
            'Record Name': 'FAIL TO PROVIDE INFO - INJURY/FATAL',
            'Record Code': 'AD02',
        },
        {
            'Risk Type': 'Major',
            'Record Type': 'Hit And Run',
            'Record Name': 'FAIL TO PROVIDE INFO - PROP DAMAGE',
            'Record Code': 'AD03',
        },
        {
            'Risk Type': 'Major',
            'Record Type': 'Hit And Run',
            'Record Name': 'FAIL TO PROVIDE INFO - UNATTEND VEH',
            'Record Code': 'AD04',
        },
        {
            'Risk Type': 'Major',
            'Record Type': 'Hit And Run',
            'Record Name': 'FAIL TO PROVIDE INFORMATION',
            'Record Code': 'AD01',
        },
        {
            'Risk Type': 'Major',
            'Record Type': 'Hit And Run',
            'Record Name': 'FAILURE TO STOP AND RENDER AID',
            'Record Code': 'AE02',
        },
        {
            'Risk Type': 'Major',
            'Record Type': 'Hit And Run',
            'Record Name': 'HIT AND RUN',
            'Record Code': 'AB01',
        },
        {
            'Risk Type': 'Major',
            'Record Type': 'Hit And Run',
            'Record Name': 'HIT AND RUN - INJURY',
            'Record Code': 'AB03',
        },
        {
            'Risk Type': 'Major',
            'Record Type': 'Hit And Run',
            'Record Name': 'HIT AND RUN - INJURY OR DEATH',
            'Record Code': 'AB02',
        },
        {
            'Risk Type': 'Major',
            'Record Type': 'Hit And Run',
            'Record Name': 'HIT AND RUN - PROPERTY DAMAGE',
            'Record Code': 'AB04',
        },
        {
            'Risk Type': 'Major',
            'Record Type': 'Hit And Run',
            'Record Name': 'LEAVE SCENE OF ACCIDENT - ANIMAL',
            'Record Code': 'AC04',
        },
        {
            'Risk Type': 'Major',
            'Record Type': 'Hit And Run',
            'Record Name': 'LEAVE SCENE OF ACCIDENT-INJ/FATAL',
            'Record Code': 'AC02',
        },
        {
            'Risk Type': 'Major',
            'Record Type': 'Hit And Run',
            'Record Name': 'LEAVE SCENE OF ACCIDENT-PROP DAMAGE',
            'Record Code': 'AC03',
        },
        {
            'Risk Type': 'Major',
            'Record Type': 'Hit And Run',
            'Record Name': 'LEAVING THE SCENE OF ACCIDENT',
            'Record Code': 'AC01',
        },
    ],
    'Lane Usage': [
        {
            'Risk Type': 'Minor',
            'Record Type': 'Lane Usage',
            'Record Name': 'FAILURE TO KEEP IN PROPER LANE',
            'Record Code': 'MB02',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Lane Usage',
            'Record Name': 'IMPROPER LANE OR LOCATION',
            'Record Code': 'MB01',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Lane Usage',
            'Record Name': 'IMPROPER LANE OR LOCATION - MEDIAN',
            'Record Code': 'MB12',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Lane Usage',
            'Record Name': 'IMPROPER LANE/LOC - BIKE LANE',
            'Record Code': 'MB08',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Lane Usage',
            'Record Name': 'IMPROPER LANE/LOC - FIRE HOSE',
            'Record Code': 'MB18',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Lane Usage',
            'Record Name': 'IMPROPER LANE/LOC - NATION NETWORK',
            'Record Code': 'MB14',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Lane Usage',
            'Record Name': 'IMPROPER LANE/LOC - OFF THE ROAD',
            'Record Code': 'MB20',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Lane Usage',
            'Record Name': 'IMPROPER LANE/LOC - SLOW LANE',
            'Record Code': 'MB21',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Lane Usage',
            'Record Name': 'IMPROPER LANE/LOC - TRUCK ROUTE',
            'Record Code': 'MB16',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Lane Usage',
            'Record Name': 'IMPROPER LANE/LOC -CENTER LINES',
            'Record Code': 'MB22',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Lane Usage',
            'Record Name': 'IMPROPER LANE/LOC -ON RAMP',
            'Record Code': 'MB07',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Lane Usage',
            'Record Name': 'IMPROPER LANE/LOC CROSSOVER',
            'Record Code': 'MB05',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Lane Usage',
            'Record Name': 'IMPROPER LANE/LOC CROSSWALK',
            'Record Code': 'MB06',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Lane Usage',
            'Record Name': 'IMPROPER LANE/LOC LTD ACCESS HWY',
            'Record Code': 'MB11',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Lane Usage',
            'Record Name': 'IMPROPER LANE/LOC MOUNTAIN ROAD',
            'Record Code': 'MB24',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Lane Usage',
            'Record Name': 'IMPROPER LANE/LOC OCCUPIED LANE',
            'Record Code': 'MB09',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Lane Usage',
            'Record Name': 'IMPROPER LANE/LOC SUICIDE LANE',
            'Record Code': 'MB23',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Lane Usage',
            'Record Name': 'IMPROPER OR ERRATIC LANE CHANGE',
            'Record Code': 'MB03',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Lane Usage',
            'Record Name': 'IMPRPR LANE/LOC - IN HOV/RESTR LANE',
            'Record Code': 'MB10',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Lane Usage',
            'Record Name': 'IMPRPR LANE/LOC - NOT ON AUTH ROUTE',
            'Record Code': 'MB15',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Lane Usage',
            'Record Name': 'IMPRPR LANE/LOC FOR MOPED',
            'Record Code': 'MB13',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Lane Usage',
            'Record Name': 'MC: ROADWAYS LANED FOR TRAFFIC',
            'Record Code': 'VA09',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Lane Usage',
            'Record Name': 'RAN OFF ROAD',
            'Record Code': 'MB04',
        },
    ],
    'License (Non-Driving)': [
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': '2 SERIOUS VIOL WITHIN 3 YEARS',
            'Record Code': 'UH20',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': '3 SERIOUS VIOL WITHIN 3 YEARS',
            'Record Code': 'UH21',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'ACCUM OF CONVICTIONS/HABITUAL OFFEN',
            'Record Code': 'UH19',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'ADMINISTRATIVE REVIEW',
            'Record Code': 'DB36',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'CANCELLATION OF ENDORSEMENT',
            'Record Code': 'DB34',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'CDL 120 DAY DISQUAL: 3CONV/3YEARS',
            'Record Code': 'KC21',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'CDL 24 HR: DETECTABLE ALCOHOL',
            'Record Code': 'KC02',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'CDL 3YR DISQ: BAC.04+ HAZ MAT',
            'Record Code': 'KC05',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'CDL 3YR DISQ: BAC.10+ HAZ MAT',
            'Record Code': 'KC06',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'CDL 3YR DISQ: DUI C/SUBST HZ',
            'Record Code': 'KC08',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'CDL 3YR DISQ: INV.FELONY HZ',
            'Record Code': 'KC11',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'CDL 3YR DISQ: INV.FELONY HZ',
            'Record Code': 'KC12',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'CDL 3YR DISQ: LVE SCENE HZ',
            'Record Code': 'KC14',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'CDL 3YR DISQ: TST REFUSE HAZM',
            'Record Code': 'KC17',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'CDL DISQUAL: 2 SERIOUS CONV',
            'Record Code': 'KC20',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'CDL DISQUAL: DUI CTRL SUBSTA',
            'Record Code': 'KC07',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'CDL DISQUAL: GENERALLY',
            'Record Code': 'KC01',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'CDL DISQUAL: INVOLV. IN FELONY',
            'Record Code': 'KC09',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'CDL DISQUAL: INVOLV. IN FELONY',
            'Record Code': 'KC10',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'CDL DISQUAL: LEAVE SCENE ACC',
            'Record Code': 'KC13',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'CDL DISQUAL: MANSLAUGHTER HM',
            'Record Code': 'KC15',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'CDL DISQUAL:CHEM TST REFUSAL',
            'Record Code': 'KC16',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'CDL DISQUALIFICATION: BAC .04+',
            'Record Code': 'KC03',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'CDL DISQUALIFICATION: BAC .10+',
            'Record Code': 'KC04',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'CDL LIFE DISQUAL: COMB.C11-C16',
            'Record Code': 'KC18',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'CDL LIFE DISQUAL:CONTROL SUB',
            'Record Code': 'KC19',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'DENIAL GENERAL',
            'Record Code': 'DB59',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'DISQUALIFICATION',
            'Record Code': 'DB28',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'DRIVING WHILE LICENSE EXPIRED',
            'Record Code': 'DB01',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'EXPIRED OR NO DL/PERMIT/ID',
            'Record Code': 'DB13',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'FAIL TO SURR SUSP/REVK DL/PERMIT/ID',
            'Record Code': 'DB37',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'FAILED FIELD EXAMINATION',
            'Record Code': 'DB55',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'FAILED SPECIAL EXAM/REVIEW',
            'Record Code': 'DB56',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'FAILED TO FILE CHANGE OF ADDR/NAME',
            'Record Code': 'DH01',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'FAILED TO SHOW DL/PERMIT/ID',
            'Record Code': 'DB14',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'FAILED TO SURRENDER DL OR REG',
            'Record Code': 'DH06',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'FAILED TO SURRENDER DL/PERMIT/ID',
            'Record Code': 'DB15',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'FAILED VISION EXAMINATION',
            'Record Code': 'DB57',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'FAILURE TO COMPLY WITH COURT ORDER',
            'Record Code': 'DB54',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'HABITUAL OFFENDER - 3 CONV/ONE YEAR',
            'Record Code': 'UH29',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'HABITUAL OFFENDER - ACCIDENTS',
            'Record Code': 'UH25',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'HABITUAL OFFENDER - FELONY VIOLATIO',
            'Record Code': 'UH27',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'HABITUAL OFFENDER - MISD VIOLATIONS',
            'Record Code': 'UH28',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'HABITUAL OFFENDER - POINTS SYSTEM',
            'Record Code': 'UH24',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'HABITUAL OFFENDER - RECKLESS/NEGIGE',
            'Record Code': 'UH26',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'ID CARD ISSUED WITHDRAWN ETC',
            'Record Code': 'DB38',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'IMPRPR CLASS/ENDORSMT/CERT ON DL',
            'Record Code': 'DB17',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'MINOR-SUSPENSION OR DELAY',
            'Record Code': 'DB33',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'MUTILATED DL/PERMIT/ID',
            'Record Code': 'DB12',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'NO SIGNATURE ON DL/PERMIT/ID',
            'Record Code': 'DB20',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'OUT OF STATE WITHDRAWAL',
            'Record Code': 'DB42',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'POINT SYSTEM SUSP',
            'Record Code': 'DB61',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'POSS/PROVDE CNTRFT/ALTRD DL/PRMT/ID',
            'Record Code': 'DB11',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'PRIVILEGES REINSTATED',
            'Record Code': 'DB35',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'REVOCATION',
            'Record Code': 'DB29',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'REVOCATION DUE TO MAJOR VIOLATION',
            'Record Code': 'DB30',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'SUSP OF ENDORSEMENT',
            'Record Code': 'DB60',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'SUSPENSION',
            'Record Code': 'DB31',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'SUSPENSION DUE TO MAJOR VIOLATION',
            'Record Code': 'DB32',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'UNABLE TO PASS DL TEST/MEET QUAL',
            'Record Code': 'UH12',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'VIO LIC RESTRICTION PROBATION',
            'Record Code': 'UH23',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'VIOL RESTRICTIONS OF DL/PERMIT/ID',
            'Record Code': 'DB27',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'VIOLATE LIMITED LICENSE CONDITIONS',
            'Record Code': 'DB26',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'VIOLATE LIMITED LICENSE CONDITIONS',
            'Record Code': 'DB45',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'VIOLATE RESTRICTIONS OF DL/PERMIT/I',
            'Record Code': 'DB43',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'W/DRWAL REINS/APPEAL DENIED',
            'Record Code': 'DB62',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'WITHDRAWAL (REASON NOT SPECIFIED)',
            'Record Code': 'UH06',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'WITHDRAWAL MEDICAL REASONS/INC',
            'Record Code': 'DB44',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'WITHDRAWAL: SERIOUS VIOLATION',
            'Record Code': 'DB58',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'WITHDRAWN: ACCIDENT INVOLVMENT',
            'Record Code': 'DB51',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'WITHDRAWN: ALCOHOL/DRUGS',
            'Record Code': 'DB53',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'WITHDRAWN: FAIL TO COMPLETE PROGRAM',
            'Record Code': 'DB50',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'WITHDRAWN: FAIL TO PAY TAX',
            'Record Code': 'DB48',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'WITHDRAWN: HABITUAL OFFENDER',
            'Record Code': 'DB52',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'WITHDRAWN: PROVIDED FALSE INFO',
            'Record Code': 'DB47',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'WITHDRAWN: PROVIDED INCORRECT INFO',
            'Record Code': 'DB46',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License (Non-Driving)',
            'Record Name': 'WITHDRAWN: SR-22',
            'Record Code': 'DB49',
        },
    ],
    'License Plate': [
        {
            'Risk Type': 'N/A',
            'Record Type': 'License Plate',
            'Record Name': 'EXPIRED OR NO PLATES/STICKER/DECAL',
            'Record Code': 'DD03',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License Plate',
            'Record Name': 'MISSING/DEFACD/ALTRD/OBSCURD PLATES',
            'Record Code': 'DD02',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'License Plate',
            'Record Name': 'SHOW OR USE IMPROPERLY PLATES/DECAL',
            'Record Code': 'DD01',
        },
    ],
    'Lighting Equipment': [
        {
            'Risk Type': 'N/A',
            'Record Type': 'Lighting Equipment',
            'Record Name': 'DEF/IMPR/INADEQUATE BRAKE LIGHTS',
            'Record Code': 'EF11',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Lighting Equipment',
            'Record Name': 'DEF/IMPR/INADEQUATE FARM EQUIPMENT',
            'Record Code': 'EF13',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Lighting Equipment',
            'Record Name': 'DEF/IMPR/INADEQUATE LIGHTS',
            'Record Code': 'EF01',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Lighting Equipment',
            'Record Name': 'DEF/IMPR/INADEQUATE MARKER LIGHTS',
            'Record Code': 'EF08',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Lighting Equipment',
            'Record Name': 'DEF/IMPR/INADEQUATE PARK LIGHT',
            'Record Code': 'EF10',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Lighting Equipment',
            'Record Name': 'DEF/IMPR/INADEQUATE SIGNAL LIGHTS',
            'Record Code': 'EF09',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Lighting Equipment',
            'Record Name': 'DEF/IMPR/INADEQUATE TAIL LIGHTS',
            'Record Code': 'EF14',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Lighting Equipment',
            'Record Name': 'HEADLIGHTS CAUSE GLARE',
            'Record Code': 'EF12',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Lighting Equipment',
            'Record Name': 'MC: DEF/IMPR/INADEQUATE HEADLIGHT',
            'Record Code': 'VA11',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Lighting Equipment',
            'Record Name': 'MC: DEF/IMPR/INADEQUATE LIGHTS',
            'Record Code': 'VA12',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Lighting Equipment',
            'Record Name': 'USE OF COLORED LIGHTS AND/OR SIREN',
            'Record Code': 'EF03',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Lighting Equipment',
            'Record Name': 'USE OF VEHICLE LIGHTS PROH',
            'Record Code': 'EF04',
        },
    ],
    Miscellaneous: [
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'ABANDONED VEHICLE',
            'Record Code': 'VH02',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'ALLOW INTOX PERSON TO DRIVE',
            'Record Code': 'CB17',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'BAD CHECK',
            'Record Code': 'DE11',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'BATTERY',
            'Record Code': 'UG09',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'BURGLARY',
            'Record Code': 'UG12',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'CARRIER-FAIL TO COMPLY W/ PUC',
            'Record Code': 'UH03',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'COMMERCIAL DRIVER NOTIFICATION REQU',
            'Record Code': 'UH02',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'COURT ORDERS/ADMINISTRATIVE ACTIONS',
            'Record Code': 'UE08',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'DAMAGING OR TAMPERING WITH VEHICLE',
            'Record Code': 'VF03',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'DEALER-FAIL TO PR/SUBMIT TITLE DOCS',
            'Record Code': 'UA05',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'DEALER-MISUSE OF SPECIAL PLATES',
            'Record Code': 'UA03',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'DEALER-SALE OF SUBSTANDARD EQUIP',
            'Record Code': 'UA04',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'DEPOSIT HARMFUL SUBST ON TRAFFICWAY',
            'Record Code': 'UF03',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'DISOBEYING TERMS OF PROBATION',
            'Record Code': 'UH18',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'DISORDERLY CONDUCT',
            'Record Code': 'UG14',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'DISTURBING THE PEACE',
            'Record Code': 'UG11',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'DMG/TAMPER W/ CONTENTS OF VEHICLE',
            'Record Code': 'VF02',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'DRIVE WHILE OUT OF SERVICE',
            'Record Code': 'DB10',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'EXCEEDING HOURS ON DUTY LIMITATIONS',
            'Record Code': 'UH04',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'EXPIRED OR NO DOCUMENT WHICH IS REQ',
            'Record Code': 'DA03',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'EXPIRED OR NO EMISSIONS INSPECTION',
            'Record Code': 'DF01',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'EXPIRED/NO VEH SAFETY INSPECTION',
            'Record Code': 'DF02',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'FAIL TO CORRECT AFTR INSPEC/NOTICE',
            'Record Code': 'EL08',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'FAIL TO DISPLAY PROPER MARKINGS',
            'Record Code': 'EL01',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'FAIL TO FILE MEDICAL CERTIFICATION',
            'Record Code': 'DH02',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'FAIL TO PAY CHILD SUPPORT',
            'Record Code': 'DE13',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'FAIL TO PAY FEE',
            'Record Code': 'DE14',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'FAIL TO PAY FINE AND COSTS',
            'Record Code': 'DE15',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'FAIL TO PAY TAX',
            'Record Code': 'DE16',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'FAIL TO PAY TOLL',
            'Record Code': 'DE17',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'FAIL TO POSSESS/SHOW PERMITS',
            'Record Code': 'DG07',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'FAIL TO RMVE HARMFUL SUBST FROM RD',
            'Record Code': 'UF04',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'FAIL TO SHOW CERTIFICATE OF WEIGHT',
            'Record Code': 'DG02',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'FAIL TO SHOW EMISSIONS/SFTY INSPEC',
            'Record Code': 'DF03',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'FAIL TO USE DISABLED VEHICLE LIGHTS',
            'Record Code': 'EF05',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'FAIL TO WEIGH OR STOP AT WEIGH STA',
            'Record Code': 'VD01',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'FAILED TO FILE ACCIDENT REPORT',
            'Record Code': 'AE07',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'FAILED TO FILE REQD DOC OR REPORT',
            'Record Code': 'DA04',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'FAILED TO GET VIN',
            'Record Code': 'VH03',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'FAILED TO SHOW DOCUMENT AS REQUIRED',
            'Record Code': 'DA05',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'FAILED TO SHOW OPERATORS LOG',
            'Record Code': 'DG03',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'FAILURE TO MAINTAIN PLACE OF BUS',
            'Record Code': 'UA02',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'FAILURE TO MAKE REQUIRED PAYMENT',
            'Record Code': 'DE12',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'FAILURE TO OBEY MOTOR CARRIER RULES',
            'Record Code': 'MA06',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'FAILURE TO OBTAIN/MAINTAIN DMV LIC',
            'Record Code': 'UA01',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'FAILURE TO REMOVE SPILLED MATERIAL',
            'Record Code': 'UF05',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'FALSE REPORT',
            'Record Code': 'DA06',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'FALSE REPORT OF ACCIDENT',
            'Record Code': 'AE08',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'FALSE REPORT OF ODOMETER',
            'Record Code': 'DH03',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'FALSE REPORT OF OPERATORS LOG',
            'Record Code': 'DG04',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'FALSE REPORT OF THEFT',
            'Record Code': 'VF07',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'FALSE REPORT OF VEHICLE SAFETY INSP',
            'Record Code': 'DF05',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'FALSE REPORT-EMISSIONS INSPECTION',
            'Record Code': 'DF04',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'FAMILY REPORT RECOMMENDED',
            'Record Code': 'UH07',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'FORGERY',
            'Record Code': 'UG13',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'FRAUD',
            'Record Code': 'UG16',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'GENERAL MISCELLANEOUS',
            'Record Code': 'UH01',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'GUN RELATED VIOLATIONS',
            'Record Code': 'UG03',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'ILLEGAL POSSESSION OF WEAPON',
            'Record Code': 'UG04',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'IMMIGRATION LAW OFFENDER',
            'Record Code': 'UH08',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'IMPROPER OPERATION OF VEHICLE',
            'Record Code': 'VG13',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'IMPROPER VEHICLE USED ON ROADWAY',
            'Record Code': 'VH01',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'IMPRPR USE OF EMRGNCY VEH MARKINGS',
            'Record Code': 'EL03',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'IMPRPR USE OF SIGNS/SIGNALS/MARKING',
            'Record Code': 'EL02',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'IN CONNECTION WITH A MISDEMEANOR',
            'Record Code': 'VG11',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'INTENT TO DO GREAT BODILY HARM',
            'Record Code': 'UG05',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'LITTERING',
            'Record Code': 'UF01',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'LITTERING FROM A MOTOR VEHICLE',
            'Record Code': 'UF02',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'LOAN DL/PERMIT/ID TO ANOTHER PERSON',
            'Record Code': 'DB18',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'LOAN REGISTRATION OR LICENSE PLATES',
            'Record Code': 'DC09',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'MALICIOUS ACTS',
            'Record Code': 'UG07',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'MC: VIOLATION OF REQUIREMENT',
            'Record Code': 'VA01',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'MFG/MAKE FLS EMISSIONS/SAFETY INSP',
            'Record Code': 'DF06',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'MUTILATED DOCUMENT',
            'Record Code': 'DA01',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'NO OR IMPROPER TRIP PERMIT',
            'Record Code': 'VD02',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'NO/IMPROPER SHIPPING DOCUMENTS',
            'Record Code': 'DG01',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'ODOMETER TAMPERING',
            'Record Code': 'EL06',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'OP OR PERMIT VEH WHERE PROHIBITED',
            'Record Code': 'VG08',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'OPEN DOOR INTO TRAFFIC/IN MOTION',
            'Record Code': 'VH05',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'OUT OF STATE VIOLATION',
            'Record Code': 'UH22',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'OVER WIDTH',
            'Record Code': 'VC11',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'OVERSIZE PERMIT REQUIRED',
            'Record Code': 'VC10',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'PARENTAL CONSENT WITHDRAWN',
            'Record Code': 'UH09',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'PERJURY',
            'Record Code': 'UG18',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'PERMIT DRIVER WITHOUT PROPER CLASS',
            'Record Code': 'DH04',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'PERMIT FRAUD USE OF DL/PERMIT/ID',
            'Record Code': 'DB19',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'PERMIT ILLEGAL OP OF VEH',
            'Record Code': 'VG01',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'PERMIT UNAUTH MINOR TO DRIVE',
            'Record Code': 'DB39',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'PERMIT UNAUTH PERSON TO DRIVE',
            'Record Code': 'DB41',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'PERMIT UNLICENSED PERSON TO DRIVE',
            'Record Code': 'DB16',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'PETTY THEFT',
            'Record Code': 'UG17',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'PHYSICAL OR MENTAL DISABILITY',
            'Record Code': 'UH10',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'PHYSICIANS` OR SPECIALISTS` REPORT',
            'Record Code': 'UH11',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'POSS/PROVIDE CNTERFEIT/ALTERED DOC',
            'Record Code': 'DA02',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'RECEIVE/DISPOSE STOLEN VEH OR PARTS',
            'Record Code': 'VF04',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'RELEASE FROM LIABILITY FOR MINOR DR',
            'Record Code': 'UH17',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'REMOVAL/FALISFY/UNAUTH USE OF VIN',
            'Record Code': 'DH05',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'SALE OF SUBSTANDARD EQUIPMENT',
            'Record Code': 'EL09',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'SELLING IN UNLAWFUL LOCATION',
            'Record Code': 'UG25',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'SEX OFFENSE IN A MOTOR VEHICLE',
            'Record Code': 'VH13',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'SHOW/USE IMPROPERLY DOCUMENT',
            'Record Code': 'DA07',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'SHOW/USE IMPROPERLY OPERATORS LOG',
            'Record Code': 'DG05',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'SHOW/USE IMPRPR EM/SFTY INSP',
            'Record Code': 'DF07',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'SHOW/USE IMPRPR INS CERTIFICATION',
            'Record Code': 'DE04',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'SNOWMOBILE EQUIPMENT VIOLATION',
            'Record Code': 'VI02',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'SNOWMOBILE ON ROADWAY',
            'Record Code': 'VI03',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'SNOWMOBILE VIOLATION',
            'Record Code': 'VI01',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'SPECIAL ROAD/BRIDGE/TUNNEL RESTRICT',
            'Record Code': 'VC08',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'SUICIDE',
            'Record Code': 'UG06',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'TAMPERING WITH TRAFFIC CONTROL DEV',
            'Record Code': 'UH05',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'THROW FROM VEH ANY HARMFUL SUBST',
            'Record Code': 'UF06',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'THROW HARMFUL SUBSTANCE AT VEH',
            'Record Code': 'UF07',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'TRESPASSING',
            'Record Code': 'UG10',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'UNABLE TO PASS RE-EXAMINATION',
            'Record Code': 'UH13',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'UNAUTH USE/TAMPERING W/ STORED VEH',
            'Record Code': 'VF01',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'UNDER AGE FOR LICENSE',
            'Record Code': 'UH14',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'UNDER AGE POSSESSION OF TOBACCO',
            'Record Code': 'UH15',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'UNDER AGE SCHOOL DROPOUT',
            'Record Code': 'UH16',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'UNDERAGE COMMERCIAL DRIVER',
            'Record Code': 'DG06',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'UNKNOWN',
            'Record Code': 'UN00',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'UNKNOWN',
            'Record Code': 'UNKN',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'UNLAWFUL SNOWMOBILE OPERATION',
            'Record Code': 'VI04',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'VIO RESTRICTED HEIGHT LIMIT',
            'Record Code': 'VC09',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Miscellaneous',
            'Record Name': 'VIOLATE TRIP PERMIT LIMITS/RESTRICT',
            'Record Code': 'VD05',
        },
    ],
    Misrepresentation: [
        {
            'Risk Type': 'N/A',
            'Record Type': 'Misrepresentation',
            'Record Name': 'FRAUD TO AVOID ARREST/PROSECUTION',
            'Record Code': 'UG23',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Misrepresentation',
            'Record Name': 'FRAUD TO OBTAIN ALCOHOL',
            'Record Code': 'CB15',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Misrepresentation',
            'Record Name': 'FRAUD TO OBTAIN DL/PERMIT/ID',
            'Record Code': 'DB21',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Misrepresentation',
            'Record Name': 'FRAUD TO OBTAIN HANDICAPPED PLATE',
            'Record Code': 'DD04',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Misrepresentation',
            'Record Name': 'FRAUD TO OBTAIN REG OR TITLE',
            'Record Code': 'DC10',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Misrepresentation',
            'Record Name': 'MAKE FALSE STATEMENTS TO POLICE',
            'Record Code': 'UG19',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Misrepresentation',
            'Record Name': 'MFG OR MAKE FALSE DL/PERMIT/ID',
            'Record Code': 'DB23',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Misrepresentation',
            'Record Name': 'MISREPRESENT ID/OTHER FACTS',
            'Record Code': 'UG24',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Misrepresentation',
            'Record Name': 'POSSESS MULTIPLE DL/PERMIT/ID',
            'Record Code': 'DB22',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Misrepresentation',
            'Record Name': 'SHOW OR USE IMPROPERLY DL/PERMIT/ID',
            'Record Code': 'DB24',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Misrepresentation',
            'Record Name': 'USE ANOTHERS DL/PERMIT/ID',
            'Record Code': 'DB25',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Misrepresentation',
            'Record Name': 'USE ANOTHERS LICENSE PLATE/STICKER',
            'Record Code': 'DD05',
        },
    ],
    'Missing / Defective Equipment': [
        {
            'Risk Type': 'N/A',
            'Record Type': 'Missing / Defective Equipment',
            'Record Name': 'ALTERATION OF EMISSIONS CONTROL DEV',
            'Record Code': 'EG03',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Missing / Defective Equipment',
            'Record Name': 'CHAINS/SNOW TIRES USED IMPROPERLY',
            'Record Code': 'EC03',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Missing / Defective Equipment',
            'Record Name': 'DEF/ IMPR/INADEQUATE EXHAUST SYS',
            'Record Code': 'EH01',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Missing / Defective Equipment',
            'Record Name': 'DEF/IMPR/INADEQ EMISSIONS DEVICE',
            'Record Code': 'EG01',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Missing / Defective Equipment',
            'Record Name': 'DEF/IMPR/INADEQ REFLECTORS',
            'Record Code': 'EF15',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Missing / Defective Equipment',
            'Record Name': 'DEF/IMPR/INADEQUATE BRAKES',
            'Record Code': 'ED01',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Missing / Defective Equipment',
            'Record Name': 'DEF/IMPR/INADEQUATE EQUIPMENT',
            'Record Code': 'EA01',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Missing / Defective Equipment',
            'Record Name': 'DEF/IMPR/INADEQUATE HORN',
            'Record Code': 'EA08',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Missing / Defective Equipment',
            'Record Name': 'DEF/IMPR/INADEQUATE MIRRORS',
            'Record Code': 'EA09',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Missing / Defective Equipment',
            'Record Name': 'DEF/IMPR/INADEQUATE TIRES',
            'Record Code': 'EC01',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Missing / Defective Equipment',
            'Record Name': 'DEF/IMPR/INADEQUATE VEH GLASS',
            'Record Code': 'EM01',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Missing / Defective Equipment',
            'Record Name': 'DEF/IMPR/INADEQUATE WIPERS',
            'Record Code': 'EA10',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Missing / Defective Equipment',
            'Record Name': 'EMISSIONS CONTROL DEVICE USED IMPRO',
            'Record Code': 'EG02',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Missing / Defective Equipment',
            'Record Name': 'EQUIPMENT ILLEGALLY MODIFIED',
            'Record Code': 'EA07',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Missing / Defective Equipment',
            'Record Name': 'EQUIPMENT USED IMPROPERLY',
            'Record Code': 'EA05',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Missing / Defective Equipment',
            'Record Name': 'EQUIPMENT-MAKING EXCESSIVE NOISE',
            'Record Code': 'EA06',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Missing / Defective Equipment',
            'Record Name': 'EXHAUST SYSTEM USED IMPROPERLY',
            'Record Code': 'EH02',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Missing / Defective Equipment',
            'Record Name': 'FAILURE TO USE BRAKES',
            'Record Code': 'ED03',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Missing / Defective Equipment',
            'Record Name': 'FAILURE TO USE EQUIPMENT AS REQ`D',
            'Record Code': 'EA04',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Missing / Defective Equipment',
            'Record Name': 'FAILURE TO USE SNOW TIRES OR CHAINS',
            'Record Code': 'EC02',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Missing / Defective Equipment',
            'Record Name': 'IMPRPR USE OF RADAR/LASER DETECTOR',
            'Record Code': 'EL04',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Missing / Defective Equipment',
            'Record Name': 'LEAV VEH IN UNSAFE/IMPRPR CONDITION',
            'Record Code': 'VH08',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Missing / Defective Equipment',
            'Record Name': 'MAKING EXCESSIVE NOISE - HORN',
            'Record Code': 'EA11',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Missing / Defective Equipment',
            'Record Name': 'MC: DEF/IMPR/INADEQUATE BRAKES',
            'Record Code': 'VA10',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Missing / Defective Equipment',
            'Record Name': 'MC: DEF/IMPR/INADEQUATE EQUIPMENT',
            'Record Code': 'VA14',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Missing / Defective Equipment',
            'Record Name': 'MC: DEF/IMPR/INADEQUATE FOOTREST',
            'Record Code': 'VA17',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Missing / Defective Equipment',
            'Record Name': 'MC: HANDLEBAR HEIGHT VIOLATION',
            'Record Code': 'VA13',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Missing / Defective Equipment',
            'Record Name': 'OP WITHOUT BRAKES AS REQUIRED',
            'Record Code': 'ED02',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Missing / Defective Equipment',
            'Record Name': 'OPERATING W/OUT REQD EQUIPMENT',
            'Record Code': 'EA03',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Missing / Defective Equipment',
            'Record Name': 'UNSAFE CONDITION OF VEHICLE',
            'Record Code': 'VH07',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Missing / Defective Equipment',
            'Record Name': 'USE OF EQUIPMENT PROHIBITED BY LAW',
            'Record Code': 'EA02',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Missing / Defective Equipment',
            'Record Name': 'VEHICLE ILLEGALLY MODIFIED',
            'Record Code': 'EL05',
        },
    ],
    Parking: [
        {
            'Risk Type': 'N/A',
            'Record Type': 'Parking',
            'Record Name': 'DOUBLE PARKED',
            'Record Code': 'VE09',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Parking',
            'Record Name': 'IMPROPER PARKED POSITION',
            'Record Code': 'VE06',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Parking',
            'Record Name': 'NON EMERGENCY STOP',
            'Record Code': 'VE02',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Parking',
            'Record Name': 'OBSTRUCTING OR IMPEDING TRAFFIC',
            'Record Code': 'VE04',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Parking',
            'Record Name': 'PARK/STOP - BETWEEN DIVIDED HWY',
            'Record Code': 'VE07',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Parking',
            'Record Name': 'PARK/STOP - BLOCK DRIVEWAY',
            'Record Code': 'VE14',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Parking',
            'Record Name': 'PARK/STOP - BLOCK TRAFFIC SIGN',
            'Record Code': 'VE19',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Parking',
            'Record Name': 'PARK/STOP - BRIDGE/TUNNEL',
            'Record Code': 'VE10',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Parking',
            'Record Name': 'PARK/STOP - CONTROLLED ACCESS HWY',
            'Record Code': 'VE15',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Parking',
            'Record Name': 'PARK/STOP - CROSSWALK',
            'Record Code': 'VE17',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Parking',
            'Record Name': 'PARK/STOP - EVACUATION ROUTE',
            'Record Code': 'VE12',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Parking',
            'Record Name': 'PARK/STOP - FIRE HYDRANT',
            'Record Code': 'VE16',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Parking',
            'Record Name': 'PARK/STOP - FIRE STATION',
            'Record Code': 'VE18',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Parking',
            'Record Name': 'PARK/STOP - MAIN ROADWAY',
            'Record Code': 'VE11',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Parking',
            'Record Name': 'PARK/STOP - PROHIBITED BY SIGN/SIGN',
            'Record Code': 'VE21',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Parking',
            'Record Name': 'PARK/STOP - SAFETY ZONE',
            'Record Code': 'VE13',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Parking',
            'Record Name': 'PARK/STOP ON SIDWALK',
            'Record Code': 'VE08',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Parking',
            'Record Name': 'PARK/STOP W/I INTERSECTION',
            'Record Code': 'VE20',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Parking',
            'Record Name': 'PARKING IN A HANDICAP ZONE',
            'Record Code': 'VE03',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Parking',
            'Record Name': 'PARKING WHERE PROHIBITED',
            'Record Code': 'MD04',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Parking',
            'Record Name': 'STOP/STAND/PARK WHERE PROHIBITED',
            'Record Code': 'VE05',
        },
    ],
    Passing: [
        {
            'Risk Type': 'Minor',
            'Record Type': 'Passing',
            'Record Name': 'IMPROPER PASSING',
            'Record Code': 'MF01',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Passing',
            'Record Name': 'MC: PASS VEHICLE BETWEEN LANES',
            'Record Code': 'VA06',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Passing',
            'Record Name': 'MC: PASS VEHICLE IN SAME LANE',
            'Record Code': 'VA07',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Passing',
            'Record Name': 'PASS IN VIOL OF OPPOSITE DIR RESTR',
            'Record Code': 'MF03',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Passing',
            'Record Name': 'PASS SCH BUS DISPLAYING WARNING',
            'Record Code': 'MF06',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Passing',
            'Record Name': 'PASS VEH STOPPED FOR PED',
            'Record Code': 'MF09',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Passing',
            'Record Name': 'PASSING IN VIOLATION OF POSTED SIGN',
            'Record Code': 'MF02',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Passing',
            'Record Name': 'PASSING ON HILL OR CURVE',
            'Record Code': 'MF05',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Passing',
            'Record Name': 'PASSING ON WRONG SIDE',
            'Record Code': 'MF04',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Passing',
            'Record Name': 'PASSING WHERE PROHIBITED',
            'Record Code': 'MF07',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Passing',
            'Record Name': 'PASSING WITH INSUFFICIENT DISTANCE',
            'Record Code': 'MF08',
        },
    ],
    Pedestrian: [
        {
            'Risk Type': 'N/A',
            'Record Type': 'Pedestrian',
            'Record Name': 'PED-NOT BLIND-CARRYING WHITE CANE',
            'Record Code': 'UD07',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Pedestrian',
            'Record Name': 'PEDESTRIAN WALKING WHERE PROHIBITED',
            'Record Code': 'UD01',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Pedestrian',
            'Record Name': 'PEDESTRIAN-FTO PED TRAF CNTRL DEV',
            'Record Code': 'UD04',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Pedestrian',
            'Record Name': 'PEDESTRIAN-FTO SIGN OR SIGNAL',
            'Record Code': 'UD05',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Pedestrian',
            'Record Name': 'PEDESTRIAN-FTY ROW',
            'Record Code': 'UD03',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Pedestrian',
            'Record Name': 'PEDESTRIAN-HITCHHIKING WHERE PROH',
            'Record Code': 'UD06',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Pedestrian',
            'Record Name': 'PEDESTRIAN-JAYWALKING',
            'Record Code': 'UD02',
        },
    ],
    Racing: [
        {
            'Risk Type': 'Major',
            'Record Type': 'Racing',
            'Record Name': 'SPEED CONTEST (RACING)',
            'Record Code': 'SA03',
        },
    ],
    Railroad: [
        {
            'Risk Type': 'Minor',
            'Record Type': 'Railroad',
            'Record Name': 'FAIL TO OBEY TRAIN WHISTLE',
            'Record Code': 'MA22',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Railroad',
            'Record Name': 'FAILURE TO OBEY RAILROAD SIGNS/SIGN',
            'Record Code': 'MA21',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Railroad',
            'Record Name': 'FTO RAILROAD CROSSING RESTRICTIONS',
            'Record Code': 'MA09',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Railroad',
            'Record Name': 'FTO RR GATES, SIGNALS OR SIGNS',
            'Record Code': 'MA10',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Railroad',
            'Record Name': 'IMPRPR LANE/LOC- ON RR TRACKS',
            'Record Code': 'MB17',
        },
    ],
    'Reckless Driving': [
        {
            'Risk Type': 'Major',
            'Record Type': 'Reckless Driving',
            'Record Name': 'RECKLESS DRIVING',
            'Record Code': 'MK05',
        },
        {
            'Risk Type': 'Major',
            'Record Type': 'Reckless Driving',
            'Record Name': 'REDUCED FROM DUI',
            'Record Code': 'CA10',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Reckless Driving',
            'Record Name': 'REDUCED FROM DUI - NEGLIGENT OPERAT',
            'Record Code': 'CA11',
        },
    ],
    Registration: [
        {
            'Risk Type': 'N/A',
            'Record Type': 'Registration',
            'Record Name': 'DRIVE WHILE REG IS CANCELLED',
            'Record Code': 'DC02',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Registration',
            'Record Name': 'DRIVE WHILE REG IS SUSPENDED',
            'Record Code': 'DC03',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Registration',
            'Record Name': 'EXPIRED OR NO REGISTRATION OR TITLE',
            'Record Code': 'DC06',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Registration',
            'Record Name': 'FAIL PROVIDE/SUBMIT TITLE TRANSFER',
            'Record Code': 'DC01',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Registration',
            'Record Name': 'FAIL TO SHOW REGISTRATION OR TITLE',
            'Record Code': 'DC07',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Registration',
            'Record Name': 'FAIL TO SURR REG/PLATES/TITLE',
            'Record Code': 'DC08',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Registration',
            'Record Name': 'MFG OR MAKE FALSE REG OR TITLE',
            'Record Code': 'DC11',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Registration',
            'Record Name': 'MUTILATED REGISTRATION OR TITLE',
            'Record Code': 'DC05',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Registration',
            'Record Name': 'POSS/PROVDE CNTRFT/ALTRD REG/TITLE',
            'Record Code': 'DC04',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Registration',
            'Record Name': 'REGISTRATION/TITLE WITHDRAWN',
            'Record Code': 'DB40',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Registration',
            'Record Name': 'SHOW ALTERED/CANCELLED TITLE/REGIST',
            'Record Code': 'DC15',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Registration',
            'Record Name': 'SHOW OR USE IMPROPERLY REG OR TITLE',
            'Record Code': 'DC12',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Registration',
            'Record Name': 'USE ANOTHERS REGISTRATION OR TITLE',
            'Record Code': 'DC13',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Registration',
            'Record Name': 'VIOLATE LIMITS OF REGISTRATION',
            'Record Code': 'DC14',
        },
    ],
    'Safety Related Equip': [
        {
            'Risk Type': 'Minor',
            'Record Type': 'Safety Related Equip',
            'Record Name': 'CHILD RESTRAINT NOT USED PROPERLY',
            'Record Code': 'EB01',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Safety Related Equip',
            'Record Name': 'FAIL TO PLACE RED FLAGS OR FLARES',
            'Record Code': 'VE01',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Safety Related Equip',
            'Record Name': 'MC: DEF/IMPR/INADEQUATE EYE PROTECT',
            'Record Code': 'VA15',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Safety Related Equip',
            'Record Name': 'MC: DEF/IMPR/INADEQUATE HELMET',
            'Record Code': 'VA16',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Safety Related Equip',
            'Record Name': 'MC: NO/IMPROPER SAFETY EQUIPMENT',
            'Record Code': 'VA04',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Safety Related Equip',
            'Record Name': 'MC: SAFETY EQUIP NOT USED AS REQUIR',
            'Record Code': 'VA18',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Safety Related Equip',
            'Record Name': 'SAFETY BELT NOT USED PROPERLY',
            'Record Code': 'EB02',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Safety Related Equip',
            'Record Name': 'SAFETY EQUIPMENT NOT USED PROPERLY',
            'Record Code': 'EL07',
        },
    ],
    'School Bus': [
        {
            'Risk Type': 'N/A',
            'Record Type': 'School Bus',
            'Record Name': 'DEF/IMPR/INADEQ SPEC SCHOOL BUS LIG',
            'Record Code': 'VJ02',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'School Bus',
            'Record Name': 'DEF/IMPR/INADEQUATE SCH BUS EQUIP',
            'Record Code': 'EJ01',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'School Bus',
            'Record Name': 'FAIL TO USE SCHOOL BUS SAFETY EQUIP',
            'Record Code': 'EJ03',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'School Bus',
            'Record Name': 'OPERATING WITHOUT SCHOOL BUS EQUIP',
            'Record Code': 'EJ02',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'School Bus',
            'Record Name': 'SCHOOL BUS DRIVER VIOLATIONS',
            'Record Code': 'VB04',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'School Bus',
            'Record Name': 'SCHOOLBUS VIOLATION',
            'Record Code': 'VJ01',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'School Bus',
            'Record Name': 'UNLAWFUL LOAD/UNLOAD OF CHILDREN FR',
            'Record Code': 'VB03',
        },
    ],
    Speeding: [
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'DRIVE TOO FAST FOR CONDITIONS',
            'Record Code': 'SA02',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'ERRATIC/SUDDENLY CHANGING SPEED',
            'Record Code': 'SA05',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPECIAL SPEED LIMITATIONS',
            'Record Code': 'SA07',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED &gt; 35 IN A 20, BUT &lt; 100',
            'Record Code': 'SB09',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED &gt; 35 IN A 25, BUT &lt; 100',
            'Record Code': 'SB10',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED &gt; 35 IN A 50, BUT &lt; 100',
            'Record Code': 'SB15',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED &gt; 45 IN A 15, BUT &lt; 100',
            'Record Code': 'SB08',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED &gt; 45 IN A 30, BUT &lt; 100',
            'Record Code': 'SB11',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED &gt; 45 IN A 35, BUT &lt; 100',
            'Record Code': 'SB12',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED &gt; 45 IN A 40, BUT &lt; 100',
            'Record Code': 'SB13',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED &gt; 45 IN A 45, BUT &lt; 100',
            'Record Code': 'SB14',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED &gt; 45 IN A 55, BUT &lt; 100',
            'Record Code': 'SB16',
        },
        {
            'Risk Type': 'Major',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED &gt; 50 PLUS &lt; 100 PLUS',
            'Record Code': 'SA95',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 1-10 OVER LIMIT IN A 15',
            'Record Code': 'SA18',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 1-10 OVER LIMIT IN A 20',
            'Record Code': 'SA24',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 1-10 OVER LIMIT IN A 25',
            'Record Code': 'SA30',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 1-10 OVER LIMIT IN A 30',
            'Record Code': 'SA36',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 1-10 OVER LIMIT IN A 35',
            'Record Code': 'SA43',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 1-10 OVER LIMIT IN A 40',
            'Record Code': 'SA50',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 1-10 OVER LIMIT IN A 45',
            'Record Code': 'SA57',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 1-10 OVER LIMIT IN A 50',
            'Record Code': 'SA64',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 1-10 OVER LIMIT IN A 55',
            'Record Code': 'SA70',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 1-10 OVER LIMIT IN A 60',
            'Record Code': 'SA77',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 1-10 OVER LIMIT IN A 65',
            'Record Code': 'SA82',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 1-10 OVER LIMIT IN A 70',
            'Record Code': 'SA89',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 1-5 OVER LIMIT',
            'Record Code': 'SA96',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 11-15 OVER LIMIT',
            'Record Code': 'SA98',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 11-15 OVER LIMIT IN A 10',
            'Record Code': 'SA17',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 11-15 OVER LIMIT IN A 15',
            'Record Code': 'SA19',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 11-15 OVER LIMIT IN A 20',
            'Record Code': 'SA25',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 11-15 OVER LIMIT IN A 25',
            'Record Code': 'SA31',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 11-15 OVER LIMIT IN A 30',
            'Record Code': 'SA37',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 11-15 OVER LIMIT IN A 35',
            'Record Code': 'SA44',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 11-15 OVER LIMIT IN A 40',
            'Record Code': 'SA51',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 11-15 OVER LIMIT IN A 45',
            'Record Code': 'SA58',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 11-15 OVER LIMIT IN A 50',
            'Record Code': 'SA65',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 11-15 OVER LIMIT IN A 55',
            'Record Code': 'SA71',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 11-15 OVER LIMIT IN A 60',
            'Record Code': 'SA78',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 11-15 OVER LIMIT IN A 65',
            'Record Code': 'SA83',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 11-15 OVER LIMIT IN A 70',
            'Record Code': 'SA90',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 16+ IN A 10, BUT &lt; 100',
            'Record Code': 'SB07',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 16-20 OVER LIMIT',
            'Record Code': 'SA99',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 16-20 OVER LIMIT IN A 15',
            'Record Code': 'SA20',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 16-20 OVER LIMIT IN A 20',
            'Record Code': 'SA26',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 16-20 OVER LIMIT IN A 25',
            'Record Code': 'SA32',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 16-20 OVER LIMIT IN A 30',
            'Record Code': 'SA38',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 16-20 OVER LIMIT IN A 35',
            'Record Code': 'SA45',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 16-20 OVER LIMIT IN A 40',
            'Record Code': 'SA52',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 16-20 OVER LIMIT IN A 45',
            'Record Code': 'SA59',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 16-20 OVER LIMIT IN A 50',
            'Record Code': 'SA66',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 16-20 OVER LIMIT IN A 55',
            'Record Code': 'SA72',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 16-20 OVER LIMIT IN A 60',
            'Record Code': 'SA79',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 16-20 OVER LIMIT IN A 65',
            'Record Code': 'SA84',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 16-20 OVER LIMIT IN A 70',
            'Record Code': 'SA91',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 21-25 OVER LIMIT',
            'Record Code': 'SB01',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 21-25 OVER LIMIT IN A 15',
            'Record Code': 'SA21',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 21-25 OVER LIMIT IN A 20',
            'Record Code': 'SA27',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 21-25 OVER LIMIT IN A 25',
            'Record Code': 'SA33',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 21-25 OVER LIMIT IN A 30',
            'Record Code': 'SA39',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 21-25 OVER LIMIT IN A 35',
            'Record Code': 'SA46',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 21-25 OVER LIMIT IN A 40',
            'Record Code': 'SA53',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 21-25 OVER LIMIT IN A 45',
            'Record Code': 'SA60',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 21-25 OVER LIMIT IN A 50',
            'Record Code': 'SA67',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 21-25 OVER LIMIT IN A 55',
            'Record Code': 'SA73',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 21-25 OVER LIMIT IN A 60',
            'Record Code': 'SA80',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 21-25 OVER LIMIT IN A 65',
            'Record Code': 'SA85',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 21-25 OVER LIMIT IN A 70',
            'Record Code': 'SA92',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 26-30 OVER LIMIT',
            'Record Code': 'SB02',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 26-30 OVER LIMIT IN A 15',
            'Record Code': 'SA22',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 26-30 OVER LIMIT IN A 20',
            'Record Code': 'SA28',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 26-30 OVER LIMIT IN A 25',
            'Record Code': 'SA34',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 26-30 OVER LIMIT IN A 30',
            'Record Code': 'SA40',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 26-30 OVER LIMIT IN A 35',
            'Record Code': 'SA47',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 26-30 OVER LIMIT IN A 40',
            'Record Code': 'SA54',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 26-30 OVER LIMIT IN A 45',
            'Record Code': 'SA61',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 26-30 OVER LIMIT IN A 50',
            'Record Code': 'SA68',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 26-30 OVER LIMIT IN A 55',
            'Record Code': 'SA74',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 26-30 OVER LIMIT IN A 65',
            'Record Code': 'SA86',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 26-30 OVER LIMIT IN A 70',
            'Record Code': 'SA93',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 31-35 OVER LIMIT',
            'Record Code': 'SB03',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 31-35 OVER LIMIT IN A 20',
            'Record Code': 'SA29',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 31-35 OVER LIMIT IN A 25',
            'Record Code': 'SA35',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 31-35 OVER LIMIT IN A 30',
            'Record Code': 'SA41',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 31-35 OVER LIMIT IN A 35',
            'Record Code': 'SA48',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 31-35 OVER LIMIT IN A 40',
            'Record Code': 'SA55',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 31-35 OVER LIMIT IN A 45',
            'Record Code': 'SA62',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 31-35 OVER LIMIT IN A 50',
            'Record Code': 'SA69',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 31-35 OVER LIMIT IN A 55',
            'Record Code': 'SA75',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 31-35 OVER LIMIT IN A 65',
            'Record Code': 'SA87',
        },
        {
            'Risk Type': 'Major',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 36-40 OVER LIMIT',
            'Record Code': 'SB04',
        },
        {
            'Risk Type': 'Major',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 36-45 OVER LIMIT IN A 15',
            'Record Code': 'SA23',
        },
        {
            'Risk Type': 'Major',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 36-45 OVER LIMIT IN A 30',
            'Record Code': 'SA42',
        },
        {
            'Risk Type': 'Major',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 36-45 OVER LIMIT IN A 35',
            'Record Code': 'SA49',
        },
        {
            'Risk Type': 'Major',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 36-45 OVER LIMIT IN A 40',
            'Record Code': 'SA56',
        },
        {
            'Risk Type': 'Major',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 36-45 OVER LIMIT IN A 45',
            'Record Code': 'SA63',
        },
        {
            'Risk Type': 'Major',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 36-45 OVER LIMIT IN A 55',
            'Record Code': 'SA76',
        },
        {
            'Risk Type': 'Major',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 36-45 OVER LIMIT IN A 60',
            'Record Code': 'SA81',
        },
        {
            'Risk Type': 'Major',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 36-45 OVER LIMIT IN A 65',
            'Record Code': 'SA88',
        },
        {
            'Risk Type': 'Major',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 41-45 OVER LIMIT',
            'Record Code': 'SB05',
        },
        {
            'Risk Type': 'Major',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 45 PLUS OVER LIMIT',
            'Record Code': 'SA94',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED 6-10 OVER LIMIT',
            'Record Code': 'SA97',
        },
        {
            'Risk Type': 'Major',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED IN EXCESS OF 100 MPH',
            'Record Code': 'SB06',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEED LESS THAN MINIMUM',
            'Record Code': 'SA04',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEEDING',
            'Record Code': 'SA01',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEEDING - TRUCK OR BUS',
            'Record Code': 'SB17',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEEDING 1+ OVER THE LIMIT',
            'Record Code': 'SA08',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEEDING 10+ OVER THE LIMIT',
            'Record Code': 'SA10',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEEDING 15+ OVER THE LIMIT',
            'Record Code': 'SA11',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEEDING 20+ OVER THE LIMIT',
            'Record Code': 'SA12',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEEDING 25+ OVER THE LIMIT',
            'Record Code': 'SA13',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEEDING 30+ OVER THE LIMIT',
            'Record Code': 'SA14',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEEDING 35+ OVER THE LIMIT',
            'Record Code': 'SA15',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEEDING 40+ OVER THE LIMIT',
            'Record Code': 'SA16',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEEDING 5+ OVER THE LIMIT',
            'Record Code': 'SA09',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'SPEEDING IN SCHOOL ZONE',
            'Record Code': 'SA06',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Speeding',
            'Record Name': 'TOO SLOW/INTENTIONALLY IMPED TRAFF',
            'Record Code': 'SB18',
        },
    ],
    Stop: [
        {
            'Risk Type': 'Minor',
            'Record Type': 'Stop',
            'Record Name': 'FAIL TO STOP - SCHOOL CROSSING',
            'Record Code': 'MD01',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Stop',
            'Record Name': 'FAIL TO STOP AT CROSSING RESTRICTIO',
            'Record Code': 'MD05',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Stop',
            'Record Name': 'FAIL TO STOP AT LINE/XWALK',
            'Record Code': 'MD03',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Stop',
            'Record Name': 'FAIL TO STOP AT UNSIGNED INTERSECT',
            'Record Code': 'MD02',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Stop',
            'Record Name': 'FAILURE TO OBEY STOP SIGN',
            'Record Code': 'MA15',
        },
    ],
    Theft: [
        {
            'Risk Type': 'N/A',
            'Record Type': 'Theft',
            'Record Name': 'ILLEGAL OPERATION OF EMERGENCY VEH',
            'Record Code': 'VG07',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Theft',
            'Record Name': 'UNAUTHORIZED USE OF A VEHICLE',
            'Record Code': 'VF05',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Theft',
            'Record Name': 'VEHICLE THEFT',
            'Record Code': 'VF06',
        },
    ],
    'Traffic Control Device': [
        {
            'Risk Type': 'Minor',
            'Record Type': 'Traffic Control Device',
            'Record Name': 'FAILURE TO OBEY',
            'Record Code': 'MA01',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Traffic Control Device',
            'Record Name': 'FAILURE TO OBEY BARRIER',
            'Record Code': 'MA02',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Traffic Control Device',
            'Record Name': 'FAILURE TO OBEY FLAGGER',
            'Record Code': 'MA04',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Traffic Control Device',
            'Record Name': 'FAILURE TO OBEY RESTRICTED LANE',
            'Record Code': 'MA11',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Traffic Control Device',
            'Record Name': 'FAILURE TO OBEY SAFETY ZONE',
            'Record Code': 'MA12',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Traffic Control Device',
            'Record Name': 'FAILURE TO OBEY TRAFFIC SIGN',
            'Record Code': 'MA17',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Traffic Control Device',
            'Record Name': 'FTO CONSTR/MAINTENANCE ZONE MARKERS',
            'Record Code': 'MA03',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Traffic Control Device',
            'Record Name': 'FTO LANE MARKINGS/SIGNAL/SIGNS',
            'Record Code': 'MA05',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Traffic Control Device',
            'Record Name': 'FTO PEDESTRIAN CONTROL DEVICE',
            'Record Code': 'MA07',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Traffic Control Device',
            'Record Name': 'FTO SCHOOL CROSSING GUARD',
            'Record Code': 'MA13',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Traffic Control Device',
            'Record Name': 'FTO SIGN OR TRAFFIC CONTROL DEVICE',
            'Record Code': 'MA14',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Traffic Control Device',
            'Record Name': 'FTO TRAFFIC SIGNAL OR LIGHT',
            'Record Code': 'MA16',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Traffic Control Device',
            'Record Name': 'FTO WARNING LIGHT OR FLASHER',
            'Record Code': 'MA18',
        },
    ],
    Turn: [
        {
            'Risk Type': 'Minor',
            'Record Type': 'Turn',
            'Record Name': 'IMPROPER METHOD OF TURNING',
            'Record Code': 'MH02',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Turn',
            'Record Name': 'IMPROPER OR UNSAFE TURN',
            'Record Code': 'MH01',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Turn',
            'Record Name': 'IMPROPER POSITION FOR TURNING',
            'Record Code': 'MH03',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Turn',
            'Record Name': 'IMPROPER TURN ON RED',
            'Record Code': 'MH08',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Turn',
            'Record Name': 'MAKING IMPROPER LEFT TURN',
            'Record Code': 'MH04',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Turn',
            'Record Name': 'MAKING IMPROPER RIGHT TURN',
            'Record Code': 'MH05',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Turn',
            'Record Name': 'MAKING IMPROPER TURN AROUND (NOT U',
            'Record Code': 'MH06',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Turn',
            'Record Name': 'MAKING IMPROPER U TURN',
            'Record Code': 'MH07',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Turn',
            'Record Name': 'TURNING - FTO TRAFFIC CONTROL DEVIC',
            'Record Code': 'MH09',
        },
    ],
    'Unsafe Action': [
        {
            'Risk Type': 'Minor',
            'Record Type': 'Unsafe Action',
            'Record Name': 'ASLEEP WHILE DRIVING',
            'Record Code': 'VG05',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Unsafe Action',
            'Record Name': 'BRAKES USED IMPROPERLY',
            'Record Code': 'ED04',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Unsafe Action',
            'Record Name': 'CLINGING TO OTHER VEHICLES',
            'Record Code': 'ML02',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Unsafe Action',
            'Record Name': 'COASTING (OP W/ GEARS DISENGAGED)',
            'Record Code': 'ML01',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Unsafe Action',
            'Record Name': 'DRIVER`S VIEW OBSTRUCTED',
            'Record Code': 'VG02',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Unsafe Action',
            'Record Name': 'FAIL TO SIGNAL INTENT TO STOP',
            'Record Code': 'MG06',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Unsafe Action',
            'Record Name': 'FAIL TO SIGNAL LANE CHG OR TURN',
            'Record Code': 'MG04',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Unsafe Action',
            'Record Name': 'FAIL TO USE TURNOUT',
            'Record Code': 'ML05',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Unsafe Action',
            'Record Name': 'FAILURE TO CANCEL SIGNAL',
            'Record Code': 'MG02',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Unsafe Action',
            'Record Name': 'FAILURE TO SET BRAKE(S)',
            'Record Code': 'ED05',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Unsafe Action',
            'Record Name': 'FAILURE TO SIGNAL INTENTION TO PASS',
            'Record Code': 'MG03',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Unsafe Action',
            'Record Name': 'FAILURE TO USE HEADLIGHT DIMMER',
            'Record Code': 'EF06',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Unsafe Action',
            'Record Name': 'FAILURE TO USE LIGHTS AS REQUIRED',
            'Record Code': 'EF07',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Unsafe Action',
            'Record Name': 'FAILURE TO USE OR IMPROPER SIGNAL',
            'Record Code': 'MG01',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Unsafe Action',
            'Record Name': 'GIVING WRONG SIGNAL',
            'Record Code': 'MG05',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Unsafe Action',
            'Record Name': 'IMPROPER BACKING',
            'Record Code': 'ML03',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Unsafe Action',
            'Record Name': 'IMPROPER PUSHING',
            'Record Code': 'VH15',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Unsafe Action',
            'Record Name': 'IMPROPER STARTING',
            'Record Code': 'ML04',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Unsafe Action',
            'Record Name': 'IMPROPER TOWING',
            'Record Code': 'VH14',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Unsafe Action',
            'Record Name': 'IMPROPER/UNSAFE OPERATION OF VEH',
            'Record Code': 'VG09',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Unsafe Action',
            'Record Name': 'INABILITY TO CONTROL VEHICLE',
            'Record Code': 'VG04',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Unsafe Action',
            'Record Name': 'INTERFERE WITH DRIVER`S CONTROL',
            'Record Code': 'VG03',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Unsafe Action',
            'Record Name': 'MC: CLINGING TO ANOTHER VEHICLE',
            'Record Code': 'VA05',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Unsafe Action',
            'Record Name': 'MC: EXCEED PASSENGER/CARGO LIMIT',
            'Record Code': 'VA03',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Unsafe Action',
            'Record Name': 'MC: IMPROPER OPERATION/RIDING',
            'Record Code': 'VA02',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Unsafe Action',
            'Record Name': 'MC: RIDE MORE THAN 2 ABREAST',
            'Record Code': 'VA08',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Unsafe Action',
            'Record Name': 'OP VEH IMPRPR DUE TO DISABILITY',
            'Record Code': 'VG06',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Unsafe Action',
            'Record Name': 'OP WITHOUT LIGHTS AS REQUIRED',
            'Record Code': 'EF02',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Unsafe Action',
            'Record Name': 'PERMITTING PASSENGERS WHERE UNSAFE',
            'Record Code': 'VB02',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Unsafe Action',
            'Record Name': 'RIDING IN VEHICLE WHERE UNSAFE',
            'Record Code': 'VB01',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Unsafe Action',
            'Record Name': 'TOW OR PUSH VEHICLE IMPROPERLY',
            'Record Code': 'VH06',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Unsafe Action',
            'Record Name': 'UNAUTHORIZED USE OF VEHICULAR CROSS',
            'Record Code': 'ML06',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Unsafe Action',
            'Record Name': 'VEHICLE UNATTENDED W/ ENGINE ON',
            'Record Code': 'VH04',
        },
    ],
    'Vehicle InterLock': [
        {
            'Risk Type': 'N/A',
            'Record Type': 'Vehicle InterLock',
            'Record Name': 'AID IN VIOL OF IGNITION INTERLOCK',
            'Record Code': 'CD01',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Vehicle InterLock',
            'Record Name': 'DRVR VIOL OF IGNITION INTERLOCK',
            'Record Code': 'CD02',
        },
    ],
    'Vehicle Load': [
        {
            'Risk Type': 'N/A',
            'Record Type': 'Vehicle Load',
            'Record Name': 'EXCEED PASSENGER/CARGO LIMITS',
            'Record Code': 'VC03',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Vehicle Load',
            'Record Name': 'EXCEEDING OR VIOLATING SIZE, WEIGHT',
            'Record Code': 'VC02',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Vehicle Load',
            'Record Name': 'NO WARNING FOR PROJECTING LOAD',
            'Record Code': 'VD03',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Vehicle Load',
            'Record Name': 'SPILL/DRAG/UNSECURED/UNSAFE LOAD',
            'Record Code': 'VD04',
        },
    ],
    'Vehicle Weight': [
        {
            'Risk Type': 'N/A',
            'Record Type': 'Vehicle Weight',
            'Record Name': 'EXCEED REGISTERED WEIGHT LIMIT',
            'Record Code': 'VC14',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Vehicle Weight',
            'Record Name': 'EXCEED SIZE LIMIT RD/BRIDGE/TUNNEL',
            'Record Code': 'VC06',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Vehicle Weight',
            'Record Name': 'EXCEED SIZE LIMITS OF VEHICLE',
            'Record Code': 'VC04',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Vehicle Weight',
            'Record Name': 'EXCEED WEIGHT LIMIT OF VEHICLE',
            'Record Code': 'VC05',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Vehicle Weight',
            'Record Name': 'EXCEED WGT LIMIT RD/BRIDGE/TUNNEL',
            'Record Code': 'VC07',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Vehicle Weight',
            'Record Name': 'SINGLE AXLE OVER WEIGHT',
            'Record Code': 'VC13',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Vehicle Weight',
            'Record Name': 'VIOL OF EXCESS SIZE/WEIGHT PERMIT',
            'Record Code': 'VC01',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Vehicle Weight',
            'Record Name': 'VIOLATE GVW LIMIT',
            'Record Code': 'VC12',
        },
    ],
    'Vehicular Assault': [
        {
            'Risk Type': 'N/A',
            'Record Type': 'Vehicular Assault',
            'Record Name': 'ASSAULT',
            'Record Code': 'UG02',
        },
        {
            'Risk Type': 'Major',
            'Record Type': 'Vehicular Assault',
            'Record Name': 'VEHICULAR ASSAULT',
            'Record Code': 'VH09',
        },
    ],
    'Vehicular Felony': [
        {
            'Risk Type': 'Major',
            'Record Type': 'Vehicular Felony',
            'Record Name': 'AID AND ABET A FELON',
            'Record Code': 'VG12',
        },
        {
            'Risk Type': 'Major',
            'Record Type': 'Vehicular Felony',
            'Record Name': 'IN CONNECTION WITH A FELONY',
            'Record Code': 'VG10',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Vehicular Felony',
            'Record Name': 'MURDER',
            'Record Code': 'UG01',
        },
    ],
    'Vehicular Homicide': [
        {
            'Risk Type': 'Major',
            'Record Type': 'Vehicular Homicide',
            'Record Name': 'VEHICULAR HOMICIDE',
            'Record Code': 'VH10',
        },
    ],
    'Vehicular Manslaughter': [
        {
            'Risk Type': 'Major',
            'Record Type': 'Vehicular Manslaughter',
            'Record Name': 'GROSS VEHICULAR MANSLAUGHTER',
            'Record Code': 'VH12',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Vehicular Manslaughter',
            'Record Name': 'MANSLAUGHTER',
            'Record Code': 'UG08',
        },
        {
            'Risk Type': 'Major',
            'Record Type': 'Vehicular Manslaughter',
            'Record Name': 'VEHICULAR MANSLAUGHTER',
            'Record Code': 'VH11',
        },
    ],
    Watercraft: [
        {
            'Risk Type': 'N/A',
            'Record Type': 'Watercraft',
            'Record Name': 'BOAT-EXCEEDING 5 MPH WHERE PROH',
            'Record Code': 'UB06',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Watercraft',
            'Record Name': 'BOATING VIOLATION',
            'Record Code': 'UB01',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Watercraft',
            'Record Name': 'IMPROPER TOWING BY VESSEL',
            'Record Code': 'UB07',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Watercraft',
            'Record Name': 'OP VESSEL UNDER INFLU OF ALC/DRUGS',
            'Record Code': 'UB03',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Watercraft',
            'Record Name': 'OPERATING VESSEL WITH BAC .08% OR +',
            'Record Code': 'UB04',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Watercraft',
            'Record Name': 'RECKLESS/NEGLIGENT OP OF VESSEL',
            'Record Code': 'UB02',
        },
        {
            'Risk Type': 'N/A',
            'Record Type': 'Watercraft',
            'Record Name': 'VESSEL-DUTIES UPON ACCIDENT',
            'Record Code': 'UB05',
        },
    ],
    'Wrong Way': [
        {
            'Risk Type': 'Minor',
            'Record Type': 'Wrong Way',
            'Record Name': 'DRIVING LEFT OF CENTER',
            'Record Code': 'MI05',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Wrong Way',
            'Record Name': 'DRIVING ON WRONG SIDE',
            'Record Code': 'MJ01',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Wrong Way',
            'Record Name': 'DRIVING WRONG SIDE OF DIVIDED HWY',
            'Record Code': 'MJ02',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Wrong Way',
            'Record Name': 'DRIVING WRONG SIDE UNDIVIDED ST/RD',
            'Record Code': 'MJ03',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Wrong Way',
            'Record Name': 'DRIVING WRONG WAY',
            'Record Code': 'MI01',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Wrong Way',
            'Record Name': 'DRIVING WRONG WAY ON DIVIDED HWY',
            'Record Code': 'MI03',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Wrong Way',
            'Record Name': 'DRIVING WRONG WAY ON ONE WAY STREET',
            'Record Code': 'MI04',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Wrong Way',
            'Record Name': 'DRIVING WRONG WAY-ROTARY INTERSECT',
            'Record Code': 'MI02',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Wrong Way',
            'Record Name': 'DRV OVER/UPON/ACROSS CENTER DIVIDER',
            'Record Code': 'MJ04',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Wrong Way',
            'Record Name': 'IMPROPER LANE/LOC ONCOMING TRAFFIC',
            'Record Code': 'MB19',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Wrong Way',
            'Record Name': 'WRONG SIDE APPROACHING HILL',
            'Record Code': 'MJ05',
        },
        {
            'Risk Type': 'Minor',
            'Record Type': 'Wrong Way',
            'Record Name': 'WRONG SIDE APPROACHING INTERSECTION',
            'Record Code': 'MJ06',
        },
    ],
};

export default violationsPerTypes;
