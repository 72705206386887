import PropTypes from 'prop-types';
import TextField from './TextField';
import { formatDate } from '@tint/core/src/formatters';

const DateField = ({ value, ...otherProps }) => {
    let formattedDate = 'N/A';
    if (value) {
        try {
            formattedDate = formatDate(value);
        } catch (err) {
            if (!(err instanceof RangeError)) {
                throw err;
            }

            formattedDate = 'Invalid Date';
        }
    }

    return <TextField value={formattedDate} {...otherProps} />;
};

DateField.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
};

DateField.defaultProps = {
    value: null,
};

export default DateField;
