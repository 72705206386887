const convertOptions = ({
    paths,
    path,
    startDatePath,
    endDatePath,
    ...options
}) => {
    return {
        ...options,
        ...(path ? { path: `attributes.${path}` } : {}),
        ...(paths ? { paths: paths.map((path) => `attributes.${path}`) } : {}),
        ...(startDatePath
            ? { startDatePath: `attributes.${startDatePath}` }
            : {}),
        ...(endDatePath ? { endDatePath: `attributes.${endDatePath}` } : {}),
    };
};

export const stateToSchema = (state) => {
    return state.list.map((id) => {
        const { label, type, options } = state.byId[id];
        return {
            type,
            label,
            ...convertOptions(options),
        };
    });
};
