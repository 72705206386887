// @see https://stackoverflow.com/questions/5639346/what-is-the-shortest-function-for-reading-a-cookie-by-name-in-javascript
const getCookieValue = (cookieName) => {
    const cookie = document.cookie.match(
        `(^|[^;]+)\\s*${cookieName}\\s*=\\s*([^;]+)`,
    );
    if (!cookie) {
        // eslint-disable-next-line no-console
        console.warn(`Unable to find cookie "${cookieName}"`);
        return null;
    }

    return cookie.pop();
};

export default getCookieValue;
