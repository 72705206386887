// Need help for typing the validation/processors directory
import { VALIDATION_CODES } from '../../validation/validationCodes';
import type { ValidationError } from '../types';

// This interface only exists for the purpose of this file
// Do not resuse elsewhere
// It will need to be replaced once we type packages/tint-core/src/processors/io.ts and its friends
interface LocalBlueprint {
    nodes?: Record<string, LocalNode>;
    edges?: Array<LocalEdge>;
}
// This interface only exists for the purpose of this file
// Do not resuse elsewhere
// It will need to be replaced once we type packages/tint-core/src/processors/io.ts and its friends
interface LocalNode {
    name?: string;
    // This bit in particular is specific to the rating table
    configuration: Configuration;
}
// This interface only exists for the purpose of this file
// Do not resuse elsewhere
// It will need to be replaced once we type packages/tint-core/src/processors/io.ts and its friends
interface LocalEdge {
    start: { node: string };
    end: { node: string };
}

interface IntervalCondition {
    gt?: number;
    lt?: number;
    gte?: number;
    lte?: number;
    eq?: number;
    label: string;
}

interface IntervalDimension {
    type: 'interval';
    conditions: Array<IntervalCondition>;
    catchAll?: string;
    input: string;
}

interface StringDimension {
    type: 'string';
    conditions: Array<{
        operation?: 'match' | 'contain' | 'start-with' | 'end-with';
        values?: Array<string>;
        label: string;
    }>;
    catchAll?: string;
    input: string;
}

interface BooleanDimension {
    type: 'boolean';
    conditions: Array<{
        value: boolean;
        label: string;
    }>;
    catchAll?: string;
    input: string;
}

type Dimension = BooleanDimension | IntervalDimension | StringDimension;

interface Configuration {
    dimensions: Array<Dimension>;
    rates: Array<number>;
}

export const getExpectedRatesLengthFromDimensions = <Value>(
    dimensions: Array<string>,
    inputValues: Record<string, Array<Value>>,
) => {
    let ratesNumber = 1;
    for (const dimension of dimensions) {
        ratesNumber *= (inputValues[dimension] as Array<Value>).length;
    }

    return ratesNumber;
};

const getValuesFromDimension = (
    acc: Record<string, Array<string>>,
    dimension: Dimension,
) => {
    acc[dimension.input] = dimension.conditions.map(({ label }) => label);

    if (dimension.catchAll !== undefined) {
        (acc[dimension.input] as Array<string>).push(dimension.catchAll);
    }

    return acc;
};

const getDimensionsWithUndefinedValue = (
    valuesByDimension: Record<string, Array<string | undefined>>,
) => {
    const dimensionsWithNullValue: string[] = [];

    for (const [dimension, values] of Object.entries(valuesByDimension)) {
        if (values.includes(undefined)) {
            dimensionsWithNullValue.push(dimension);
        }
    }

    return dimensionsWithNullValue;
};

const getMissingInputForDimensions = (
    blueprint: LocalBlueprint,
    nodeId: string,
    dimensions: Array<Dimension>,
): Array<ValidationError> => {
    const errors: Array<ValidationError> = [];

    for (const dimension of dimensions) {
        const dimensionInputEdge = blueprint.edges?.find(
            (edge) =>
                edge.end.node === nodeId && edge.start.node === dimension.input,
        );

        if (!dimensionInputEdge) {
            errors.push({
                id: nodeId,
                code: VALIDATION_CODES.NODE_CONFIGURATION_ERROR,
                message: `The dimension "${dimension.input}" do not have its corresponding input connected`,
            });
        }
    }

    return errors;
};

interface Bound {
    value: number;
    inclusive: boolean;
}

interface Unhandled {
    from: Bound;
    to: Bound;
}

const listUnhandledCases = (
    conditions: Array<IntervalCondition>,
): Array<Unhandled> => {
    let unhandled: Array<Unhandled> = [
        {
            from: { value: -Infinity, inclusive: true },
            to: { value: +Infinity, inclusive: true },
        },
    ];

    conditions.forEach((condition) => {
        const minimum: Bound | null =
            condition.gt || condition.gte
                ? {
                      value: condition.gt || (condition.gte as number),
                      inclusive: !!condition.gte,
                  }
                : null;
        const maximum: Bound | null =
            condition.lt || condition.lte
                ? {
                      value: condition.lt || (condition.lte as number),
                      inclusive: !!condition.lte,
                  }
                : null;

        const updatedUnhandled: Array<Unhandled> = [];
        unhandled.forEach((unhandled) => {
            if (minimum && maximum) {
                if (
                    (unhandled.to.value < maximum.value &&
                        unhandled.from.value > minimum.value) ||
                    (maximum.inclusive &&
                        minimum.inclusive &&
                        unhandled.to.value === maximum.value &&
                        unhandled.from.value === minimum.value)
                ) {
                    // Nothing, this case has been handled
                } else if (
                    (unhandled.to.value > maximum.value &&
                        unhandled.from.value < minimum.value) ||
                    (unhandled.to.inclusive &&
                        !maximum.inclusive &&
                        unhandled.to.value === maximum.value &&
                        unhandled.from.value < minimum.value) ||
                    (unhandled.from.inclusive &&
                        !minimum.inclusive &&
                        unhandled.to.value > maximum.value &&
                        unhandled.from.value === minimum.value)
                ) {
                    // This case is split in two
                    updatedUnhandled.push({
                        from: unhandled.from,
                        to: {
                            value: minimum.value,
                            inclusive: !minimum.inclusive,
                        },
                    });
                    updatedUnhandled.push({
                        from: {
                            value: maximum.value,
                            inclusive: !maximum.inclusive,
                        },
                        to: unhandled.to,
                    });
                } else if (
                    (unhandled.to.value < maximum.value &&
                        unhandled.to.value > minimum.value) ||
                    (maximum.inclusive &&
                        unhandled.to.value <= maximum.value &&
                        unhandled.to.value > minimum.value)
                ) {
                    // This case is restricted
                    updatedUnhandled.push({
                        from: unhandled.from,
                        to: {
                            value: minimum.value,
                            inclusive: !minimum.inclusive,
                        },
                    });
                } else if (
                    (unhandled.from.value > minimum.value &&
                        unhandled.from.value < maximum.value) ||
                    (minimum.inclusive &&
                        unhandled.from.value >= minimum.value &&
                        unhandled.from.value < maximum.value)
                ) {
                    // This case is restricted
                    updatedUnhandled.push({
                        from: {
                            value: maximum.value,
                            inclusive: !maximum.inclusive,
                        },
                        to: unhandled.to,
                    });
                } else {
                    // No intersection, we keep the case
                    updatedUnhandled.push(unhandled);
                }
            } else if (minimum) {
                if (
                    minimum.value < unhandled.from.value ||
                    ((minimum.inclusive || !unhandled.from.inclusive) &&
                        minimum.value <= unhandled.from.value)
                ) {
                    // Nothing, this case has been handled
                } else if (
                    (minimum.value > unhandled.from.value &&
                        minimum.value < unhandled.to.value) ||
                    (unhandled.from.inclusive &&
                        minimum.value >= unhandled.from.value &&
                        minimum.value < unhandled.to.value) ||
                    (unhandled.to.inclusive &&
                        minimum.value > unhandled.from.value &&
                        minimum.value <= unhandled.to.value) ||
                    (minimum.inclusive &&
                        minimum.value >= unhandled.from.value &&
                        minimum.value <= unhandled.to.value)
                ) {
                    // This case is restricted
                    updatedUnhandled.push({
                        from: unhandled.from,
                        to: {
                            value: minimum.value,
                            inclusive: !minimum.inclusive,
                        },
                    });
                } else {
                    // No intersection, we keep the case
                    updatedUnhandled.push(unhandled);
                }
            } else if (maximum) {
                if (
                    maximum.value > unhandled.to.value ||
                    ((maximum.inclusive || !unhandled.to.inclusive) &&
                        maximum.value >= unhandled.to.value)
                ) {
                    // Nothing, this case has been handled
                } else if (
                    (maximum.value > unhandled.from.value &&
                        maximum.value < unhandled.to.value) ||
                    (unhandled.from.inclusive &&
                        maximum.value >= unhandled.from.value &&
                        maximum.value < unhandled.to.value) ||
                    (unhandled.to.inclusive &&
                        maximum.value > unhandled.from.value &&
                        maximum.value <= unhandled.to.value) ||
                    (maximum.inclusive &&
                        maximum.value >= unhandled.from.value &&
                        maximum.value <= unhandled.to.value)
                ) {
                    // This case is restricted
                    updatedUnhandled.push({
                        from: {
                            value: maximum.value,
                            inclusive: !maximum.inclusive,
                        },
                        to: unhandled.to,
                    });
                } else {
                    // No intersection, we keep the case
                    updatedUnhandled.push(unhandled);
                }
            } else if (condition.eq) {
                if (
                    condition.eq > unhandled.from.value &&
                    condition.eq < unhandled.to.value
                ) {
                    // This case is split in two
                    updatedUnhandled.push({
                        from: unhandled.from,
                        to: {
                            value: condition.eq,
                            inclusive: false,
                        },
                    });
                    updatedUnhandled.push({
                        from: {
                            value: condition.eq,
                            inclusive: false,
                        },
                        to: unhandled.to,
                    });
                }
            }
        });
        unhandled = updatedUnhandled;
    });

    return unhandled;
};

export const validateExhaustiveMatch = (
    dimensions: Array<Dimension>,
    nodeId: string,
) =>
    dimensions.flatMap((dimension) => {
        switch (dimension.type) {
            case 'boolean':
                // Boolean rating tables are always exhaustive
                return [];
            case 'interval': {
                if (dimension.catchAll) {
                    return [];
                }

                if (dimension.conditions.length === 0) {
                    return [
                        {
                            id: nodeId,
                            code: VALIDATION_CODES.NODE_CONFIGURATION_ERROR,
                            message: 'This rating table is empty.',
                        },
                    ];
                }

                const unhandledCases = listUnhandledCases(dimension.conditions);

                if (unhandledCases.length === 0) {
                    return [];
                }

                return {
                    id: nodeId,
                    code: VALIDATION_CODES.NODE_CONFIGURATION_ERROR,
                    message: `The conditions are not covering values ${unhandledCases
                        .map((unhandled) => {
                            if (unhandled.from.value === -Infinity) {
                                return `below ${unhandled.to.value}`;
                            }
                            if (unhandled.to.value === +Infinity) {
                                return `above ${unhandled.from.value}`;
                            }
                            if (unhandled.from.value === unhandled.to.value) {
                                return unhandled.from.value;
                            }

                            return `between ${unhandled.from.value} and ${unhandled.to.value}`;
                        })
                        .join(', ')}. Please add the corresponding conditions.`,
                };
            }
            case 'string':
                return dimension.catchAll
                    ? []
                    : [
                          {
                              id: nodeId,
                              code: VALIDATION_CODES.NODE_CONFIGURATION_ERROR,
                              message:
                                  'The conditions are not covering all possible cases. Please add a default category.',
                          },
                      ];
        }
    });

const validateRatingTable = (
    blueprint: LocalBlueprint,
    nodeId: keyof LocalBlueprint['nodes'],
): Array<ValidationError> => {
    const { rates, dimensions } = (blueprint.nodes?.[nodeId] as LocalNode)
        .configuration;

    const missingInputForDimensions = getMissingInputForDimensions(
        blueprint,
        nodeId,
        dimensions,
    );

    if (missingInputForDimensions.length > 0) {
        return missingInputForDimensions;
    }

    const valuesByDimension = dimensions.reduce(getValuesFromDimension, {});
    const dimensionsWithNullValue =
        getDimensionsWithUndefinedValue(valuesByDimension);

    if (dimensionsWithNullValue.length > 0) {
        return [
            {
                id: nodeId,
                code: VALIDATION_CODES.NODE_CONFIGURATION_ERROR,
                message: `The following dimensions are containing null values: ${dimensionsWithNullValue.join(
                    ', ',
                )}`,
            },
        ];
    }

    const expectedRatesLength = getExpectedRatesLengthFromDimensions(
        dimensions.map((dimension) => dimension.input),
        valuesByDimension,
    );

    if (rates.length !== expectedRatesLength) {
        return [
            {
                id: nodeId,
                code: VALIDATION_CODES.NODE_CONFIGURATION_ERROR,
                message: `This rating table must have exactly ${expectedRatesLength} values given its number of rows, but currently have ${rates.length}.`,
            },
        ];
    }

    return validateExhaustiveMatch(dimensions, nodeId);
};

export default validateRatingTable;
