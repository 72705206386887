/* eslint-disable react/prop-types */

import { useEffect, useState } from 'react';
import ArchiveIcon from '@material-ui/icons/Archive';
import { useMutation, gql } from '@apollo/client';
import { makeStyles, ThemeProvider, Typography } from '@material-ui/core';
import theme, { mainRed } from '../v4/theme';

import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { useHistory, Link } from 'react-router-dom';
import { TopToolbar, useNotify } from 'react-admin';
import { Button, Confirm } from '../v4';

const useStyles = makeStyles(() => ({
    archiveButton: {
        color: mainRed,
    },
    archiveConfirmationButton: {
        background: mainRed,
    },
}));

const ARCHIVE_MODEL = gql`
    mutation archiveModel($id: ID!) {
        archiveModel(id: $id) {
            id
        }
    }
`;

const ModelEditActions = ({ basePath, data }) => {
    const classes = useStyles();
    const history = useHistory();
    const [showConfirmation, setShowConfirmation] = useState(false);

    const [
        archiveModel,
        { data: archivedModeData, loading: archiving, error },
    ] = useMutation(ARCHIVE_MODEL);

    const notify = useNotify();

    useEffect(() => {
        if (!error) {
            return;
        }

        notify(error.message, 'error');
    }, [error]);

    useEffect(() => {
        if (!archivedModeData) {
            return;
        }

        notify('The model has been archived!');
        history.push(basePath);
    }, [archivedModeData]);

    if (!data) {
        return null;
    }

    if (data.archived) {
        return (
            <TopToolbar>
                <Button className={classes.archiveButton} disabled>
                    Archived
                </Button>
            </TopToolbar>
        );
    }

    const onArchiveConfirmation = () => {
        setShowConfirmation(false);
        archiveModel({ variables: { id: data.id } });
    };

    return (
        <ThemeProvider theme={theme}>
            <TopToolbar>
                <Link
                    to={`/Model/${data.id}/test`}
                    component={Button}
                    startIcon={<PlayArrowIcon />}
                    disabled={!data.isValid}
                >
                    Test
                </Link>
                <Button
                    startIcon={<ArchiveIcon />}
                    onClick={() => setShowConfirmation(true)}
                    className={classes.archiveButton}
                    loading={archiving}
                >
                    Archive
                </Button>
            </TopToolbar>
            <Confirm
                danger
                open={showConfirmation}
                onClose={() => setShowConfirmation(false)}
                onConfirm={onArchiveConfirmation}
                title="Archiving a model"
                confirmClassName={classes.archiveConfirmationButton}
            >
                <>
                    <Typography variant="body1">
                        You are about to archive the{' '}
                        <strong>{data.name}</strong> model.
                    </Typography>
                    <Typography variant="body1">
                        An archived model can't be cloned, nor edited anymore.
                        It will not appear anymore on the app.
                    </Typography>
                    <Typography variant="body1">
                        All past predictions using this model will be kept. And
                        simulations are still able to be run through the
                        archived models.
                    </Typography>
                </>
            </Confirm>
        </ThemeProvider>
    );
};

// Do not put default props here as it breaks the RA vaudoo!

export default ModelEditActions;
