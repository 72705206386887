import validator from './validate';

const maximumProcessor = {
    name: 'Maximum',
    slug: 'maximum',
    description: 'Return the highest value of all given inputs',
    validate: validator,
    inputs: {
        id: 'inputs',
        dynamic: true,
        min: 2,
        schema: { type: 'number' },
    },
    outputs: [
        {
            id: 'output',
            name: 'Output',
            schema: { type: 'number' },
        },
    ],
};

export default maximumProcessor;
