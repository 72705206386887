import { UnControlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/addon/fold/brace-fold';
import 'codemirror/addon/fold/foldgutter';
import 'codemirror/addon/fold/foldgutter.css';

import PropTypes from 'prop-types';
import { Button, makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

const LINE_HEIGHT = 17;

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        height: ({ expanded, expandThreshold, expandable }) =>
            expandable && !expanded ? expandThreshold * LINE_HEIGHT : 'initial',
    },
    codeMirror: {
        height: ({ expanded }) => (expanded ? 'initial' : '100%'),
        maxWidth: '100%',
        '& .CodeMirror': {
            padding: theme.spacing(1),
            height: ({ expanded }) => (expanded ? 'initial' : '100%'),
        },
    },
    expandMoreOverlay: {
        cursor: 'pointer',
        backgroundImage: ({ expanded }) =>
            !expanded && `linear-gradient(to bottom, #0000 0%, #000f 85%)`,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 10,
        '& $expandButton': {
            opacity: 0,
        },
        '&:hover $expandButton': {
            opacity: 1,
        },
    },
    expandButton: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
    },
    reduceOverlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
    reduceButton: {
        position: 'absolute',
        right: theme.spacing(1),
        bottom: theme.spacing(2),
        transform: 'translate(-50%, -50%)',
        zIndex: 10,
    },
}));

const getBlacklistReplacer = (blacklistedProperties) => (key, value) => {
    if (blacklistedProperties.includes(key)) {
        return undefined;
    }

    return value;
};

const JsonField = ({
    expandThreshold,
    blacklistedProperties,
    json,
    options,
}) => {
    const numberLines = JSON.stringify(json, null, 2).split('\n').length;
    const expandable = expandThreshold > 0 && numberLines > expandThreshold;

    const [instance, setInstance] = useState(null);

    const [expanded, setExpanded] = useState(false);
    const classes = useStyles({ expandable, expandThreshold, expanded });

    const expand = () => setExpanded(true);
    const reduce = () => setExpanded(false);

    useEffect(() => {
        if (!instance) {
            return;
        }

        instance.refresh();
    }, [instance, expanded]);

    return (
        <div className={classes.root}>
            {expandable && !expanded && (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                <div
                    className={classes.expandMoreOverlay}
                    onClick={expand}
                    role="button"
                    tabIndex={0}
                    index={0}
                >
                    <Button
                        className={classes.expandButton}
                        variant="contained"
                        endIcon={<ExpandMore />}
                    >
                        Expand
                    </Button>
                </div>
            )}
            <CodeMirror
                editorDidMount={setInstance}
                className={classes.codeMirror}
                value={JSON.stringify(
                    json,
                    getBlacklistReplacer(blacklistedProperties),
                    4,
                )}
                options={{
                    mode: {
                        name: 'javascript',
                        json: true,
                    },
                    theme: 'material',
                    lineNumbers: true,
                    readOnly: true,
                    foldGutter: true,
                    gutters: [
                        'CodeMirror-linenumbers',
                        'CodeMirror-foldgutter',
                    ],
                    lineWrapping: true,
                    ...options,
                }}
            />
            {expandable && expanded && (
                <div className={classes.reduceOverlay}>
                    <Button
                        className={classes.reduceButton}
                        onClick={reduce}
                        variant="contained"
                    >
                        Collapse <ExpandLess />
                    </Button>
                </div>
            )}
        </div>
    );
};

JsonField.propTypes = {
    expandThreshold: PropTypes.number,
    blacklistedProperties: PropTypes.arrayOf(PropTypes.string),
    json: PropTypes.any,
    options: PropTypes.object,
};

JsonField.defaultProps = {
    blacklistedProperties: [],
    expandThreshold: null,
    json: null,
    options: {},
};

export default JsonField;
