import { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Grid } from '@material-ui/core';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import Schedule from '@material-ui/icons/Schedule';
import { useFormState } from 'react-final-form';

import TextInput from './TextInput';

const useStyles = makeStyles((theme) => ({
    dialogPaperRoot: {
        padding: 0,
    },
    adornmentRoot: {
        position: 'relative',
        marginRight: -9,
        left: -8,
        '& svg': {
            marginRight: 0,
        },
    },
    input: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
    },
}));

const setTimeToDate = (dateTime, date) => {
    if (!dateTime) {
        return null;
    }

    return new Date(
        new Date(date).setHours(
            dateTime.getHours(),
            dateTime.getMinutes(),
            0,
            0,
        ),
    );
};

const DateTimePicker = ({
    dateLabel,
    timeLabel,
    input,
    variant,
    meta,
    ...rest
}) => {
    const classes = useStyles();
    const { values } = useFormState();
    const [dateTime, setDateTime] = useState(
        input.value && typeof input.value === 'string'
            ? new Date(input.value)
            : input.value,
    );

    const handleTimeChange = (time) => {
        setDateTime(time);
        if (!time || isNaN(time.getTime())) {
            return;
        }
        input.onChange(setTimeToDate(time, values[input.name] || new Date()));
    };

    return (
        <Grid container justifyContent="center">
            <Grid item xs={5} className={classes.input}>
                <KeyboardDatePicker
                    format="MM/dd/yyyy"
                    input={input}
                    inputVariant={variant}
                    value={input.value ? input.value : null}
                    classes={{
                        root: classes.root,
                    }}
                    InputAdornmentProps={{
                        position: 'start',
                        classes: {
                            root: classes.adornmentRoot,
                        },
                    }}
                    variant="inline"
                    PopoverProps={{
                        PaperProps: {
                            classes: {
                                root: classes.dialogPaperRoot,
                                adornmentStart: classes.adornmentStart,
                            },
                        },
                    }}
                    onChange={() => undefined} // Prevent from invalid date message while typing
                    onAccept={(date) => {
                        setDateTime(date);
                        input.onChange(date);
                    }}
                    onBlur={(e) => {
                        if (!e.target.value) {
                            setDateTime(null);
                            input.onChange(null);
                            return;
                        }
                        const newDate = setTimeToDate(
                            dateTime || new Date(),
                            new Date(e.target.value),
                        );
                        setDateTime(newDate);
                        input.onChange(newDate);
                    }}
                    TextFieldComponent={TextInput}
                    {...rest}
                    shrink={!!input.value}
                    label={dateLabel}
                    meta={meta}
                    error={!!meta.error && meta.touched}
                    autoOk
                />
            </Grid>
            <Grid item xs={5} className={classes.input}>
                <KeyboardTimePicker
                    inputVariant={variant}
                    value={dateTime ? dateTime : null}
                    input={{ ...input, value: dateTime ? dateTime : null }}
                    classes={{
                        root: classes.root,
                    }}
                    InputAdornmentProps={{
                        position: 'start',
                        classes: {
                            root: classes.adornmentRoot,
                        },
                    }}
                    variant="inline"
                    PopoverProps={{
                        PaperProps: {
                            classes: {
                                root: classes.dialogPaperRoot,
                                adornmentStart: classes.adornmentStart,
                            },
                        },
                    }}
                    onChange={handleTimeChange}
                    onAccept={handleTimeChange}
                    {...rest}
                    TextFieldComponent={TextInput}
                    keyboardIcon={<Schedule />}
                    label={timeLabel}
                    meta={meta}
                    error={!!meta.error && meta.touched}
                    autoOk
                />
            </Grid>
        </Grid>
    );
};

DateTimePicker.propTypes = {
    dateLabel: PropTypes.string.isRequired,
    timeLabel: PropTypes.string.isRequired,
    input: PropTypes.object.isRequired,
    variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
    meta: PropTypes.shape({
        touched: PropTypes.bool.isRequired,
        error: PropTypes.string,
    }).isRequired,
};

DateTimePicker.defaultProps = {
    variant: 'standard',
};

export default DateTimePicker;
