import PropTypes from 'prop-types';
import {
    Checkbox as CheckboxBase,
    FormControlLabel,
    FormHelperText,
    makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
    label: {
        position: 'relative',
        top: 1,
    },
}));

const Checkbox = ({ label, helper, input, ...otherProps }) => {
    const classes = useStyles();

    return (
        <>
            <FormControlLabel
                classes={{
                    label: classes.label,
                }}
                control={
                    <CheckboxBase color="primary" {...input} {...otherProps} />
                }
                label={label}
            />
            {helper && <FormHelperText>{helper}</FormHelperText>}
        </>
    );
};

Checkbox.propTypes = {
    label: PropTypes.string.isRequired,
    helper: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    input: PropTypes.shape({
        value: PropTypes.string,
        onChange: PropTypes.func.isRequired,
    }).isRequired,
};

Checkbox.defaultProps = {
    helper: null,
};

export default Checkbox;
