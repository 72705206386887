class UnknownAttributeError extends Error {
    path: string;

    constructor(path: string) {
        super(path);
        this.path = path;
        this.message = `Unable to find ${path} in validation schema.`;
    }
}

export default UnknownAttributeError;
