import {
    forwardRef,
    cloneElement,
    Children,
    useState,
    useRef,
    useEffect,
} from 'react';

import PropTypes from 'prop-types';
import classnames from 'classnames';

import {
    ClickAwayListener,
    Grow,
    Paper,
    Popper,
    MenuList,
    IconButton,
    makeStyles,
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';

const useButtonStyles = makeStyles((theme) => ({
    button: {
        height: theme.spacing(4),
        width: theme.spacing(4),
        marginLeft: theme.spacing(1),
        color: theme.palette.cardOutlineGray,
        '& svg': {
            fill: theme.palette.darkerGray,
        },
    },
}));

export const DefaultButton = forwardRef(function DefaultButton(props, ref) {
    const classes = useButtonStyles();

    return (
        <IconButton
            {...props}
            classes={{
                root: classnames(classes.button, props.className),
                ...props.classes,
            }}
            ref={ref}
        >
            {props.icon}
        </IconButton>
    );
});
DefaultButton.displayName = 'DefaultButton';

DefaultButton.propTypes = {
    icon: PropTypes.node,
    className: PropTypes.string,
    classes: PropTypes.object,
};

DefaultButton.defaultProps = {
    icon: null,
    className: null,
    classes: {},
};

const useStyles = makeStyles(() => ({
    paper: {
        padding: 0,
    },
}));

/**
 * @see https://material-ui.com/components/menus/#menulist-composition
 *
 * @example
 * <PopoverMenu>
 *     <MenuItem onClick={console.log}>Item 1</MenuItem>
 *     <MenuItem onClick={console.log}>Item 2</MenuItem>
 *     <MenuItem onClick={console.log}>Item 3</MenuItem>
 * </PopoverMenu>
 */
const PopoverMenu = ({
    children,
    button,
    buttonIcon,
    buttonClassName,
    container,
    PopperProps,
    buttonAnchorRef,
    open: defaultOpen,
    onChange,
    ...props
}) => {
    const classes = useStyles();

    const [open, setOpenValue] = useState(defaultOpen);
    const anchorRef = buttonAnchorRef || useRef(null);

    const setOpen = (value) => {
        setOpenValue(value);
        onChange(value);
    };

    useEffect(() => {
        setOpen(defaultOpen);
    }, [defaultOpen]);

    const handleClick = (evt) => {
        evt.preventDefault();
        evt.stopPropagation();
        setOpen((prevOpen) => !prevOpen);
    };

    const onMouseDown = (evt) => {
        evt.preventDefault();
        evt.stopPropagation();
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const handleListKeyDown = (evt) => {
        if (evt.key === 'Tab') {
            evt.preventDefault();
            setOpen(false);
        }
    };

    const handleChildClick =
        (fn) =>
        (...args) => {
            if (fn) {
                fn(...args);
            }

            setOpen(false);
        };

    return (
        <>
            {cloneElement(button, {
                ...props,
                ref: anchorRef,
                'aria-controls': open ? 'open-popover-menu' : undefined,
                'aria-haspopup': 'true',
                onClick: handleClick,
                onMouseDown,
                className: buttonClassName,
                icon: buttonIcon,
            })}
            <Popper
                {...PopperProps}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                container={container}
                style={{ zIndex: 5 }}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom'
                                    ? 'center top'
                                    : 'center bottom',
                        }}
                    >
                        <Paper className={classes.paper} elevation={2}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    id="open-popover-menu"
                                    onKeyDown={handleListKeyDown}
                                >
                                    {Children.map(children, (child) =>
                                        cloneElement(child, {
                                            ...child.props,
                                            onClick: handleChildClick(
                                                child.props.onClick,
                                            ),
                                        }),
                                    )}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};

PopoverMenu.propTypes = {
    open: PropTypes.bool,
    onChange: PropTypes.func,
    button: PropTypes.node,
    buttonIcon: PropTypes.node,
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]).isRequired,
    buttonClassName: PropTypes.string,
    container: PropTypes.any,
    PopperProps: PropTypes.object,
    buttonAnchorRef: PropTypes.any,
};

PopoverMenu.defaultProps = {
    open: false,
    onChange: () => {},
    button: <DefaultButton />,
    buttonIcon: <MoreVert />,
    buttonClassName: null,
    container: undefined,
    PopperProps: {},
    buttonAnchorRef: null,
};

export default PopoverMenu;
