import { createValidator } from '../../validation/validatorFactory';

const validator = createValidator();

const validate = validator.compile({
    type: 'object',
    properties: {
        type: {
            enum: ['interval', 'string', 'boolean'],
            type: 'string',
        },
        conditions: {
            type: 'array',
            items: {
                oneOf: [
                    {
                        type: 'object',
                        required: ['label'],
                        properties: {
                            gt: { type: 'number' },
                            lt: { type: 'number' },
                            gte: { type: 'number' },
                            lte: { type: 'number' },
                            eq: { type: 'number' },
                            label: {
                                nullable: false,
                                type: 'string',
                            },
                        },
                        additionalProperties: false,
                    },
                    {
                        type: 'object',
                        required: ['label'],
                        properties: {
                            operation: {
                                type: 'string',
                                enum: [
                                    'match',
                                    'contain',
                                    'start-with',
                                    'end-with',
                                ],
                            },
                            values: {
                                type: 'array',
                                items: { type: 'string' },
                                nullable: false,
                            },
                            label: {
                                nullable: false,
                                type: 'string',
                            },
                        },
                        additionalProperties: false,
                    },
                    {
                        type: 'object',
                        required: ['value', 'label'],
                        properties: {
                            value: {
                                type: 'boolean',
                                nullable: false,
                            },
                            label: {
                                nullable: false,
                                type: 'string',
                            },
                        },
                        additionalProperties: false,
                    },
                ],
            },
        },
        catchAll: { nullable: true, type: 'string' },
    },
    additionalProperties: false,
});

export default validate;
