import 'codemirror';
import type { FunctionComponent } from 'react';
import { useState } from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';

import { useInput, Labeled, InputHelperText } from 'react-admin';
import FormHelperText from '@material-ui/core/FormHelperText';

import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/javascript/javascript';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    codeMirror: {
        '& .CodeMirror': {
            height: ({ height = 300 }: { height: number }) =>
                `${height}px !important`, // remove CodeMirror 300px default CSS
        },
    },
}));

type Props = {
    height?: number;
    disabled?: boolean;
    helperText?: string;
    label: string;
    source: string;
};

export const JsonInput: FunctionComponent<Props> = ({
    height = 300,
    disabled = false,
    helperText,
    ...props
}) => {
    const classes = useStyles({ height });

    const {
        input: { onChange, value },
        meta: { touched, error },
    } = useInput({
        ...props,
        format: (value) => JSON.stringify(value, null, 4),
        parse: (value) => {
            try {
                return JSON.parse(value);
            } catch (err) {
                return value;
            }
        },
    });

    const [rawValue, setRawValue] = useState(value);

    const handleChange = (editor, data, value: string) => {
        setRawValue(value);
        onChange(value);
    };

    const handleBeforeChange = (editor, data, value: string) => {
        setRawValue(value);
    };

    return (
        <>
            <Labeled label={props.label} fullWidth>
                <div data-value={rawValue}>
                    <CodeMirror
                        className={classes.codeMirror}
                        value={rawValue}
                        onChange={handleChange}
                        options={{
                            mode: {
                                name: 'javascript',
                                json: true,
                            },
                            theme: 'material',
                            lineNumbers: true,
                            readOnly: !!disabled,
                        }}
                        onBeforeChange={handleBeforeChange}
                    />
                </div>
            </Labeled>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
            {touched &&
                error &&
                error.split('\n').map((chunk) => (
                    <FormHelperText key={chunk} error>
                        <InputHelperText error={chunk} touched />
                    </FormHelperText>
                ))}
        </>
    );
};
