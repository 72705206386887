import PropTypes from 'prop-types';
import { Grid, Typography, Tab } from '@material-ui/core';
import { Field } from 'react-final-form';

import { ExternalDataCredentialInput, Select } from '../../../../forms';
import { insuranceProductProptype } from '../../../../../../../tint-app/src/insuranceProduct/versions/propTypes';

import TimeoutField from './fields/TimeoutField';

const DEFAULT_TIMEOUT = 3000;
const DEFAULT_FALLBACK_VALUE = 'Challenge';

const parseFallbackValue = (value) => (value === 'null' ? null : value);
const formatFallbackValue = (value) => (value === null ? 'null' : value);

export const getEquifaxConfigurationTabs = () => [
    <Tab key="configuration" label="Configuration" />,
    <Tab key="advanced" label="Advanced" />,
];

const options = [
    {
        value: 'Allow',
        label: 'Allow',
    },
    {
        value: 'Challenge',
        label: 'Challenge',
    },
    { value: 'Deny', label: 'Deny' },
    {
        value: 'null',
        label: 'Null',
    },
];

const EquifaxDigitalIdentityTrustConfiguration = ({
    insuranceProduct,
    disabled,
    tab,
}) => {
    if (tab === 1) {
        return (
            <Grid container direction="column" spacing={2}>
                <Grid item xs={8}>
                    <TimeoutField
                        disabled={disabled}
                        defaultTimeout={DEFAULT_TIMEOUT}
                        helperText="Max wait time for Equifax response before raising an error"
                    />
                </Grid>
                <Grid item xs={8}>
                    <Field
                        component={Select}
                        disabled={disabled}
                        name="node.configuration.fallbackValue"
                        label="Default Value on Error"
                        options={options}
                        defaultValue={DEFAULT_FALLBACK_VALUE}
                        helperText={
                            <>
                                Equifax may sometimes be too slow to respond or
                                return an error,
                                <br />
                                in those cases, this default value will be
                                returned
                            </>
                        }
                        disableClearable
                        parse={parseFallbackValue}
                        format={formatFallbackValue}
                    />
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <Typography variant="body2">
                    The following credentials will be encrypted before being
                    saved, and nobody in your team will be able to read them.
                </Typography>
            </Grid>
            <Grid item>
                <ExternalDataCredentialInput
                    insuranceProduct={insuranceProduct}
                    providerSlug="equifax-digital-identity-trust"
                    name="applicationId"
                    label="Application ID"
                    variant="outlined"
                    fullWidth
                    required
                    type="password"
                    disabled={disabled}
                />
            </Grid>
            <Grid item>
                <ExternalDataCredentialInput
                    insuranceProduct={insuranceProduct}
                    providerSlug="equifax-digital-identity-trust"
                    name="tenantId"
                    label="Tenant ID"
                    variant="outlined"
                    fullWidth
                    required
                    type="password"
                    disabled={disabled}
                />
            </Grid>
            <Grid item>
                <ExternalDataCredentialInput
                    insuranceProduct={insuranceProduct}
                    providerSlug="equifax-digital-identity-trust"
                    name="clientId"
                    label="Client ID"
                    variant="outlined"
                    fullWidth
                    required
                    type="password"
                    disabled={disabled}
                />
            </Grid>
            <Grid item>
                <ExternalDataCredentialInput
                    insuranceProduct={insuranceProduct}
                    providerSlug="equifax-digital-identity-trust"
                    name="clientSecret"
                    label="Client Secret"
                    variant="outlined"
                    fullWidth
                    required
                    type="password"
                    disabled={disabled}
                />
            </Grid>
        </Grid>
    );
};

EquifaxDigitalIdentityTrustConfiguration.propTypes = {
    disabled: PropTypes.bool,
    insuranceProduct: insuranceProductProptype.isRequired,
    tab: PropTypes.number.isRequired,
};

EquifaxDigitalIdentityTrustConfiguration.defaultProps = {
    disabled: false,
};

export default EquifaxDigitalIdentityTrustConfiguration;
