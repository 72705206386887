import { isEqual, cloneDeep } from 'lodash';
import { useState, useRef, useEffect } from 'react';

import validateModelBlueprint from '@tint/core/src/validation/validateModelBlueprint';

import aggregateBlueprintState from './aggregateBlueprintState';

const useBlueprintEditorState = (
    initialBlueprint,
    initialEditorData,
    validationSchema = null,
) => {
    // keep reference of original for history settings
    const originalBlueprint = useRef(cloneDeep(initialBlueprint));
    const originalEditorData = useRef(cloneDeep(initialEditorData));

    const [blueprint, setBlueprint] = useState(initialBlueprint);
    const [editorData, setEditorData] = useState(initialEditorData);
    const [errors, setErrors] = useState([]);
    const pristine = useRef(true);

    // Using a ref to store events because we don't want to trigger a change when the events are stacked
    const eventsRef = useRef([]);
    const deletedEventsRef = useRef([]);

    const recomputeState = () => {
        const { blueprint: newBlueprint, editorData: newEditorData } =
            aggregateBlueprintState(
                originalBlueprint.current,
                originalEditorData.current,
                eventsRef.current,
            );

        if (!isEqual(blueprint, newBlueprint)) {
            setBlueprint(newBlueprint);
        }

        if (!isEqual(editorData, newEditorData)) {
            setEditorData(newEditorData);
        }
    };

    useEffect(() => {
        // This reset will be called:
        // At the first render of the component to bootstrap the blueprint & editorData state
        if (pristine.current) {
            eventsRef.current = [];
            pristine.current = false;
        }
        recomputeState();
    }, [initialBlueprint, initialEditorData]);

    useEffect(() => {
        // Manually debounce the blueprint value, it can save a render compared with the usage of:
        // const debouncedBlueprint = useDebounce(blueprint, 300);
        const timeout = setTimeout(() => {
            if (validationSchema) {
                setErrors(validateModelBlueprint(blueprint, validationSchema));
            }
        }, 300);

        return () => {
            clearTimeout(timeout);
        };
    }, [blueprint, validationSchema]);

    const dispatch = (event) => {
        eventsRef.current.push(event);
        deletedEventsRef.current = [];
        recomputeState();
    };

    const undo = () => {
        const events = [...eventsRef.current];

        if (events.length > 0) {
            const lastEvent = events.pop();
            eventsRef.current = events;
            deletedEventsRef.current.push(lastEvent);

            recomputeState();
        }
    };

    const redo = () => {
        const deletedEvents = [...deletedEventsRef.current];

        if (deletedEvents.length > 0) {
            const lastDeletedEvent = deletedEvents.pop();
            eventsRef.current.push(lastDeletedEvent);
            deletedEventsRef.current = deletedEvents;

            recomputeState();
        }
    };

    const reset = (newBlueprint, newEditorData) => {
        originalBlueprint.current = cloneDeep(newBlueprint);
        originalEditorData.current = cloneDeep(newEditorData);
        setBlueprint(newBlueprint);
        setEditorData(newEditorData);
        setErrors([]);
        eventsRef.current = [];
        deletedEventsRef.current = [];
    };

    return {
        blueprint,
        editorData,
        dispatch,
        undo,
        redo,
        undoAble: !!eventsRef.current.length,
        redoAble: !!deletedEventsRef.current.length,
        errors,
        reset,
    };
};

export default useBlueprintEditorState;
