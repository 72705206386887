import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Field } from 'react-final-form';

import { Select } from '../../../../forms';

const options = [
    {
        value: 'day_of_week',
        label: 'Day of Week',
    },
    {
        value: 'day_of_month',
        label: 'Day of Month',
    },
    {
        value: 'day_of_year',
        label: 'Day of Year',
    },
    {
        value: 'week_number',
        label: 'Week Number',
    },
    {
        value: 'month',
        label: 'Month',
    },
    {
        value: 'quarter',
        label: 'Quarter',
    },
    {
        value: 'year',
        label: 'Year',
    },
    {
        value: 'iso_date',
        label: 'ISO 8601 Date (YYYY-MM-DD)',
    },
];

const DatePartConfiguration = ({ disabled }) => {
    return (
        <Grid container direction="column" spacing={1}>
            <Grid item>
                <Field
                    component={Select}
                    disabled={disabled}
                    name="node.configuration.date_part"
                    label="Date Part"
                    options={options}
                />
            </Grid>
        </Grid>
    );
};

DatePartConfiguration.propTypes = {
    disabled: PropTypes.bool,
};

DatePartConfiguration.defaultProps = {
    disabled: false,
};

export default DatePartConfiguration;
