import { gql, useLazyQuery } from '@apollo/client';
import { get } from 'lodash';
import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { IconButton, makeStyles, Typography } from '@material-ui/core';

import { dataPropType } from '../blockPropTypes';
import ConnectorPin from '../ConnectorPin';
import { VIEW_MODE_SIMPLIFIED } from '../../viewModes/viewModes';

import DefaultPins from '../DefaultPins';
import { SearchIcon } from '../../../../icons';

export const ReferencedModelHeaderPins = ({ data, ...props }) => {
    const { viewMode, connectedInputs, disabled } = data;

    return (
        <>
            {(!disabled || !!connectedInputs.length) && (
                <ConnectorPin
                    {...props}
                    selector={`${data.id}-conditions[0]`}
                    nodeId={data.id}
                    type="target"
                    position={
                        viewMode === VIEW_MODE_SIMPLIFIED ? 'left' : 'top'
                    }
                    id="conditions[0]"
                    name="Trigger"
                />
            )}
            <DefaultPins data={data} {...props} />
        </>
    );
};

ReferencedModelHeaderPins.propTypes = {
    data: PropTypes.shape(dataPropType).isRequired,
};

ReferencedModelHeaderPins.defaultProps = {};

export const GET_MODEL_QUERY = gql`
    query getModel($id: ID!) {
        model(id: $id) {
            id
            name
            tintManaged
            blueprint
            editorData
            outputType
        }
    }
`;

export const GET_PREDICTION_TRACE = gql`
    query getPredictionTrace($id: ID!) {
        prediction(id: $id) {
            trace
        }
    }
`;

const useStyles = makeStyles((theme) => ({
    section: {
        position: 'relative',
        '&:not(:last-child)': {
            borderBottom: `1px dashed ${theme.palette.lighterGray}`,
        },
    },
    label: {
        padding: theme.spacing(2),
        fontSize: 12,
    },
    icon: {
        marginRight: theme.spacing(),
    },
    actionSection: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        padding: theme.spacing(1, 2),
        fontSize: 12,
        color: theme.palette.primary.main,
        cursor: 'pointer',
        borderRadius: 0,
    },
}));

const stopEventPropagation = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
};

const ReferencedModelContent = ({ data, onViewDetails }) => {
    const classes = useStyles();

    const modelId = get(data, 'configuration.modelId', null);
    const predictionId = get(data, 'trace.outputs.predictionId');

    const [getModel, { data: modelData }] = useLazyQuery(GET_MODEL_QUERY);
    const [getPredictionTrace, { data: predictionData }] =
        useLazyQuery(GET_PREDICTION_TRACE);
    const model = get(modelData, 'model', null);
    const trace = get(predictionData, 'prediction.trace', null);

    useEffect(() => {
        if (modelId) {
            getModel({ variables: { id: modelId } });
        }
    }, [modelId]);

    useEffect(() => {
        if (predictionId) {
            getPredictionTrace({ variables: { id: predictionId } });
        }
    }, [predictionId]);

    const handleSeeDetails = (evt) => {
        stopEventPropagation(evt);
        onViewDetails(model, trace);
    };

    if (model) {
        return (
            <>
                <div className={classes.section}>
                    <Typography
                        component="div"
                        variant="body1"
                        className={classes.label}
                    >
                        {model.name}
                    </Typography>
                </div>
                {!data.disabled && (
                    <div className={classes.section}>
                        <Typography
                            component="div"
                            variant="body1"
                            className={classes.actionSection}
                        >
                            + Change Model
                        </Typography>
                    </div>
                )}
                {!model.tintManaged && trace && onViewDetails && (
                    <div className={classes.section}>
                        <IconButton
                            size="small"
                            className={classes.actionSection}
                            onClick={handleSeeDetails}
                            onMouseUp={stopEventPropagation}
                        >
                            <SearchIcon className={classes.icon} /> View details
                        </IconButton>
                    </div>
                )}
            </>
        );
    }

    if (!data.disabled) {
        return (
            <div className={classes.section}>
                <Typography
                    component="div"
                    variant="body1"
                    className={classes.actionSection}
                >
                    + Choose Model
                </Typography>
            </div>
        );
    }

    return null;
};

ReferencedModelContent.propTypes = {
    data: PropTypes.shape(dataPropType).isRequired,
    onViewDetails: PropTypes.func,
};

ReferencedModelContent.defaultProps = {
    onViewDetails: null,
};

export default ReferencedModelContent;
