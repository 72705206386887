// Need help for typing the validation/processors directory
import { get } from 'lodash';
import type { ProcessorName } from '.';
import processors from '.';
import InternalError from '../InternalError';

import OUTPUT_TYPES from './outputTypes';
import type { Blueprint } from './types';

const getNodeProcessor = (node: any) => {
    const processor = processors[node.processor as ProcessorName];
    if (!processor) {
        throw new InternalError(
            `Unable to find the processor "${node.processor}"`,
        );
    }

    return processor;
};

export const hasDynamicOutputs = (node: any) => {
    const processor: any = getNodeProcessor(node);
    return !!processor.outputs.dynamic;
};

export const hasDynamicInputs = (node: any) => {
    const processor: any = getNodeProcessor(node);
    return !!processor.inputs.dynamic;
};

export const getMinimumRequiredInputs = (node: any) => {
    const processor = getNodeProcessor(node);
    return get(processor, 'inputs.min', 1);
};

const regroupInputs = (inputs: any) =>
    inputs.reduce((acc: any, input: any) => {
        if (input.group) {
            if (acc.find((inp: any) => inp.id === input.group)) {
                return acc;
            }

            acc.push({
                id: input.group,
                name: inputs
                    .filter((inp: any) => inp.group === input.group)
                    .map((inp: any) => inp.name)
                    .join('\n'),
            });
            return acc;
        }

        acc.push(input);
        return acc;
    }, []);

export const parseDescription = (
    description: any,
    node: any,
    regrouped: any = true,
) => {
    if (!description) {
        return null;
    }

    if (Array.isArray(description)) {
        return regrouped ? regroupInputs(description) : description;
    }

    if (typeof description === 'function') {
        return regrouped ? regroupInputs(description(node)) : description(node);
    }

    if (description.dynamic) {
        return [
            {
                id: description.id,
                name: description.name,
                schema: description.schema,
            },
        ];
    }

    throw new InternalError('Unable to transform the I/O description to a pin');
};

export const isGroupedInput = (node: any, input: any) => {
    if (hasDynamicInputs(node)) {
        return false;
    }

    const processor = getNodeProcessor(node);
    const inputs = parseDescription(processor.inputs, node, false);

    return !!inputs.find((inp: { group: any }) => inp.group === input);
};

export const hasNamedPins = (node: any) => {
    const processor = getNodeProcessor(node);
    const inputs = parseDescription(processor.inputs, node, false);
    return inputs.length && inputs[0].name && inputs[0].name !== 'Input';
};

export const getNodeInputs = (node: any, regrouped = true) => {
    const processor = getNodeProcessor(node);
    return parseDescription(processor.inputs, node, regrouped);
};

export const getNodeOutputs = (node: any, regrouped = true) => {
    const processor = getNodeProcessor(node);
    return parseDescription(processor.outputs, node, regrouped);
};

export const getOutputTypeFromSchema: any = (schema: any) => {
    if (schema.oneOf) {
        return schema.oneOf.map(getOutputTypeFromSchema);
    }

    const { type, format } = schema;

    if (Array.isArray(type)) {
        const nullableType = type.find((v) => v !== 'null');
        return getOutputTypeFromSchema({ type: nullableType, format });
    }

    if (type === 'string') {
        if (format && format.toLowerCase() === 'date') {
            return OUTPUT_TYPES.DATE;
        }

        if (format && format.toLowerCase() === 'date-time') {
            return OUTPUT_TYPES.DATETIME;
        }

        return OUTPUT_TYPES.STRING;
    }

    if (type === 'integer' || type === 'number') {
        return OUTPUT_TYPES.NUMBER;
    }

    if (type === 'boolean') {
        return OUTPUT_TYPES.BOOLEAN;
    }

    throw new InternalError(`Unsupported JSON Schema type "${type}"`);
};

export const getInputTypes = (blueprint: any, nodeId: any, inputId: any) => {
    const node = blueprint.nodes[nodeId];
    const inputs = getNodeInputs(node, false);
    const input = hasDynamicInputs(node)
        ? inputs[0] // The dynamic inputs return the same output type
        : inputs.find((i: { id: string }) => i.id === inputId);

    if (!input) {
        throw new InternalError(
            `Unable to find the input "${inputId}" for the node "${nodeId}"`,
        );
    }

    if (!input.schema) {
        return [OUTPUT_TYPES.ANY];
    }

    const inputType = getOutputTypeFromSchema(input.schema);

    return Array.isArray(inputType) ? inputType : [inputType];
};

export const isConditionEdge = (edge: any) =>
    edge.end.pin.startsWith('conditions[');

type GetEdgesForNode = {
    nodeId: string;
    blueprint: Blueprint;
    incoming?: boolean;
    outgoing?: boolean;
    conditions?: boolean;
};

export const getEdgesForNode = ({
    nodeId,
    blueprint,
    incoming = true,
    outgoing = true,
    conditions = true,
}: GetEdgesForNode) => {
    const edges = (blueprint.edges || []).filter(
        (edge) =>
            (outgoing && edge.start.node === nodeId) ||
            (incoming && edge.end.node === nodeId),
    );

    return conditions ? edges : edges.filter((edge) => !isConditionEdge(edge));
};

export const getOutputNodeIds = (blueprint: any) =>
    Object.keys(get(blueprint, 'nodes', {})).filter(
        (nodeId) =>
            getEdgesForNode({
                nodeId,
                blueprint,
                incoming: false,
                conditions: true,
            }).length === 0 &&
            // @TODO Find a better way to flag output nodes
            ['constant', 'passthrough', 'decision'].includes(
                blueprint.nodes[nodeId].processor,
            ),
    );
