import { createValidator } from '../../validation/validatorFactory';

const validator = createValidator();

const validate = validator.compile({
    type: 'object',
    required: ['modelId'],
    properties: {
        modelId: { type: 'string' },
        outputType: { type: 'string', enum: ['NUMBER', 'DECISION'] },
    },
    additionalProperties: false,
});

export default validate;
