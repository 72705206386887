// Same as packages/tint-app/src/apolloClientFactory.js

import { ApolloClient } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';
import graphqlCache from './graphqlCache';
import askLogin from './requireAuthentication';

export const defaultOnError = ({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) =>
            console.error(
                `[GraphQL error]: Message: ${message}, Location: ` +
                    `${locations}, Path: ${path}`,
            ),
        );
    }

    if (networkError) {
        if (networkError.statusCode === 401) {
            askLogin();
            return;
        }

        console.error(`[Network error]: ${networkError}`);
    }
};

const getLink = (uri) =>
    createUploadLink({
        credentials: 'include',
        uri,
    });

const organizationContextLink = setContext((_, { headers }) => {
    const organizationId = sessionStorage.getItem('organization-context');

    if (organizationId) {
        return {
            headers: {
                ...headers,
                'x-organization-id': organizationId,
            },
        };
    }

    return { headers };
});

const apolloClientFactory = ({ uri, ...otherProps }) =>
    new ApolloClient({
        connectToDevTools: true,
        cache: graphqlCache,
        onError: defaultOnError,
        link: organizationContextLink.concat(getLink(uri)),
        ...otherProps,
    });

export default apolloClientFactory;
