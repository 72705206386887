import PropTypes from 'prop-types';
import { FieldArray } from 'react-final-form-arrays';

import { makeStyles, Grid } from '@material-ui/core';

import { Field, useForm } from 'react-final-form';
import BooleanRow from './BooleanRow';
import { useEffect } from 'react';
import { get } from 'lodash';
import CatchAllInput from '../CatchAllInput';

const useStyles = makeStyles((theme) => ({
    defaultLabelWrapper: {
        marginLeft: theme.spacing(2),
    },
    row: {
        padding: theme.spacing(3, 2),
        '&:nth-child(odd)': {
            background: theme.palette.fieldBackground,
        },
    },
    input: {
        width: '100%',
    },
    newRow: {
        display: 'flex',
        marginTop: theme.spacing(1),
    },
}));

const BooleanSwitch = ({ disabled, input }) => {
    const classes = useStyles();

    const form = useForm();
    const { values } = form.getState();

    useEffect(() => {
        const conditions = get(values, `${input.name}.conditions`, []);

        if (!disabled && conditions.length === 0) {
            form.change(`${input.name}.conditions`, [
                {
                    value: true,
                    label: '',
                },
                {
                    value: false,
                    label: '',
                },
            ]);
        }
    }, [input]);

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <FieldArray name={`${input.name}.conditions`}>
                    {({ fields }) => (
                        <div>
                            {fields.map((field) => (
                                <BooleanRow
                                    key={field}
                                    className={classes.row}
                                    name={field}
                                    disabled={disabled}
                                />
                            ))}
                        </div>
                    )}
                </FieldArray>
            </Grid>
            <Grid item xs={12} className={classes.defaultLabelWrapper}>
                <Field
                    component={CatchAllInput}
                    allowNull
                    name={`${input.name}.catchAll`}
                    disabled={disabled}
                    label="Default Category (optional)"
                />
            </Grid>
        </Grid>
    );
};

BooleanSwitch.propTypes = {
    disabled: PropTypes.bool,
    input: PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.shape({
            type: PropTypes.string.isRequired,
            conditions: PropTypes.array.isRequired,
            catchAll: PropTypes.string,
        }),
        onChange: PropTypes.func.isRequired,
    }),
    maximumDisplayableThresholds: PropTypes.number,
};

BooleanSwitch.defaultProps = {
    disabled: false,
    input: {},
};

export default BooleanSwitch;
