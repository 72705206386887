import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import { TextInput } from '../../../../../forms';

const parse = (rawValue) => {
    if (rawValue === '') {
        return null;
    }

    const value = parseFloat(rawValue);

    return value * 1000;
};

const format = (value) => value / 1000;

const TimeoutField = ({ disabled, defaultTimeout, helperText, ...props }) => {
    return (
        <Field
            name="node.configuration.timeout"
            label="Timeout Duration (seconds)"
            component={TextInput}
            fullWidth
            disabled={disabled}
            defaultValue={defaultTimeout}
            autoFocus
            parse={parse}
            format={format}
            type="number"
            min="0"
            max="29"
            helperText={helperText}
            {...props}
        />
    );
};

TimeoutField.propTypes = {
    disabled: PropTypes.bool,
    defaultTimeout: PropTypes.number.isRequired,
    helperText: PropTypes.string,
};

TimeoutField.defaultProps = {
    disabled: false,
};

export default TimeoutField;
