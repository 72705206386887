import PropTypes from 'prop-types';

import classnames from 'classnames';
import {
    MenuItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
    useTheme,
} from '@material-ui/core';

import {
    MODEL_TYPES,
    MODEL_TYPE_DECISION,
    MODEL_TYPE_NUMBER,
} from '@tint/core/src/models/types';
import { blueprintPropType } from '../blueprintEditorPropTypes';
import { createNode } from '../state/actions';

import { FEATURE_BLOCK, OUTPUT_BLOCK, PROCESSOR_BLOCK } from '../blocks';
import { findAvailableNodeId } from './helpers';
import { FeatureIcon } from '../../../icons';
import ProcessorIcon from '../blocks/ProcessorIcon';
import DecisionIcon from '../blocks/icons/DecisionIcon';
import { OutputIcon } from '../blocks/icons/OutputIcon';
import findAvailableBlockPosition from './findAvailableBlockPosition';

const getBlockList = (theme, modelOutputType) => [
    {
        type: FEATURE_BLOCK,
        name: 'Policy Details',
        icon: <FeatureIcon color={theme.palette.secondaryBlue} />,
        node: {
            name: 'New Block',
            processor: 'attribute',
        },
    },
    {
        type: PROCESSOR_BLOCK,
        name: 'Function',
        icon: <ProcessorIcon color={theme.palette.mainBlue} />,
        node: {
            name: 'New Block',
        },
    },
    {
        type: OUTPUT_BLOCK,
        name: 'Decision',
        icon: <DecisionIcon fontSize={16} />,
        node: {
            name: 'New Block',
            processor: 'constant',
            outputType: MODEL_TYPE_DECISION,
        },
        disabled: modelOutputType === MODEL_TYPE_NUMBER,
    },
    {
        type: OUTPUT_BLOCK,
        name: 'Outcome',
        icon: <OutputIcon fontSize={16} />,
        output: true,
        node: {
            name: 'Outcome',
            processor: 'passthrough',
            outputType: MODEL_TYPE_NUMBER,
        },
        disabled: modelOutputType === MODEL_TYPE_DECISION,
    },
];

const useStyles = makeStyles(() => ({
    disabled: {
        opacity: 0.25,
    },
}));

const ContextMenuNodes = ({
    blueprint,
    editorData,
    onOpenProcessorMenu,
    dispatchEvent,
    cameraPosition,
    onSelect,
    position,
    modelOutputType,
    onClick,
    project,
}) => {
    const classes = useStyles();
    const theme = useTheme();

    const handleClick = (block) => () => {
        if (block.disabled) {
            return;
        }

        const coordinates =
            position && project
                ? project(position)
                : findAvailableBlockPosition(cameraPosition, editorData);

        if (block.type === PROCESSOR_BLOCK) {
            onOpenProcessorMenu(coordinates);
            onClick();
            return;
        }

        const id = findAvailableNodeId(blueprint, block.node);

        const isOutputBlock = block.type === OUTPUT_BLOCK;
        dispatchEvent(createNode(id, block.node, coordinates, isOutputBlock));

        if (block.type === FEATURE_BLOCK || block.name === 'Decision') {
            onSelect(id);
        }

        onClick();
    };

    return getBlockList(theme, modelOutputType).map((block) => (
        <MenuItem
            key={block.name}
            className={classnames({ [classes.disabled]: block.disabled })}
            title={
                block.disabled
                    ? "A model can't contain both decisions and numeric outcomes."
                    : null
            }
            onClick={handleClick(block)}
        >
            <ListItemIcon>{block.icon}</ListItemIcon>
            <ListItemText primary={block.name} />
        </MenuItem>
    ));
};

ContextMenuNodes.propTypes = {
    blueprint: PropTypes.shape(blueprintPropType).isRequired,
    onOpenProcessorMenu: PropTypes.func.isRequired,
    dispatchEvent: PropTypes.func.isRequired,
    cameraPosition: PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired,
        zoom: PropTypes.number.isRequired,
    }).isRequired,
    onSelect: PropTypes.func.isRequired,
    onClick: PropTypes.func,
    onOutsideClick: PropTypes.func,
    position: PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired,
    }),
    viewport: PropTypes.shape({
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
    }),
    modelOutputType: PropTypes.oneOf(MODEL_TYPES),
    reactFlow: PropTypes.func,
};

ContextMenuNodes.defaultProps = {
    onClick: () => {},
    position: null,
    viewport: undefined,
    modelOutputType: null,
    reactFlow: null,
};

export default ContextMenuNodes;
