import PropTypes from 'prop-types';
import { Tab } from '@material-ui/core';
import CredentialsTab from './CredentialsTab';
import ConfigurationTab from './ConfigurationTab';
import AdvancedTab from './AdvancedTab';

const CONFIGURATION_INDEX = 0;
const CREDENTIALS_INDEX = 1;
const ADVANCED_INDEX = 2;

export const getSambaSafetyConfigurationTabs = () => [
    <Tab key="configuration" label="Configuration" />,
    <Tab key="credentials" label="Credentials" />,
    <Tab key="advanced" label="Advanced" />,
];

const SambaSafetyConfiguration = ({ tab, ...props }) => {
    switch (tab) {
        case CREDENTIALS_INDEX:
            return <CredentialsTab {...props} />;

        case CONFIGURATION_INDEX:
            return <ConfigurationTab {...props} />;

        case ADVANCED_INDEX:
            return <AdvancedTab {...props} />;

        default:
            return null;
    }
};

SambaSafetyConfiguration.propTypes = {
    tab: PropTypes.number,
};

SambaSafetyConfiguration.defaultProps = {
    tab: CONFIGURATION_INDEX,
};

export default SambaSafetyConfiguration;
