import type { ModelOutputType } from './types';

const getModelOutputType = (blueprint: {
    nodes: Record<string, { outputType?: ModelOutputType }>;
}): ModelOutputType | null => {
    for (const node of Object.values(blueprint.nodes || {})) {
        if (node.outputType) {
            return node.outputType;
        }
    }

    return null;
};

export default getModelOutputType;
