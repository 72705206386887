import type { Blueprint } from '../processors/types';

export enum ModelCalculationType {
    CONSTANT = 'CONSTANT',
    CUSTOM = 'CUSTOM',
}

export enum ModelType {
    END_USER = 'END_USER',
    INSURER = 'INSURER',
}

export enum ModelOutputType {
    DECISION = 'DECISION',
    NUMBER = 'NUMBER',
}

export type ModelBlueprint = {
    nodes: Record<string, unknown>;
    edges: {
        start: { node: string; pin: string };
        end: { node: string; pin: string };
    }[];
};

export type ModelInput = {
    id?: string;
    name: string;
    blueprint?: ModelBlueprint;
    editorData?: Record<string, unknown>;
    insuranceProductId: string;
    tintManaged?: boolean;
    type?: ModelType;
};

export type Model = {
    id: string;
    name: string;
    blueprint: Blueprint;
    insuranceProductId: string;
    createdAt: Date;
    updatedAt: Date;
    type: ModelType;
    outputType: ModelOutputType;
    isValid: boolean;
    editorData: Record<string, unknown>;
    archivedAt?: Date;
    referencedModels: string[];
    tintManaged: boolean;
};

export type NumberModel = Model & {
    outputType: ModelOutputType.NUMBER;
};

// Legacy
export const MODEL_TYPE_DECISION = ModelOutputType.DECISION;
export const MODEL_TYPE_NUMBER = ModelOutputType.NUMBER;
export const MODEL_TYPES = [MODEL_TYPE_NUMBER, MODEL_TYPE_DECISION];
