import { createValidator } from '../../validation/validatorFactory';

const validator = createValidator();

const validate = validator.compile({
    type: 'object',
    required: ['path'],
    properties: { path: { type: 'string' } },
    additionalProperties: false,
});

export default validate;
