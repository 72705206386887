import PropTypes from 'prop-types';
import { useInput, FieldTitle } from 'react-admin';
import { makeStyles, MuiThemeProvider } from '@material-ui/core';
import PiiAttributeSelector from '../v4/forms/PiiAttributeSelector';
import tintTheme from '../v4/theme';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2, 0),
    },
}));

const PiiAttributesInput = ({
    validationSchema,
    label,
    value: initialValue,
    source,
    resource,
    ...rest
}) => {
    const classes = useStyles();

    const {
        id,
        input,
        isRequired,
        meta: { error, touched },
    } = useInput({
        ...rest,
        label,
        source,
        resource,
        value: initialValue,
    });

    return (
        <MuiThemeProvider theme={tintTheme}>
            <div className={classes.root}>
                <PiiAttributeSelector
                    validationSchema={validationSchema}
                    id={id}
                    {...input}
                    label={
                        label !== '' &&
                        label !== false && (
                            <FieldTitle
                                label={label}
                                source={source}
                                resource={resource}
                                isRequired={isRequired}
                            />
                        )
                    }
                    error={!!(touched && error)}
                    {...rest}
                />
            </div>
        </MuiThemeProvider>
    );
};

PiiAttributesInput.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    options: PropTypes.object,
    resource: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
    validationSchema: PropTypes.object,
    value: PropTypes.arrayOf(PropTypes.string),
};

PiiAttributesInput.defaultProps = {
    label: false,
    options: {},
    validationSchema: {},
    value: [],
};

export default PiiAttributesInput;
