import { createValidator } from '@tint/core/src/validation/validatorFactory';
import { TextInput } from 'react-admin';
import { CustomToolbar } from '../forms/CustomToolbar';
import { checkRequiredFieldsFactory } from '../forms/validation';
import { JsonInput } from '../inputs/JsonInput';

import SimpleForm from '../controllers/SimpleForm';

import InsuranceProductInput from '../insuranceProducts/InsuranceProductInput';

const QUESTIONNAIRE_SCHEMA = {
    type: 'object',
    patternProperties: {
        '^\\w{8}-\\w{4}-\\w{4}-\\w{4}-\\w{12}$': {
            type: 'object',
            required: ['id', 'question', 'slug', 'possible_answers'],
            properties: {
                id: { type: 'string', format: 'uuid' },
                question: { type: 'string' },
                slug: { type: 'string' },
                default_answer: { type: 'string' },
                possible_answers: {
                    type: 'array',
                    items: {
                        type: 'object',
                        required: ['value', 'label'],
                        properties: {
                            value: { type: 'string' },
                            label: { type: 'string' },
                            next_question_id: {
                                type: 'string',
                                format: 'uuid',
                            },
                        },
                        additionalProperties: false,
                    },
                },
                custom_attributes: {
                    type: 'object',
                    properties: {},
                    additionalProperties: true,
                },
            },
            additionalProperties: false,
        },
    },
    additionalProperties: false,
};

const validator = createValidator();
const validateQuestions = validator.compile(QUESTIONNAIRE_SCHEMA);

export const validateQuestionnaire = (values) => {
    const errors = checkRequiredFieldsFactory([
        'name',
        'initialQuestionId',
        'questions',
        'insuranceProduct.id',
    ])(values);

    if (!errors.questions) {
        if (typeof values.questions === 'string') {
            errors.questions = 'Invalid JSON object';
        } else {
            if (!validateQuestions(values.questions)) {
                errors.questions =
                    'Invalid questionnaire: ' +
                    validateQuestions.errors
                        .map((error) => `${error.schemaPath} ${error.message}`)
                        .join('\n');
            }
        }
    }

    if (
        !errors.initialQuestionId &&
        values.questions &&
        !values.questions[values.initialQuestionId]
    ) {
        errors.initialQuestionId = `Unable to find question "${values.initialQuestionId}".`;
    }

    return errors;
};

export const QuestionnaireForm = (props) => {
    return (
        <SimpleForm
            toolbar={
                <CustomToolbar
                    deleteConfirmTitle={(record) => `${record.name} removal`}
                />
            }
            validate={validateQuestionnaire}
            {...props}
        >
            <InsuranceProductInput source="insuranceProduct.id" />
            <TextInput source="name" required fullWidth />
            <JsonInput label="Questions" source="questions" required />
            <TextInput
                source="initialQuestionId"
                label="Initial Question ID"
                required
                fullWidth
            />
        </SimpleForm>
    );
};
