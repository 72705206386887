// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// Ignore because v4
import type { CSSProperties } from '@material-ui/styles/withStyles';
import type { Shadows } from '@material-ui/core/styles/shadows';
import defaultShadows from '@material-ui/core/styles/shadows';
import type { ThemeOptions } from '@material-ui/core/styles/createTheme';
import createTheme from '@material-ui/core/styles/createTheme';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import type { Color } from '@material-ui/core';

declare module '@material-ui/core/styles/createPalette' {
    interface Palette {
        grays?: Partial<Color>;
        purples?: Partial<Color>;
        oranges?: Partial<Color>;
        greens?: Partial<Color>;
        yellows: Partial<Color>;
        reds: Partial<Color>;
        grayBackground: string;
        grayHover: string;
        grayActive: string;
        mainBlue10Percent: string;
        menuInactive: string;
        mainBlue: string;
        mainBlueHover: string;
        secondaryBlue15Percent: string;
        secondaryBlueCharts: string;
        secondaryBlue: string;
        secondaryBlueDarker: string;
        fieldBackground: string;
        cardOutlineGray: string;
        lighterGray: string;
        darkerGray: string;
        regularText: string;
        mainBlack: string;
        backgroundWhite: string;
        textWhite: string;
        iconWhite: string;
        mainGreen10Percent: string;
        mainGreen20Percent: string;
        mainGreen: string;
        mainGreenHover: string;
        mainRed10Percent: string;
        mainRed: string;
        mainRedHover: string;
        primaryText: string;
        positive: { standard: string; standardHover: string };
        critical: { standard: string };
    }
    interface PaletteOptions {
        grays?: Partial<Color>;
        purples?: Partial<Color>;
        oranges?: Partial<Color>;
        greens?: Partial<Color>;
        yellows: Partial<Color>;
        reds: Partial<Color>;
        grayBackground: string;
        grayHover: string;
        grayActive: string;
        mainBlue10Percent: string;
        menuInactive: string;
        mainBlue: string;
        mainBlueHover: string;
        secondaryBlue15Percent: string;
        secondaryBlueCharts: string;
        secondaryBlue: string;
        secondaryBlueDarker: string;
        fieldBackground: string;
        cardOutlineGray: string;
        lighterGray: string;
        darkerGray: string;
        regularText: string;
        mainBlack: string;
        backgroundWhite: string;
        textWhite: string;
        iconWhite: string;
        mainGreen10Percent: string;
        mainGreen20Percent: string;
        mainGreen: string;
        mainGreenHover: string;
        mainRed10Percent: string;
        mainRed: string;
        mainRedHover: string;
        primaryText: string;
        positive: { standard: string; standardHover: string };
        critical: { standard: string };
    }
    interface PaletteColor {
        active?: string;
        background?: string;
    }
    interface SimplePaletteColorOptions {
        active?: string;
        background?: string;
    }
}

declare module '@material-ui/core/styles/overrides' {
    export interface ComponentNameToClassKey {
        MuiTreeItem: string;
        MuiAutocomplete: string;
        PrivateTabIndicator: string;
        MuiTimelineContent: string;
        MuiTimelineOppositeContent: string;
    }
}

// Main blues
const mainBlue10Percent = '#00439E1A';
const menuInactive = '#7DA0CE';
const primaryText = '#668EC5';
const primaryActive = '#002B65';
const mainBlue = '#00439E';
const mainBlueHover = '#003986';

// Secondary blues
export const secondaryBlue15Percent = '#E1F5FF';
const secondaryBlueCharts = '#7ACDF7';
const secondaryBlue = '#02A1F1';
const secondaryBlueDarker = '#00367E';

// Grays
const fieldBackground = '#FAFAFC';
const cardOutlineGray = '#F0F0F0';
export const lighterGray = '#CBCCD6';
const darkerGray = '#A0A1AE';
const regularText = '#6A6B77';
const mainBlack = '#141627';
const grayBackground = '#F3F7FA';
const grayHover = '#E6EBF1';
const grayActive = '#D0DAE5';

// White
const backgroundWhite = '#fff';
const textWhite = '#fafafa';
const iconWhite = '#f5f5f5';

// Greens
const mainGreen10Percent = '#87D1801A';
const mainGreen20Percent = '#87D18033';
export const mainGreen = '#84C77F';
const mainGreenHover = '#6CA468';

// Reds (deprecated)
const mainRed10Percent = '#DC376E1A';
export const mainRed = '#DC376E';
const mainRedHover = '#B22B58';

const greens = {
    100: '#E7F6E6',
    500: '#69C960',
    700: '#6CA468',
    900: '#2F7A2A',
};

const grays: Partial<Color> = {
    100: '#FAFAFC',
    200: '#F0F0F0',
    300: '#E7E7E7',
    400: '#CBCCD6',
    500: '#A0A1AE',
    600: '#878895',
    700: '#6A6B77',
    900: '#4A4B57',
};

const reds = {
    100: '#FCEFF3',
    500: '#DC376E',
    700: '#B22B58',
};

const purples = {
    300: '#efdbf2',
    700: '#ba68c8',
};

const yellows = {
    100: '#FFF6D7',
    500: '#FFCC15',
};

const oranges = {
    200: '#FDF6E4',
    500: '#FF9800',
    700: '#F57C00',
    900: '#A85400',
};

const text = {
    primary: '#2B2E32',
    secondary: '#9299A1',
};

const positive = {
    standard: '#28A138',
    standardHover: '#269836',
};

const critical = {
    standard: '#D21C1C',
};

const h3: CSSProperties = {
    fontSize: 16,
    fontWeight: 'normal',
    color: mainBlack,
};

const SPACING = 8;

const shadows: Shadows = [...defaultShadows];

// Elevation 1
shadows[1] =
    '0px 3px 1px -2px rgba(0, 67, 158, 0.12), 0px 2px 2px 0px rgba(0, 67, 158, 0.1), 0px 1px 5px 0px rgba(0, 67, 158, 0.08)';
// Elevation 2
shadows[2] =
    '0px 3px 1px -2px rgba(0, 67, 158, 0.12),0px 2px 2px 0px rgba(0, 67, 158, 0.1),0px 1px 5px 0px rgba(0, 67, 158, 0.08)';
// elevation 3
shadows[3] = '0px 0px 20px rgba(0, 0, 0, 0.1)';

const baseTheme = createTheme();

const breakpoints = createBreakpoints({});

export const themeData: ThemeOptions = {
    typography: {
        fontFamily: [
            'Roboto',
            'Open Sans',
            'Helvetica',
            'Arial',
            'sans-serif',
        ].join(','),
        h1: {
            fontSize: 22,
            fontWeight: 'normal',
            color: mainBlack,
        },
        h2: {
            fontSize: 18,
            fontWeight: 'normal',
            color: mainBlack,
        },
        h3,
        h4: {
            ...h3,
            color: regularText,
        },
        body1: {
            fontSize: '0.875rem',
            color: regularText,
        },
        body2: {
            fontSize: '0.75rem',
            color: darkerGray,
        },
    },
    palette: {
        primary: {
            active: primaryActive,
            main: mainBlue,
            background: '#E6ECF5',
        },
        secondary: {
            main: secondaryBlue,
            contrastText: backgroundWhite,
        },
        success: {
            dark: mainGreenHover,
            main: mainGreen,
            light: mainGreen10Percent,
        },
        error: {
            light: reds[100],
            main: reds[500],
            dark: reds[700],
        },
        warning: {
            dark: oranges[700],
            main: oranges[500],
            light: oranges[200],
        },
        grays,
        grayBackground,
        grayHover,
        grayActive,
        oranges,
        greens,
        mainBlue10Percent,
        menuInactive,
        mainBlue,
        mainBlueHover,
        secondaryBlue15Percent,
        secondaryBlueCharts,
        secondaryBlue,
        secondaryBlueDarker,
        fieldBackground,
        cardOutlineGray,
        lighterGray,
        darkerGray,
        regularText,
        mainBlack,
        backgroundWhite,
        textWhite,
        iconWhite,
        mainGreen10Percent,
        mainGreen20Percent,
        mainGreen,
        mainGreenHover,
        mainRed10Percent,
        mainRed,
        mainRedHover,
        yellows,
        reds,
        purples,
        primaryText,
        text,
        positive,
        critical,
    },
    spacing: SPACING,
    shadows,
    transitions: {
        duration: {
            standard: 200,
        },
    },
    overrides: {
        MuiPaper: {
            root: {
                borderRadius: 5,
                border: `1px solid ${cardOutlineGray}`,
                padding: 24,
                '& form': {
                    display: 'contents',
                },
            },
            rounded: {
                boxRadius: 10,
            },
        },
        MuiCard: {
            root: {
                borderRadius: 6,
                padding: 0,
                border: `1.5px solid ${grays[300]}`,
                boxShadow: '0px 0px 0px',
            },
        },
        MuiCardContent: {
            root: {
                boxShadow: `inset 0px 2px 0px ${grays[300]}`,
                '&:last-child': {
                    paddingBottom: SPACING * 1.5,
                },
            },
        },
        MuiButton: {
            root: {
                borderRadius: 3,
                textTransform: 'initial',
                lineHeight: 1,
            },
            contained: {
                boxShadow: 'none !important',
            },
            containedPrimary: {
                '&:hover': {
                    backgroundColor: mainBlueHover,
                },
            },
            containedSecondary: {
                color: mainBlue,
                backgroundColor: grayBackground,
                '&:hover': {
                    backgroundColor: grayHover,
                },
            },
            startIcon: {
                marginLeft: 0,
                marginRight: SPACING,
            },
            iconSizeMedium: {
                '& > *:first-child': {
                    fontSize: 16,
                },
            },
        },
        MuiTooltip: {
            tooltip: {
                borderRadius: 3,
                fontSize: '0.75rem',
                lineHeight: '16px',
                padding: `${SPACING}px ${SPACING * 1.5}px`,
                backgroundColor: primaryActive,
                border: 0,
                '& a': {
                    color: '#fff',
                    textDecoration: 'underline',
                },
            },
            arrow: {
                '&:before': {
                    backgroundColor: primaryActive,
                },
            },
        },
        MuiButtonGroup: {
            grouped: {
                borderRadius: 8,
            },
        },
        MuiChip: {
            root: {
                fontWeight: 500,
                borderRadius: '3px',
            },
            label: {
                padding: `${SPACING * 0.75}px ${SPACING * 1.75}px !important`,
            },
            outlinedPrimary: {
                border: `1px solid ${lighterGray}`,
                color: regularText,
            },
        },
        MuiCheckbox: {
            colorPrimary: {
                padding: '8px 12px 6px 12px',
                color: mainBlue,
                '&$checked': {
                    color: mainBlue,
                },
            },
        },
        MuiListItemIcon: {
            root: {
                minWidth: 32,
            },
        },
        MuiDivider: {
            root: {
                margin: `${SPACING * 2}px 0`,
            },
        },
        MuiFab: {
            root: {
                boxShadow: `${shadows[2]} !important`,
            },
        },
        MuiFormLabel: {
            root: {
                fontSize: '1rem',
            },
        },
        MuiFormHelperText: {
            root: {
                color: grays[500],
            },
        },
        MuiInput: {
            root: {
                fontSize: '1rem',
            },
            underline: {
                '&:before': {
                    borderBottomColor: 'transparent',
                },
                '&:hover:not($disabled):before': {
                    borderBottomColor: grays[400],
                },
                '&:after': {
                    borderBottomColor: mainBlack,
                },
            },
        },
        MuiInputBase: {
            root: {
                '&$disabled': {
                    background: grays[200],
                },
                // Disable yellow/blue background when auto-filling a field
                '& input': {
                    '&:-webkit-autofill': {
                        transition:
                            'background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s',
                    },
                    '&:-webkit-autofill:focus': {
                        transition:
                            'background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s',
                    },
                    '&:-webkit-autofill:hover': {
                        transition:
                            'background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s',
                    },
                },
                '&.Mui-error .MuiInputBase-input': {
                    color: reds[700],
                },
            },
            input: {
                fontSize: '1rem',
                color: '#000',
                '&:disabled': {
                    color: grays[500],
                },
            },
        },
        MuiInputLabel: {
            root: {
                color: grays[500],
            },
            outlined: {
                transform: 'translate(14px, 18px) scale(1)',
            },
            filled: {
                paddingTop: 0,
                '&$shrink': {
                    transform: 'translate(12px, 10px) scale(0.75)',
                },
            },
        },
        MuiFilledInput: {
            underline: {
                '&:before': {
                    borderBottom: `1px solid ${grays[400]}`,
                },
            },
            adornedStart: {
                '& svg': {
                    marginRight: SPACING * 2,
                },
            },
        },
        MuiSwitch: {
            root: {
                width: 27,
                height: 16,
                padding: 0,
                margin: `${SPACING / 2}px 0`,
            },
            switchBase: {
                '&.Mui-disabled': {
                    opacity: 0.75,
                    '& + .MuiSwitch-track': {
                        opacity: 0.25,
                        backgroundColor: `${lighterGray} !important`,
                    },
                },
                padding: 1,
                '&.Mui-checked': {
                    transform: 'translateX(11px)',
                    '& + .MuiSwitch-track': {
                        opacity: 1,
                        backgroundColor: `${secondaryBlue} !important`,
                    },
                },
            },
            thumb: {
                width: 14,
                height: 14,
                boxShadow: 'none',
                background: '#fff !important',
            },
            track: {
                borderRadius: 26 / 2,
                backgroundColor: lighterGray,
                opacity: 1,
                transition: baseTheme.transitions.create([
                    'background-color',
                    'border',
                ]),
            },
        },
        MuiSelect: {
            select: {
                borderRadius: 90,
                paddingLeft: 12,
                '&:focus': {
                    borderRadius: 90,
                },
                '&$select': {
                    paddingRight: 32,
                },
            },
        },
        MuiOutlinedInput: {
            input: {
                padding: SPACING * 2,
            },
            adornedStart: {
                '& svg': {
                    marginRight: 8,
                },
            },
            notchedOutline: {
                '& legend': {
                    fontSize: '0.75rem',
                },
            },
        },
        MuiTreeItem: {
            root: {
                '&$selected > $content $label': {
                    backgroundColor: 'transparent',
                },
                '&$selected > $content $label:hover, &$selected:focus > $content $label':
                    {
                        backgroundColor: 'transparent',
                    },
            },
        },
        MuiBackdrop: {
            root: {
                background: 'rgba(20, 22, 39, 0.5)',
                backdropFilter: 'blur(3px)',
            },
            invisible: {
                backdropFilter: 'unset',
            },
        },
        MuiDialog: {
            paper: {
                margin: 0,
                boxShadow: '0px 10px 40px rgba(0, 67, 158, 0.08)',
            },
        },
        MuiDialogTitle: {
            root: {
                fontSize: '1.125rem',
                color: mainBlack,
                fontWeight: 400,
            },
        },
        MuiDialogContentText: {
            root: {
                color: darkerGray,
                fontSize: '0.875rem',
            },
        },
        MuiDialogActions: {
            root: {
                padding: `${SPACING}px ${SPACING * 3}px`,
            },
        },
        MuiMenu: {
            paper: {
                padding: 0,
            },
        },
        MuiAutocomplete: {
            paper: {
                padding: 0,
            },
            inputRoot: {
                paddingRight: 0,
                '& > $input': {
                    width: 'auto',
                },
                '&[class*="MuiOutlinedInput-root"]': {
                    padding: 6.5,
                },
            },
        },
        MuiAvatar: {
            root: {
                width: 30,
                height: 30,
            },
            colorDefault: {
                backgroundColor: cardOutlineGray,
                color: darkerGray,
            },
        },
        MuiTab: {
            root: {
                textTransform: 'unset',
                padding: 0,
                [breakpoints.up('md')]: {
                    minWidth: 'initial',
                },
                '& .MuiTouchRipple-root': {
                    display: 'none',
                },
                '&:hover': {
                    color: mainBlack,
                },
                '&:focus': {
                    color: mainBlack,
                },
            },
            textColorPrimary: {
                color: grays[500],
                fontSize: 14,
                fontWeight: 'normal',
                '&.Mui-selected': {
                    color: mainBlack,
                },
                '&.Mui-disabled': {
                    color: grays[400],
                },
            },
        },
        MuiTabs: {
            root: {
                position: 'relative',
                '&:not(.MuiTabs-vertical) .MuiTab-root': {
                    margin: `0 ${SPACING * 2.5}px 0 ${SPACING * 2.5}px`,
                    '&:first-child': {
                        marginLeft: 0,
                    },
                    '&:last-child': {
                        marginRight: 0,
                    },
                },
                '&.MuiTabs-vertical': {
                    margin: 0,
                    '&:after': {
                        top: 0,
                        height: '100%',
                        left: 'calc(100% - 1.5px)',
                        width: 1,
                        borderBottom: 0,
                        borderRight: `1px solid ${grays[300]}`,
                    },
                },
                '&:after': {
                    zIndex: -1,
                    content: '""',
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    bottom: 0.5,
                    height: 1,
                    borderBottom: `1px solid ${grays[300]}`,
                },
            },
        },
        PrivateTabIndicator: {
            root: {
                borderRadius: 5,
                marginTop: 2,
            },
        },
        MuiTableContainer: {
            root: {
                padding: 0,
            },
        },
        MuiTable: {
            root: {
                boxShadow: 'none',
            },
        },
        MuiRadio: {
            root: {
                '& + .MuiTypography-body1': {
                    fontSize: '16px',
                    fontWeight: 500,
                    color: mainBlack,
                },
            },
        },
        MuiTableHead: {
            root: {
                borderBottom: `1px solid ${cardOutlineGray}`,
                fontSize: '0.875rem',
            },
        },
        MuiTableRow: {
            root: {
                backgroundColor: 'transparent',
                '&:nth-of-type(odd)': {
                    backgroundColor: fieldBackground,
                },
                '&$hover:hover': {
                    backgroundColor: cardOutlineGray,
                },
            },
            head: {
                backgroundColor: backgroundWhite,
                '&:nth-of-type(odd)': {
                    backgroundColor: backgroundWhite,
                },
            },
        },
        MuiTableCell: {
            root: {
                borderBottom: 0,
            },
            head: {
                ...h3,
                fontSize: '0.875rem',
                fontWeight: 500,
            },
            body: {
                fontSize: 14,
                color: regularText,
            },
        },
        MuiAccordion: {
            root: {
                '&:before': {
                    display: 'none',
                },
                padding: 0,
                '&$disabled': {
                    backgroundColor: 'transparent',
                },
            },
        },
        MuiAccordionSummary: {
            root: {
                padding: 20,
                flexDirection: 'row-reverse',
                '&$disabled': {
                    opacity: 1,
                },
                '&$expanded': {
                    minHeight: 48,
                },
            },
            content: {
                margin: 0,
                '&$expanded': {
                    margin: 0,
                },
            },
            expandIcon: {
                padding: 0,
                marginRight: 1.5 * SPACING,
                transform: 'rotate(-90deg)',
                '&$expanded': {
                    padding: 0,
                    transform: 'rotate(0)',
                },
            },
        },
        MuiAccordionDetails: {
            root: {
                borderTop: `1px solid ${cardOutlineGray}`,
                padding: 24,
            },
        },
        MuiTimelineContent: {
            root: {
                paddingTop: 16,
            },
        },
        MuiTimelineOppositeContent: {
            root: {
                paddingTop: 16,
            },
        },
        MuiStepIcon: {
            root: {
                color: grayBackground,
                '&$completed': {
                    color: positive.standard,
                },
                '&$active': {
                    color: positive.standard,
                    '& $text': {
                        fill: 'white',
                    },
                },
                '& $text': {
                    fill: 'black',
                },
            },
        },
    },
};

const theme = createTheme(themeData);

export default theme;
