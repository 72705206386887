import { get } from 'lodash';
import getCookieValue from './v4/getCookieValue';

export const logout = async () => {
    window.location.href = `${process.env.REACT_APP_SSO_BASE_URL}/#logout`;
};

const redirectToLoginForm = () => {
    window.location.replace(
        `${process.env.REACT_APP_SSO_BASE_URL}/#/?redirect=${process.env.REACT_APP_ADMIN_BASE_URL}`,
    );
};

export const checkAuth = async () => {
    const rawProfile = getCookieValue('profile');
    if (!rawProfile) {
        // eslint-disable-next-line no-console
        console.warn('User is not connected.');
        redirectToLoginForm();
    }
};

export const checkError = (error) => {
    const status = get(error, 'networkError.statusCode');
    if (status === 401 || status === 403) {
        redirectToLoginForm();
        return;
    }

    return Promise.resolve();
};

export const getPermissions = () => Promise.resolve();

export const authProvider = {
    checkError,
    checkAuth,
    logout,
    login: async () => {},
    getPermissions,
};
