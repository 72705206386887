import PropTypes from 'prop-types';

export const editorPropType = {
    outputType: PropTypes.string,
    position: PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired,
    }).isRequired,
};

// The `data` prop is the data attribute of the elements we're passing to <ReactFlow> in BlueprintEditor
export const dataPropType = {
    id: PropTypes.string.isRequired,
    processor: PropTypes.string,
    configuration: PropTypes.object,
    disabled: PropTypes.bool,
    editor: PropTypes.shape(editorPropType).isRequired,
    onClick: PropTypes.func,
    onRemove: PropTypes.func,
    connectedInputs: PropTypes.arrayOf(PropTypes.string).isRequired,
    errors: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            code: PropTypes.string.isRequired,
            message: PropTypes.string.isRequired,
        }),
    ).isRequired,
    connecting: PropTypes.object,
    isValidConnection: PropTypes.func.isRequired,
    trace: PropTypes.object, // Block-related trace
    onViewDetails: PropTypes.func,
};

export const blockIoDescription = PropTypes.oneOfType([
    PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            schema: PropTypes.object,
        }),
    ).isRequired,
    PropTypes.shape({
        dynamic: PropTypes.exact(true),
        min: PropTypes.number,
    }),
]);

const blockPropTypes = {
    id: PropTypes.string.isRequired,
    data: PropTypes.shape(dataPropType).isRequired,
    selected: PropTypes.bool,
};

export default blockPropTypes;
