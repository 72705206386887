import { get } from 'lodash';
import { useRef } from 'react';
import PropTypes from 'prop-types';

import {
    makeStyles,
    Paper,
    lighten,
    Typography,
    Divider,
    useTheme,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import blockPropType from './blockPropTypes';
import BlockHeader from './BlockHeader';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        minWidth: 150,
        padding: 0,
        margin: 0,
        border: ({ hasError, selected, color }) =>
            hasError
                ? selected
                    ? `1px solid ${theme.palette.mainRed}`
                    : `1px solid ${lighten(theme.palette.mainRed, 0.7)}`
                : selected
                ? `1px solid ${color}`
                : `1px solid ${lighten(color, 0.6)}`,
        '&:hover': {
            border: ({ color, hasError }) =>
                hasError
                    ? `1px solid ${theme.palette.mainRed}`
                    : `1px solid ${color}`,
            '& $contentDivider': {
                backgroundColor: ({ color, hasError }) =>
                    hasError ? theme.palette.mainRed : color,
            },
        },
        boxSizing: 'border-box',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '0.875rem',
    },
    menuIcon: {
        fontSize: '1.5rem',
    },
    title: {
        color: theme.palette.mainBlack,
        flex: '1 0 0',
    },
    contentDivider: {
        margin: 0,
        backgroundColor: ({ color, selected, hasError }) =>
            hasError
                ? selected
                    ? theme.palette.mainRed
                    : lighten(theme.palette.mainRed, 0.7)
                : selected
                ? color
                : lighten(color, 0.6),
    },
}));

const Block = ({
    data,
    disabled,
    selected,
    color,
    title,
    icon,
    pins,
    children,
}) => {
    const theme = useTheme();
    const hasError =
        (data.errors || []).length > 0 || !!get(data, 'trace.error');
    const classes = useStyles({ selected, color, hasError });
    const menuButtonRef = useRef();
    const movedRef = useRef();

    const onMouseDown = () => {
        movedRef.current = false;
    };

    const onMouseMove = () => {
        movedRef.current = true;
    };

    const onMouseUp = (evt) => {
        // Do not open the drawer if we click on the menu button
        if (
            menuButtonRef.current &&
            (menuButtonRef.current.isSameNode(evt.target) ||
                menuButtonRef.current.contains(evt.target))
        ) {
            return;
        }

        // Ensure the configuration drawer do not open when the block is dragged
        if (data.onClick && !movedRef.current) {
            data.onClick(evt);
        }
    };

    return (
        <Paper
            className={classes.root}
            onMouseDown={onMouseDown}
            onMouseMove={onMouseMove}
            onMouseUp={onMouseUp}
            elevation={1}
        >
            <BlockHeader
                id={data.id}
                title={
                    title || (
                        <Typography variant="body1" className={classes.title}>
                            {data.name}
                        </Typography>
                    )
                }
                selected={selected}
                color={hasError ? theme.palette.mainRed : color}
                icon={icon}
                menuIcon={<MoreVertIcon className={classes.menuIcon} />}
                onRemove={disabled ? null : data.onRemove}
                menuButtonRef={menuButtonRef}
                pins={pins}
                hasError={hasError}
            />
            {children && (
                <div>
                    <Divider className={classes.contentDivider} />
                    {children}
                </div>
            )}
        </Paper>
    );
};

Block.propTypes = {
    ...blockPropType,
    icon: PropTypes.node.isRequired,
    pins: PropTypes.any,
    title: PropTypes.node,
    children: PropTypes.node,
    disabled: PropTypes.bool,
};

Block.defaultProps = {
    title: null,
    pins: null,
    children: null,
    disabled: false,
};

export default Block;
