import validator from './validate';

const moduloProcessor = {
    name: 'Modulo',
    slug: 'modulo',
    description: 'Return the division remainder of the two inputs',
    validate: validator,
    inputs: [
        {
            id: 'numerator',
            name: 'Numerator',
            schema: { type: 'number' },
        },
        {
            id: 'divisor',
            name: 'Divisor',
            schema: { type: 'number' },
        },
    ],
    outputs: [
        {
            id: 'output',
            name: 'Output',
            schema: { type: 'number' },
        },
    ],
};

export default moduloProcessor;
