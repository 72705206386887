import type { Options } from 'ajv';
import Ajv from 'ajv';
import addFormats from 'ajv-formats';
import isRFC3339 from './isRFC3339';

export const createValidator = (options: Options = {}): Ajv => {
    const validator = new Ajv({
        allErrors: true,
        strict: false,
        ...options,
    });
    addFormats(validator);
    validator.addFormat('date-time', { type: 'string', validate: isRFC3339 });
    return validator;
};
