import PropTypes from 'prop-types';

import { formatCurrency } from '@tint/core/src/formatters';
import LabelledValue from '../LabelledValue';

const CurrencyField = ({ value, numberDecimals, ...otherProps }) => {
    const formattedValue =
        value === null ? 'N/A' : formatCurrency(value, numberDecimals);
    return <LabelledValue value={formattedValue} {...otherProps} />;
};

CurrencyField.propTypes = {
    numberDecimals: PropTypes.number,
    value: PropTypes.number,
};

CurrencyField.defaultProps = {
    numberDecimals: 0,
    value: null,
};

export default CurrencyField;
