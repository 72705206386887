import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Field } from 'react-final-form';
import { TextInput } from '../../../../forms';

const ModuloConfiguration = ({ disabled }) => (
    <Grid container direction="column" spacing={1}>
        <Grid item>
            <Field
                component={TextInput}
                disabled={disabled}
                name="node.configuration.default"
                parse={(v) => v && parseFloat(v)}
                label="Default value"
                helper="Returned if the divisor is null or if one of the input is missing"
                required
                autoFocus
                type="number"
                variant="outlined"
            />
        </Grid>
    </Grid>
);

ModuloConfiguration.propTypes = {
    disabled: PropTypes.bool,
};

ModuloConfiguration.defaultProps = {
    disabled: false,
};

export default ModuloConfiguration;
