// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// Ignore because v4
import type { FunctionComponent } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { isDate, parse, formatISO } from 'date-fns';
import type { FieldRenderProps } from 'react-final-form';

import TextInput from './TextInput';
import { CalendarTransactionClaim } from '../../v4/icons';

const useStyles = makeStyles(() => ({
    dialogPaperRoot: {
        padding: 0,
    },
    adornmentRoot: {
        position: 'relative',
        marginRight: -9,
        top: ({ variant }: { variant: 'standard' | 'filled' | 'outlined' }) =>
            variant === 'filled' ? -8 : 0,
        left: -8,
        '& svg': {
            marginRight: 0,
        },
    },
}));

type DatePickerProp = FieldRenderProps<string, HTMLInputElement> & {
    label: string;
    variant: 'standard' | 'filled' | 'outlined';
    input: {
        onChange: (dateString: string | null) => void;
        value: string | null;
    };
};

const DatePicker: FunctionComponent<DatePickerProp> = ({
    label,
    input,
    variant,
    ...rest
}) => {
    const classes = useStyles({ variant });

    const handleDateChange = (dateString) => {
        if (!dateString) {
            return input.onChange(null);
        }

        try {
            const date = isDate(dateString)
                ? formatISO(dateString)
                : formatISO(parse(dateString, 'MM/dd/yyyy', new Date()));
            input.onChange(date);
        } catch (error) {
            input.onChange('Invalid Date');
        }
    };

    return (
        <KeyboardDatePicker
            format="MM/dd/yyyy"
            // input props does exists on component even though it's not typed
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error:next-line
            input={input}
            keyboardIcon={
                // input props does exists on component even though it's not typed
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error:next-line
                <CalendarTransactionClaim style={{ width: '12px' }} />
            }
            inputVariant={variant}
            value={input.value ? input.value : null}
            InputAdornmentProps={{
                position: 'start',
                classes: {
                    root: classes.adornmentRoot,
                },
            }}
            variant="inline"
            PopoverProps={{
                PaperProps: {
                    classes: {
                        root: classes.dialogPaperRoot,
                    },
                },
            }}
            onChange={() => undefined} // Prevent from invalid date message while typing
            onAccept={(e) => handleDateChange(e)}
            onBlur={(e) => {
                handleDateChange(e.target.value || null);
            }}
            TextFieldComponent={TextInput}
            {...rest}
            label={label}
            autoOk
        />
    );
};

DatePicker.defaultProps = {
    variant: 'standard',
};

export default DatePicker;
