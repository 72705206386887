import { Edit } from 'react-admin';
import { QuestionnaireForm } from './Form';

const QuestionnaireEdit = (props) => (
    <Edit title="Edit Questionnaire" {...props}>
        <QuestionnaireForm />
    </Edit>
);

export default QuestionnaireEdit;
