import PropTypes from 'prop-types';

import {
    makeStyles,
    Fab,
    Typography,
    Paper,
    Grid,
    Button,
} from '@material-ui/core';
import { Check, Warning, ArrowRight } from '@material-ui/icons';

import { VALIDATION_CODES } from '@tint/core/src/validation/validationCodes';
import { blueprintPropType } from './blueprintEditorPropTypes';

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'absolute',
        bottom: theme.spacing(2),
        left: 60,
        zIndex: 10,
        display: 'flex',
        flexDirection: 'column-reverse',
    },
    fab: {
        backgroundColor: ({ isValid }) =>
            isValid ? 'white' : theme.palette.mainRed,
        textTransform: 'unset',
        '&:hover': {
            backgroundColor: ({ isValid }) =>
                isValid ? 'inherit' : theme.palette.mainRedHover,
        },
        '&.MuiFab-sizeSmall': {
            backgroundColor: ({ isValid }) =>
                isValid ? 'white' : theme.palette.mainRed,
        },
        '&.MuiFab-extended.MuiFab-sizeSmall': {
            backgroundColor: ({ isValid }) =>
                isValid ? 'white' : theme.palette.mainRed,
        },
    },
    label: {
        margin: theme.spacing(0, 1),
        color: ({ isValid }) => (isValid ? 'inherit' : 'white'),
    },
    validCheck: {
        fill: theme.palette.success.main,
    },
    errorWarning: {
        fill: 'white',
    },
    paper: {
        width: 450,
        marginTop: theme.spacing(),
        padding: theme.spacing(2),
    },
    errorDetails: {
        maxHeight: '80vh',
        overflowY: 'auto',
        paddingBottom: theme.spacing(),
        paddingRight: theme.spacing(),
        display: 'flex',
        flexDirection: 'column-reverse',
    },
}));

const getNodeName = (node) => (node || {}).name;

const ErrorTargetName = ({ blueprint, error }) => {
    if (error.code === VALIDATION_CODES.NODE_CONFIGURATION_ERROR) {
        const node = blueprint.nodes[error.id];

        return <Typography variant="h3">{getNodeName(node)}</Typography>;
    }

    if (error.code === VALIDATION_CODES.EDGE_ERROR) {
        const { source: sourceId, target: targetId } = error;

        const source = blueprint.nodes[sourceId];
        const target = blueprint.nodes[targetId];

        if (!source || !target) {
            return null;
        }

        return (
            <Typography variant="h3">
                {getNodeName(source)}&nbsp;
                <ArrowRight style={{ position: 'relative', top: 6 }} />
                &nbsp;{getNodeName(target)}
            </Typography>
        );
    }

    return null;
};

ErrorTargetName.propTypes = {
    blueprint: PropTypes.shape(blueprintPropType).isRequired,
    error: PropTypes.shape({
        id: PropTypes.string.isRequired,
        code: PropTypes.string,
        source: PropTypes.string,
        target: PropTypes.string,
        message: PropTypes.string.isRequired,
    }).isRequired,
};

const ErrorCard = ({ blueprint, error, className, onSelect }) => {
    if (error.code === VALIDATION_CODES.NODE_CONFIGURATION_ERROR) {
        const handleClick = () => onSelect(error.id);

        return (
            <Paper className={className} elevation={2}>
                <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid item>
                        <ErrorTargetName blueprint={blueprint} error={error} />{' '}
                        <Typography variant="body1">{error.message}</Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleClick}
                        >
                            Configure
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        );
    }

    return (
        <Paper className={className} elevation={2}>
            <ErrorTargetName blueprint={blueprint} error={error} />
            <Typography variant="body1">{error.message}</Typography>
        </Paper>
    );
};

ErrorCard.propTypes = {
    className: PropTypes.string.isRequired,
    error: PropTypes.shape({
        id: PropTypes.string.isRequired,
        code: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired,
    }).isRequired,
    blueprint: PropTypes.shape(blueprintPropType).isRequired,
    onSelect: PropTypes.func.isRequired,
};

const isConfigurationError = (error) =>
    error.code === VALIDATION_CODES.NODE_CONFIGURATION_ERROR;

const mergeConfigurationErrors = (errors) => {
    const configurationErrors = errors.filter(isConfigurationError);
    const otherErrors = errors.filter((err) => !isConfigurationError(err));

    return [
        ...configurationErrors.reduce((acc, error) => {
            if (acc.find((err) => err.id === error.id)) {
                return acc;
            }

            acc.push({
                id: error.id,
                code: VALIDATION_CODES.NODE_CONFIGURATION_ERROR,
                message: 'Has an invalid configuration',
            });
            return acc;
        }, []),
        ...otherErrors,
    ];
};

const ValidationDisplay = ({
    expanded,
    onChange,
    errors: rawErrors,
    blueprint,
    onSelect,
}) => {
    const errors = mergeConfigurationErrors(rawErrors);
    const isValid = errors.length === 0;
    const classes = useStyles({ isValid });

    return (
        <div className={classes.container}>
            <div>
                <Fab
                    aria-label="Validation"
                    size="small"
                    className={classes.fab}
                    classes={{ label: classes.label }}
                    variant={isValid && !expanded ? 'round' : 'extended'}
                    onClick={onChange}
                >
                    {isValid ? (
                        <Check
                            fontSize="small"
                            className={classes.validCheck}
                            data-testid="model-is-valid"
                        />
                    ) : (
                        <Warning
                            fontSize="small"
                            className={classes.errorWarning}
                            data-testid="model-is-invalid"
                        />
                    )}
                    {(!isValid || expanded) && (
                        <Typography
                            variant="body2"
                            component="div"
                            className={classes.label}
                        >
                            {!isValid &&
                                `${errors.length} error${
                                    errors.length === 1 ? '' : 's'
                                }`}
                            {isValid &&
                                expanded &&
                                'This model passed all the validation checks'}
                        </Typography>
                    )}
                </Fab>
            </div>
            {expanded && (
                <div className={classes.errorDetails}>
                    {errors.map((error) => (
                        <ErrorCard
                            key={error.id + error.message}
                            blueprint={blueprint}
                            error={error}
                            className={classes.paper}
                            onSelect={onSelect}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

ValidationDisplay.propTypes = {
    expanded: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            code: PropTypes.string.isRequired,
            message: PropTypes.string.isRequired,
        }),
    ).isRequired,
    blueprint: PropTypes.shape(blueprintPropType).isRequired,
    onSelect: PropTypes.func.isRequired,
};

ValidationDisplay.defaultProps = {};

export default ValidationDisplay;
