import { createValidator } from '../../validation/validatorFactory';

const validator = createValidator();

const validate = validator.compile({
    type: 'object',
    required: ['source'],
    properties: {
        source: { type: 'string' },
        options: { type: 'object', additionalProperties: true },
        inputs: {
            type: 'array',
            items: {
                type: 'object',
                required: ['id', 'name'],
                properties: {
                    id: { type: 'string' },
                    name: { type: 'string' },
                    schema: { type: 'object' },
                },
                additionalProperties: false,
            },
        },
        outputs: {
            type: 'array',
            items: {
                type: 'object',
                required: ['id', 'name'],
                properties: {
                    id: { type: 'string' },
                    name: { type: 'string' },
                    schema: { type: 'object', additionalProperties: true },
                },
                additionalProperties: false,
            },
            minItems: 1,
        },
    },
    additionalProperties: false,
});

export default validate;
