/* eslint-disable react/prop-types */
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';

const styles = (theme) => ({
    root: {
        color: theme.palette.lighterGray,
        fontWeight: 300,
        lineHeight: 1.5,
        marginBottom: theme.spacing(),
    },
});

export const H6 = ({ classes, className, children, ...restProps }) => (
    <Typography
        variant="h6"
        className={`${classes.root} ${className}`}
        {...restProps}
    >
        {children}
    </Typography>
);

H6.defaultProps = {
    className: '',
};

export default withStyles(styles)(H6);
