import type { Model } from './models/types';
import BaseError, { isBaseError } from './BaseError';

export type InternalErrorCode =
    | 'INTERNAL'
    | 'AGGREGATION'
    | 'PREDICTION'
    | 'PROCESSOR_FALLBACK'
    | 'TIMEOUT'
    | 'PROVIDER_API'
    | 'MAIL_DELIVERY'
    | 'INVALID_FILTER';

// An internal error
class InternalError extends BaseError {
    code: InternalErrorCode;
    override type = 'INTERNAL' as const;
    constructor(
        message: string,
        code: InternalErrorCode = 'INTERNAL',
        stack?: string,
    ) {
        super(message, 'INTERNAL', stack);
        this.type = 'INTERNAL';
        this.code = code;
    }
}

export class AggregationError extends InternalError {
    override code = 'AGGREGATION' as const;
    override details?: {
        state: unknown;
        event?: Record<string, unknown>;
        operation:
            | 'ClaimAggregation'
            | 'CaseAggregation'
            | 'PolicyAggregation'
            | 'DecisionAggregation'
            | 'EndorsementAggregation'
            | 'QuoteAggregation'
            | 'FrontendContractAggregation'
            | 'BackendContractAggregation';
    } = undefined;
    constructor(
        message: string,
        details: {
            state: unknown;
            event?: Record<string, unknown>;
            operation:
                | 'ClaimAggregation'
                | 'CaseAggregation'
                | 'PolicyAggregation'
                | 'DecisionAggregation'
                | 'EndorsementAggregation'
                | 'QuoteAggregation'
                | 'FrontendContractAggregation'
                | 'BackendContractAggregation';
        },
    ) {
        super(message, 'AGGREGATION');
        this.type = 'INTERNAL';
        this.code = 'AGGREGATION';
        this.details = details;
        this.name = 'AggregationError';
    }
}

export class PredictionError extends InternalError {
    override code = 'PREDICTION' as const;
    override type = 'INTERNAL' as const;
    override details: {
        trace?: unknown;
        featureId?: string;
        featureName?: string;
        modelId?: string;
        modelName?: string;
    } = {};
    constructor(message: string, trace?: unknown) {
        super(message, 'PREDICTION');
        this.type = 'INTERNAL';
        this.code = 'PREDICTION';
        this.details = trace ? { trace } : {};
        this.name = 'PredictionError';
    }
    setContext({
        feature,
        model,
    }: {
        feature: { nodeId: string; name: string };
        model: Pick<Model, 'id' | 'name'>;
    }) {
        this.details.featureId = feature.nodeId;
        this.details.featureName = feature.name;
        this.details.modelId = model.id;
        this.details.modelName = model.name;
    }
}

type FallbackValue = number | string | boolean | Date | null;

export type FallbackOutputs = Record<string, FallbackValue>;
type FallbackReason = 'TIMEOUT' | 'INVALID_REQUEST' | 'ERROR';

export class ProcessorFallbackError extends InternalError {
    override type = 'INTERNAL' as const;
    override code = 'PROCESSOR_FALLBACK' as const;
    override details?: {
        reason: FallbackReason;
        fallbackOutputs: FallbackOutputs;
    } = undefined;
    constructor(
        message: string,
        {
            reason,
            fallbackOutputs,
        }: {
            reason: FallbackReason;
            fallbackOutputs: FallbackOutputs;
        },
    ) {
        super(message, 'PROCESSOR_FALLBACK');
        this.type = 'INTERNAL';
        this.code = 'PROCESSOR_FALLBACK';
        this.details = { reason, fallbackOutputs };
        this.name = 'ProcessorFallbackError';
    }
}

export class TimeoutError extends InternalError {
    override code = 'TIMEOUT' as const;
    constructor(message: string) {
        super(message, 'TIMEOUT');
        this.type = 'INTERNAL';
        this.code = 'TIMEOUT';
        this.name = 'TimeoutError';
    }
}

export class ExternalAPIError extends InternalError {
    override code = 'PROVIDER_API' as const;
    override type = 'INTERNAL' as const;
    override details?: {
        responseBody: string;
        payload?: string;
        apiName: string;
    } = undefined;
    constructor(
        message: string,
        apiName: string,
        details: { responseBody: string; payload?: string },
    ) {
        super(message, 'PROVIDER_API');
        this.name = apiName;
        this.details = {
            apiName,
            ...details,
        };
    }
}

interface MailDeliveryDetails {
    from: string;
    to: string;
    subject: string;
    isMailgun: boolean;
    emailId: string | null;
}

export class MailDeliveryError extends InternalError {
    override code = 'MAIL_DELIVERY' as const;
    override details?: MailDeliveryDetails = undefined;
    override cause: Error;
    constructor(message: string, details: MailDeliveryDetails, cause: Error) {
        super(message, 'MAIL_DELIVERY');
        this.type = 'INTERNAL';
        this.code = 'MAIL_DELIVERY';
        this.details = details;
        this.name = 'MailDeliveryError';
        this.cause = cause;
    }
}

interface InvalidFilterDetails {
    filter: string;
    nullable: boolean;
}

export class InvalidFilterError extends InternalError {
    override code = 'INVALID_FILTER' as const;
    override details?: InvalidFilterDetails = undefined;
    constructor(message: string, details: InvalidFilterDetails) {
        super(message, 'INVALID_FILTER');
        this.type = 'INTERNAL';
        this.code = 'INVALID_FILTER';
        this.details = details;
        this.name = 'InvalidFilterError';
    }
}

export const isInternalError = (error: unknown): error is InternalError => {
    return isBaseError(error) && error.type === 'INTERNAL';
};

export const isPredictionError = (error: unknown): error is PredictionError => {
    return isInternalError(error) && error.code === 'PREDICTION';
};

export const isProcessorFallbackError = (
    error: unknown,
): error is ProcessorFallbackError => {
    return isInternalError(error) && error.code === 'PROCESSOR_FALLBACK';
};

export const isMailDeliveryError = (
    error: unknown,
): error is MailDeliveryError => {
    return isInternalError(error) && error.code === 'MAIL_DELIVERY';
};

export const isInvalidFilterError = (
    error: unknown,
): error is InvalidFilterError => {
    return isInternalError(error) && error.code === 'INVALID_FILTER';
};

export const isAggregationError = (
    error: unknown,
): error is AggregationError => {
    return isInternalError(error) && error.code === 'AGGREGATION';
};

export default InternalError;
