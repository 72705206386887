const SHIFT_X = 60;
const SHIFT_Y = 30;

const MARGIN_TOLERANCE = 10;

const findAvailableBlockPosition = (cameraPosition, editorData = {}) => {
    let x = cameraPosition.x + SHIFT_X;
    let y = cameraPosition.y + SHIFT_Y;

    const { nodes = {} } = editorData;
    for (const { position } of Object.values(nodes)) {
        if (Math.abs(position.x - x) < MARGIN_TOLERANCE) {
            x += SHIFT_X;
        }

        if (Math.abs(position.y - y) < MARGIN_TOLERANCE) {
            y += SHIFT_Y;
        }
    }

    return { x, y };
};

export default findAvailableBlockPosition;
