import { get, isNil } from 'lodash';

import theme from '../../../theme';

import { getBlockType } from '../blocks';
import isBrowsedEdge from './isBrowsedEdge';
import isBrowsedNode from './isBrowsedNode';
import { legacyFormatNumber } from '@tint/core/src/formatters';

const getNodeStyleFactory = (blueprint, trace, errors) => {
    const browsedEdges = blueprint.edges.filter(isBrowsedEdge(trace));
    const browsedNodeIds = Object.keys(blueprint.nodes).filter(
        isBrowsedNode(browsedEdges),
    );
    const haveErrors = errors.length > 0;

    return (node) => {
        const nodeErrors = errors.filter((error) => error.id === node.id);
        if (
            (trace && !browsedNodeIds.includes(node.id)) ||
            (haveErrors && nodeErrors.length === 0)
        ) {
            return { opacity: 0.25 };
        }

        return undefined;
    };
};

const getEdgeStyleFactory = (blueprint, trace, errors) => {
    const browsedEdges = blueprint.edges.filter(isBrowsedEdge(trace));
    const haveErrors = errors.length > 0;

    return (edge, id) => {
        const edgeErrors = errors.filter((error) => error.id === id);

        if (edgeErrors.length > 0) {
            return { stroke: theme.palette.mainRed };
        }

        if (
            (trace && !browsedEdges.includes(edge)) ||
            (haveErrors && edgeErrors.length === 0)
        ) {
            return { opacity: 0.33 };
        }

        return undefined;
    };
};

export const getEdgeLabelFactory = (blueprint, trace) => {
    const browsedEdges = blueprint.edges.filter(isBrowsedEdge(trace));

    return (edge) => {
        if (trace && browsedEdges.includes(edge)) {
            const label = get(
                trace,
                `["${edge.start.node}"].outputs["${edge.start.pin}"]`,
            );

            if (isNil(label)) {
                return 'N/A';
            }

            return typeof label === 'number'
                ? legacyFormatNumber(label)
                : label.toString();
        }

        return undefined;
    };
};

const blueprintToElements = ({
    blueprint,
    nodesEditorData,
    disabled,
    trace,
    onNodeClick,
    onRemove,
    viewMode,
    connecting,
    isValidConnection,
    onViewDetails,
    errors = [],
}) => {
    const elements = [];

    const nodes = Object.entries(blueprint.nodes).map(([id, node]) => ({
        ...node,
        id,
    }));

    const getNodeStyle = getNodeStyleFactory(blueprint, trace, errors);
    const getEdgeStyle = getEdgeStyleFactory(blueprint, trace, errors);
    const getEdgeLabel = getEdgeLabelFactory(blueprint, trace);

    for (const node of nodes) {
        // Reset position at (0, 0) in case we don't have it (which is abnormal)
        const position = get(nodesEditorData, `["${node.id}"].position`, {
            x: 0,
            y: 0,
        });

        elements.push({
            id: node.id,
            type: getBlockType(node),
            style: getNodeStyle(node),
            data: {
                ...node,
                disabled,
                editor: get(nodesEditorData, node.id, {}),
                onClick: onNodeClick && onNodeClick(node),
                onRemove: onRemove && onRemove(node),
                connectedInputs: blueprint.edges
                    .filter((edge) => edge.end.node === node.id)
                    .map((edge) => edge.end.pin),
                viewMode,
                errors: errors.filter((error) => error.id === node.id),
                connecting,
                isValidConnection,
                trace: trace ? trace[node.id] : null,
                onViewDetails,
            },
            disabled,
            position,
        });
    }

    for (const edge of blueprint.edges) {
        const { start, end } = edge;
        const id = `${start.node}-${start.pin}-${end.node}-${end.pin}`;

        elements.push({
            id,
            source: start.node,
            sourceHandle: start.pin,
            target: end.node,
            targetHandle: end.pin,
            style: getEdgeStyle(edge, id),
            label: getEdgeLabel(edge),
        });
    }

    return elements;
};

export default blueprintToElements;
