import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

import {
    Button,
    ButtonGroup,
    makeStyles,
    Typography,
    Grow,
    Popper,
    Paper,
    Grid,
} from '@material-ui/core';

import { getNodeInputs } from '@tint/core/src/processors/io';
import { blueprintPropType } from './blueprintEditorPropTypes';

const useStyles = makeStyles((theme) => ({
    popperDummy: {
        width: 12,
        height: 12,
        position: 'absolute',
        zIndex: 5,
    },
    paper: {
        padding: theme.spacing(2),
    },
}));

const InputSelector = ({
    sourceName,
    source,
    sourceHandle,
    target,
    inputs,
    onConnect,
    onCancel,
    isValidConnection,
}) => {
    return (
        <Grid container direction="column" spacing={1}>
            <Grid item>
                <Typography variant="body2">
                    Connect <strong>{sourceName}</strong> to
                </Typography>
            </Grid>
            <Grid item>
                <ButtonGroup orientation="vertical">
                    {inputs.map((input) => (
                        <Button
                            key={input.id}
                            variant="outlined"
                            color="primary"
                            onClick={onConnect(input.id)}
                            disabled={
                                !isValidConnection({
                                    source,
                                    sourceHandle,
                                    target,
                                    targetHandle: input.id,
                                })
                            }
                        >
                            {input.name}
                        </Button>
                    ))}
                </ButtonGroup>
            </Grid>
            <Grid item>
                <Button variant="outlined" fullWidth onClick={onCancel}>
                    Cancel
                </Button>
            </Grid>
        </Grid>
    );
};

InputSelector.propTypes = {
    sourceName: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
    sourceHandle: PropTypes.string.isRequired,
    target: PropTypes.string.isRequired,
    inputs: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        }),
    ).isRequired,
    onConnect: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    isValidConnection: PropTypes.func.isRequired,
};

const GroupedInputMenu = ({
    blueprint,
    onConnect,
    onCancel,
    container,
    connectingInputGroup,
    isValidConnection,
}) => {
    const classes = useStyles();
    const popperDummyRef = useRef();
    const [position, setPosition] = useState();

    const open = !!position;

    useEffect(() => {
        if (!connectingInputGroup && position) {
            setPosition(null);
        }

        if (connectingInputGroup && !position) {
            const { target, targetHandle: group } = connectingInputGroup;

            const pinElement = document.querySelector(
                `.react-flow__handle[data-nodeid="${target}"][data-handleid="${group}"]`,
            );

            if (pinElement) {
                const { top, left } = pinElement.getBoundingClientRect();
                setPosition({ top, left });
            }
        }
    }, [connectingInputGroup]);

    const { source, sourceHandle, target } = connectingInputGroup || {};
    const sourceName = source && blueprint.nodes[source].name;
    const inputs = target ? getNodeInputs(blueprint.nodes[target], false) : [];

    const handleConnect = (targetHandle) => () => {
        onConnect({
            source,
            sourceHandle,
            target,
            targetHandle,
        });
    };

    const handleCancel = () => {
        setPosition(null);
        onCancel();
    };

    return (
        <>
            {container &&
                createPortal(
                    <div
                        className={classes.popperDummy}
                        style={position || { top: -1000, left: -1000 }}
                        ref={popperDummyRef}
                    />,
                    container,
                )}
            {open && (
                <Popper
                    open
                    anchorEl={popperDummyRef.current}
                    transition
                    placement="right"
                    style={{ zIndex: 5 }}
                >
                    {({ TransitionProps }) => (
                        <Grow {...TransitionProps}>
                            <Paper elevation={2} className={classes.paper}>
                                <InputSelector
                                    sourceName={sourceName}
                                    source={source}
                                    sourceHandle={sourceHandle}
                                    target={target}
                                    inputs={inputs}
                                    onConnect={handleConnect}
                                    onCancel={handleCancel}
                                    isValidConnection={isValidConnection}
                                />
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            )}
        </>
    );
};

GroupedInputMenu.propTypes = {
    blueprint: PropTypes.shape(blueprintPropType).isRequired,
    connectingInputGroup: PropTypes.shape({
        source: PropTypes.string.isRequired,
        sourceHandle: PropTypes.string.isRequired,
        target: PropTypes.string.isRequired,
        targetHandle: PropTypes.string.isRequired,
    }),
    onConnect: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    container: PropTypes.any,
    isValidConnection: PropTypes.func.isRequired,
};

GroupedInputMenu.defaultProps = {
    container: null,
    connectingInputGroup: null,
};

export default GroupedInputMenu;
