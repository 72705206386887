import PropTypes from 'prop-types';
import { get } from 'lodash';
import { TableCell as MuiTableCell } from '@material-ui/core';

import TableCellHeader from './TableCellHeader';

const defaultRenderValue = ({ value }) => <>{value}</>;

const TableCell = ({
    isHeader,
    name,
    label,
    data,
    sortable,
    handleSortChange,
    sortBy,
    sortDir,
    renderValue = defaultRenderValue,
}) => {
    if (isHeader) {
        return (
            <TableCellHeader
                key={name}
                name={name}
                label={label}
                sortBy={sortBy}
                sortDir={sortDir}
                handleSortChange={sortable && handleSortChange}
            />
        );
    }
    return (
        <MuiTableCell key="name">
            {renderValue({ value: get(data, name), data })}
        </MuiTableCell>
    );
};
TableCell.propTypes = {
    isHeader: PropTypes.bool,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(
        PropTypes.shape({ id: PropTypes.string.isRequired }),
    ).isRequired,
    sortable: PropTypes.bool,
    handleSortChange: PropTypes.func,
    sortBy: PropTypes.string,
    sortDir: PropTypes.oneOf(['asc', 'dir']),
    renderValue: PropTypes.func,
};

export default TableCell;
