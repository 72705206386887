import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/AddBox';
import DragHandle from '@material-ui/icons/DragHandle';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

export const SummaryDisplaySchemaItemList = ({
    itemList,
    selectedId,
    selectDisplaySchema,
    removeDisplaySchema,
    addDisplaySchema,
    moveDisplaySchema,
}) => {
    return (
        <DragDropContext onDragEnd={moveDisplaySchema}>
            <Droppable droppableId="droppable">
                {(dropProvided) => (
                    <List ref={dropProvided.innerRef}>
                        <ListItem
                            button
                            key="new"
                            onClick={() => addDisplaySchema(0)}
                        >
                            <ListItemIcon>
                                <AddIcon />
                            </ListItemIcon>
                            <ListItemText primary="Add New Entry" />
                        </ListItem>
                        {itemList.map(({ label, id }, index) => (
                            <Draggable key={id} draggableId={id} index={index}>
                                {(dragProvided) => (
                                    <ListItem
                                        ref={dragProvided.innerRef}
                                        {...dragProvided.draggableProps}
                                        button
                                        key={id}
                                        selected={id === selectedId}
                                        onClick={() =>
                                            selectDisplaySchema({ id })
                                        }
                                    >
                                        <ListItemIcon
                                            {...dragProvided.dragHandleProps}
                                        >
                                            <DragHandle />
                                        </ListItemIcon>
                                        <ListItemText primary={label} />
                                        <ListItemSecondaryAction>
                                            <IconButton
                                                edge="end"
                                                size="small"
                                                ariaLabel="Delete"
                                                onClick={() =>
                                                    removeDisplaySchema(id)
                                                }
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                            <IconButton
                                                edge="end"
                                                size="small"
                                                ariaLabel="Add"
                                                onClick={() =>
                                                    addDisplaySchema(index + 1)
                                                }
                                            >
                                                <AddIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                )}
                            </Draggable>
                        ))}
                    </List>
                )}
            </Droppable>
        </DragDropContext>
    );
};

SummaryDisplaySchemaItemList.propTypes = {
    itemList: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            label: PropTypes.string,
        }),
    ).isRequired,
    selectedId: PropTypes.string,
    selectDisplaySchema: PropTypes.func.isRequired,
    removeDisplaySchema: PropTypes.func.isRequired,
    addDisplaySchema: PropTypes.func.isRequired,
    moveDisplaySchema: PropTypes.func.isRequired,
};

SummaryDisplaySchemaItemList.defaultProps = {};
