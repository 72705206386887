import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@material-ui/core';
import OUTPUT_TYPES from '@tint/core/src/processors/outputTypes';
import { Field, useForm } from 'react-final-form';
import { get } from 'lodash';
import IntervalSwitch from './interval/IntervalSwitch';
import StringSwitch from './string/StringSwitch';
import BooleanSwitch from './boolean/BooleanSwitch';
import getCategorizationType from './getCategorizationType';

const CONDITIONS_FORM_BY_INPUT_TYPE = {
    [OUTPUT_TYPES.NUMBER]: IntervalSwitch,
    [OUTPUT_TYPES.STRING]: StringSwitch,
    [OUTPUT_TYPES.BOOLEAN]: BooleanSwitch,
};

export const CONFIGURATION_TYPE_PER_INPUT_TYPE = {
    [OUTPUT_TYPES.NUMBER]: 'interval',
    [OUTPUT_TYPES.STRING]: 'string',
    [OUTPUT_TYPES.BOOLEAN]: 'boolean',
};

const CategorizationForm = ({
    nodeId,
    blueprint,
    insuranceProduct,
    ...otherProps
}) => {
    const inputType = getCategorizationType({
        nodeId,
        blueprint,
        validationSchema: insuranceProduct.validationSchema,
    });

    const form = useForm();

    useEffect(() => {
        if (!inputType) {
            return;
        }

        const { values } = form.getState();
        const expectedType = CONFIGURATION_TYPE_PER_INPUT_TYPE[inputType];

        const configurationType = get(values, 'node.configuration.type');

        if (configurationType === expectedType) {
            return;
        }

        switch (expectedType) {
            case 'string': {
                form.change('node.configuration', {
                    type: 'string',
                    conditions: [
                        {
                            operation: 'match',
                            values: [],
                            label: '',
                        },
                    ],
                });

                break;
            }

            case 'interval': {
                form.change('node.configuration', {
                    type: 'interval',
                    conditions: [],
                });
                break;
            }

            case 'boolean': {
                form.change('node.configuration', {
                    type: 'boolean',
                    conditions: [],
                });
                break;
            }

            default:
                form.change('node.configuration', null);
                break;
        }
    }, [inputType]);

    const ConditionsForm = CONDITIONS_FORM_BY_INPUT_TYPE[inputType];
    if (!ConditionsForm) {
        return (
            <Typography variant="body1">
                Please connect another block to this block input.
            </Typography>
        );
    }

    return (
        <Field
            component={ConditionsForm}
            name="node.configuration"
            {...otherProps}
        />
    );
};

CategorizationForm.propTypes = {
    nodeId: PropTypes.string.isRequired,
    blueprint: PropTypes.object.isRequired,
    insuranceProduct: PropTypes.object.isRequired,
};

CategorizationForm.defaultProps = {};

export default CategorizationForm;
