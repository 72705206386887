import { get, omit } from 'lodash';
import { gql } from '@apollo/client';
import pluralize from 'pluralize';

import { GET_LIST, CREATE, UPDATE, DELETE, GET_ONE, GET_MANY } from 'ra-core';
import { uncapitalize } from '../stringUtils';
import queries from './queries';

export const getMatchingType = (introspectionResults, typeName) =>
    introspectionResults.types.find((t) => t.name === typeName);

const getQueryForIntrospectedField = (field, name = null) => {
    const type = name ? field : field.type;

    const fieldName = name || field.name;
    const { kind, ofType } = type;

    switch (kind) {
        case 'LIST':
        case 'NON_NULL':
            return getQueryForIntrospectedField(ofType, fieldName);

        case 'SCALAR':
        case 'ENUM':
            return fieldName;

        case 'INTERFACE':
        case 'OBJECT':
            if (fieldName === 'mailConfig') {
                return `${fieldName} {
                    host
                    port
                    secure
                    auth {
                        type
                        user
                        clientId
                        clientSecret
                        refreshToken
                        accessUrl
                        customParams
                    }
                }`;
            }
            return `${fieldName} { id }`;

        default:
            throw new Error(`Unhandled GraphQL kind: ${kind}`);
    }
};

export const getFieldsForType = (type) => {
    const resource = queries[type.name];
    const ignoreFields = resource?.ignoreFields ?? [];

    return type.fields
        .filter((field) => !ignoreFields.includes(field.name))
        .map((field) => getQueryForIntrospectedField(field));
};

export const buildFields = (type) => {
    const fields = getFieldsForType(type);
    return fields.join(' ');
};

export const getInputValue = (field, paramValue, introspectionResults) => {
    if (paramValue instanceof Date) {
        return paramValue.toISOString();
    }

    const type = field.type || field;
    const { kind, ofType } = type;

    if (kind === 'NON_NULL') {
        return getInputValue(ofType, paramValue, introspectionResults);
    }

    if (kind === 'LIST' && ofType.name) {
        const result = introspectionResults.types.find(
            (type) => type.name === ofType.name,
        );

        const graphqlFields = result.inputFields.map((inp) => inp.name);

        return paramValue.map((record) =>
            Object.keys(record).reduce((acc, key) => {
                if (graphqlFields.includes(key)) {
                    acc[key] = record[key];
                }

                return acc;
            }, {}),
        );
    }

    if (!ofType) {
        return paramValue;
    }

    if (ofType.name === 'Boolean') {
        return !!paramValue;
    }

    if (ofType.kind === 'LIST') {
        return paramValue
            ? paramValue.map((item) => omit(item, ['__typename']))
            : [];
    }

    return paramValue;
};

export const isRelationship = (field) => {
    const objectKinds = ['OBJECT', 'INPUT_OBJECT'];

    if (objectKinds.includes(get(field, 'type.kind'))) {
        return true;
    }

    if (objectKinds.includes(get(field, 'type.ofType.kind'))) {
        return true;
    }

    if (
        get(field, 'type.name') === 'ID' ||
        get(field, 'type.ofType.name') === 'ID'
    ) {
        return true;
    }

    return false;
};

export const paramsToInputsFactory = (resource, introspectionResults) => {
    const { inputFields } = introspectionResults.types.find(
        (type) => type.name === `${resource.type.name}Input`,
    );

    return (params) =>
        inputFields.reduce((inputs, currentField) => {
            if (isRelationship(currentField)) {
                const fieldName = currentField.name;

                if (!(fieldName in params)) {
                    // Hack to support GraphQL relations without having to create a new input field in the schema
                    return {
                        ...inputs,
                        [fieldName]: get(
                            params,
                            `${fieldName.replace(/Id$/, '')}.id`,
                        ),
                    };
                }
            }

            return {
                ...inputs,
                [currentField.name]: getInputValue(
                    currentField,
                    params[currentField.name],
                    introspectionResults,
                ),
            };
        }, {});
};

export const buildArgsForQuery = (query) => {
    const parsedArgs = query.args.map((arg) => ({
        name: arg.name,
        type: arg.type.name,
    }));

    return {
        variables: parsedArgs
            .map(({ name, type }) => `$${name}: ${type}`)
            .join(',\n'),
        query: parsedArgs.map(({ name }) => `${name}: $${name}`).join(',\n'),
    };
};

export const getQueryByName = (introspectionResults, name) =>
    introspectionResults.queries.find((q) => q.name === name);

// Temporary, until https://github.com/marmelab/react-admin/pull/5858 is available
export const parseFilters = (resource, filters) => {
    if (!filters) {
        return filters;
    }

    if (['Model', 'InsuranceProductVersion'].includes(resource)) {
        if (filters.archived === 'null') {
            return {
                ...filters,
                archived: null,
            };
        }
    }

    return filters;
};

export const buildQuery =
    (introspectionResults) => (raFetchType, resourceName, params) => {
        const resource = introspectionResults.resources.find(
            (r) => r.type.name === resourceName,
        );

        if (!resource) {
            throw new Error(
                `Unknown resource ${resourceName} (${raFetchType})`,
            );
        }

        if (raFetchType === GET_MANY) {
            let query = get(queries, `${resourceName}.getList`);
            const queryName = uncapitalize(pluralize(resourceName));

            if (!query) {
                const preparedArgs = buildArgsForQuery(
                    getQueryByName(introspectionResults, queryName),
                );

                query = gql`
                    query get${pluralize(resourceName)}(
                        ${preparedArgs.variables}
                    ) {
                        ${queryName}(
                            ${preparedArgs.query}
                        ) {
                            count
                            items {
                                ${buildFields(resource.type)}
                            }
                        }
                    }
                `;
            }

            return {
                query: typeof query === 'string' ? gql(query) : query,
                variables: {
                    filter: {
                        ids: params.ids,
                    },
                },
                parseResponse: ({ data }) => ({
                    data: data[queryName].items,
                    total: data[queryName].count,
                }),
            };
        }

        if (raFetchType === GET_LIST) {
            let query = get(queries, `${resourceName}.getList`);
            const queryName = uncapitalize(pluralize(resourceName));

            if (!query) {
                if (resourceName === 'Coverage') {
                    query = require('./queries/coveragesList.graphql');
                } else if (resourceName === 'Quote') {
                    query = require('./queries/quotes/getList.graphql');
                } else {
                    const preparedArgs = buildArgsForQuery(
                        getQueryByName(introspectionResults, queryName),
                    );

                    query = gql`
                        query get${resourceName}(
                            ${preparedArgs.variables}
                        ) {
                            ${queryName}(
                                ${preparedArgs.query}
                            ) {
                                count
                                items {
                                    ${buildFields(resource.type)}
                                }
                            }
                        }
                    `;
                }
            }

            return {
                query,
                variables: {
                    sortBy: params.sort.field,
                    sortDir: params.sort.order,
                    page: params.pagination.page,
                    perPage: params.pagination.perPage,
                    filter: parseFilters(resourceName, params.filter),
                },
                parseResponse: ({ data }) => ({
                    data: data[queryName].items,
                    total: data[queryName].count,
                }),
            };
        }

        if (raFetchType === GET_ONE) {
            let query = get(queries, `${resourceName}.getOne`);
            const queryName = uncapitalize(resourceName);

            if (!query) {
                switch (resourceName === 'InsuranceProductVersion') {
                    case 'Quote':
                        query = require('./queries/quotes/getOne.graphql');
                        break;

                    default:
                        query = gql`
                            query get${resourceName}($id: ID!) {
                                ${queryName}(id: $id) {
                                    ${buildFields(resource.type)}
                                }
                            }
                        `;
                }
            }

            return {
                query,
                variables: {
                    id: params.id,
                },
                parseResponse: ({ data }) => ({ data: data[queryName] }),
            };
        }

        const paramsToInputs = paramsToInputsFactory(
            resource,
            introspectionResults,
        );

        if (raFetchType === CREATE) {
            const queryName = `create${resourceName}`;
            const inputName = `${resourceName}Input`;

            return {
                query: gql`
                mutation ${queryName}($input: ${inputName}!) {
                    ${queryName}(input: $input) {
                        ${buildFields(resource.type)}
                    }
                }
            `,
                variables: { input: paramsToInputs(params.data) },
                parseResponse: ({ data }) => ({ data: data[queryName] }),
            };
        }

        if (raFetchType === UPDATE) {
            const queryName = `update${resourceName}`;
            const inputName = `${resourceName}Input`;

            return {
                query: gql`
                mutation ${queryName}($id: ID!, $input: ${inputName}!) {
                    ${queryName}(id: $id, input: $input) {
                        ${buildFields(resource.type)}
                    }
                }
            `,
                variables: {
                    id: params.id,
                    input: paramsToInputs(params.data),
                },
                parseResponse: ({ data }) => ({ data: data[queryName] }),
            };
        }

        if (raFetchType === DELETE) {
            const queryName = `delete${resourceName}`;

            return {
                query: gql`
                mutation ${queryName}($id: ID!) {
                    ${queryName}(id: $id) {
                        ${buildFields(resource.type)}
                    }
                }
            `,
                variables: { id: params.id },
                parseResponse: ({ data }) => ({ data: data[queryName] }),
            };
        }

        throw new Error(`Unknow dataprovider verb "${raFetchType}"`);
    };
