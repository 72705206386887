import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import PropTypes from 'prop-types';

import { IconButton, makeStyles, Tabs } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import CloseIcon from '@material-ui/icons/Close';
import { hasNamedPins } from '@tint/core/src/processors/io';
import {
    getNodeInputPins,
    getConfigurationTabs,
    getConfigurationForm,
} from './processors';

import {
    blueprintPropType,
    editorDataPropType,
} from '../blueprintEditorPropTypes';
import EditableTextInput from '../../../forms/EditableTextInput';
import InputsConfiguration from './InputsConfiguration';

const useStyles = makeStyles((theme) => ({
    drawerPaper: {
        maxWidth: 900,
        minWidth: 670,
        padding: 0,
        overflowX: 'hidden', // or the delete row button from the Switch will be trigger the scroll bar
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(3),
    },
    name: {
        flex: '1 0 0',
    },
    close: {
        flex: '0 0 auto',
    },
    tabs: {
        borderBottom: `1px solid ${theme.palette.cardOutlineGray}`,
        padding: theme.spacing(0, 3),
    },
    body: {
        padding: theme.spacing(3),
    },
}));

const getInitialTab = ({ node, disabled }) => {
    if (!node) {
        return 0;
    }

    return node.processor === 'rating-table' && disabled ? 1 : 0;
};

const requiredNodeName = (value) =>
    value && value.trim() !== '' ? undefined : 'The block name is required';

const BlockConfigurationForm = ({
    editorContainer,
    nodeId,
    node,
    disabled,
    blueprint,
    editorData,
    errors,
    onClose,
    onDisconnect,
    onReorderEdges,
    insuranceProduct,
    isOutputNode,
    showRestrictedConfiguration,
    modelId,
    ReadOnlyLink,
}) => {
    const [openedDrawer, setOpenedDrawer] = useState(!!node);
    const [tab, setCurrentTab] = useState(getInitialTab({ node, disabled }));

    useEffect(() => {
        setOpenedDrawer(!!node);

        if (node) {
            setCurrentTab(getInitialTab({ node, disabled }));
        }
    }, [node, disabled]);

    const classes = useStyles();
    const editor = get(editorData, `nodes["${nodeId}"]`);

    const onTabChange = (evt, newTab) => setCurrentTab(newTab);

    let configurationTabs = [];
    let ConfigurationForm = null;
    let inputs = [];

    if (node) {
        configurationTabs = getConfigurationTabs(
            node,
            showRestrictedConfiguration,
        );
        ConfigurationForm = getConfigurationForm(
            node,
            showRestrictedConfiguration,
        );

        inputs = getNodeInputPins(blueprint, nodeId, node);
    }

    const onDrawerClose = (handleSubmit, isValid) => () => {
        if (openedDrawer && isValid) {
            handleSubmit();
            setOpenedDrawer(false);
        }
    };

    const currentTab = configurationTabs[tab];

    useEffect(() => {
        if (errors.length) {
            console.error(errors);
        }
    }, [errors]);

    return (
        <Form
            onSubmit={onClose}
            initialValues={{ node, editor }}
            mutators={arrayMutators}
            render={({ handleSubmit, valid }) => (
                <form onSubmit={handleSubmit}>
                    <Drawer
                        container={editorContainer}
                        open={openedDrawer}
                        onClose={onDrawerClose(handleSubmit, valid)}
                        anchor="right"
                        classes={{ paper: classes.drawerPaper }}
                        ModalProps={{
                            BackdropProps: { 'data-testid': 'backdrop' },
                        }}
                    >
                        <div className={classes.header}>
                            <div className={classes.name}>
                                <Field
                                    component={EditableTextInput}
                                    variant="h1"
                                    name="node.name"
                                    disabled={disabled}
                                    required={true}
                                    validate={requiredNodeName}
                                />
                            </div>
                            <div className={classes.close}>
                                <IconButton
                                    aria-label="Close drawer"
                                    onClick={onDrawerClose(handleSubmit, valid)}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        </div>
                        {configurationTabs.length > 0 && (
                            <>
                                <div className={classes.tabs}>
                                    <Tabs
                                        indicatorColor="primary"
                                        textColor="primary"
                                        value={tab}
                                        onChange={onTabChange}
                                    >
                                        {configurationTabs}
                                    </Tabs>
                                </div>
                                <div className={classes.body}>
                                    {currentTab &&
                                        currentTab.key !== 'inputs' &&
                                        ConfigurationForm && (
                                            <ConfigurationForm
                                                tab={tab}
                                                insuranceProduct={
                                                    insuranceProduct
                                                }
                                                disabled={disabled}
                                                nodeId={nodeId}
                                                node={node}
                                                inputs={inputs}
                                                blueprint={blueprint}
                                                editorData={editorData}
                                                isOutputNode={isOutputNode}
                                                modelId={modelId}
                                                ReadOnlyLink={ReadOnlyLink}
                                            />
                                        )}
                                    {currentTab &&
                                        currentTab.key === 'inputs' && (
                                            <InputsConfiguration
                                                canBeReordered={
                                                    !hasNamedPins(node)
                                                }
                                                target={nodeId}
                                                inputs={inputs}
                                                onDisconnect={onDisconnect}
                                                onReorderEdges={onReorderEdges}
                                                disabled={disabled}
                                            />
                                        )}
                                </div>
                            </>
                        )}
                    </Drawer>
                </form>
            )}
        />
    );
};

BlockConfigurationForm.propTypes = {
    editorContainer: PropTypes.object,
    nodeId: PropTypes.string,
    node: PropTypes.object,
    blueprint: PropTypes.shape(blueprintPropType).isRequired,
    editorData: PropTypes.shape(editorDataPropType).isRequired,
    errors: PropTypes.arrayOf(PropTypes.object),
    onClose: PropTypes.func,
    onDisconnect: PropTypes.func,
    onReorderEdges: PropTypes.func,
    insuranceProduct: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
    disabled: PropTypes.bool,
    isOutputNode: PropTypes.bool.isRequired,
    showRestrictedConfiguration: PropTypes.bool,
    modelId: PropTypes.string.isRequired,
    ReadOnlyLink: PropTypes.elementType,
};

BlockConfigurationForm.defaultProps = {
    editorContainer: null,
    nodeId: null,
    node: null,
    disabled: false,
    errors: [],
    onClose: () => {},
    onDisconnect: () => {},
    onReorderEdges: () => {},
    showRestrictedConfiguration: false,
    ReadOnlyLink: null,
};

export default BlockConfigurationForm;
