import getOne from './getOne.graphql';
import getList from './getList.graphql';
import create from './create.graphql';
import update from './update.graphql';

const insuranceProductQueries = {
    getOne,
    getList,
    create,
    update,
    ignoreFields: ['reporting', 'postmanCollection'],
};

export default insuranceProductQueries;
