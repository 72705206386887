import { ApolloProvider } from '@apollo/client';

import { useState, useEffect } from 'react';

import IdleSessionModal from './v4/notifications/IdleSessionModal';
import pluralize from 'pluralize';
import { Admin, Resource } from 'react-admin';
import { GET_LIST, GET_ONE } from 'ra-core';

import InsuranceProductIcon from '@material-ui/icons/Folder';
import QuestionnaireIcon from '@material-ui/icons/ContactSupport';
import { Route } from 'react-router-dom/cjs/react-router-dom.min';
import DomainIcon from '@material-ui/icons/Domain';
import PeopleIcon from '@material-ui/icons/People';
import buildDataProvider from './dataProvider';
import { apolloClient } from './apolloClient';
import { uncapitalize } from './stringUtils';
import { __schema as graphQLSchema } from './graphql/schema.json';
import { authProvider } from './authProvider';

import { InsuranceProductsList } from './insuranceProducts/List';
import { InsuranceProductsEdit } from './insuranceProducts/Edit';

import {
    ModelIcon,
    ModelsList,
    ModelCreate,
    ModelEdit,
    ModelTest,
} from './models';
import {
    ClaimFormIcon,
    ClaimFormList,
    ClaimFormCreate,
    ClaimFormEdit,
} from './claimForms';
import { OrganizationList } from './organizations/List';
import { OrganizationEdit } from './organizations/Edit';

import { UserList } from './users/List';
import { UserEdit } from './users/Edit';

import {
    ExternalDataProviderCredentialsCreate,
    ExternalDataProviderCredentialsEdit,
} from './externalDataProviderCredentials';
import {
    QuestionnairesList,
    QuestionnaireEdit,
    QuestionnaireCreate,
} from './questionnaires';

export const App = (): JSX.Element => {
    const [dataProvider, setDataProvider] = useState(null);

    useEffect(() => {
        async function setProvider() {
            const provider = await buildDataProvider({
                schema: graphQLSchema,
                operationNames: {
                    [GET_LIST]: (resource) =>
                        uncapitalize(pluralize(resource.name, 0, false)),
                    [GET_ONE]: (resource) => uncapitalize(resource.name),
                },
            });

            setDataProvider(() => provider);
        }

        setProvider();
    }, []);

    if (!dataProvider) {
        return <h1>Loading...</h1>;
    }

    return (
        /* Let's use the official GraphQL client instead of the RA one to get more flexibility. */
        <ApolloProvider client={apolloClient}>
            <IdleSessionModal
                timeoutInMs={29 * 60 * 1000}
                promptTimeoutInMs={60 * 1000}
                onIdle={authProvider.logout}
            />
            <Admin
                customRoutes={[
                    <Route
                        key="test"
                        path="/Model/:modelId/test"
                        component={ModelTest}
                    />,
                ]}
                dataProvider={dataProvider}
                authProvider={authProvider}
            >
                <Resource
                    name="Organization"
                    icon={DomainIcon}
                    list={OrganizationList}
                    edit={OrganizationEdit}
                />
                <Resource
                    name="User"
                    icon={PeopleIcon}
                    list={UserList}
                    edit={UserEdit}
                />
                <Resource
                    name="Form"
                    icon={ClaimFormIcon}
                    list={ClaimFormList}
                    create={ClaimFormCreate}
                    edit={ClaimFormEdit}
                />
                <Resource
                    name="InsuranceProduct"
                    icon={InsuranceProductIcon}
                    options={{ label: 'Insurance Products' }}
                    list={InsuranceProductsList}
                    edit={InsuranceProductsEdit}
                />
                <Resource
                    name="Questionnaire"
                    icon={QuestionnaireIcon}
                    list={QuestionnairesList}
                    create={QuestionnaireCreate}
                    edit={QuestionnaireEdit}
                />
                <Resource
                    name="Model"
                    icon={ModelIcon}
                    options={{ label: 'Models' }}
                    list={ModelsList}
                    create={ModelCreate}
                    edit={ModelEdit}
                />
                <Resource
                    name="ExternalDataProviderCredential"
                    create={ExternalDataProviderCredentialsCreate}
                    edit={ExternalDataProviderCredentialsEdit}
                />
                <Resource name="ApiToken" />
                <Resource name="Datasource" />
                <Resource name="Role" />
                <Resource name="DatasourceConfiguration" />
            </Admin>
        </ApolloProvider>
    );
};
