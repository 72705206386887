import { createValidator } from '../../validation/validatorFactory';

const validator = createValidator();

const validate = validator.compile({
    oneOf: [
        { type: 'null' },
        {
            type: 'object',
            required: [],
            properties: {
                timeout: {
                    type: 'number',
                    minimum: 0,
                    maximum: 29000,
                },
                fallbackIdentityCheckScore: {
                    type: ['number', 'null'],
                    minimum: 0,
                    maximum: 500,
                },
                fallbackIdentityNetworkScore: {
                    type: ['number', 'null'],
                    minimum: 0,
                    maximum: 1,
                },
                fallbackEstimatedAge: {
                    type: ['number', 'null'],
                },
                fallbackPhoneNumberIsValid: { type: ['boolean', 'null'] },
                fallbackPhoneNumberCarrier: {
                    type: ['string', 'null'],
                },
                fallbackPhoneNumberIsPrepaid: {
                    type: ['boolean', 'null'],
                },
                fallbackPhoneNumberMatchToName: {
                    type: ['string', 'null'],
                },
                fallbackPhoneNumberMatchToAddress: {
                    type: ['string', 'null'],
                },
                fallbackAddressMatchToName: {
                    type: ['string', 'null'],
                },
                fallbackEmailFirstSeenDays: {
                    type: ['number', 'null'],
                },
                fallbackEmailMatchToName: {
                    type: ['string', 'null'],
                },
                fallbackEmailIsAutogenerated: { type: ['boolean', 'null'] },
                fallbackEmailIsDisposable: { type: ['boolean', 'null'] },
                fallbackEmailDomainCreationDays: {
                    type: ['number', 'null'],
                },
                fallbackIpDistanceFromAddress: {
                    type: ['number', 'null'],
                },
                fallbackIpDistanceFromPhoneNumber: {
                    type: ['number', 'null'],
                },
            },
            additionalProperties: false,
        },
    ],
});

export default validate;
