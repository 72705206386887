import { createValidator } from '../../../validation/validatorFactory';

const validator = createValidator();

const validate = validator.compile({
    oneOf: [
        { type: 'null' },
        {
            type: 'object',
            required: [],
            properties: {
                timeout: {
                    type: 'number',
                    minimum: 0,
                    maximum: 29000,
                },
                fallbackValue: {
                    type: ['string', 'null'],
                    enum: ['Allow', 'Deny', 'Challenge'],
                },
            },
            additionalProperties: false,
        },
    ],
});

export default validate;
