import FeatureBlock, { getFeatureBlockColor } from './FeatureBlock';
import ProcessorBlock, { getProcessorBlockColor } from './ProcessorBlock';
import OutputBlock, { getOutputBlockColor } from './OutputBlock';

export const OUTPUT_BLOCK = 'outputBlock';
export const FEATURE_BLOCK = 'feature';
export const PROCESSOR_BLOCK = 'processor';

export const getBlockType = (node) => {
    if (node.outputType) {
        return OUTPUT_BLOCK;
    }

    if (node.processor === 'attribute') {
        return FEATURE_BLOCK;
    }

    return PROCESSOR_BLOCK;
};

export const getNodeColor = (theme) => (node) => {
    switch (node.type) {
        case FEATURE_BLOCK:
            return getFeatureBlockColor(theme);
        case PROCESSOR_BLOCK:
            return getProcessorBlockColor(theme, node.data);
        case OUTPUT_BLOCK:
            return getOutputBlockColor(theme, node.data);
        default:
            return theme.palette.cardOutlineGray;
    }
};

const nodeTypes = {
    feature: FeatureBlock,
    processor: ProcessorBlock,
    outputBlock: OutputBlock,
};

export default nodeTypes;
