import PropTypes from 'prop-types';

import { makeStyles, Typography } from '@material-ui/core';

import { dataPropType, blockIoDescription } from '../blockPropTypes';
import { getPinPositionFromTop } from '../DefaultPins';
import ConnectorPin from '../ConnectorPin';
import ExternalDataReportContent from './ExternalDataReportContent';

const useStyles = makeStyles((theme) => ({
    section: {
        position: 'relative',
        '&:not(:last-child)': {
            borderBottom: `1px dashed ${theme.palette.lighterGray}`,
        },
    },
    label: {
        padding: theme.spacing(2),
        fontSize: 12,
    },
}));

export const OneSectionPerOutputHeaderPins = ({ data, inputs, ...props }) => {
    const getInputPinPosition = getPinPositionFromTop(inputs.length);

    return (
        <>
            {inputs.map((input, i) => (
                <ConnectorPin
                    {...props}
                    key={input.id}
                    selector={`${data.id}-${input.id}`}
                    nodeId={data.id}
                    type="target"
                    position="left"
                    id={input.id}
                    name={input.name}
                    style={{
                        top: getInputPinPosition(i),
                    }}
                />
            ))}
        </>
    );
};

OneSectionPerOutputHeaderPins.propTypes = {
    data: PropTypes.shape(dataPropType).isRequired,
    inputs: blockIoDescription.isRequired,
    outputs: blockIoDescription.isRequired,
};

OneSectionPerOutputHeaderPins.defaultProps = {};

const OneSectionPerOutputContent = ({ data, outputs, ...props }) => {
    const classes = useStyles();

    return (
        <>
            <ExternalDataReportContent
                className={classes.section}
                data={data}
            />
            {outputs.map((output) => (
                <div className={classes.section} key={output.id}>
                    <Typography
                        component="div"
                        variant="body1"
                        className={classes.label}
                    >
                        {output.name}
                    </Typography>
                    <ConnectorPin
                        {...props}
                        selector={`${data.id}-${output.id}`}
                        nodeId={data.id}
                        type="source"
                        position="right"
                        id={output.id}
                        name={output.name}
                        style={{
                            top: '50%',
                        }}
                    />
                </div>
            ))}
        </>
    );
};

OneSectionPerOutputContent.propTypes = {
    data: PropTypes.shape(dataPropType).isRequired,
    inputs: blockIoDescription.isRequired,
    outputs: blockIoDescription.isRequired,
};

OneSectionPerOutputContent.defaultProps = {};

export default OneSectionPerOutputContent;
