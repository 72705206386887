import { get } from 'lodash';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    CircularProgress,
    Grid,
    IconButton,
    Typography,
} from '@material-ui/core';
import { Cancel, DeleteForever, Edit, Save } from '@material-ui/icons';
import { Confirm } from '../Confirm';
import processors from '@tint/core/src/processors';
import { useSnackbar } from 'notistack';

import TextInput from './TextInput';

export const GET_EXTERNAL_DATA_PROVIDER_CREDENTIALS = gql`
    query externalDataProviderCredentials(
        $filter: ExternalDataProviderCredentialFilter
    ) {
        externalDataProviderCredentials(filter: $filter, perPage: 1) {
            items {
                id
            }
        }
    }
`;

export const CREATE_EXTERNAL_PROVIDER_CREDENTIALS = gql`
    mutation createExternalDataProviderCredential(
        $input: ExternalDataProviderCredentialInput!
    ) {
        createExternalDataProviderCredential(input: $input) {
            id
        }
    }
`;

export const UPDATE_EXTERNAL_PROVIDER_CREDENTIALS = gql`
    mutation updateExternalDataProviderCredential(
        $id: ID!
        $input: ExternalDataProviderCredentialInput!
    ) {
        updateExternalDataProviderCredential(id: $id, input: $input) {
            id
        }
    }
`;

export const DELETE_EXTERNAL_PROVIDER_CREDENTIALS = gql`
    mutation deleteExternalDataProviderCredential($id: ID!) {
        deleteExternalDataProviderCredential(id: $id) {
            id
        }
    }
`;

const ExternalDataCredentialInput = ({
    insuranceProduct,
    providerSlug,
    name,
    ...props
}) => {
    const inputRef = useRef();
    const [editMode, setEditMode] = useState(false);
    const [value, setValue] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const [confirmUpdate, setConfirmUpdate] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const onConfirmUpdateClose = () => setConfirmUpdate(false);
    const onConfirmDeleteClose = () => setConfirmDelete(false);

    const organizationId = get(insuranceProduct, 'organization.id', null);

    const {
        data,
        loading: retrieveLoading,
        refetch,
    } = useQuery(GET_EXTERNAL_DATA_PROVIDER_CREDENTIALS, {
        variables: { filter: { organizationId, providerSlug, name } },
    });

    const [
        createCredentials,
        { data: creationData, loading: creationLoading, error: creationError },
    ] = useMutation(CREATE_EXTERNAL_PROVIDER_CREDENTIALS);
    const [
        updateCredentials,
        { data: updateData, loading: updateLoading, error: updateError },
    ] = useMutation(UPDATE_EXTERNAL_PROVIDER_CREDENTIALS);
    const [
        deleteCredentials,
        { data: deletionData, loading: deletionLoading, error: deletionError },
    ] = useMutation(DELETE_EXTERNAL_PROVIDER_CREDENTIALS);

    const loading =
        retrieveLoading || creationLoading || updateLoading || deletionLoading;
    const credentials = get(data, 'externalDataProviderCredentials.items[0]');
    const editable = !loading && ((credentials && editMode) || !credentials);

    useEffect(() => {
        if (editMode && inputRef.current) {
            inputRef.current.focus();
        }
    }, [editMode]);

    useEffect(() => {
        if (!editMode && value.length > 0) {
            setEditMode(true);
        }
    }, [editMode, value]);

    useEffect(() => {
        if (creationData || updateData || deletionData) {
            refetch();
            setValue('');
            setEditMode(false);
            enqueueSnackbar(
                deletionData
                    ? 'Your credentials have been deleted!'
                    : 'Your credentials have been saved!',
                {
                    variant: 'success',
                },
            );
        }
    }, [creationData, updateData, deletionData]);

    useEffect(() => {
        if (!creationError && !updateError && !deletionError) {
            return;
        }

        enqueueSnackbar(
            deletionError
                ? 'An error occured while deleting your credentials'
                : 'An error occured while saving your credentials',
            {
                variant: 'error',
            },
        );
    }, [creationError, updateError, deletionError]);

    const handleEdit = () => setEditMode(true);
    const handleCancel = () => {
        setEditMode(false);
        setValue('');
    };
    const handleChange = (evt) => setValue(evt.target.value);

    const handleSave = () => {
        if (!credentials) {
            createCredentials({
                variables: {
                    input: { organizationId, providerSlug, name, value },
                },
            });
            return;
        }

        setConfirmUpdate(true);
    };

    const handleDelete = () => setConfirmDelete(true);

    const handleConfirmUpdate = () => {
        setConfirmUpdate(false);
        updateCredentials({
            variables: {
                id: credentials.id,
                input: { organizationId, providerSlug, name, value },
            },
        });
    };

    const handleConfirmDelete = () => {
        setConfirmDelete(false);
        deleteCredentials({
            variables: {
                id: credentials.id,
            },
        });
    };

    return (
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={10}>
                <TextInput
                    name={name}
                    {...props}
                    disabled={!editable}
                    input={{
                        value:
                            credentials && !editMode
                                ? 'welltriedhacker'
                                : value,
                        onChange: handleChange,
                    }}
                    inputProps={{ 'data-lpignore': true }}
                    fullWidth
                    ref={inputRef}
                    autoFocus
                    InputLabelProps={{ shrink: true }}
                />
            </Grid>
            {loading && (
                <Grid item xs={1}>
                    <CircularProgress size={20} />
                </Grid>
            )}
            {!loading && (credentials || editMode) && (
                <>
                    <Grid item xs={1}>
                        {editMode ? (
                            <IconButton
                                color="primary"
                                aria-label="Save"
                                disabled={value.length === 0}
                                onClick={handleSave}
                                title="Save"
                            >
                                <Save />
                            </IconButton>
                        ) : (
                            <IconButton
                                color="primary"
                                aria-label="Edit"
                                onClick={handleEdit}
                                title="Edit"
                            >
                                <Edit />
                            </IconButton>
                        )}
                    </Grid>
                    <Grid item xs={1}>
                        {editMode ? (
                            <IconButton
                                title="Cancel"
                                aria-label="Cancel"
                                onClick={handleCancel}
                            >
                                <Cancel />
                            </IconButton>
                        ) : (
                            <IconButton
                                title="Delete"
                                aria-label="Delete"
                                onClick={handleDelete}
                            >
                                <DeleteForever />
                            </IconButton>
                        )}
                    </Grid>
                </>
            )}
            <Confirm
                open={confirmUpdate}
                onClose={onConfirmUpdateClose}
                onConfirm={handleConfirmUpdate}
            >
                <Grid container spacing={2}>
                    <Grid item>
                        <Typography variant="body1">
                            This will affect every models that is using that
                            secret even for the live versions.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1">
                            Are you sure that you want to change the{' '}
                            <strong>{name}</strong> secret?
                        </Typography>
                    </Grid>
                </Grid>
            </Confirm>
            <Confirm
                danger
                open={confirmDelete}
                onClose={onConfirmDeleteClose}
                onConfirm={handleConfirmDelete}
            >
                <Grid container spacing={2}>
                    <Grid item>
                        <Typography variant="body1">
                            This will affect every models that is using that
                            secret even for the live versions.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1">
                            Are you sure that you want to delete the{' '}
                            <strong>{name}</strong> secret?
                        </Typography>
                    </Grid>
                </Grid>
            </Confirm>
        </Grid>
    );
};

ExternalDataCredentialInput.propTypes = {
    ...TextInput.propTypes,
    providerSlug: PropTypes.oneOf(Object.keys(processors)).isRequired,
};

ExternalDataCredentialInput.defaultProps = {
    ...TextInput.defaultProps,
};

export default ExternalDataCredentialInput;
