import ReactDOM from 'react-dom';
import { apiClient } from './apiClient';
import { App } from './App';
import * as serviceWorker from './serviceWorker';

setInterval(
    () => {
        apiClient.get('/refresh-permissions'); // Actually not a graphql endpoint
    },
    5 * 60 * 1000,
); // Every 5 minutes

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
