import OUTPUT_TYPES from '../outputTypes';
import validator from './validate';

const currentDateProcessor = {
    name: 'Current Date',
    slug: 'current-date',
    description: 'Return the current datetime when the model is ran',
    validate: validator,
    inputs: [] as never[],
    outputs: [
        {
            id: 'output',
            name: 'Output',
            getType: () => OUTPUT_TYPES.DATETIME,
        },
    ],
};

export default currentDateProcessor;
