import { get, pickBy, isEqual } from 'lodash';
import Fuse from 'fuse.js';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useForm } from 'react-final-form';
import getDefaultLookoutPeriod from '@tint/core/src/processors/sambaSafety/getDefaultLookoutPeriod';
import { OnChange } from 'react-final-form-listeners';

import VIOLATION_PER_TYPES from '@tint/core/src/processors/sambaSafety/violationsPerTypes';

import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { TextInput } from '../../../../../forms';

import NativeSelect from '../../../../../forms/NativeSelect';

import useDebounce from '../../../../../hooks/useDebounce';

import DEFAULT_EVENTS from './defaultEvents';
import { ViolationRow } from './ViolationRow';

// Miscellanous has 124 violations, so let's display them all if filtered by record type
const MAXIMUM_DISPLAYED_VIOLATIONS = 125;

const filterViolations = (recordType, search) => {
    const typeFilteredViolations = recordType
        ? VIOLATION_PER_TYPES[recordType]
        : Object.values(VIOLATION_PER_TYPES).flat();

    if (!search) {
        return typeFilteredViolations;
    }

    const fuse = new Fuse(typeFilteredViolations, {
        keys: [
            {
                name: 'Record Name',
                weight: 3,
            },
            {
                name: 'Record Code',
                weight: 5,
            },
        ],
    });

    return fuse.search(search).map((r) => r.item);
};

export const defaultEvents = Object.entries(DEFAULT_EVENTS).reduce(
    (acc, [code, config]) => {
        acc[code] = {
            ...config,
            lookoutPeriodInYear: getDefaultLookoutPeriod(code),
        };

        return acc;
    },
    {},
);

const ViolationTable = ({ disabled, ...otherProps }) => {
    const [recordType, setRecordType] = useState('Accident');
    const [search, setSearch] = useState('');

    const [displayedViolations, setDisplayedViolations] = useState(
        filterViolations('Accident'),
    );

    const debouncedValue = useDebounce(search, 600);
    useEffect(() => {
        setDisplayedViolations(filterViolations(recordType, debouncedValue));
    }, [recordType, debouncedValue]);

    const form = useForm();

    useEffect(() => {
        const monitoredEvents = get(
            form.getState(),
            'values.node.configuration.events',
            {},
        );

        if (
            Object.keys(monitoredEvents).length <
            Object.keys(DEFAULT_EVENTS).length
        ) {
            form.change('node.configuration.events', defaultEvents);
            return;
        }
    }, []);

    const handleRecordTypeChange = (e) => setRecordType(e.target.value);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} container spacing={2} alignItems="flex-end">
                <Grid item xs={5}>
                    <NativeSelect
                        label="Record Type"
                        input={{
                            name: 'recordTypeFilter',
                            value: recordType,
                            onChange: handleRecordTypeChange,
                        }}
                    >
                        <option value=""></option>
                        {Object.keys(VIOLATION_PER_TYPES).map((type) => (
                            <option value={type} key={type}>
                                {type}
                            </option>
                        ))}
                    </NativeSelect>
                </Grid>
                <Grid item xs={7}>
                    <TextInput
                        value={search}
                        name={search}
                        type="search"
                        label="Search"
                        size="small"
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </Grid>
            </Grid>
            {displayedViolations.length > MAXIMUM_DISPLAYED_VIOLATIONS && (
                <Grid item xs={12}>
                    <Alert severity="warning">
                        Given search criteria returned too many results. Only
                        the first {MAXIMUM_DISPLAYED_VIOLATIONS} violation codes
                        are displayed.
                    </Alert>
                </Grid>
            )}
            <Grid item xs={12}>
                <OnChange name="node.configuration.events">
                    {(value, prevValue) => {
                        if (!value || isEqual(value, prevValue)) {
                            return;
                        }

                        form.change(
                            'node.configuration.events',
                            pickBy(
                                value,
                                (event) =>
                                    !!event.threshold || event.threshold === 0,
                            ),
                        );
                    }}
                </OnChange>
                {displayedViolations.length === 0 ? (
                    <Typography variant="body2">
                        No violation matches your criteria.
                    </Typography>
                ) : (
                    <TableContainer
                        component={Paper}
                        elevation={0}
                        {...otherProps}
                    >
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Code</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Threshold</TableCell>
                                    <TableCell>Lookback Period</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {displayedViolations
                                    .slice(0, MAXIMUM_DISPLAYED_VIOLATIONS)
                                    .map((violation) => (
                                        <ViolationRow
                                            key={violation['Record Code']}
                                            violation={violation}
                                            disabled={disabled}
                                        />
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Grid>
        </Grid>
    );
};

ViolationTable.propTypes = {
    disabled: PropTypes.bool,
};

ViolationTable.defaultProps = {
    disabled: false,
};

export default ViolationTable;
