import slug from 'slug';

slug.defaults.mode = 'rfc3986';
slug.defaults.charmap['.'] = '-';

const slugify = (str: string): string =>
    slug(str)
        .replace(/-{2,}/g, '-') // Merge multiple `--` into one `-`
        .replace(/^-/, '') // Remove leading `-`
        .replace(/-$/, ''); // Remove trailing `-`

export default slugify;
