import { v4 as uuidV4 } from 'uuid';
import { Fragment, useState, forwardRef, useRef } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Handle, useStore as useReactFlowStore } from 'react-flow-renderer';
import { makeStyles, Tooltip } from '@material-ui/core';

const NOT_CONNECTING = 'not_connecting';
const CONNECTING_AND_VALID = 'connecting_and_valid';
const CONNECTING_AND_INVALID = 'connecting_and_invalid';

const INVISIBLE_MASK_DIAMETER = 16;
const PIN_DIAMETER = 6; // in px

const useStyles = makeStyles((theme) => ({
    handle: {
        backgroundColor: 'transparent',
        width: INVISIBLE_MASK_DIAMETER,
        height: INVISIBLE_MASK_DIAMETER,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        left: ({ position }) =>
            position === 'left' ? -(INVISIBLE_MASK_DIAMETER / 2) : null,
        right: ({ position }) =>
            position === 'right' ? -(INVISIBLE_MASK_DIAMETER / 2) : null,
        top: ({ position }) =>
            position === 'top' ? -(INVISIBLE_MASK_DIAMETER / 2) : null,
        border: 'none',
        pointerEvents: 'all',
        zIndex: 3,

        '&:hover $pin': {
            width: ({ state }) =>
                state === CONNECTING_AND_VALID
                    ? PIN_DIAMETER * 1.5
                    : PIN_DIAMETER,
            height: ({ state }) =>
                state === CONNECTING_AND_VALID
                    ? PIN_DIAMETER * 1.5
                    : PIN_DIAMETER,
            border: ({ state }) =>
                state === CONNECTING_AND_VALID
                    ? `1px solid ${theme.palette.mainGreen}`
                    : 'none',
        },
        '&.react-flow__handle.connectable': {
            cursor: ({ state }) =>
                state === CONNECTING_AND_INVALID ? 'not-allowed' : 'crosshair',
        },
    },
    pin: {
        position: 'absolute',
        width: PIN_DIAMETER,
        height: PIN_DIAMETER,
        borderRadius: '50%',
        backgroundColor: ({ color, state }) =>
            state === CONNECTING_AND_INVALID
                ? theme.palette.lighterGray
                : color,
        pointerEvents: 'none',
    },
    tooltipDummy: {
        position: 'absolute',
        height: INVISIBLE_MASK_DIAMETER,
        width: INVISIBLE_MASK_DIAMETER,
    },
}));

const shouldRenderPinHandles = () => {
    let reactFlowStore;
    try {
        reactFlowStore = useReactFlowStore((x) => x);
    } catch (err) {
        // We are outside of a <ReactFlow> component (for example in the Storybook)
        return false;
    }

    // Do not render pin handles if the block is not wrapped with a <ReactFlow> component
    // Useful for the storybook
    return !!reactFlowStore;
};

const Pin = forwardRef(function Pin(
    { selector, children, onMouseEnter, onMouseLeave, nodeId, ...props },
    ref,
) {
    if (shouldRenderPinHandles()) {
        return (
            <Handle
                {...props}
                data-testid={`pin-${selector || uuidV4()}`}
                ref={ref}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                {children}
            </Handle>
        );
    }

    const { type = 'source', position, id: handleId } = props;
    const isTarget = type === 'target';

    // Simulate pin handles when not inside the <ReactFlow> component
    // Useful for the storybook
    return (
        <div
            {...props}
            className={classnames([
                'react-flow__handle',
                `react-flow__handle-${position}`,
                'nodrag',
                {
                    source: !isTarget,
                    target: isTarget,
                },
                props.className,
            ])}
            data-nodeid={nodeId}
            data-handleid={handleId}
            data-handlepos={position}
            data-testid={`pin-${selector || uuidV4()}`}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            ref={ref}
        >
            {children}
        </div>
    );
});

Pin.displayName = 'Pin';
Pin.defaultProps = {
    selector: null,
};

const PinName = ({ name }) => {
    const parts = name.split('\n');

    return (
        <>
            {parts.map((part, i) => (
                <Fragment key={part}>
                    {i !== 0 && <br />}
                    {part}
                </Fragment>
            ))}
        </>
    );
};

PinName.propTypes = { name: PropTypes.string.isRequired };

const getConnectionState = (
    isValidConnection,
    connecting,
    nodeId,
    handleId,
) => {
    if (!connecting) {
        return NOT_CONNECTING;
    }

    if (connecting.nodeId === nodeId && connecting.handleId === handleId) {
        return NOT_CONNECTING;
    }

    const isValid = isValidConnection(
        connecting.handleType === 'source'
            ? {
                  source: connecting.nodeId,
                  sourceHandle: connecting.handleId,
                  target: nodeId,
                  targetHandle: handleId,
              }
            : {
                  source: nodeId,
                  sourceHandle: handleId,
                  target: connecting.nodeId,
                  targetHandle: connecting.handleId,
              },
    );

    if (isValid) {
        return CONNECTING_AND_VALID;
    }

    return CONNECTING_AND_INVALID;
};

const ConnectorPin = ({
    blockSelected,
    nodeId,
    name,
    color,
    connecting,
    isValidConnection,
    ...props
}) => {
    const classes = useStyles({
        blockSelected,
        color,
        state: getConnectionState(
            isValidConnection,
            connecting,
            nodeId,
            props.id,
        ),
        ...props,
    });
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const pinRef = useRef();

    const position =
        name && pinRef.current ? pinRef.current.getBoundingClientRect() : null;

    const onMouseEnter = () => setTooltipOpen(true);
    const onMouseLeave = () => setTooltipOpen(false);

    return (
        <>
            <Pin
                className={classes.handle}
                {...props}
                nodeId={nodeId}
                ref={pinRef}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                isValidConnection={isValidConnection}
            >
                <div className={classes.pin} />
            </Pin>
            {name &&
                position &&
                createPortal(
                    <Tooltip
                        title={<PinName name={name} />}
                        placement={props.position || { x: 0, y: 0 }}
                        open={tooltipOpen}
                    >
                        <div
                            className={classes.tooltipDummy}
                            style={{ top: position.top, left: position.left }}
                        />
                    </Tooltip>,
                    document.body,
                )}
        </>
    );
};

ConnectorPin.propTypes = {
    ...Pin.propTypes,
    blockSelected: PropTypes.bool,
    nodeId: PropTypes.string,
    name: PropTypes.string,
    color: PropTypes.string.isRequired,
    isValidConnection: PropTypes.func.isRequired,
};

ConnectorPin.defaultProps = {
    ...Pin.defaultProps,
    name: null,
    blockSelected: false,
};

export default ConnectorPin;
