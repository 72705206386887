import { List, Datagrid, TextField, ReferenceField } from 'react-admin';

export const ClaimFormList = (props) => (
    <List title="Forms" bulkActionButtons={false} perPage={25} {...props}>
        <Datagrid rowClick="edit">
            <TextField source="name" />
            <ReferenceField
                label="Insurance Product"
                source="insuranceProduct.id"
                reference="InsuranceProduct"
            >
                <TextField source="name" />
            </ReferenceField>
            <TextField source="createdAt" />
            <TextField source="updatedAt" />
        </Datagrid>
    </List>
);
