import {
    TextInput,
    Filter,
    List,
    Datagrid,
    TextField,
    ReferenceField,
    DateField,
} from 'react-admin';
import InsuranceProductInput from '../insuranceProducts/InsuranceProductInput';

export const QuestionnairesFilter = (props) => (
    <Filter {...props}>
        <InsuranceProductInput source="insuranceProductId" alwaysOn />
        <TextInput label="Name" source="name" alwaysOn />
    </Filter>
);

const QuestionnairesList = (props) => (
    <List
        title="Questionnaires"
        filters={<QuestionnairesFilter />}
        bulkActionButtons={false}
        perPage={25}
        {...props}
    >
        <Datagrid rowClick="edit">
            <TextField source="name" />
            <ReferenceField
                label="Insurance Product"
                source="insuranceProduct.id"
                reference="InsuranceProduct"
            >
                <TextField source="name" />
            </ReferenceField>
            <DateField showTime source="createdAt" />
            <DateField showTime source="lastUpdatedAt" />
        </Datagrid>
    </List>
);

export default QuestionnairesList;
