import { Edit } from 'react-admin';
import { Grid } from '@material-ui/core';
import ExportPostmanCollectionButton from './ExportPostmanCollectionButton';
import PropTypes from 'prop-types';
import { InsuranceProductForm } from './Form';
import { formatDate } from '@tint/core/src/formatters';

const InsuranceProductTitle = ({ record }) => {
    return (
        <span>
            Edit Insurance Product{' '}
            {record
                ? `"${record.name}" created on ${formatDate(record.createdAt)}`
                : ''}
        </span>
    );
};

InsuranceProductTitle.propTypes = {
    record: PropTypes.object.isRequired,
};

export const InsuranceProductsEdit = (props) => (
    <>
        <Grid container justifyContent="flex-end">
            <Grid item>
                <ExportPostmanCollectionButton insuranceProductId={props.id} />
            </Grid>
        </Grid>
        <Edit title={<InsuranceProductTitle />} {...props}>
            <InsuranceProductForm />
        </Edit>
    </>
);

InsuranceProductsEdit.propTypes = {
    id: PropTypes.string.isRequired,
};
