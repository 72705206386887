import validator from './validate';

export type EquifaxDigitalIdentityTrustNode = {
    processor: 'equifax-digital-identity-trust';
    configuration?: {
        timeout?: number;
        fallbackValue?: string;
    };
};

const equifaxDigitalIdentityTrustProcessor = {
    name: 'Equifax Digital Identity Trust',
    slug: 'equifax-digital-identity-trust',
    color: '#981e32',
    description:
        "Verify an identity with confidence through real-time, risk assessments of the consumer's digital identity",
    validate: validator,
    inputs: [
        {
            id: 'firstName',
            name: 'First Name',
            schema: { type: 'string' },
            required: false,
            group: 'inputs',
        },
        {
            id: 'middleName',
            name: 'Middle Name',
            schema: { type: 'string' },
            required: false,
            group: 'inputs',
        },
        {
            id: 'lastName',
            name: 'Last Name',
            schema: { type: 'string' },
            group: 'inputs',
        },
        {
            id: 'addressLine1',
            name: 'Address (Street Line 1)',
            schema: { type: 'string' },
            required: false,
            group: 'inputs',
        },
        {
            id: 'addressLine2',
            name: 'Address (Street Line 2)',
            schema: { type: 'string' },
            required: false,
            group: 'inputs',
        },
        {
            id: 'city',
            name: 'City',
            schema: { type: 'string' },
            group: 'inputs',
        },
        {
            id: 'state',
            name: 'State',
            schema: { type: 'string' },
            group: 'inputs',
        },
        {
            id: 'zipCode',
            name: 'Zip Code',
            schema: { type: 'string' },
            group: 'inputs',
        },
        {
            id: 'country',
            name: 'Country',
            schema: { type: 'string' },
            group: 'inputs',
        },
        {
            id: 'dateOfBirth',
            name: 'Date of Birth',
            schema: { type: 'string', format: 'date' },
            required: false,
            group: 'inputs',
        },
        {
            id: 'emailAddress',
            name: 'Email Address',
            schema: { type: 'string' },
            group: 'inputs',
        },
        {
            id: 'phoneNumber',
            name: 'Phone Number',
            schema: { type: 'string' },
            group: 'inputs',
        },
        {
            id: 'ipAddress',
            name: 'IP Address',
            schema: { type: 'string' },
            required: false,
            group: 'inputs',
        },
    ],
    outputs: [
        {
            id: 'decision',
            name: 'Decision',
            schema: { type: 'string' },
        },
    ],
};

export default equifaxDigitalIdentityTrustProcessor;
