export const AUTOLAYOUT = 'AUTOLAYOUT';
export const CREATE_NODE = 'CREATE_NODE';
export const UPDATE_NODE = 'UPDATE_NODE';
export const MOVE_NODE = 'MOVE_NODE';
export const MOVE_NODES = 'MOVE_NODES';
export const REMOVE_NODE = 'REMOVE_NODE';
export const CONNECT_NODES = 'CONNECT_NODES';
export const DISCONNECT_NODES = 'DISCONNECT_NODES';
export const REORDER_EDGES = 'REORDER_EDGES';

export const createNode = (nodeId, node, position, isOutputBlock = false) => ({
    action: CREATE_NODE,
    payload: { nodeId, node, position, isOutputBlock },
    createdAt: new Date(),
});

export const updateNode = (nodeId, node, editor) => ({
    action: UPDATE_NODE,
    payload: { nodeId, node, editor },
    createdAt: new Date(),
});

export const moveNode = (nodeId, position, viewMode) => ({
    action: MOVE_NODE,
    payload: { nodeId, position, viewMode },
    createdAt: new Date(),
});

export const moveNodes = (nodes, viewMode) => ({
    action: MOVE_NODES,
    payload: { nodes, viewMode },
    createdAt: new Date(),
});

export const removeNode = (nodeId) => ({
    action: REMOVE_NODE,
    payload: { nodeId },
    createdAt: new Date(),
});

export const connectNodes = ({
    source,
    sourceHandle,
    target,
    targetHandle,
}) => ({
    action: CONNECT_NODES,
    payload: { source, sourceHandle, target, targetHandle },
    createdAt: new Date(),
});

export const autoLayout = ({ viewMode }) => ({
    action: AUTOLAYOUT,
    payload: { viewMode },
    createdAt: new Date(),
});

export const disconnectNodes = ({ source, sourceHandle, target }) => ({
    action: DISCONNECT_NODES,
    payload: { source, sourceHandle, target },
    createdAt: new Date(),
});

export const reorderEdges = ({ source, destination, target }) => ({
    action: REORDER_EDGES,
    payload: { source, destination, target },
    createdAt: new Date(),
});
