enum OutputTypes {
    ANY = 'ANY', // Accept any type below
    DATE = 'DATE',
    DATETIME = 'DATETIME',
    NUMBER = 'NUMBER',
    STRING = 'STRING',
    BOOLEAN = 'BOOLEAN',
}

export default OutputTypes;
