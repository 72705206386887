import { useState } from 'react';
import PropTypes from 'prop-types';

import { useInput, Labeled, InputHelperText } from 'react-admin';
import FormHelperText from '@material-ui/core/FormHelperText';

import { makeStyles, MuiThemeProvider } from '@material-ui/core';
import ModelEditor from '../v4/models/ModelEditor';

import {
    blueprintPropType,
    editorDataPropType,
} from '../v4/models/blueprintEditor/blueprintEditorPropTypes';
import { SnackbarProvider } from '../v4';
import tintTheme from '../v4/theme';

const useStyles = makeStyles(() => ({
    editorWrapper: {
        // 100vh - top bar height - page content margin / 2 - title block height
        height: 'calc(100vh - 78px - 16px - 100px)',
    },
}));

export const ModelEditorInput = ({
    record,
    insuranceProduct,
    height,
    disabled,
    helperText,
    ...props
}) => {
    const classes = useStyles({ height });

    const {
        source: { blueprint, editorData },
    } = props;

    const {
        input: { onChange: blueprintOnChange },
        meta: { error: blueprintError },
    } = useInput({
        ...props,
        source: blueprint,
    });
    const {
        input: { onChange: editorDataOnChange },
        meta: { error: editorDataError },
    } = useInput({
        ...props,
        source: editorData,
    });

    const [touched, setTouched] = useState(false);

    const onSave = (value) => {
        setTouched(true);
        blueprintOnChange(value.blueprint);
        editorDataOnChange(value.editorData);
    };

    return (
        <MuiThemeProvider theme={tintTheme}>
            <SnackbarProvider>
                <Labeled label={props.label} fullWidth>
                    <ModelEditor
                        model={record}
                        readOnly={!!disabled}
                        saving={false}
                        insuranceProduct={insuranceProduct}
                        editorClassName={classes.editorWrapper}
                        showRestrictedProcessors
                        onSave={onSave}
                    />
                </Labeled>
                {helperText && <FormHelperText>{helperText}</FormHelperText>}
                {touched && (blueprintError || editorDataError) && (
                    <>
                        {blueprintError.split('\n').map((chunk) => (
                            <FormHelperText key={chunk} error>
                                <InputHelperText error={chunk} touched />
                            </FormHelperText>
                        ))}
                        {editorDataError.split('\n').map((chunk) => (
                            <FormHelperText key={chunk} error>
                                <InputHelperText error={chunk} touched />
                            </FormHelperText>
                        ))}
                    </>
                )}
            </SnackbarProvider>
        </MuiThemeProvider>
    );
};

ModelEditorInput.propTypes = {
    label: PropTypes.string.isRequired,
    record: PropTypes.object.isRequired,
    source: PropTypes.shape({
        blueprint: blueprintPropType,
        editorData: editorDataPropType,
    }).isRequired,
    insuranceProduct: PropTypes.object.isRequired,
    height: PropTypes.number,
    disabled: PropTypes.bool,
    helperText: PropTypes.string,
};

ModelEditorInput.defaultProps = {
    height: 300,
    disabled: false,
    helperText: null,
};
