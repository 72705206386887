import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFormState } from 'react-final-form';
import getValidationSchemaAttributes from '@tint/core/src/insuranceProducts/getValidationSchemaAttributes';

import { humanizePath } from '../../forms/PiiAttributeSelector';
import Select from '../../forms/primitives/Select';

const usePathChoices = (filter) => {
    const { values } = useFormState();
    const { validationSchema } = values;
    const attributes = useMemo(() => {
        return getValidationSchemaAttributes(
            validationSchema,
            null,
            filter,
        ).map((attribute) => ({
            value: attribute,
            label: humanizePath(attribute),
        }));
    }, []);

    return attributes;
};

export const PathSelect = ({ id, label, value, onChange, pathFilter }) => {
    const choices = usePathChoices(pathFilter);

    return (
        <Select
            id={id}
            label={label}
            fullWidth
            options={choices}
            input={{ value, onChange }}
            meta={{}}
            variant="standard"
        />
    );
};

PathSelect.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    pathFilter: PropTypes.shape({
        type: PropTypes.string,
        format: PropTypes.string,
    }),
};
