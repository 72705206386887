import PropTypes from 'prop-types';

export const insuranceProductProptype = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
});

export const modelProptype = PropTypes.shape({
    id: PropTypes.string.isRequired,
});

export const coverageProptype = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    limitModel: modelProptype,
    deductibleModel: modelProptype,
    securityDepositModel: modelProptype,
    premiumModel: modelProptype,
});

export const insurancePlanProptype = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    coverages: PropTypes.arrayOf(coverageProptype).isRequired,
});
