import violationsPerTypes from './violationsPerTypes';

const DRIVING_UNDER_INFLUENCE_CODES = violationsPerTypes[
    'Driving Under the Influence'
].map((violation) => violation['Record Code']);

const getDefaultLookoutPeriodInYearForActivity = (code: string): number => {
    if (DRIVING_UNDER_INFLUENCE_CODES.includes(code)) {
        return 7;
    }

    return 3;
};

export default getDefaultLookoutPeriodInYearForActivity;
