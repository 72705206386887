import { createValidator } from '../../validation/validatorFactory';

const validator = createValidator();

const validate = validator.compile({
    oneOf: [
        { type: 'null' },
        {
            type: 'object',
            properties: {
                digits: { type: 'integer', minimum: 0 },
                method: {
                    enum: ['closest', 'ceil', 'floor'],
                    type: 'string',
                },
            },
            additionalProperties: false,
        },
    ],
});

export default validate;
