import {
    Filter,
    List,
    Datagrid,
    DateField,
    TextField,
    TextInput,
    ReferenceField,
    SelectInput,
    BooleanInput,
    BooleanField,
} from 'react-admin';
import UuidField from '../inputs/UuidInput';
import InsuranceProductInput from '../insuranceProducts/InsuranceProductInput';
import UsageField from './UsageField';

export const ModelsFilter = (props) => (
    <Filter {...props}>
        <UuidField source="id" alwaysOn />
        <TextInput source="name" />
        <SelectInput
            source="archived"
            choices={[
                { id: 'null', name: 'All' },
                { id: false, name: 'Non-archived only' },
                { id: true, name: 'Archived only' },
            ]}
        />
        <BooleanInput label="Live" source="live" defaultValue={true} />
        <InsuranceProductInput source="insuranceProductId" alwaysOn />
    </Filter>
);

export const ModelsList = (props) => (
    <List
        title="Models"
        filters={<ModelsFilter />}
        filterDefaultValues={{ archived: false }}
        bulkActionButtons={false}
        sort={{ field: 'createdAt', order: 'DESC' }}
        perPage={25}
        {...props}
    >
        <Datagrid rowClick="edit">
            <UsageField sortable={false} />
            <TextField source="name" />
            <ReferenceField
                label="Insurance Product"
                source="insuranceProduct.id"
                reference="InsuranceProduct"
            >
                <TextField source="name" />
            </ReferenceField>
            <BooleanField source="isValid" />
            <TextField source="outputType" />
            <DateField showTime source="createdAt" />
            <DateField showTime source="lastUpdatedAt" />
        </Datagrid>
    </List>
);
