import PropTypes from 'prop-types';
import TextInput from '../../../../../forms/TextInput';
import { Grid, Typography } from '@material-ui/core';
import { Checkbox } from '../../../../../forms';
import { useEffect, useState } from 'react';

const CatchAllInput = ({ label, input, disabled }) => {
    const [value, setValue] = useState(input.value);

    useEffect(() => {
        input.onChange({ target: { value } });
    }, [value]);

    const handleChange = (e) => {
        setValue(e.target.value);
    };

    return (
        <Grid container spacing={2} alignItems="center">
            <Grid item>
                <TextInput
                    name="catchAll"
                    label={label}
                    margin="dense"
                    input={{
                        value,
                        onChange: handleChange,
                    }}
                    disabled={disabled || value === null}
                />
            </Grid>
            <Grid item>
                <Typography variant="body1">or</Typography>
            </Grid>
            <Grid item>
                <Checkbox
                    name="catchAll"
                    checked={value === null}
                    label="Return a null value"
                    disabled={disabled}
                    onChange={() =>
                        value === null ? setValue('') : setValue(null)
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body2">
                    Returned when the value doesn't match any of the above
                    conditions, or when it is null.
                </Typography>
            </Grid>
        </Grid>
    );
};

CatchAllInput.propTypes = {
    label: PropTypes.string.isRequired,
    input: PropTypes.shape({
        value: PropTypes.any,
        onChange: PropTypes.func.isRequired,
    }).isRequired,
    disabled: PropTypes.bool,
};

CatchAllInput.defaultProps = {
    label: 'Default Label (optional)',
    disabled: false,
};

export default CatchAllInput;
