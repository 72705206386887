const typeWithDefinedOptions = [
    'status',
    'customId',
    'template',
    'currency',
    'number',
    'date',
    'boolean',
    'period',
    'date-range',
    'score',
];

const convertOptions = (
    { paths, path, startDatePath, endDatePath, ...options },
    type,
) => {
    // for type string and all other default type, we handle both path and paths options
    // forcing the paths syntax here by converting path to paths if needed
    if (!typeWithDefinedOptions.includes(type)) {
        return {
            ...options,
            ...(path ? { paths: [path.replace(/^attributes\./, '')] } : {}),
            ...(paths
                ? {
                      paths: paths.map((path) =>
                          path.replace(/^attributes\./, ''),
                      ),
                  }
                : {}),
        };
    }
    return {
        ...options,
        ...(path ? { path: path.replace(/^attributes\./, '') } : {}),
        ...(paths
            ? { paths: paths.map((path) => path.replace(/^attributes\./, '')) }
            : {}),
        ...(startDatePath
            ? { startDatePath: startDatePath.replace(/^attributes\./, '') }
            : {}),
        ...(endDatePath
            ? { endDatePath: endDatePath.replace(/^attributes\./, '') }
            : {}),
    };
};

export const schemaToState = (schema) => {
    return {
        list: schema.map((_, index) => `${index}`),
        byId: schema.reduce(
            (acc, { type, label, ...options }, index) => ({
                ...acc,
                [index]: {
                    id: `${index}`,
                    type,
                    label,
                    options: convertOptions(options, type),
                },
            }),
            {},
        ),
        selectedId: null,
    };
};
