import PropTypes from 'prop-types';

import { formatPercentage } from '@tint/core/src/formatters';
import TextField from './TextField';

const PercentageField = ({ value, ...otherProps }) => {
    const formattedValue = value === null ? 'N/A' : formatPercentage(value);
    return <TextField value={formattedValue} {...otherProps} />;
};

PercentageField.propTypes = {
    value: PropTypes.number,
};

PercentageField.defaultProps = {
    value: null,
};

export default PercentageField;
