import { createValidator } from '../../../validation/validatorFactory';

const validator = createValidator();

const validate = validator.compile({
    type: 'object',
    required: ['modelNumber', 'modelField', 'vendorIdentificationCode'],
    properties: {
        timeout: {
            type: 'number',
            minimum: 0,
            maximum: 29000,
        },
        modelNumber: {
            type: 'string',
        },
        modelField: {
            type: 'string',
        },
        fallbackValue: {
            type: ['number', 'null'],
        },
        vendorIdentificationCode: {
            type: 'string',
        },
    },
    additionalProperties: false,
});

export default validate;
