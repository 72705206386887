import { createValidator } from '../../validation/validatorFactory';

const validator = createValidator();

const validate = validator.compile({
    oneOf: [
        { type: 'null' },
        {
            type: 'object',
            properties: {
                unit: {
                    enum: ['seconds', 'minutes', 'hours', 'days', 'year'],
                    type: 'string',
                },
            },
            additionalProperties: false,
        },
    ],
});

export default validate;
