import humanizeDuration from 'humanize-duration';
import parseISO from 'date-fns/parseISO';
import intervalToDuration from 'date-fns/intervalToDuration';
import differenceInDays from 'date-fns/differenceInDays';
import format from 'date-fns/format';

import { DeductibleType } from './coverages/types';

export type RenewalScheduleLabels = ' / month' | ' / year' | ' / ' | '';

const numberFormatter = new Intl.NumberFormat(undefined, {});

export const formatHrDuration = (duration: number): string => {
    if (duration < 1000) {
        return `${numberFormatter.format(duration)}ms`;
    }

    return `${numberFormatter.format(duration / 1000)}s`;
};

const dateTimeFormatter = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
});

const parseDate = (date: string | Date): Date =>
    typeof date === 'string' ? parseISO(date) : date;

export const formatDateTime = (datetime: string | Date): string =>
    dateTimeFormatter.format(parseDate(datetime));

export const formatTimestampDuration = humanizeDuration;

export const formatDuration = (
    from: Date | string,
    to: Date | string,
): string => {
    if (!from || !to) {
        return 'N/A';
    }
    const fromDateTime = typeof from === 'string' ? parseISO(from) : from;
    const toDateTime = typeof to === 'string' ? parseISO(to) : to;
    const duration = intervalToDuration({
        start: fromDateTime,
        end: toDateTime,
    });
    const daysDiff = differenceInDays(toDateTime, fromDateTime);
    const days = (duration.hours || 0) < 12 ? daysDiff : daysDiff + 1;
    return `${days} day${days === 1 ? '' : 's'}`;
};

export const formatCurrency = (
    number: number | null | undefined,
    numberDecimals = 0,
    currency = 'USD',
): string =>
    new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
        minimumFractionDigits: numberDecimals,
    }).format(number || 0);

export const convertDecimalNumberToCurrency = (number: number): number => {
    return Math.floor(number * 100) / 100;
};

export const formatNumber = (
    number: number,
    options?: Intl.NumberFormatOptions,
): string => new Intl.NumberFormat('en-US', options).format(number);

export const legacyFormatNumber = (
    number: number,
    options?: Intl.NumberFormatOptions,
): string | null => {
    if (number === null || number === undefined) {
        return null;
    }

    return new Intl.NumberFormat('en-US', options).format(number);
};

const dateFormatter = new Intl.DateTimeFormat('en-US');
export const formatDate = (date: string | Date): string =>
    dateFormatter.format(parseDate(date));
export const formatMonth = (date: Date): string => format(date, 'MMMM yyyy');

export const formatUTCDate = (
    date: Date,
    formatter: Intl.DateTimeFormat,
): string =>
    formatter.format(
        new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()),
    );
export const formatUTCMonth = (date: Date): string =>
    format(new Date(date.getUTCFullYear(), date.getUTCMonth()), 'MMMM');
export const formatUTCMonthYear = (date: Date): string =>
    format(new Date(date.getUTCFullYear(), date.getUTCMonth()), 'MMMM yyyy');

const timeFormatter = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
});

export const formatTimeFromDate = (datetime: Date | string): string =>
    timeFormatter.format(parseDate(datetime));

export const formatPercentage = (
    percentage: number | null = null,
    options?: Intl.NumberFormatOptions,
): string | null =>
    percentage === null
        ? null
        : new Intl.NumberFormat('en-US', {
              style: 'percent',
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
              ...options,
          }).format(percentage);

export const formatDeductible = (
    deductible: number,
    type: DeductibleType,
): string => {
    if (type === DeductibleType['TOTAL_LOSS_PERCENTAGE']) {
        return `${formatPercentage(deductible / 100)} of total loss`;
    }
    return formatCurrency(deductible);
};

export const formatTitle = (string: string): string =>
    string
        .split(' ')
        .map((word) => {
            if (word.length < 3) {
                return word.toLowerCase();
            }

            return word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase();
        })
        .join(' ');

export const formatBooleanAsYesNo = (
    value: boolean | null | undefined,
): string => {
    let preparedValue = 'N/A';

    if (value === true) {
        preparedValue = 'Yes';
    } else if (value === false) {
        preparedValue = 'No';
    }

    return preparedValue;
};

const renewalScheduleLabels = {
    NONE: '',
    MONTHLY: 'month',
    YEARLY: 'year',
} as const;

export const getRenewalScheduleLabel = (
    renewalSchedule: keyof typeof renewalScheduleLabels,
): RenewalScheduleLabels => {
    return renewalScheduleLabels[renewalSchedule]
        ? ` / ${renewalScheduleLabels[renewalSchedule]}`
        : '';
};

export const formatSize = (bytes: number, fractionDigits = 1): string => {
    // We return early, because there cannot be partial bytes (10.2 B makes no sense, and neither does 10.0 B)
    if (Math.abs(bytes) < 1000) {
        return bytes + ' B';
    }

    const units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    let unit = 0;
    for (
        ;
        Math.round(Math.abs(bytes) * 10) / 10 >= 1000 && unit < units.length;
        unit++
    ) {
        bytes /= 1000;
    }

    return bytes.toFixed(fractionDigits) + ' ' + units[unit];
};
