/* eslint-disable react/prop-types */
import 'codemirror';

import { useState } from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';

import { useInput } from 'react-admin';

import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/javascript/javascript';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    codeMirror: {
        '& .CodeMirror': {
            height: ({ height = 300 }) => `${height}px !important`, // remove CodeMirror 300px default CSS
        },
    },
}));

export const JsonModeInput = ({ height = 300, disabled = false, ...props }) => {
    const classes = useStyles({ height });

    const {
        input: { name, onChange, value },
    } = useInput({
        ...props,
        format: (value) => JSON.stringify(value, null, 4),
        parse: (value) => {
            try {
                return JSON.parse(value);
            } catch (err) {
                return value;
            }
        },
    });

    const [rawValue, setRawValue] = useState(value);

    const handleChange = (editor, data, value) => {
        setRawValue(value);
        onChange(value);
    };

    const handleBeforeChange = (editor, data, value) => {
        setRawValue(value);
    };

    return (
        <div data-value={rawValue}>
            <CodeMirror
                className={classes.codeMirror}
                name={name}
                value={rawValue}
                label={props.label}
                onChange={handleChange}
                options={{
                    mode: {
                        name: 'javascript',
                        json: true,
                    },
                    theme: 'material',
                    lineNumbers: true,
                    readOnly: !!disabled,
                }}
                onBeforeChange={handleBeforeChange}
            />
        </div>
    );
};
