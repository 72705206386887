import PropTypes from 'prop-types';

import { Typography } from '@material-ui/core';

const FieldLabel = ({ children, ...otherProps }) => {
    return (
        <Typography variant="body2" {...otherProps}>
            {children}
        </Typography>
    );
};

FieldLabel.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
};

FieldLabel.defaultProps = {
    className: null,
};

export default FieldLabel;
