import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';

import { ExternalDataCredentialInput } from '../../../../../forms';
import { insuranceProductProptype } from '../../../../../../../../tint-app/src/insuranceProduct/versions/propTypes';

const CredentialsTab = ({ insuranceProduct, disabled }) => (
    <Grid container direction="column" spacing={2}>
        <Grid item>
            <Typography variant="body2">
                The following credentials will be encrypted before being saved,
                and nobody in your team will be able to read them.
            </Typography>
        </Grid>
        <Grid item>
            <ExternalDataCredentialInput
                insuranceProduct={insuranceProduct}
                providerSlug="samba-safety"
                name="accountId"
                label="Account ID"
                variant="outlined"
                fullWidth
                required
                type="password"
                disabled={disabled}
            />
        </Grid>
        <Grid item>
            <ExternalDataCredentialInput
                insuranceProduct={insuranceProduct}
                providerSlug="samba-safety"
                name="userId"
                label="User ID"
                variant="outlined"
                fullWidth
                required
                type="password"
                disabled={disabled}
            />
        </Grid>
        <Grid item>
            <ExternalDataCredentialInput
                insuranceProduct={insuranceProduct}
                providerSlug="samba-safety"
                name="password"
                label="Password"
                variant="outlined"
                fullWidth
                required
                type="password"
                disabled={disabled}
            />
        </Grid>
    </Grid>
);

CredentialsTab.propTypes = {
    disabled: PropTypes.bool,
    insuranceProduct: insuranceProductProptype.isRequired,
};

CredentialsTab.defaultProps = {
    disabled: false,
};

export default CredentialsTab;
