// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// Ignore because v4
import type { FunctionComponent, ReactNode } from 'react';
import { forwardRef, useEffect, useState } from 'react';

import { v4 as uuidV4 } from 'uuid';
import type { FieldInputProps, FieldRenderProps } from 'react-final-form';

import TextField from '@material-ui/core/TextField/TextField';
import type { InputProps } from '@material-ui/core/Input';
import type { InputLabelProps } from '@material-ui/core/InputLabel';
import ReadOnlyInput from './ReadOnlyInput';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';
import type { Theme } from '@material-ui/core';
import '../theme';

// There is currently an issue with MUI in regards of Material specs: the
// label is not properly positionned in a filled input with a start adornment.
// @see https://github.com/mui-org/material-ui/issues/25000
const useStyles = makeStyles((theme: Theme) => ({
    inputBase: {
        background: ({
            variant,
            shrink,
        }: {
            variant: 'standard' | 'outlined' | 'filled';
            shrink: boolean;
        }) => {
            if (variant !== 'filled') {
                return null;
            }

            return shrink ? theme.palette.grays[200] : 'transparent';
        },
    },
    startAdornmentLabelFixClass: {
        paddingLeft: ({ variant }) => (variant === 'filled' ? 40 : 32),
    },
    shrunkLabel: {
        paddingLeft: 52,
    },
}));

type TextInputProps = FieldRenderProps<string, HTMLInputElement> & {
    id: string;
    label: ReactNode;
    InputProps: InputProps;
    InputLabelProps: InputLabelProps;
    input: Partial<InputProps['inputProps']>;
    readOnly: boolean;
    meta: {
        touched: boolean;
        error: string;
    };
    helperText: ReactNode;
    variant: 'standard' | 'outlined' | 'filled';
    shrink: boolean;
    value: string;
    classes?: Record<string, string>;
};

const TextInput: FunctionComponent<TextInputProps> = forwardRef(
    function TextInput(
        {
            id,
            label,
            InputProps = {},
            InputLabelProps = {},
            input = {
                onChange: undefined,
                onBlur: undefined,
                onFocus: undefined,
                value: undefined,
                name: undefined,
            },
            onBlur = () => undefined,
            onFocus = () => undefined,
            meta: { touched = false, error = null } = {
                touched: false,
                error: null,
            },
            helperText,
            readOnly = false,
            variant = 'outlined',
            shrink: initialShrink,
            ...otherProps
        },
        ref,
    ) {
        const isShrinkControlled =
            typeof initialShrink !== 'undefined' || InputProps.startAdornment;
        const [shrink, setShrink] = useState(
            isShrinkControlled ? initialShrink : null,
        );
        const [isFocused, setFocused] = useState(false);

        useEffect(() => {
            if (!isShrinkControlled) {
                return;
            }

            setShrink(!!input.value || isFocused);
        }, [isShrinkControlled, isFocused, input]);

        const classes = useStyles({ variant, shrink });
        const inputId = id || uuidV4();

        const handleFocus = (e) => {
            setFocused(true);
            onFocus(e);
        };

        const handleBlur = (e) => {
            setFocused(false);
            onBlur(e);
        };

        const finalInput: Partial<FieldInputProps<string, HTMLInputElement>> =
            input || {
                onChange: otherProps.onChange,
                value: otherProps.value,
            };

        if (readOnly) {
            return <ReadOnlyInput label={label} value={input.value} />;
        }

        return (
            <TextField
                inputRef={ref}
                label={label}
                error={touched && !!error}
                helperText={touched && error ? error : helperText}
                InputProps={{
                    ...InputProps,
                    id: inputId,
                    onFocus: handleFocus,
                    onBlur: handleBlur,
                    classes: {
                        root: classes.inputBase,
                        input: otherProps.classes?.input,
                    },
                }}
                InputLabelProps={{
                    htmlFor: inputId,
                    ...InputLabelProps,
                    className: classNames({
                        [classes.startAdornmentLabelFixClass]:
                            !shrink && !!InputProps.startAdornment,
                        [classes.shrunkLabel]:
                            shrink &&
                            variant === 'filled' &&
                            !!InputProps.startAdornment,
                    }),
                    shrink: isShrinkControlled ? shrink : undefined,
                }}
                {...finalInput}
                value={input.value === null ? '' : input.value}
                variant={variant}
                {...otherProps}
            />
        );
    },
);

export default TextInput;
