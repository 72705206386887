import PropTypes from 'prop-types';

import { Grid, IconButton, makeStyles, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import ProcessorSelect from '../ProcessorSelect/ProcessorSelect';

const useStyles = makeStyles((theme) => ({
    header: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(3),
    },
    name: {
        flex: '1 0 0',
    },
    close: {
        flex: '0 0 auto',
    },
    body: {
        paddingTop: theme.spacing(3),
    },
}));

const ProcessorMenu = ({ showRestrictedProcessors, onSubmit, onClose }) => {
    const classes = useStyles();

    const onChange = (processor) => {
        if (!processor) {
            return null;
        }

        const { name, slug } = processor;

        return onSubmit({
            name,
            processor: slug,
            configuration: null,
        });
    };

    return (
        <div>
            <div className={classes.header}>
                <div className={classes.name}>
                    <Typography variant="h1" component="h2">
                        Choose a Function
                    </Typography>
                </div>
                <div className={classes.close}>
                    <IconButton aria-label="Close drawer" onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </div>
            </div>
            <div className={classes.body}>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <ProcessorSelect
                            name="processor"
                            label="Function"
                            required
                            onChange={onChange}
                            showRestrictedProcessors={showRestrictedProcessors}
                        />
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

ProcessorMenu.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    showRestrictedProcessors: PropTypes.bool,
};

ProcessorMenu.defaultProps = {
    showRestrictedProcessors: false,
};

export default ProcessorMenu;
