import validator from './validate';

const dataRobotProcessor = {
    name: 'DataRobot',
    slug: 'data-robot',
    color: '#2D8FE2',
    description:
        'Retrieve the pre-trained AI model predictions for given inputs',
    validate: validator,
    inputs: { id: 'inputs', dynamic: true, min: 0 },
    outputs: [
        {
            id: 'output',
            name: 'Output',
            schema: { type: 'number' },
        },
    ],
};

export default dataRobotProcessor;
