// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// Ignore because v4
import type { FunctionComponent } from 'react';
import classnames from 'classnames';

import type { TypographyProps } from '@material-ui/core/Typography';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import FieldLabel from './FieldLabel';

const useStyles = makeStyles(() => ({
    value: {
        margin: 0,
    },
    capitalized: {
        '&:first-letter': {
            textTransform: 'capitalize',
        },
    },
    ellipsable: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    // There is currently an issue with MUI in regards of Material specs: the
    // label is not properly positionned in a filled input with a start adornment.
    // @see https://github.com/mui-org/material-ui/issues/25000
    startAdornmentLabelFixClass: {},
}));

type TextFieldProps = {
    label: string;
    value: string;
    capitalize?: boolean;
    classNames?: { root?: string; value?: string };
    ellipsable?: boolean;
    variant?: TypographyProps['variant'];
};

const TextField: FunctionComponent<TextFieldProps> = ({
    capitalize = true,
    classNames = {},
    ellipsable = false,
    label = null,
    value = false,
    variant = 'h2',
    ...otherProps
}) => {
    const classes = useStyles();

    return (
        <div className={classNames.root} {...otherProps}>
            {label && <FieldLabel>{label}</FieldLabel>}
            <Typography
                aria-label={label}
                variant={variant}
                component="p"
                className={classnames(classes.value, classNames.value, {
                    [classes.capitalized]: capitalize,
                    [classes.ellipsable]: ellipsable,
                })}
            >
                {value === null || value === undefined ? 'N/A' : value}
            </Typography>
        </div>
    );
};

export default TextField;
