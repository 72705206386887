import { cloneDeep, isEqual, defaultsDeep, get, set, sortBy } from 'lodash';

import processors from '@tint/core/src/processors';

import { getEdgesForNode } from '@tint/core/src/processors/io';

import {
    CREATE_NODE,
    UPDATE_NODE,
    MOVE_NODE,
    MOVE_NODES,
    REMOVE_NODE,
    CONNECT_NODES,
    AUTOLAYOUT,
    DISCONNECT_NODES,
    REORDER_EDGES,
} from './actions';

import { findAvailableNodeId } from '../NewBlock/helpers';
import { VIEW_MODE_ADVANCED } from '../viewModes/viewModes';
import getSimplifiedBlueprint from '../viewModes/getSimplifiedBlueprint';
import { computeNodePositions } from '../positioning';

const findFirstAvailablePin = (edges, nodeId, i = 0) => {
    const pinId = i.toString();

    if (
        !edges.find(
            (edge) => edge.end.node === nodeId && edge.end.pin === pinId,
        )
    ) {
        return pinId;
    }

    return findFirstAvailablePin(edges, nodeId, i + 1);
};

const handleNodeRemoval = ({ blueprint, editorData, nodeId }) => {
    delete blueprint.nodes[nodeId];

    const outputEdges = getEdgesForNode({
        nodeId,
        blueprint,
        incoming: false,
        conditions: false,
    });
    const nextNodeIds = outputEdges.map((e) => e.end.node);

    nextNodeIds.forEach((nextNodeId) => {
        const followingNode = get(blueprint, `nodes.["${nextNodeId}"]`);
        const inputEdges = getEdgesForNode({
            nodeId: nextNodeId,
            blueprint,
            incoming: true,
            outgoing: false,
            conditions: false,
        });

        // update configuration.mapping
        if (
            followingNode &&
            followingNode.configuration &&
            followingNode.configuration.mapping
        ) {
            const edgeToDeleteIndex = inputEdges.findIndex(
                (e) => e.start.node === nodeId,
            );
            if (edgeToDeleteIndex !== -1) {
                followingNode.configuration.mapping.splice(
                    edgeToDeleteIndex,
                    1,
                );
            }
        }

        // recompute pin order
        const sortedEdges = sortBy(inputEdges, (e) => +e.end.pin).filter(
            (e) => e.start.node !== nodeId,
        );
        sortedEdges.forEach((edge, index) => {
            const matchingEdgeIndex = blueprint.edges.findIndex((e) =>
                isEqual(e, edge),
            );

            if (matchingEdgeIndex !== -1) {
                set(
                    blueprint,
                    `edges[${matchingEdgeIndex}].end.pin`,
                    index.toString(),
                );
            }
        });
    });

    // remove edge
    blueprint.edges = blueprint.edges.filter(
        (edge) => edge.start.node !== nodeId && edge.end.node !== nodeId,
    );

    delete editorData.nodes[nodeId];
    if (editorData.simplifiedNodes[nodeId]) {
        delete editorData.simplifiedNodes[nodeId];
    }
};

const changeNodePosition = (editorData, nodeId, position, viewMode) => {
    if (!editorData.nodes[nodeId]) {
        editorData.nodes[nodeId] = {};
    }

    if (!editorData.simplifiedNodes[nodeId]) {
        editorData.simplifiedNodes[nodeId] = {};
    }

    editorData[viewMode === 'simplified' ? 'simplifiedNodes' : 'nodes'][
        nodeId
    ].position = position;
};

const mutateStateForEvent = (
    blueprint,
    editorData,
    { action, payload, createdAt },
) => {
    switch (action) {
        case CREATE_NODE: {
            const { nodeId, node, position } = payload;
            blueprint.nodes[nodeId] = node;
            editorData.nodes[nodeId] = { position };

            break;
        }

        case UPDATE_NODE: {
            const { nodeId, node, editor = {} } = payload;

            const updatingNodeName = get(blueprint, `nodes["${nodeId}"].name`);
            if (!updatingNodeName) {
                throw new Error(
                    `Trying to update a non-existing node: ${nodeId}`,
                );
            }

            const newId =
                node.name !== updatingNodeName
                    ? findAvailableNodeId(blueprint, node)
                    : nodeId;

            blueprint.nodes[nodeId] = node;
            editorData.nodes[nodeId] = {
                ...editorData.nodes[nodeId],
                ...editor,
            };

            if (newId !== nodeId) {
                blueprint.nodes[newId] = blueprint.nodes[nodeId];
                editorData.nodes[newId] = editorData.nodes[nodeId];
                editorData.simplifiedNodes[newId] =
                    editorData.simplifiedNodes[nodeId];
                delete blueprint.nodes[nodeId];
                delete editorData.nodes[nodeId];
                delete editorData.simplifiedNodes[nodeId];

                blueprint.edges = blueprint.edges.map(({ start, end }) => ({
                    start: {
                        ...start,
                        node: start.node === nodeId ? newId : start.node,
                    },
                    end: {
                        ...end,
                        node: end.node === nodeId ? newId : end.node,
                    },
                }));
            }
            break;
        }

        case MOVE_NODE: {
            const { nodeId, position, viewMode } = payload;

            changeNodePosition(editorData, nodeId, position, viewMode);
            break;
        }

        case MOVE_NODES: {
            const { nodes, viewMode } = payload;

            nodes.forEach((node) => {
                changeNodePosition(
                    editorData,
                    node.id,
                    node.position,
                    viewMode,
                );
            });
            break;
        }

        case REMOVE_NODE: {
            const { nodeId } = payload;
            handleNodeRemoval({ blueprint, editorData, nodeId });
            break;
        }

        case CONNECT_NODES: {
            const { source, sourceHandle, target, targetHandle } = payload;

            const targetNode = blueprint.nodes[target];

            const isDynamicInput =
                processors[targetNode.processor].inputs.dynamic;

            const targetPin = isDynamicInput
                ? findFirstAvailablePin(blueprint.edges, target)
                : targetHandle;

            blueprint.edges = blueprint.edges.filter(
                (edge) =>
                    !(
                        edge.start.node === source &&
                        edge.start.pin === sourceHandle
                    ),
            );
            blueprint.edges.push({
                start: { node: source, pin: sourceHandle },
                end: { node: target, pin: targetPin },
            });
            break;
        }

        case AUTOLAYOUT: {
            const { viewMode } = payload;

            const refinedBlueprint =
                viewMode === VIEW_MODE_ADVANCED
                    ? blueprint
                    : getSimplifiedBlueprint(blueprint, editorData);

            editorData[
                viewMode === VIEW_MODE_ADVANCED ? 'nodes' : 'simplifiedNodes'
            ] = computeNodePositions(refinedBlueprint);
            editorData.lastAutoPositionned = createdAt;

            break;
        }

        case DISCONNECT_NODES: {
            const { source, sourceHandle, target } = payload;

            blueprint.edges = blueprint.edges.filter(
                (edge) =>
                    !(
                        edge.start.node === source &&
                        edge.start.pin === sourceHandle &&
                        edge.end.node === target
                    ),
            );
            break;
        }

        case REORDER_EDGES: {
            const { source, destination, target } = payload;

            const startIndex = blueprint.edges.findIndex(
                (x) =>
                    x.start.pin === source.pin &&
                    x.start.node === source.id &&
                    x.end.node === target,
            );
            const endIndex = blueprint.edges.findIndex(
                (x) =>
                    x.start.pin === destination.pin &&
                    x.start.node === destination.id &&
                    x.end.node === target,
            );
            const [removed] = blueprint.edges.splice(startIndex, 1);
            blueprint.edges.splice(endIndex, 0, removed);
            break;
        }

        default:
            throw new Error(`Unhandled blueprint editor event: ${action}`);
    }
};
export const EMPTY_BLUEPRINT = { nodes: {}, edges: [] };
export const EMPTY_EDITOR_DATA = { nodes: {}, simplifiedNodes: {} };

const sortEventsByCreatedAtAscending = (a, b) => a.createdAt - b.createdAt;

const aggregateBlueprintState = (
    initialBlueprint = EMPTY_BLUEPRINT,
    initialEditorData = EMPTY_EDITOR_DATA,
    events = [],
) => {
    const blueprint = defaultsDeep(
        cloneDeep(initialBlueprint),
        EMPTY_BLUEPRINT,
    );
    const editorData = defaultsDeep(
        cloneDeep(initialEditorData),
        EMPTY_EDITOR_DATA,
    );
    events.sort(sortEventsByCreatedAtAscending);

    for (const event of events) {
        mutateStateForEvent(blueprint, editorData, event);
    }

    return { blueprint, editorData };
};

export default aggregateBlueprintState;
