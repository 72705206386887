import { get } from 'lodash';
import { forwardRef, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import {
    Card,
    CardContent,
    ClickAwayListener,
    makeStyles,
    useTheme,
} from '@material-ui/core';
import { SettingsOverscan } from '@material-ui/icons';

import ReactFlow, {
    Background,
    Controls,
    ControlButton,
    isNode,
    isEdge,
    MiniMap,
    ReactFlowProvider,
} from 'react-flow-renderer';
import { getNodeInputs } from '@tint/core/src/processors/io';

import getModelOutputType from '@tint/core/src/models/getModelOutputType';
import GroupedInputMenu from './GroupedInputMenu';
import ValidationDisplay from './ValidationDisplay';

import {
    blueprintPropType,
    editorDataPropType,
} from './blueprintEditorPropTypes';
import blockTypes, { getNodeColor } from './blocks';

import {
    removeNode,
    moveNode,
    connectNodes,
    moveNodes,
    disconnectNodes,
} from './state/actions';
import {
    VIEW_MODES,
    VIEW_MODE_ADVANCED,
    VIEW_MODE_SIMPLIFIED,
} from './viewModes/viewModes';
import blueprintToElements from './viewModes/blueprintToElements';
import getSimplifiedBlueprint from './viewModes/getSimplifiedBlueprint';
import ContextMenuNodes from './NewBlock/ContextMenuNodes';
import useFullscreenStatus from '../../hooks/useFullscreenStatus';

const useStyles = makeStyles(() => ({
    container: {
        height: ({ height }) => height,
        position: 'relative',
    },
    contextMenu: {
        padding: 0,
        position: 'absolute',
        left: ({ x }) => `${x}px`,
        top: ({ y }) => `${y}px`,
        zIndex: 12,
    },
    contextMenuContent: {
        padding: 0,
        '&:last-child': {
            padding: 0,
        },
    },
}));

const getTargetInputGroups = (blueprint, editorData, target) => {
    if (get(editorData, 'outputNodes', []).includes(target)) {
        return [];
    }

    const node = blueprint.nodes[target];
    const inputs = getNodeInputs(node, false);

    return Array.from(new Set(inputs.map((inp) => inp.group).filter(Boolean)));
};

export const onConnect =
    (dispatchEvent, blueprint, editorData, setConnectingInputGroup) =>
    (connection) => {
        const { target, targetHandle } = connection;
        const inputGroups = getTargetInputGroups(blueprint, editorData, target);

        if (inputGroups.includes(targetHandle)) {
            setConnectingInputGroup(connection);
            return;
        }

        dispatchEvent(connectNodes(connection));
    };

export const onConnectGroupedInput =
    (dispatchEvent, setConnectingInputGroup) => (connection) => {
        dispatchEvent(connectNodes(connection));
        setConnectingInputGroup(null);
    };

export const onNodeDragStop = (dispatchEvent, viewMode) => (evt, node) => {
    dispatchEvent(moveNode(node.id, node.position, viewMode));
};

export const onSelectionDragStop =
    (dispatchEvent, viewMode) => (evt, nodes) => {
        dispatchEvent(moveNodes(nodes, viewMode));
    };

export const onElementsRemove = (dispatchEvent) => (elements) => {
    const nodesToRemove = elements.filter(isNode);
    const edgesToRemove = elements.filter(isEdge);

    nodesToRemove.forEach(({ id }) => {
        dispatchEvent(removeNode(id));
    });
    edgesToRemove.forEach(({ source, sourceHandle, target }) => {
        dispatchEvent(
            disconnectNodes({
                source,
                sourceHandle,
                target,
            }),
        );
    });
};

const onRemove = (viewMode, dispatchEvent) => {
    if (viewMode === VIEW_MODE_SIMPLIFIED) {
        return null;
    }

    return (node) => () => {
        dispatchEvent(removeNode(node.id));
    };
};

const onNodeClick = (onSelect) => (node) => () => onSelect(node.id);

export const onMoveEnd = (onCameraMove) => (position) => {
    onCameraMove({
        x: -position.x / position.zoom,
        y: -position.y / position.zoom,
        zoom: position.zoom,
    });
};

const getNodeEditorDataForViewMode = (editorData, viewMode) => {
    if (viewMode === VIEW_MODE_ADVANCED) {
        return editorData.nodes;
    }

    const { simplifiedNodes = {} } = editorData;

    // We need the `outputType` present only on `nodes`, not `simplifiedNodes`
    return Object.keys(simplifiedNodes).reduce(
        (data, nodeId) => ({
            ...data,
            [nodeId]: {
                ...get(editorData, `nodes.${nodeId}`, {}),
                ...simplifiedNodes[nodeId],
            },
        }),
        {},
    );
};

const BlueprintEditor = forwardRef(function BlueprintEditor(
    {
        disabled,
        height,
        blueprint,
        editorData,
        onSelect,
        trace,
        minimap,
        background,
        disableNodesDraggable,
        viewMode,
        dispatchEvent,
        cameraPosition,
        onCameraMove,
        connectingInputGroup,
        setConnectingInputGroup,
        errors,
        isValidConnection,
        displayValidation,
        onOpenProcessorMenu,
        onViewDetails,
        shouldFitView,
        onViewFitted,
    },
    ref,
) {
    const instanceRef = useRef();
    const theme = useTheme();
    const [expandValidation, setExpandValidation] = useState(false);
    const toggleValidation = () => setExpandValidation(!expandValidation);

    const [connecting, setConnecting] = useState(null);
    const [contextMenu, setContextMenu] = useState({
        enabled: false,
        x: 0,
        y: 0,
    });
    const onConnectStart = (evt, connection) => setConnecting(connection);
    const onConnectStop = () => setConnecting(null);

    const classes = useStyles({
        height,
        x: contextMenu.x,
        y: contextMenu.y,
    });

    const cameraMoveEnabled = !connectingInputGroup;
    const interactionsEnabled =
        cameraMoveEnabled && !disabled && viewMode === 'advanced';
    const nodesDraggable = cameraMoveEnabled && !disableNodesDraggable;

    const {
        isFullscreen,
        setFullscreen,
        shouldFitViewFullScreen,
        setShouldFitViewFullScreen,
    } = useFullscreenStatus(ref);

    const [lastFitDate, setLastFitDate] = useState(null);

    const fitView = () => {
        if (!instanceRef.current) {
            return;
        }

        instanceRef.current.fitView();
        setLastFitDate(new Date());
        onViewFitted();
        setShouldFitViewFullScreen(false);
    };

    const onLoad = (instance) => {
        instanceRef.current = instance;
        fitView();
    };

    useEffect(() => {
        if (editorData.lastAutoPositionned > lastFitDate) {
            fitView();
        }
    }, [editorData]);

    useEffect(() => {
        fitView();
    }, [viewMode]);

    useEffect(() => {
        if (shouldFitView || shouldFitViewFullScreen) {
            fitView();
        }
    }, [shouldFitView, shouldFitViewFullScreen]);

    const onCancelGroupedInput = () => setConnectingInputGroup(null);

    const onContextMenu = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const { left, top } = ref.current.getBoundingClientRect();
        setContextMenu({
            enabled: true,
            x: e.clientX - left,
            y: e.clientY - top,
        });
    };

    const interactionProps = interactionsEnabled
        ? {
              onConnect: onConnect(
                  dispatchEvent,
                  blueprint,
                  editorData,
                  setConnectingInputGroup,
              ),
              onConnectStart,
              onConnectStop,
              onContextMenu,
              onElementsRemove: onElementsRemove(dispatchEvent),
              nodesConnectable: true,
          }
        : {
              nodesConnectable: false,
          };

    const movementProps = nodesDraggable
        ? {
              onNodeDragStop: onNodeDragStop(dispatchEvent, viewMode),
              onSelectionDragStop: onSelectionDragStop(dispatchEvent, viewMode),
          }
        : {};

    const refinedBlueprint =
        viewMode === VIEW_MODE_ADVANCED
            ? blueprint
            : getSimplifiedBlueprint(blueprint, editorData);
    const { outputNodes } = editorData;

    const closeContextMenu = () => setContextMenu({ enabled: false });

    const modelOutputType = getModelOutputType(blueprint);

    return (
        <div
            className={classes.container}
            role="application"
            ref={ref}
            style={{ backgroundColor: isFullscreen ? 'white' : null }}
        >
            <ReactFlowProvider key={viewMode}>
                <ReactFlow
                    onLoad={onLoad}
                    maxZoom={1.5}
                    elements={blueprintToElements({
                        disabled,
                        blueprint: refinedBlueprint,
                        outputNodes,
                        nodesEditorData: getNodeEditorDataForViewMode(
                            editorData,
                            viewMode,
                        ),
                        onNodeClick: onNodeClick(onSelect),
                        onRemove: onRemove(viewMode, dispatchEvent),
                        viewMode,
                        trace,
                        errors: expandValidation ? errors : [],
                        connecting,
                        isValidConnection,
                        onViewDetails,
                    })}
                    defaultZoom={get(cameraPosition, 'zoom')}
                    style={{ minHeight: 400 }}
                    nodeTypes={blockTypes}
                    paneMoveable={cameraMoveEnabled}
                    zoomOnDoubleClick={cameraMoveEnabled}
                    zoomOnScroll={cameraMoveEnabled}
                    nodesDraggable={nodesDraggable}
                    connectionLineStyle={{ zIndex: 10 }}
                    onMoveEnd={onMoveEnd(onCameraMove)}
                    {...interactionProps}
                    {...movementProps}
                >
                    {background && <Background variant="dots" />}
                    <Controls showFitView showInteractive={interactionsEnabled}>
                        {!isFullscreen && (
                            <ControlButton
                                onClick={setFullscreen}
                                className="react-flow__controls-fullscreen"
                            >
                                <SettingsOverscan
                                    style={{
                                        maxWidth: 20,
                                        maxHeight: 20,
                                        width: 20,
                                        height: 20,
                                        fill: 'black',
                                    }}
                                />
                            </ControlButton>
                        )}
                    </Controls>
                    {minimap && <MiniMap nodeColor={getNodeColor(theme)} />}
                    <GroupedInputMenu
                        blueprint={blueprint}
                        onConnect={onConnectGroupedInput(
                            dispatchEvent,
                            setConnectingInputGroup,
                        )}
                        onCancel={onCancelGroupedInput}
                        connectingInputGroup={connectingInputGroup}
                        container={ref.current}
                        isValidConnection={isValidConnection}
                    />
                    {!disabled &&
                        viewMode === VIEW_MODE_ADVANCED &&
                        displayValidation && (
                            <ValidationDisplay
                                expanded={expandValidation}
                                onChange={toggleValidation}
                                blueprint={blueprint}
                                errors={errors}
                                onSelect={onSelect}
                            />
                        )}
                </ReactFlow>
            </ReactFlowProvider>
            {contextMenu.enabled && (
                <ClickAwayListener onClickAway={closeContextMenu}>
                    <Card className={classes.contextMenu} role="menu">
                        <CardContent className={classes.contextMenuContent}>
                            <ContextMenuNodes
                                project={
                                    instanceRef.current
                                        ? instanceRef.current.project
                                        : null
                                }
                                blueprint={blueprint}
                                editorData={editorData}
                                modelOutputType={modelOutputType}
                                onOpenProcessorMenu={onOpenProcessorMenu}
                                dispatchEvent={dispatchEvent}
                                cameraPosition={cameraPosition}
                                onSelect={onSelect}
                                onClick={closeContextMenu}
                                position={contextMenu}
                            />
                        </CardContent>
                    </Card>
                </ClickAwayListener>
            )}
        </div>
    );
});

BlueprintEditor.propTypes = {
    height: PropTypes.any,
    blueprint: PropTypes.shape(blueprintPropType).isRequired,
    editorData: PropTypes.shape(editorDataPropType).isRequired,
    disabled: PropTypes.bool,
    trace: PropTypes.object,
    minimap: PropTypes.bool,
    background: PropTypes.bool,
    disableNodesDraggable: PropTypes.bool,
    onSelect: PropTypes.func,
    onOpenProcessorMenu: PropTypes.func,
    viewMode: PropTypes.oneOf(VIEW_MODES),
    dispatchEvent: PropTypes.func.isRequired,
    cameraPosition: PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired,
        zoom: PropTypes.number.isRequired,
    }).isRequired,
    onCameraMove: PropTypes.func.isRequired,
    connectingInputGroup: PropTypes.shape({
        source: PropTypes.string.isRequired,
        sourceHandle: PropTypes.string.isRequired,
        target: PropTypes.string.isRequired,
        targetHandle: PropTypes.string.isRequired,
    }),
    setConnectingInputGroup: PropTypes.func,
    errors: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            message: PropTypes.string.isRequired,
        }),
    ),
    isValidConnection: PropTypes.func,
    displayValidation: PropTypes.bool,
    onViewDetails: PropTypes.func,
    shouldFitView: PropTypes.bool,
    onViewFitted: PropTypes.func,
};

BlueprintEditor.defaultProps = {
    height: '100%',
    disabled: false,
    trace: null,
    minimap: true,
    background: true,
    disableNodesDraggable: false,
    onSelect: () => {},
    onOpenProcessorMenu: () => {},
    viewMode: VIEW_MODE_ADVANCED,
    connectingInputGroup: null,
    setConnectingInputGroup: () => {},
    errors: [],
    isValidConnection: () => true,
    displayValidation: true,
    onViewDetails: null,
    shouldFitView: false,
    onViewFitted: () => {},
};

export default BlueprintEditor;
