import validator from './validate';

const decisionProcessor = {
    name: 'Decision',
    slug: 'decision',
    description: 'Output Processor for Decision Models',
    validate: validator,
    inputs: [] as never[],
    outputs: [
        {
            id: 'output',
            name: 'Output',
            schema: { type: 'string' },
        },
    ],
};

export default decisionProcessor;
