import PropTypes from 'prop-types';

import { useTheme } from '@material-ui/core';

import { FeatureIcon as FeatureSvgIcon } from '../../../icons';

import blockPropType from './blockPropTypes';

import BaseBlock from './BaseBlock';
import DefaultPins from './DefaultPins';

export const getFeatureBlockColor = (theme) => theme.palette.secondaryBlue;

export const FeatureIcon = ({ withErrors }) => {
    const theme = useTheme();

    return (
        <FeatureSvgIcon
            alt="Feature"
            title="Feature"
            color={
                withErrors ? theme.palette.mainRed : theme.palette.secondaryBlue
            }
        />
    );
};

FeatureIcon.propTypes = { withErrors: PropTypes.bool };
FeatureIcon.defaultProps = { withErrors: false };

const FeatureBlock = (props) => {
    const theme = useTheme();
    const color = getFeatureBlockColor(theme);

    return (
        <BaseBlock
            {...props}
            disabled={props.data.disabled}
            color={color}
            icon={<FeatureIcon withErrors={props.data.errors.length > 0} />}
            pins={
                <DefaultPins
                    {...props}
                    nodeId={props.id}
                    color={color}
                    connecting={props.data.connecting}
                    isValidConnection={props.data.isValidConnection}
                />
            }
        />
    );
};

FeatureBlock.propTypes = blockPropType;

export default FeatureBlock;
