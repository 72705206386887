import { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';

import {
    makeStyles,
    Grid,
    InputAdornment,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@material-ui/core';
import Button from '@material-ui/core/Button/Button';

import { FilterIcon } from '../icons';
import TextInput from './TextInput';

const useStyles = makeStyles((theme) => ({
    filterIcon: {
        fill: ({ hasFilters }) =>
            hasFilters ? theme.palette.primary.main : theme.palette.lighterGray,
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-between',
    },
}));

const FilterInput = ({ label, filters, fields, title, onChange }) => {
    const classes = useStyles({
        hasFilters: Object.values(filters).filter((x) => x).length > 0,
    });

    const [modalOpened, setModalOpened] = useState(false);

    const clearFilters = () => {
        setModalOpened(false);
        onChange({});
    };

    const saveFilters = (newFilters) => {
        setModalOpened(false);
        onChange(newFilters);
    };

    const onClose = () => setModalOpened(false);

    return (
        <>
            <TextInput
                label={label}
                fullWidth
                variant="standard"
                meta={{}}
                input={{
                    value: Object.values(filters).join(', '),
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <FilterIcon className={classes.filterIcon} />
                        </InputAdornment>
                    ),
                }}
                onClick={() => setModalOpened(true)}
            />
            <Dialog
                open={modalOpened}
                onClose={onClose}
                PaperProps={{ elevation: 2 }}
            >
                <DialogTitle>{title}</DialogTitle>
                <Form
                    onSubmit={saveFilters}
                    initialValues={filters}
                    render={({ handleSubmit }) => (
                        <>
                            <DialogContent>
                                <Grid container spacing={2}>
                                    {fields.map((field) => (
                                        <Grid
                                            item
                                            key={field.props.name}
                                            sm={12}
                                        >
                                            {field}
                                        </Grid>
                                    ))}
                                </Grid>
                            </DialogContent>
                            <DialogActions classes={{ root: classes.actions }}>
                                <Button variant="text" onClick={clearFilters}>
                                    Clear
                                </Button>
                                <Button
                                    onClick={handleSubmit}
                                    variant="outlined"
                                    color="primary"
                                >
                                    Apply
                                </Button>
                            </DialogActions>
                        </>
                    )}
                />
            </Dialog>
        </>
    );
};

FilterInput.propTypes = {
    fields: PropTypes.arrayOf(PropTypes.element).isRequired,
    label: PropTypes.string.isRequired,
    filters: PropTypes.object,
    title: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

FilterInput.defaultProps = {
    filters: {},
};

export default FilterInput;
