/* eslint-disable react/prop-types */
import { validate as validateUuid } from 'uuid';
import { useState } from 'react';
import PropTypes from 'prop-types';
import {
    useInput,
    FieldTitle,
    ResettableTextField,
    InputHelperText,
} from 'react-admin';

const UuidInput = ({
    label,
    format,
    helperText,
    onBlur,
    onFocus,
    onChange,
    options,
    parse,
    resource,
    source,
    validate,
    value: initialValue,
    ...rest
}) => {
    const {
        id,
        input,
        isRequired,
        meta: { error, touched },
    } = useInput({
        format,
        onBlur,
        onChange,
        onFocus,
        parse,
        resource,
        source,
        type: 'text',
        validate,
        value: initialValue,
        ...rest,
    });

    const [value, setValue] = useState(initialValue);
    const [uuidError, setUuidError] = useState();

    const handleChange = (evt) => {
        const { value: newValue } = evt.target;
        setValue(newValue);

        if (validateUuid(newValue)) {
            input.onChange(evt);
        } else {
            setUuidError(`"${newValue}" is not a valid UUID`);
        }
    };

    return (
        <ResettableTextField
            id={id}
            {...input}
            onChange={handleChange}
            value={value}
            label={
                label !== '' &&
                label !== false && (
                    <FieldTitle
                        label={label}
                        source={source}
                        resource={resource}
                        isRequired={isRequired}
                    />
                )
            }
            error={!!(touched && error)}
            helperText={
                <InputHelperText
                    touched={touched}
                    error={uuidError || error}
                    helperText={helperText}
                />
            }
            {...options}
            {...rest}
        />
    );
};

UuidInput.propTypes = {
    className: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    options: PropTypes.object,
    resource: PropTypes.string,
    source: PropTypes.string,
};

UuidInput.defaultProps = {
    options: {},
};

export default UuidInput;
