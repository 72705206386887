import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { PaginationCaret } from '../icons';

const styles = (theme) => ({
    root: {
        '& a': {
            textDecoration: 'none',
            color: theme.palette.primary.main,
            '&:hover': {
                textDecoration: 'underline',
            },
        },
    },
    caret: {
        position: 'relative',
        top: 2,
        marginRight: theme.spacing(3),
        width: 6,
    },
});

// eslint-disable-next-line jsx-a11y/anchor-has-content
const defaultRenderLink = (props) => <a {...props} />;

export const BackLink = ({ classes, href, label, renderLink }) => (
    <div className={classes.root}>
        {renderLink({
            href,
            children: (
                <>
                    <PaginationCaret className={classes.caret} />
                    {label}
                </>
            ),
        })}
    </div>
);

BackLink.propTypes = {
    classes: PropTypes.object.isRequired,
    href: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    renderLink: PropTypes.func,
};

BackLink.defaultProps = {
    renderLink: defaultRenderLink,
};

export default withStyles(styles)(BackLink);
